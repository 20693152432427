import { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { notifications } from "../../api";
import Spinner from "react-bootstrap/Spinner";
import NTitle from "./NTile";
import { useLoadItems } from "../../hooks/useLoadItems";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";


export default function NList() {
    const [page, setPage] = useState(0);
    const { t }= useTranslation();
    const {updateUserData}  = useAuth();
    const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: notifications.getNotificationList,
      page
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading,
      hasNextPage,
      onLoadMore: () => setPage(page + 1),
      disabled: !!error,
      rootMargin: "0px 0px 400px 0px",
    });

    // useEffect(() => {
    //   notifications.bulkRead().then((res:any) => {
    //     updateUserData({ totalNotificationsCount: 0 });

    //   });
    // }, [])

    return (
      <div className="notification-content" ref={rootRef}>
        <h3>{t('recentNotification')}</h3>
        <div className="scroll-notification">
          {
            !hasNextPage && items.length === 0  ? 
              <div className="dashboard-no-data">
                <p>{t("NoResultFound")}</p>
              </div> 
            : 
            null
          }
          {items.map((detail:any, key:any) => (
              <NTitle
                  key={detail?._id}
                  detail={detail}
                  state={key}
              />
          ))}

          
        </div>
        {hasNextPage && (
            <div ref={sentryRef} className="spinner-wrap">
              <div className="">
                <Spinner />
              </div>
            </div>
        )}
      </div>
    );
}
