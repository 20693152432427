export const ENDPOINTS = {
  GET_TEAM_SAMPLE_DATA: "v1/user/download-members-sample",
  UPLOAD_BULK_TEAM_DATA: "v1/user/upload-file-members",
  GET_CATEGORY_SAMPLE_DATA: "v1/category/download-sample-file",
  UPLOAD_BULK_CATEGORY_DATA: "v1/category/upload-file",
  GET_PRODUCT_SAMPLE_DATA: "v1/product/download-sample-file",
  UPLOAD_BULK_PRODUCT_DATA: "v1/product/upload-file",
  GET_QUESTION_SAMPLE_DATA: "v1/question/download-sample-file",
  UPLOAD_BULK_QUESTION_DATA: "v1/question/upload-file",
};
