import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { AddTeamMember, UploadTeamMembers } from "../../../components";

export default function CreateTeam() {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
            <div className="mid_outer_content">
              <div className="heading-common">
                <h1>
                  {t('teamCreation')}
                </h1>
                <p>{t('teamCreationSubTitile')}</p>
              </div>
              <div className="create-team-wrapper">
                <Col md={12} xl={6} className="create-team-grid">
                  <div className="teamcreateheading">
                    <h2>{t('createAMember')}</h2>
                    {/* <p>{t('teamCreationSubTitile')}</p> */}
                  </div>
                  <div className="form_inner_wrapper">
                    <AddTeamMember />
                  </div>
                </Col>
                <Col md={12} xl={6} className="create-team-grid">
                  <div className="teamcreateheading">
                    <h2>{t('uploadAfile')}</h2>
                    {/* <p>{t('teamCreationSubTitile')}</p> */}
                  </div>
                  <div className="form_inner_wrapper">
                    <UploadTeamMembers />
                  </div>
                </Col>
              </div>
            </div>
        {/* </section> */}
      </Row>
    </>
  );
}
