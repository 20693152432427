import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import { useTranslation } from "react-i18next";
import iconInfo from "../../assets/images/iconInfo.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import iconError from "../../assets/images/iconError.svg";
import iconRemove from "../../assets/images/iconRemoveRed.svg";
import iconCalls from "../../assets/images/calls.svg";
import iconUpsell from "../../assets/images/upsell.svg";
import iconCards from "../../assets/images/cards.svg";
import thumbsUp from "../../assets/images/thumbs-up.png";

import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useEffect, useState } from "react";
import { customerInteraction } from "../../api";
import { useAuth } from "../../hooks/useAuth";

const ICON = {
  success: iconSuccess,
  error: iconError,
  info: iconInfo,
  warning: iconRemove,
};

export default function CustomerInteractionFinishModal({
  heading,
  body,
  footer,
  show,
  handleClose,
  type = "success",
  buttonMain = "Got it",
}: MessageModalType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [stats, setStats] = useState<any>();
  const width ='125px';
  const height ='125px';

  function closeDialog() {
    handleClose();
    navigate(ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION);
  }

  function addQuestion() {
    navigate(ROUTE_NAVIGATION_PATH.ADD_QUESTION);
  }

  useEffect(() => {
    if(show) {
      customerInteraction.customerCallStats(auth.user.id).then((res: any) => {
        setStats(res.data);
      });
    }
    
  }, [show]);

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      dialogClassName={"model-common _confirmation-common _nice-work-dialog"}
    >
      <Modal.Header>
        <div className="_icon-img-custom">
              <img src={thumbsUp} alt="info"/>
        </div>
        <Modal.Title>Nice work <span>🎉</span> </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
        <div className="gridnice-dialog">
          <div className="grid">
            <div className="txt">
              <img src={iconCalls} alt="icon" />
              {t("Calls")}
            </div>
            <span className="numbers">{stats ? stats.calls : 0}</span>
            <div className="progress-content">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={60}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ maxWidth: "60%" }}
              >
                <span className="title"></span>
                
              </div>
            </div>
            </div>
          </div>
          <div className="grid">
            <div className="txt">
              <img src={iconUpsell} alt="icon" />
              {t("Upsells")}
            </div>
            <span className="numbers">{stats ? stats.upsells : 0}</span>
            <div className="progress-content">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={60}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ maxWidth: "60%" }}
              >
                <span className="title"></span>
              </div>
            </div>
            </div>
          </div>
          <div className="grid">
            <div className="txt">
              <img src={iconCards} alt="icon" />
              {t("Cards")}
            </div>
            <span className="numbers">{stats ? stats.cards : 0}</span>
            <div className="progress-content">
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow={60}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ maxWidth: "60%" }}
              >
                <span className="title"></span>
              </div>
            </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {footer}
        {buttonMain == "goToSuggestion" ? (
          <Button variant="primary" onClick={addQuestion}>
            {t(buttonMain)}
            
          </Button>
        ) : (
          <Button variant="primary" onClick={closeDialog}>
            {t(buttonMain)}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
