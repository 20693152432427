import { useEffect } from "react";
import {
  composeValidators,
  required,
  maxlength,
} from "../../validations";
import { FormControl } from "../FormElements/FormControl";
import {
  VALIDATIONS
} from "../../constants";
import profileImg from "../../assets/images/icon-profile.png";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import useUploadImage from "../../hooks/useUploadImage";
import { Any } from "../../types/global.type";
import { TagsInput } from "./TagsInput";

export default function AddEditProduct({
  updateImage,
  isEditPhoto = false,
  editForm,
  photo = "",
  onlyPhoto = false,
  addPopup = false,
  tags = []
}: {
  updateImage: (file: File | null | undefined) => void;
  isEditPhoto?: boolean;
  editForm?: boolean;
  photo?: string;
  onlyPhoto?: boolean;
  addPopup?: boolean;
  tags?: any;
}) {
  const { t } = useTranslation();
  const { onImageChange, image, file, errorMsg, setImage } = useUploadImage({
    photo: editForm ? photo : "",
  });

  useEffect(() => {
    updateImage(file);
  }, [file]);

  const removeImage = () => {
    setImage("");
    updateImage(null);
  };
  return (
    <>
        <div className="category-profilewrpper">
            <div className="profileImage">
              {image ? (
                <img
                  src={image}
                  onError={(e: Any) => {
                    e.target.src = profileImg;
                  }}
                  alt="ProfileImg"
                />
              ) : null}
               <div className="file-input">
                        <span>{isEditPhoto ? t("EditPhoto") : t("AddPhoto")}</span>
                        <input
                          className="form-control"
                          type="file"
                          onChange={onImageChange}
                        />
                      </div>
            </div>
           
            {errorMsg ? (
                <div className="max-img-size-error">{errorMsg}</div>
            ) : null}
            <div className="profileInfo">
              <div>
                  <h2>{editForm ? t('EditAPhoto') : t('AddAPhoto')}</h2>
                  {/* <div className="subheading">
                      {editForm ? t("EditAPhotoForThe") : t("AddAPhotoForThe")} <span>{t("Product")}</span> 
                  </div> */}
                  <p><span>{t("Drag&Drop")}</span> {t("ToAddPhotos")}</p>
                  <div className="btn-wrapper _deletebtnleft">
                      {/* <div className="btn-primary">
                        <span>{isEditPhoto ? t("EditPhoto") : t("AddPhoto")}</span>
                        <input
                          className="form-control"
                          type="file"
                          onChange={onImageChange}
                        />
                      </div> */}
                      <Button variant="primary cancel" onClick={removeImage}>
                          {t("DeletePhoto")}
                      </Button>
                  </div>
              </div>
            </div>
        </div>

        {isEditPhoto ? <div className="edithd">{t('editProductHeader')}</div> : null}
        {addPopup ? <div className="edithd">{t('addProductHeader')}</div> : null}
        <div className="form-category _product">
                <div className="signupfield _tagheight">
                    <FormControl
                        name="name"
                        label={t("Product name") as string}
                        type="Name"
                        validate={composeValidators(
                            required,
                            maxlength(VALIDATIONS.MAX_NAME)
                        )}
                    />
                    <TagsInput
                      label="Tags"
                      name="tags"
                      // validate={composeValidators(
                      //   required
                      // )}
                      tags={tags}
                      
                    />
                    <FormControl
                        label={t("Description") as string}
                        name="description"
                        type="textarea"
                        validate={composeValidators(
                            required,
                            maxlength(VALIDATIONS.MAX_DESCRIPTION)
                        )}
                    />
                    <FormControl
                        label={t("Conditions") as string}
                        name="conditions"
                        type="textarea"
                        validate={composeValidators(
                            maxlength(VALIDATIONS.MAX_DESCRIPTION)
                        )}
                    />
                </div>
        </div>
    </>
  );
}
