import Button from "react-bootstrap/Button";
import profileIcon from "../../../assets/images/iconprofile.png";
import { Member } from "../../../types/User";
import { useState } from "react";
import { team } from "../../../api";
import Spinner from "react-bootstrap/Spinner";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { ROLE } from "../../../constants";
import { useTranslation } from "react-i18next";

export default function NonActiveTeamMemberTile({ user }: { user: Member }) {
  const [loader, setLoader] = useState(false);
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  let userInfo = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : null;

  const inviteMember = () => {
    setLoader(true);
    team
      .inviteMember(user._id)
      .then((res) => {
        setLoader(false);
        showMessage({
          heading: t("success"),
          body: <p>{t('InvitationSent')}</p>,
          type: "success",
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoader(false);
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <div className="active-list-grid">
      <div className="_profile-img">
        <img
          src={user?.image ? user?.image : profileIcon}
          onError={(e: any) => {
            e.target.src = profileIcon;
          }}
          alt="ProfileImg"
        />
      </div>
      <div className="name" title={user.name + ' ' + user.surname}>
        {user.name} {user.surname}
      </div>
      <div className="designation">{user.position}</div>
      { userInfo.role === ROLE.TEAM_LEADER && user ? 
        <Button variant="secondary" onClick={inviteMember} disabled={loader}>
         {loader ? <Spinner /> : null} {t("ResendInvitation")}
        </Button>
        :
        null
      }
     
    </div>
  );
}
