import { api } from "../api";
import { Any } from "../../types/global.type";

export const ENDPOINTS = {
    PATH_LIST: "v1/path/list",
    CREATE_PATH: "v1/path/add",
    DELETE_PATH: "/v1/path/delete",
    EDIT_PATH: "/v1/path/edit",
    GET_PATH: "/v1/path/getPathById"
};

async function createPath(data: Any) {
    return api.post(ENDPOINTS.CREATE_PATH, data).then((res) => res.data);
}

async function allPathList(data: Record<string, any>) {
    return api.get(ENDPOINTS.PATH_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.paths,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function editPath(data:Any, id:Any) {
    delete data.id;
    return api
    .put(ENDPOINTS.EDIT_PATH + '/' + id, data)
    .then((res) => res.data);
}

async function deletePath(pathId:string) {
    return api
    .delete(ENDPOINTS.DELETE_PATH + '/' + pathId)
    .then((res) => res.data);
}

async function getPathById(pathId:string) {
    return api
    .get(ENDPOINTS.GET_PATH + '/' + pathId)
    .then((res) => res.data);
}

export {
    createPath,
    allPathList,
    editPath,
    deletePath,
    getPathById
};
