import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";

import { admin } from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { AddEditInvoice } from "..";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function CreateInvoice({
    detail,
    handleClose
}: {
    detail: boolean,
    handleClose: VoidFunction;
}) {
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  const params = useParams();
  const onSubmit = (values: any) => {
    const data = {
        planStartDate: new Date(values.planStartDate),
        planEndDate: new Date(values.planEndDate),
        permittedMembers: values.permittedMembers,
        invoiceAmount: values.invoiceAmount,
        teamLeaderId: params.teamLeaderId,
        addedMembers: 0,
        invoiceDate: values.invoiceDate
    };
    admin
      .createInvoice(data)
      .then((res: any) => {
        window.location.reload();
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        handleClose();
        showMessage({
          heading: t("error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={!!detail}
      onHide={handleClose}
      dialogClassName={"model-common"}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{t('InvoiceDetails')}</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body className="_visible">
              <AddEditInvoice values={values}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary full" type="submit">
                {t('save')}
              </Button>
              <Button variant="cancel full" onClick={handleClose}>
                {t('cancel')}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}
