import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Any } from "../../types/global.type";
import { Form } from "react-final-form";
import { FormControl } from "..";
import {
  composeValidators,
  maxlength,
  required,
  validEmail,
} from "../../validations";
import Iconcategory from "../../assets/images/dummy-img.png";
import iconStar from "../../assets/images/starIcon.svg";
import iconAlert from "../../assets/images/warning.png";

import salesIcon from "../../assets/images/clerk.png";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { VALIDATIONS } from "../../constants";
import { useCustomMutation } from "../../hooks/useApi";
import { customerInteraction, question } from "../../api";
import { useMessageModal } from "../../hooks/useMessage";
import useCustomInteraction from "../../hooks/useCustomerInteraction";

export default function CallStartModal({
  show,
  detail,
  handleClose,
  close,
}: {
  show: boolean;
  detail: any;
  handleClose: VoidFunction;
  close: VoidFunction;
}) {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  const [mainContinue, setMainContinue] = useState(false);
  const [selectedProductDetail, setselectedProductDetail] = useState<any>();
  const [upsellIndex, setUpsellIndex] = useState(0);
  const auth = useAuth();
  const [upsellTimer, setUpsellTimer] = useState("00:00");
  var sec = 0o0;
  var minutes = 0o0;
  const [interval, setInterval2] = useState();
  var intervalId: Any;
  var timeSecond: Any;
  var timerMinutes: Any; 
  
  const startTimer = (e:any) => {
    sec++;
    if (sec <= 9) {
        timeSecond = "0" + sec;
    }

    if (sec > 9) {
        timeSecond = sec;
    }

    if (sec > 59) {
        minutes++;
        timerMinutes = "0" + minutes;
        sec = 0;
        timeSecond = "0" + 0;
    }
    if (minutes > 9) {
        timerMinutes = minutes;
    }
    setUpsellTimer((timerMinutes ? timerMinutes : "00") + ':' + timeSecond);
  };

  const onClickReset = (id?: Any) => {
    clearInterval(interval);
    setUpsellTimer("00:00");
  };


  function start() {
    if (intervalId) {
      clearInterval(intervalId);
    }
    
    intervalId = setInterval(startTimer, 1000);
    setInterval2(intervalId);
  }
 
  const { mutate } = useCustomMutation({
    mutationFn: customerInteraction.customerInfoForUpsell,
    onSuccess: (res: any) => {
      setMainContinue(false);
      handleClose();
      showMessage({
        heading: t("salesEmailSent"),
        body: <p>{t("salesEmailSentMessage")}</p>,
        type: "success",
      });
    },
  });

  useEffect(() => {
    console.log(detail);
    if (detail?.question.upsell && detail?.question.upsell.length > 0) {
      detail.question.upsell.forEach((val: any) => {
        val.selected = false;
      });
      detail.question.upsell[0].selected = true;
    }
  }, [detail]);

  useEffect(() => {
    if(show) {
      start();
    }
  }, [show])

  function onSubmit(values: Any) {
    values["upsellId"] = selectedProductDetail?._id;
    // values["callId"] = callUpdate._id;
    mutate(values);
  }

  function continueToDetail() {
    setselectedProductDetail(detail.upsell[upsellIndex]);
    setMainContinue(true);
  }

  function updateCategory(id: string, index: any) {
    detail.question.upsell.forEach((val: any) => {
      val.selected = false;
    });
    detail.question.upsell[index].selected = true;
    setUpsellIndex(index);
  }

  function tags(value: any) {
    if (value.length > 0) {
      value.forEach(function (elem: any, idx: any, array: any) {
        if (array.length > 1 && idx === array.length - 1) {
          if (!elem.includes("and")) {
            value[idx] = " and " + elem;
          }
        }
      });

      let final = value.join(", ");
      const after_ = final.split("and")[1];
      const lastElement = final.substring(0, final.indexOf("and"));
      if (after_) {
        return lastElement.replace(/,(?=[^,]*$)/, "") + "and " + after_;
      } else {
        return final;
      }
    }
  }

  function handleCloseClick() {
    onClickReset()
    setMainContinue(false);
    handleClose();
  }

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }

  function cancel() {
    onClickReset();
    close();
  }

  return (
    <Modal
      show={show}
      //onHide={handleClose}
      backdrop="static"
      dialogClassName={"team-memeber-model upsell-detail-dialog"}
      keyboard={false}
    >
      {
        detail?.question.upsell && detail?.question.upsell.length == 0 ?
        <div className="customer-q-dialog-content">

          <div className="bottomgrid">
            <div className="paygrid-common _customer-inter-list">
              <div
                className="payment-info">
                <div className="paytxt">
                  <div className="category">{t("Category")}</div>
                  <div className="payment">
                    {detail?.question.category ? questionArray(detail.question.category) : ""}
                  </div>
                </div>
                <div className="timetxt">
                  <div className="category">{t("avgTime")}</div>
                  <div className="time">{upsellTimer}</div>
                </div>
                {
                   detail?.question.time < upsellTimer
                  ?
                  <div className="time-exceed">
                    <div className="alerticon"><img src={iconAlert} alt="Alert" /></div> <p>{t('averageTimeExceed')}</p>
                  </div>
                :
                  null
                }
              </div>
              <div className="heading-info _description">
                <h3>
                  {detail?.question.question}
                </h3>
                <p>
                  {detail?.question.answer}
                </p>
                <div className="btn-info">
                  <button className="btn-continues" onClick={handleClose}>
                    {t("Start")}
                  </button>
                  <button className="btn-continues cancel" onClick={cancel}>
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <>
          <div className="bottom-pay-wrapper">
            <div className="bottomgrid">
              <div className="top-circle">
                <img src={iconStar} alt="" />
              </div>
              <div className="line-with-circle">
                <span></span>
              </div>
              <div className="paygrid-common _customer-inter-list">
                <div className="payment-info">
                  <div className="paytxt">
                    <div className="category">{t("Category")}</div>
                    <div className="payment">
                      {detail?.question.category ? questionArray(detail.question.category) : ""}
                    </div>
                  </div>
                  <div className="timetxt">
                    <div className="category">{t("avgTime")}</div>
                    <div className="time">{upsellTimer}</div>
                  </div>
                  {
                    detail?.question.time < upsellTimer
                    ?
                    <div className="time-exceed">
                      <div className="alerticon"><img src={iconAlert} alt="Alert" /></div> <p>{t('averageTimeExceed')}</p> 
                    </div>
                    :
                    null
                  }
                </div>
                <div className="heading-info _description">
                  <h3>{detail?.question.question}</h3>
                  <p>{detail?.question.answer}</p>
                  <div className="btn-info">
                    <button className="btn-continues" onClick={handleClose}>
                      {t("Start")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!mainContinue ? (
            <div className="content-info">
              <div className="midupsells-wp">
                <div className="upsells-desc">
                  <div className="hadingtxt">{t("Question")}</div>
                  <p>{detail?.question.question}</p>
                </div>
                <div className="upsells-desc _lastchld">
                  <div className="hadingtxt">
                    {t("Answer")} <span></span>
                  </div>
                  <p>{detail?.question.answer}</p>
                </div>
                <div className="upsells-listing">
                  {detail?.question.upsell ? (
                    <>
                      {detail?.question.upsell.map((data: any, key: any) => (
                        <>
                          <div className="item-details">
                            <div
                              onClick={() => updateCategory(data._id, key)}
                              className={
                                data.selected ? "_items active" : "_items"
                              }
                              key={data._id}
                            >
                              <h4>{data?.name}</h4>
                              <div className="_image">
                                <img
                                  src={data?.image ? data?.image : Iconcategory}
                                  onError={(e: any) => {
                                    e.target.src = Iconcategory;
                                  }}
                                  alt="ProfileImg"
                                />
                              </div>
                            </div>
                            <div className="desc-info">
                              <span>{t("Description")}:</span> {data?.description}
                            </div>
                            {data?.tags ? (
                              <div className="tagstxt">
                                <span> {t("Tag")} :</span>{" "}
                                {data?.finaltags
                                  ? data?.finaltags
                                  : tags(data?.tags)}
                              </div>
                            ) : null}

                            {data?.conditions ? (
                              <div className="tagstxt">
                                <span>{t("Conditions")}:</span> {data?.conditions}
                              </div>
                            ) : null}
                          </div>
                        </>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
              <Modal.Footer>
                {/* {detail?.question.upsell.length > 0 ? (
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleClose}
                  >
                    {t("Start")}
                  </Button>
                ) : null} */}
                <Button variant="cancel" type="button" onClick={cancel}>
                  {t("Cancel")}
                </Button>
              </Modal.Footer>
            </div>
          ) : (
            <div className="content-info">
              <div className="image-with-content">
                <div className="_image">
                  <div className="_image">
                    <img
                      src={
                        selectedProductDetail?.image
                          ? selectedProductDetail?.image
                          : Iconcategory
                      }
                      onError={(e: any) => {
                        e.target.src = Iconcategory;
                      }}
                      alt="ProfileImg"
                    />
                  </div>
                </div>
                <div className="_content-info">
                  <div className="hadingtxt">
                    {t("Product")} : <span> {selectedProductDetail?.name}</span>
                  </div>
                  <p>{selectedProductDetail?.description}</p>
                </div>
              </div>
              {auth.user.salesEmail ? (
                <div className="sales-document">
                  <div className="hadingtxt">{t("salesDepartment")} :</div>
                  <div className="sales-info">
                    <div className="leftinfo">
                      <div className="salesiocn">
                        <img src={salesIcon} alt="MagicMoment" />
                      </div>
                      <div className="emailtxt">
                        <div className="salename">{t("salesBox")}</div>
                        <div className="emailtext">{auth.user.salesEmail}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="form-diaolog">
                <div className="hadingtxt">{t("customerInformation")} :</div>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit} className="editteamform">
                      <Modal.Body>
                        <FormControl
                          name="firstName"
                          type="text"
                          label="firstName"
                          validate={composeValidators(
                            required,
                            maxlength(VALIDATIONS.MAX_NAME)
                          )}
                        />
                        <FormControl
                          name="lastName"
                          type="text"
                          label="lastName"
                          validate={composeValidators(
                            required,
                            maxlength(VALIDATIONS.MAX_NAME)
                          )}
                        />
                        <FormControl
                          name="email"
                          type="email"
                          label="Email"
                          validate={composeValidators(required, validEmail)}
                        />
                        <FormControl
                          name="message"
                          type="textarea"
                          label="Message"
                          validate={composeValidators(
                            required,
                            maxlength(VALIDATIONS.MAX_DESCRIPTION)
                          )}
                        />
                        <FormControl
                          name="additionalDetails"
                          type="textarea"
                          label="additionalDetails"
                          validate={composeValidators(
                            required,
                            maxlength(VALIDATIONS.MAX_DESCRIPTION)
                          )}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" type="submit">
                          {t("sendMessage")}
                        </Button>
                      </Modal.Footer>
                    </form>
                  )}
                />
              </div>
            </div>
          )}
        </>
      }
    </Modal>
  );
}
