import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { AddCategoryModal, SearchBar } from "../../../../components";
import TopCategoryList from "../../../../components/Category/TopCategoryList";
import AllCategoryList from "../../../../components/Category/AllCategoryList"; 
import { useState } from "react";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import DefaultCategoryList from "../../../../components/Category/DefaultCategory";
import { useAuth } from "../../../../hooks/useAuth";
import { ROLE } from "../../../../constants";

export default function CategoryList() {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(document.location.search).get('redirect')
  const [category, setCatrgory] = useState(false);
  const [refresh, setRefresh] = useState<any>({});

  const navigate = useNavigate();
  const auth = useAuth();

  const refreshCategory =  (isOk: boolean) => {
    if (isOk) {
      setCatrgory(false);
      //window.location.reload();
    }
  };

  const updateListItem = (editData: any) => {
    setRefresh(editData);
  }

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS)
  }

  function updateFilterCategoryId(value: boolean) {
    setRefresh(false);
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              {/* {params ? <BackIcon /> : null} */}
              <h1>{t("Categories")}</h1>
              <p>{auth.user.role === ROLE.TEAM_LEADER ? t("categoryHeader") : ""}</p>
            </div>
            <div  className={`search-wth-content2 ${auth.user.role !== ROLE.TEAM_LEADER
              ? "w-100" : searchParams ? "search-with-continue": "search-with-add"}
            `}>
              <SearchBar
                placeHolder={t("searchCategory")}
              />
              {
                auth.user.role === ROLE.TEAM_LEADER ?
                  <div className="add-btn" onClick={() => setCatrgory(true)}>{t("addCategory")}</div>
                :
                  null
              }
              {searchParams ? 
                  <div className="continue-btn" onClick={handleClick}>{t("Continue")}</div>
                  :
                  null
              }
            </div>
            <div className="select-category-wrapper">
              <div className="mid-categorycontent _categorydetail _scrollnone">
                
                  {/* <DefaultCategoryList /> */}
                  
                  <TopCategoryList />
                
                  <AllCategoryList refresh={refresh} updateFilterCategoryId={updateFilterCategoryId}/>
               
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <AddCategoryModal
        show={!!category}
        handleClose={() => setCatrgory(false)}
        callback={refreshCategory}
        updateListItem={updateListItem}
      />
    </>
  );
}
