export const ROUTE_PATH = {
  ROOT: "/",
  LEADER: "/leader",
  MEMBER: "/member",
  SUPPORT: "/support",
  ADMIN: "/admin",

  AUTH: "/auth",
  SIGN_IN: "sign-in",
  SIGN_UP: "sign-up",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  VERIFY_EMAIL: "verify-email",
  MY_PROFILE: "my-profile",
  
  
  //admin
  CREATE_TEAM: "create-team",
  CHOOSE_PLAN: "choose-plan",
  PAYMENT_PLAN: "payment",
  MEMBER_LIST: "member-list",
  ACTIVE_MEMBER_LIST: "active-member-list",
  PRE_CREATION: "pre-creation",
  TEAM_PRE_CREATION: "team-pre-creation",
  TEAM_CREATION_INFO: "team-creation-info",
  LEADER_DASHBOARD: "home",
  SUBSCRIPTION: "subscription",
  TEAM_DASHBOARD: "team-dashboard",
  MANAGE_TEAM: "manage-team",
  SETTING: "setting",
  ELEMENTS_INFO: "element-info",
  FAQ_CREATION_INFO: "faq-creation-info",
  CREATE_CATEGORIES: "create-category",
  CATEGORY_LIST: "category-list",
  CREATE_PRODUCTS: "create-product",
  PRODUCT_LIST: "product-list",
  CREATE_QUESTIONS: "create-question",
  QUESTION_LIST: "question-list",
  ADD_QUESTION: "add-question",
  EDIT_QUESTION: "edit-question",
  CREATE_PATH: "create-path",
  EDIT_PATH: "edit-path",
  PATH_LIST: "path-list",
  UPLOAD_ELEMENT_INFO: "upload-element-info",
  UPLOAD_ELEMENT: "upload-element",
  AGENT_LIST: "agent-list",
  TALKING_DURATION: "talking-duration",
  CALL_LIST: "call-list",
  SUGGESTION_LIST: "suggestion-list",
  EDIT_SUGGESTION: "edit-path-suggestion",
  TOP_CATEGORIES: "top-categories",
  TOP_PRODUCTS: "top-products",
  TOP_QUESTIONS: "top-questions",
  EDIT_PROPOSAL: "edit-proposal",

  //Member
  INVITE: "invite",
  MEMBER_DASHBOARD: "home",
  FAQ: "faq",
  CONTACT_US: "contact-us",
  PAYMENT_HISTORY: "payment-history",
  MEMBER_PROFILE: "member-profile",
  CUSTOMER_INTERACTION: "customer-interaction",
  CUSTOMER_INTERACTION_PROCESS: "customer-interaction-process",
  MEMBER_INSIGHTS: "member-insights",

  //Super admin
  MANAGE_ADMIN: "manage-admin",
  MANAGE_INVOICE: "manage-invoices",
};

export const ROUTE_NAVIGATION_PATH = {
  // Auth
  SIGN_IN: ROUTE_PATH.AUTH,
  SIGN_UP: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.SIGN_UP,
  FORGOT_PASSWORD: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.FORGOT_PASSWORD,
  RESET_PASSWORD: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.RESET_PASSWORD,
  VERIFY_EMAIL: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.VERIFY_EMAIL,

  // Leader
  CREATE_TEAM: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_TEAM,
  MEMBER_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_LIST,
  ACTIVE_MEMBER_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.ACTIVE_MEMBER_LIST,
  PAYMENT_HISTORY: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PAYMENT_HISTORY,
  PRE_CREATION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PRE_CREATION,
  TEAM_PRE_CREATION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TEAM_PRE_CREATION,
  LEADER_DASHBOARD: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.LEADER_DASHBOARD,
  TEAM_CREATION_INFO: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TEAM_CREATION_INFO,
  ELEMENT_INFO: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.ELEMENTS_INFO,
  FAQ_CREATION_INFO: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.FAQ_CREATION_INFO,
  LEADER_MY_PROFILE:ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MY_PROFILE,
  MANAGE_TEAM_MEMBERS: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MANAGE_TEAM,
  LEADER_SETTING: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.SETTING,
  SUBSCRIPTION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.SUBSCRIPTION,
  MEMBER_PROFILE: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_PROFILE,
  CREATE_CATEGORIES: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_CATEGORIES,
  CATEGORY_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CATEGORY_LIST,
  CREATE_PRODUCTS: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_PRODUCTS,
  PRODUCT_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PRODUCT_LIST,
  CREATE_QUESTIONS: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_QUESTIONS,
  QUESTION_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.QUESTION_LIST,
  ADD_QUESTION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.ADD_QUESTION,
  EDIT_QUESTION: ROUTE_PATH.LEADER  + ROUTE_PATH.ROOT + ROUTE_PATH.EDIT_QUESTION,
  CREATE_PATH: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CREATE_PATH,
  PATH_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.PATH_LIST,
  EDIT_PATH: ROUTE_PATH.LEADER  + ROUTE_PATH.ROOT + ROUTE_PATH.EDIT_PATH,
  UPLOAD_ELEMENT_INFO: ROUTE_PATH.LEADER  + ROUTE_PATH.ROOT + ROUTE_PATH.UPLOAD_ELEMENT_INFO,
  UPLOAD_ELEMENT: ROUTE_PATH.LEADER  + ROUTE_PATH.ROOT + ROUTE_PATH.UPLOAD_ELEMENT,
  AGENT_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.AGENT_LIST,
  TALKING_DURATION: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.TALKING_DURATION,
  CALL_LIST: ROUTE_PATH.LEADER + ROUTE_PATH.ROOT + ROUTE_PATH.CALL_LIST,
  SUGGESTION_LIST: ROUTE_PATH.LEADER + ROUTE_PATH .ROOT + ROUTE_PATH.SUGGESTION_LIST,
  EDIT_SUGGESTION: ROUTE_PATH.LEADER + ROUTE_PATH .ROOT + ROUTE_PATH.EDIT_SUGGESTION,
  TOP_CATEGORIES: ROUTE_PATH.LEADER + ROUTE_PATH .ROOT + ROUTE_PATH.TOP_CATEGORIES,
  TOP_PRODUCTS: ROUTE_PATH.LEADER + ROUTE_PATH .ROOT + ROUTE_PATH.TOP_PRODUCTS,
  TOP_QUESTIONS: ROUTE_PATH.LEADER + ROUTE_PATH .ROOT + ROUTE_PATH.TOP_QUESTIONS,
  EDIT_PROPOSAL: ROUTE_PATH.LEADER + ROUTE_PATH .ROOT + ROUTE_PATH.EDIT_PROPOSAL,

  //member
  INVITE: ROUTE_PATH.AUTH + ROUTE_PATH.ROOT + ROUTE_PATH.INVITE,
  MEMBER_DASHBOARD: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_DASHBOARD,
  MEMBER_MY_PROFILE: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.MY_PROFILE,
  MEMBER_SETTING: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.SETTING,
  CUSTOMER_INTERACTION: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.CUSTOMER_INTERACTION,
  CUSTOMER_INTERACTION_PROCESS: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.CUSTOMER_INTERACTION_PROCESS,
  MEMBER_INSIGHTS: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.MEMBER_INSIGHTS,
  
  // support 
  FAQ: ROUTE_PATH.SUPPORT + ROUTE_PATH.ROOT + ROUTE_PATH.FAQ,
  CONTACT_US: ROUTE_PATH.SUPPORT + ROUTE_PATH.ROOT + ROUTE_PATH.CONTACT_US,

  //Admin
  MANAGE_ADMIN: ROUTE_PATH.ADMIN + ROUTE_PATH.ROOT + ROUTE_PATH.MANAGE_ADMIN,
  MANAGE_INVOICE: ROUTE_PATH.ADMIN + ROUTE_PATH.ROOT + ROUTE_PATH.MANAGE_INVOICE,
  ADMIN_SETTING: ROUTE_PATH.MEMBER + ROUTE_PATH.ROOT + ROUTE_PATH.SETTING,

  
};
