import Row from "react-bootstrap/Row";
import { NoData, SearchBar } from "../../../components";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Iconcalendar from "../../../assets/images/calendar.svg";
import { useTranslation } from "react-i18next";
import { useLoadItems } from "../../../hooks/useLoadItems";
import { customerInteraction } from "../../../api";
import { USER_STATUS } from "../../../constants";
import moment from 'moment';
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Spinner } from "react-bootstrap";
import ProgressBar from "../../../components/ProgressBar";
import DatePicker1 from "react-datepicker";
import ScrollMenu from "react-horizontal-scroll-menu";
import { default as dayjs } from "dayjs";

type Select = string | number | null;

export default function Calls() {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [fromDate, setFromdate] = useState<any>(new Date());
  const [toDate, setToDate] = useState<any>(new Date());
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const location = useLocation();
  let status = USER_STATUS.ACTIVE;
  const [daysOfweek, setDaysOfWeek] = useState<dayjs.Dayjs[]>([]);
  let today = moment().format("DD");
  const [selected, setSelected] = useState<Select>(parseInt(today, 10));
  const [customFormat, setCustomFormat] = useState<string>("ddd");
  const currentDay = dayjs().format("D");
  const { loading, items, hasNextPage, totalInMinutes, error } = useLoadItems({
    loadItems: customerInteraction.getTotalCall,
    page,
    search,
    status,
    fromDate,
    toDate
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");

  }, [location.search]);

  const changeDate = (date: any) => {
    setPage(1);
    setFromdate(date);
    setToDate(date);
    daysOfweek.map((day: any) => {
      let input = moment(day);
      let now = moment(date);
      const isThisWeek = (now.isoWeek() === input.isoWeek())
      if(isThisWeek) {
        setSelected(parseInt(now.format('DD'), 10))
      } else {
        setSelected(-1);
      }
    })
    setShowDatePicker(false)
  }

  function formatDate(value: any) {
    const format1 = "MMM DD YYYY"
    const date = new Date(value);
    return moment(date).format(format1);
  }

  const setDatePicker = () => {
    setShowDatePicker(!showDatePicker)
  }

  const getCurrentWeekDays = () => {
    const weekStart = dayjs().startOf("week");

    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(dayjs(weekStart).add(i, "days"));
    }

    return days;
  };

  useEffect(() => {
    setDaysOfWeek(getCurrentWeekDays());
  }, []);

  const MenuItem = ({ title, text, selected, key }: any) => {
    return (
      <div
        className={`menu-item dayItem ${selected ? "active" : currentDay === text ? "today" : null}`}
        key={key}
      >
        <h5 className="title">{title}</h5>
        <span className="text"> {text}</span>
      </div>
    );
  };

  const Menu = (selected: any) =>
    daysOfweek.map((day: any) => {
      return (
        <MenuItem
          title={day.format(customFormat)}
          text={day.format("D")}
          key={day.format("D")}
          selected={selected}
        />
      );
  });

  const onSelect = (key: Select) => {
    setSelected(key);
    let d = moment().format('MM')+ '/'+ key+ '/'+moment().format('yyyy');
    let date = new Date(d);
    setFromdate(date);
    setToDate(date);
    setPage(1);
  };

  const menu = Menu(selected);

  return (
    <>
       <Row>
        {/* <section className="main-container resize_container shrink-width"> */}
        <div className="mid_outer_content">
            <div className="talking-duration-wrapper">
              <div className="heading-common">
                <h1>{t('totalCalls')}</h1>
                <p>{t('totalCallsHeading')}</p>
              </div>
              <div className="search-wth-content2 w-100">
                <SearchBar placeHolder={t('seachAnAgent')} />
              </div>
              <div className="calendar-wrpper">
                <div className="table-calendar">
                  {/* <div className="relative">
                      <span className="dropheading">
                        This Month <img src={IconDown} alt="MagicMoment" />
                      </span>
                      <div className="dropdowncontent">
                        <ul>
                          <li className="active">In Min</li>
                          <li>In Sec</li>
                        </ul>
                      </div>
                  </div> */}
                  <div className="heading-withcalendar">
                    <div className="calenderheading">
                      { moment().format('MMM')  + ' ' + moment().format('YYYY')}
                    </div>
                    <div className="calenderinfo _calls-c">
                      <div className="date" onClick={() => setDatePicker()}>
                        <img src={Iconcalendar} alt="" />
                        
                      </div>
                      {
                        showDatePicker ?
                          <div className="dropinfo">
                            <div className="dwon"></div>
                            <DatePicker1
                              selected={fromDate}
                              onChange={(update: any) => {
                                changeDate(update);
                              }}
                              dateFormat="MMM dd yyyy"
                              //onOk={() => setShowDatePicker(false)}
                              open={showDatePicker}
                            />
                            
                          </div>
                          :
                          <span onClick={() => setDatePicker()}>
                            {formatDate(fromDate)}
                          </span>
                      }
                    </div>
                  </div>
                  <div className="calender-horizontal">
                    <ScrollMenu
                      data={menu}
                      // arrowLeft={ArrowLeft}
                      // arrowRight={ArrowRight}
                      selected={selected as string}
                      onSelect={onSelect}
                      scrollToSelected={true}
                    />
                  </div>
                </div>
              </div>
              <div className="content-fullwidth">
                <div className="h1">
                  {t("Agents")}
                  {/* <span className="dropheading">
                    All <img src={IconDownblue} alt="MagicMoment" />
                  </span> */}
                  {/* <div className="dropdowncontent">
                    <ul>
                      <li className="active">Questions</li>
                      <li>Product</li>
                      <li>Category</li>
                    </ul>
                  </div> */}
                </div>
                
                <div className="top-heading">
                  <h3>{t('Call')}</h3>
                </div>
                {!items.length && !loading ? <NoData /> : null}
                {
                  items.length ?
                  <div className="progress-content" ref={rootRef}>
                    {items.map((calls:any, key:number) => (
                        <ProgressBar
                            key={calls?._id}
                            name={calls.agentName}
                            totalNumber={totalInMinutes}
                            now={calls?.numberOfCalls}
                            min={0}
                            max={totalInMinutes}
                            width={(calls?.numberOfCalls) }
                            background={'#000'}
                        />
                            
                    ))}
                  </div>
                :
                  null
                }
                {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}