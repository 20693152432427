import { ROUTE_NAVIGATION_PATH } from "../routes/routes";
import iconDashboard from "../assets/images/dashboard-nav.svg";
import iconSupport from "../assets/images/support-nav.svg";
import iconTeam from "../assets/images/interaction-nav.svg";
import iconElement from "../assets/images/element.svg";

const SUPPORT = {
  url: ROUTE_NAVIGATION_PATH.FAQ,
  title: "Support",
  icon: iconSupport,
  activeOn: [ROUTE_NAVIGATION_PATH.FAQ, ROUTE_NAVIGATION_PATH.CONTACT_US],
};

export const LEADER_MENU = {
  DASHBOARD: {
    url: ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD,
    title: "Dashboard",
    icon: iconDashboard,
    activeOn: [
      ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD,
      ROUTE_NAVIGATION_PATH.AGENT_LIST,
      ROUTE_NAVIGATION_PATH.TALKING_DURATION,
      ROUTE_NAVIGATION_PATH.CALL_LIST,
      ROUTE_NAVIGATION_PATH.SUGGESTION_LIST,
      ROUTE_NAVIGATION_PATH.TOP_CATEGORIES,
      ROUTE_NAVIGATION_PATH.TOP_PRODUCTS,
      ROUTE_NAVIGATION_PATH.TOP_QUESTIONS
    ]
  },
  TEAM: {
    url: ROUTE_NAVIGATION_PATH.ELEMENT_INFO,
    title: "Elements",
    icon: iconElement,
    activeOn: [
      ROUTE_NAVIGATION_PATH.ELEMENT_INFO,
      ROUTE_NAVIGATION_PATH.FAQ_CREATION_INFO,
      ROUTE_NAVIGATION_PATH.TEAM_PRE_CREATION,
      ROUTE_NAVIGATION_PATH.TEAM_CREATION_INFO,
      ROUTE_NAVIGATION_PATH.MEMBER_LIST,
      ROUTE_NAVIGATION_PATH.CREATE_TEAM,
      ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES,
      ROUTE_NAVIGATION_PATH.CATEGORY_LIST,
      ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS,
      ROUTE_NAVIGATION_PATH.PRODUCT_LIST,
      ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS,
      ROUTE_NAVIGATION_PATH.QUESTION_LIST,
      ROUTE_NAVIGATION_PATH.CREATE_PATH,
      ROUTE_NAVIGATION_PATH.PATH_LIST,
      ROUTE_NAVIGATION_PATH.ADD_QUESTION,
      ROUTE_NAVIGATION_PATH.EDIT_QUESTION,
      ROUTE_NAVIGATION_PATH.UPLOAD_ELEMENT_INFO,
      ROUTE_NAVIGATION_PATH.UPLOAD_ELEMENT
    ]
  },
  SUPPORT
};

export const LEADER_MENUS = [
  LEADER_MENU.DASHBOARD,
  LEADER_MENU.TEAM,
  // ADMIN_MENU.STATISTICS,
  LEADER_MENU.SUPPORT,
];

export const MEMBER_MENU = {
  DASHBOARD: {
    url: ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD,
    title: "Dashboard",
    icon: iconDashboard,
    activeOn: [ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD]
  },
  TEAM: {
    url: ROUTE_NAVIGATION_PATH.ELEMENT_INFO,
    title: "Elements",
    icon: iconElement,
    activeOn: [
      ROUTE_NAVIGATION_PATH.ELEMENT_INFO,
      ROUTE_NAVIGATION_PATH.MEMBER_LIST,
      ROUTE_NAVIGATION_PATH.CATEGORY_LIST,
      ROUTE_NAVIGATION_PATH.PRODUCT_LIST,
      ROUTE_NAVIGATION_PATH.QUESTION_LIST,
      ROUTE_NAVIGATION_PATH.PATH_LIST
    ]
  },
  CUSTOMER_INTERACTION: {
    url: ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION,
    title: "Customer Interaction",
    icon: iconTeam,
    activeOn: [
      ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION,
      ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION_PROCESS
    ]
  },
  SUPPORT
};

export const MEMBER_MENUS = [
  MEMBER_MENU.DASHBOARD,
  MEMBER_MENU.TEAM,
  MEMBER_MENU.CUSTOMER_INTERACTION,
  MEMBER_MENU.SUPPORT,
];

export const ADMIN_MENU = {
  ADMIN : {
    url: ROUTE_NAVIGATION_PATH.MANAGE_ADMIN,
    title: "Dashboard",
    icon: iconDashboard,
    activeOn: [ROUTE_NAVIGATION_PATH.MANAGE_ADMIN, ROUTE_NAVIGATION_PATH.MANAGE_INVOICE]
  }
};

export const ADMIN_MENUS = [
  ADMIN_MENU.ADMIN
];
