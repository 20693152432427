import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";
import { category } from "../../api";
import { useTranslation } from "react-i18next";
import AddEditCategory from "../FormElements/AddEditCategory";
import { Any } from "../../types/global.type";
import { useCustomMutation } from "../../hooks/useApi";
import DefaultCategoryList from "../Category/DefaultCategory";
import { useEffect, useState } from "react";

export default function AddCategoryModal({
  show,
  handleClose,
  updateListItem,
  callback
}: {
  show: boolean;
  handleClose: VoidFunction;
  updateListItem?:(data: any | undefined) => void;
  callback?: (data?: any) => void;
}) {
  const { t } = useTranslation();
  const [data, setData] = useState({})

  const onOk = (isOk: boolean) => {
    callback && callback(isOk);
    !isOk && handleClose();
  };

  const { mutate } = useCustomMutation({
      mutationFn: category.createCategory,
      onSuccess: (res:any) => {
        if (updateListItem) updateListItem(res.data);
        setData({});
        handleClose();
        onOk(true);
      }
  });

  const onSubmit = (values: Any) => {
      mutate(values);
  };

  const updateFilterCategoryId = (value: any) => {
    console.log(data);
    if(value.name) {
      setData({
        name: value.name,
        description: value.description
      });
    } else {
      setData({});
    }
  };

  const close = () => {
    handleClose();
  }

  useEffect(()=> {
    if(show) {
      setData({});
    }
    
  }, [show])

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      //backdrop="static"
      dialogClassName={"team-memeber-model"}
      keyboard={false}
    >
        <Modal.Header>
          <Modal.Title>{t("createCategory")}</Modal.Title>
        </Modal.Header>
        <p className="subtxt">
          {t("addCategoryManually")}
        </p>
        <div className="categoryoverflowContent">
          <DefaultCategoryList selectedData={data} updateFilterCategoryId={updateFilterCategoryId}/>
          <p style={{ marginTop: '10px'}}>
              {t('addA')} <span>{t('category')}</span> {t('manually')} :
          </p>
        <Form
          initialValues={data}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <AddEditCategory updateFilterCategoryId={updateFilterCategoryId}/>
                <Modal.Footer>
                  <Button variant="primary cancel" type="button" onClick={close}>
                    {t("Cancel")}
                  </Button>
                  <Button variant="primary" type="submit">
                    {t("add")}
                  </Button>
              </Modal.Footer>
            </form>
          )}
        />
        </div>
    </Modal>
  );
}
