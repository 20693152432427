import { useTranslation } from "react-i18next";
import aveIcon from "../../assets/images/details-pic.jpeg";
import lineIcon from "../../assets/images/SvgjsSvg1526.svg";
import downIconFat from "../../assets/images/icon-arrow-down-fat.svg";
import folderIcon from "../../assets/images/folder.svg";
import activityIcon from "../../assets/images/activity.svg";
import bagIcon from "../../assets/images/bag.svg";
import tagsIcon from "../../assets/images/tagsIcon.svg";
import IconNext from "../../assets/images/cheveron-right2.svg";
import { Any } from "../../types/global.type";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useEffect, useState } from "react";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import moment from "moment";


export default function MemberDashboardStats({
    totalStats
}: {
    totalStats: Any
}) {
    const { t } = useTranslation();
    const [label, setLabel]  = useState<any>([]);
    const [count, setCount] = useState<any>([]);

    function formatDate(value: any) {
        const format1 = "MMM DD, YYYY"
        const date = new Date(value);
        return moment(date).format(format1);
    }

    useEffect(() => {
        var stats= totalStats?.callsByDate?.totalCalls;
        if(stats) {
            stats.forEach((element:any) => {
                setLabel((prev:any) => [...prev, formatDate(element.date)])
                setCount((prev:any) => [...prev, element.count])
            });
        }
        
       
    }, [totalStats]);

    // function formattedNumber(value:any) {
    //     let data = +value;
    //     return new Intl.NumberFormat('en-US', {
    //         notation: 'compact',
    //         compactDisplay: 'short',
    //       }).format(data);
    // }

    return (
        <div className="callparday-content">
            <div className="top-heading">
                <h3>{t("callsPerDay")}</h3>
                <Link to={ROUTE_NAVIGATION_PATH.MEMBER_INSIGHTS}>
                    {t("seeInsights")}
                    <img src={IconNext} alt="magicmoment" />
                </Link>
            </div>
            <div className="graphwith-data">
                <div className="left-text">
                    <div className="numbers">{totalStats?.callsToday?.numberOfCalls ? totalStats?.callsToday?.numberOfCalls : 0}</div>
                    <div className="detail-ave">
                        <div className="img">
                            <img width={40} height={40} src={aveIcon} alt="MagicMoment" />
                        </div>
                        {/* <div className="ave-txt">
                            <p>{t('Average')}</p>
                            <p>{t('perDay')}</p>
                        </div> */}
                        <div className="graphvalueleft">
                            <div className="leftgrp">
                                {/* <img src={lineIcon} alt="MagicMoment" /> */}
                                {
                                    count.length > 0 ?
                                        <LineChart labels={label} count={count} />
                                    :
                                    null
                                }
                            </div>
                            <div className="percentagetxt">
                                <span>
                                    <img src={downIconFat} alt="Icon" className={ parseInt(totalStats.callsToday?.percentage) < 0 ? "": "upicon"}/>
                                </span>{" "}
                                {totalStats.callsToday?.percentage.toString().replace("-", "")}%
                            </div>
                        </div>
                        {/* <div className="info">
                            {t('updateCallSetting')}
                        </div> */}
                    </div>
                </div>
                <div className="right-graph">
                    {
                        count.length > 0 ?
                        <BarChart labels={label} count={count} />
                        :
                        null
                    }
                </div>
            </div>
            <div className="all-info-wrapper">
                <div className="grid-info">
                    <div className="w-100">
                        <div className="heading-with-icon">
                            {/* <div className="icons">
                                <img src={folderIcon} alt="" />
                            </div> */}
                            {t('Categories')}
                        </div>
                       <Link to={ROUTE_NAVIGATION_PATH.TOP_CATEGORIES}>
                        <div className="numbers-ave">{totalStats.cards?.totalCategories ? totalStats.cards?.totalCategories : 0}</div>
                       </Link>
                    </div>
                </div>
                <div className="grid-info">
                    <div className="w-100">
                        <div className="heading-with-icon">
                            {/* <div className="icons _pink">
                                <img src={activityIcon} alt="Icon" />
                            </div> */}
                            {t('Products')}
                        </div>
                        <Link to={ROUTE_NAVIGATION_PATH.TOP_PRODUCTS}>
                            <div className="numbers-ave">{totalStats.cards?.totalProducts ? totalStats.cards?.totalProducts : 0}</div>
                        </Link>
                    </div>
                </div>
                <div className="grid-info">
                    <div className="w-100">
                        <div className="heading-with-icon">
                            {/* <div className="icons _blue">
                                <img src={bagIcon} alt="Icon" />
                            </div> */}
                            {t('Questions')}
                        </div>
                        <Link to={ROUTE_NAVIGATION_PATH.TOP_QUESTIONS}>
                            <div className="numbers-ave">{totalStats.cards?.totalQuestions ? totalStats.cards?.totalQuestions : 0}</div>

                        </Link>
                    </div>
                </div>
                {/* <div className="grid-info">
                    <div className="w-100">
                        <div className="heading-with-icon">
                            <div className="big-img">
                                <img src={tagsIcon} alt="Icon" />
                            </div>
                        </div>
                        <div className="numbers-ave _small">{totalStats.cards?.numberOfCalls ? totalStats.cards?.numberOfCalls : 0} {t('Calls')}</div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
