import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import iconDelete from "../../assets/images/delete-dialogIcon.svg";
import { useTranslation } from "react-i18next";

export default function DeleteModal({
  heading,
  body,
  show,
  handleClose,
  type = "success",
  buttonMain = "Yes",
  buttonSecondary = "No",
  hideIcon = false,
  callback,
}: MessageModalType) {
  const { t } = useTranslation();
  const onOk = (isOk: boolean) => {
    callback && callback(isOk);
    !isOk && handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      dialogClassName={"model-common delete-dialog-common"}
    >
      <Modal.Header>
          <div className="icondelete">
            <img src={iconDelete} alt="info" />
          </div>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button className="btndelete cancel" onClick={() => onOk(false)}>
          {t(buttonSecondary)}
        </Button>
        <Button className="btndelete remove" onClick={() => onOk(true)} type="submit">
          {t(buttonMain)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
