import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import AddQuestion from "../../../components/Question/AddQuestion";
import { useEffect } from "react";
import { question } from "../../../api";
import { useCreateEditQuestion } from "../../../hooks/useCreateEditQuestion";


export default function EditProposal() {
  const { t } = useTranslation();
  const questionId = new URLSearchParams(document.location.search).get('id');
  const fromProduct = new URLSearchParams(document.location.search).get('fromProduct')

  const { updateQuestionData } = useCreateEditQuestion();

  useEffect(() => {
    if(!fromProduct) {
      question
        .getQuestionById(questionId as string)
        .then((res) => {
          const categoryId = res.data.category?.map((data:any) => data._id);
          let data = {
            id: res.data._id,
            question: res.data.question,
            answer: res.data.answer,
            time: res.data.time,
            category: categoryId,
            productDetails: res.data.upsell
          }
          updateQuestionData(data);
        });
    }
  }, []);

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="stepheading questionwrapper">
                {t("EditProposal")}
            </div>
            <div className="select-category-wrapper questionwrapper">
              <div className="mid-categorycontent"> 
                <div className="form-category _questioncategory">
                    {}
                    <AddQuestion editForm={true}/>
                </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
