import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import iconArrowUp from "../../assets/images/arrow-up2.svg";
import iconArrowNext from "../../assets/images/cheveron-right.svg";
import profileImg from "../../assets/images/icon-profile.png";
import dashboardIntro from "../../assets/images/intro-dashboard.png";
import { useTranslation } from "react-i18next";



export default function MemberInfo({
  heading,
  body,
  footer,
  show,
  handleClose,
  type = "success",
  callback,
  buttonMain = "LET'S GO",
}: MessageModalType) {
  const { t } = useTranslation();
  const onOk = () => {
    callback && callback();
    handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      // backdrop="static"
      keyboard={false}
      dialogClassName={"_introduction-dialog"}
    >
      <Modal.Header>
        <Modal.Title>{t("Introduction")}</Modal.Title>
        <p>
          {t('memberInfoHeading')}
          <br />
          {t("memberInfoSubHeading")}
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className="left-content">
          <div className="stepper _active">
            <div className="numbers">1</div>
            <div className="_txt">
            <div className="subheading">{t('teamUp')}</div>
              {/* <p>{t('teamUpHeading')}</p> */}
              <p>{t('teamUpSubHeading')}</p>
            </div>
          </div>
          <div className="stepper">
            <div className="numbers">2</div>
            <div className="_txt">
              <div className="subheading">{t('HubUp')}</div>
              {/* <p>{t('teamUpHeading')}</p> */}
              <p>{t('HubUpHeading')}</p>
            </div>
          </div>
          <div className="stepper">
            <div className="numbers">3</div>
            <div className="_txt">
              <div className="subheading">{t("getGuided")}</div>
              <p>
                {t('getGuidedHeading')}
              </p>
            </div>
          </div>
          <div className="stepper">
            <div className="numbers">4</div>
            <div className="_txt">
              <div className="subheading">{t("yourInishgts")}</div>
              <p>{t("yourInishgtsHeading")}</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <div className="agent-availble">
             <div className="a-heading">
              <div className="h4">{t("agentsAvailable")}</div>
                <div className="linksee">{t("SeeAllAgents")} <img src={iconArrowNext} alt="magicmoment" /></div>
             </div>
             <div className="agent-content">
              <div className="lefttxt">
                <div className="_image">
                  <img src={profileImg} alt="Profile" />
                </div>
                <div className="text">
                  <div className="name">Jenny Wilson</div>
                  <p>w.lawson@example.com</p>
                </div>
              </div>
              <div className="righttext">
                 <div className="connecttext">
                  <span></span>
                  Connected
                 </div>
              </div>
             </div>
             <div className="agent-content">
              <div className="lefttxt">
                <div className="_image">
                  <img src={profileImg} alt="Profile" />
                </div>
                <div className="text">
                  <div className="name">Devon Lane</div>
                  <p>dat.roberts@example.com</p>
                </div>
              </div>
              <div className="righttext">
                 <div className="connecttext">
                  <span></span>
                  Connected
                 </div>
              </div>
             </div>
          </div>
          
           <div className="avgcall">
              <div className="avg">Avg. Call Length</div>
               <div className="count-with-text">
                  <div className="count">5,593</div>
                   <div className="percent">36% <img src={iconArrowUp} alt="magicmoment" /></div>
               </div>
           </div>
           <img src={dashboardIntro} alt="magicmoment" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {footer}
        <Button variant="primary" onClick={onOk}>
          {t(buttonMain)}
        </Button>
        {/* <Button variant="download2" type="submit">
          <div className="icon-download">
            <img src={iconDownload} alt="Download" />
          </div>
          Download
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}
