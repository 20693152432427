import { useState } from "react";
import { dashboard, team } from "../../../api";
import { useTranslation } from "react-i18next";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { NoData } from "../../../components";
import { Spinner } from "react-bootstrap";
import iconProfile from "../../../assets/images/icon-profile.png";
import { ROLE, USER_STATUS } from "../../../constants";


export default function AgentList() {
    const [page, setPage] = useState(0);
    const search = "";
    const status = USER_STATUS.ALL;
    const role = "0";
    const { loading, items, hasNextPage, error } =
        useLoadItems({
            loadItems: team.getOfflineOnlineMembers,
            page,
            status,
            search,
            role
        });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage(page + 1),
        disabled: !!error,
        rootMargin: "0px 0px 400px 0px",
    });

    const capitalizeFirst = (str: any) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    };

    return (
        <>


            <div className="availble-agent-content" ref={rootRef}>
                {!items.length && !loading ? <NoData /> : null}

                {items.map((mem: any, key: any) => (
                    <div className="agent-listing" key={mem?._id}>
                        <div className="left-text">
                            <div className="_image">
                                <img
                                    src={mem?.image ? mem?.image : iconProfile}
                                    onError={(e: any) => {
                                        e.target.src = iconProfile;
                                    }}
                                    alt="MagicMoment"
                                />
                            </div>
                            <div className="user-info">
                                <div className="name">{capitalizeFirst(mem.name) + ' ' + (mem.surname ? capitalizeFirst(mem.surname) : '')}</div>
                                <div className="email">
                                    {mem?.position ? mem.position : ''}
                                </div>
                            </div>
                        </div>
                        <div className="righttext">
                            <div className="connected">
                                <span className={`${mem?.loginStatus === USER_STATUS.ACTIVE ? "green" : "grey"}`}></span>
                                {mem.loginStatus === USER_STATUS.ACTIVE ? 'Online' : 'Offline'}
                            </div>
                        </div>
                    </div>
                ))}

                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                        <div className="">
                            <Spinner />
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}
