import { useTranslation } from "react-i18next";
import IconNoData from "../../assets/images/neutral_face.png";

export default function NoData() {
  const {t} = useTranslation();
  return (
    <div className="no-data-found">
      <div className="_center">
      <img src={IconNoData} alt="MagicMoment" />
      <p>{t("NoResultFound")}</p>
      </div>
      
    </div>
  );
}
