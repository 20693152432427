import { Any } from "../types/global.type";
import { QuestionType } from "../types/question.type";
import React, { useMemo, useState } from "react";
import { DEFAULT_PATH } from "../constants";

export const DragAndDropContext = React.createContext<{
  droppedQuestion: any[];
  questionId: any[];
  drag: (ev: Any, data: QuestionType) => void;
  dropped: (id: string, step:any, index:number) => void;
  onDragStart: (ev: Any) => void;
  removeDroppedQuestion: (ids: string[], step:any, index:number) => void;
  addNewStep: (data: Any) => void;
  setQuestionItem: (id: string) => void;
  resetState: () => void;
}>(null!);


export function DragAndDropProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [droppedQuestion, setDroppedQuestion] = useState<any[]>(DEFAULT_PATH);
  const [questionId, setQuestionId] = useState<any[]>([]);  

  function drag(ev:any, data:any) {
    ev.dataTransfer.setData("questionDetail", JSON.stringify(data));
  }

  function dropped(data: any, sequence:any, index:number) {
    const updatedData = {
      _id: data._id,
      category: data?.category ? data.category : {},
      time: data.time,
      question: data.question
    }

    setDroppedQuestion((prev:any) => prev.map(
      (item:any) => {
        if(item.sequence === sequence) {
          item.questions[index] = updatedData;
        }
        return item;
      })
    );

    setQuestionItem(data._id)
  }

  function addNewStep(data:any) {
    setDroppedQuestion((prev:any) => [...prev, data]);
  }

  function removeDroppedQuestion(data: any, sequence:any, index:number) {
    setQuestionId((prev:any) => prev.filter((item:any) =>  item !== data._id));
    setDroppedQuestion((prev:any) => prev.filter((item:any) => {
      if(item.sequence === sequence) {
        item.questions[index] = {_id: "", category: {}, time: "", question: ""};
      }
      return item;
    }));
  }

  function setQuestionItem(data: any) {
    if(data) {
      setQuestionId((prev:any) => [...prev, data]);
    } else {
      setQuestionId([]);
    }
  }

  function questionArray(data: any) {
    
  }

  function resetState() {
    setDroppedQuestion([
      {
        name: "Step 1",
        sequence: 1,
        questions: [{_id: "", category: {}, time: "", question: ""}] 
      },
      {
        name: "Step 2",
        sequence: 2,
        questions: [
          {_id: "", category: {}, time: "", question: ""}, 
          {_id: "", category: {}, time: "", question: ""},
          {_id: "", category: {}, time: "", question: ""}
        ] 
      },
      {
        name: "Step 3",
        sequence: 3,
        questions: [
          {_id: "", category: {}, time: "", question: ""}, 
          {_id: "", category: {}, time: "", question: ""},
          {_id: "", category: {}, time: "", question: ""}
        ] 
      },
      {
        name: "Step 4",
        sequence: 4,
        questions: [
          {_id: "", category: {}, time: "", question: ""}, 
          {_id: "", category: {}, time: "", question: ""},
          {_id: "", category: {}, time: "", question: ""}
        ] 
      }
    ]);
  }

  function onDragStart(ev:any) {
    ev.preventDefault();
  }

  const value = useMemo(
    () => ({
      droppedQuestion,
      questionId,
      drag,
      dropped,
      onDragStart,
      removeDroppedQuestion,
      addNewStep,
      setQuestionItem,
      resetState
    }),
    [droppedQuestion]
  );
  return (
    <DragAndDropContext.Provider value={value}>
      {children}
    </DragAndDropContext.Provider>
  );
}
