import { useTranslation } from "react-i18next";
import IconNext from "../../assets/images/cheveron-right2.svg";
import { Any } from "../../types/global.type";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import iconUp from "../../assets/images/arrow-up2.svg";
import iconDown from "../../assets/images/downred.svg";
import { useEffect, useState } from "react";
import { question } from "../../api";
import { SUGGESTION_STATUS, USER_STATUS } from "../../constants";
import { Member } from "../../types/User";
import { NoData } from "..";
import moment from "moment";


export default function MemberDashboardInsights({
    totalStats
}: {
    totalStats: Any
}) {
    const { t } = useTranslation();
    const [suggestionQuestion, setSuggestionQuestion] = useState<Member[]>([]);

    useEffect(() => {
      question.getQuestionProposalList({
        page: 1,
        count: 3,
        status: USER_STATUS.ALL,
      }).then((res: { data: Member[] }) => {
        setSuggestionQuestion(res.data);
      });
    }, [])

    const truncateText = (text: string) => {
      return text && text?.length > 24 ? text.slice(0, 100) + "..." : text;
    };

    function formatDate(value:any) {
      const format1 = "MMM DD, YYYY"
      const date = new Date(value);
      // Format the date to DD-MM-YYYY
      return  moment(date).format(format1);
    }

    return (
        <div className="insight-top-data">
        <div className="left-wp">
          <div className="topgrid">
            <h3>{t('totalCalls')}</h3>
            <div className="loginfo">
              <div className="numbers">
                {totalStats?.numberOfCalls?.total}
              </div>
              <div className={ parseInt(totalStats.numberOfCalls?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
                {totalStats.numberOfCalls?.percentage.toString().replace("-", "")}%
                <img src={ parseInt(totalStats.numberOfCalls?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
              </div>
            </div>
          </div>
          <div className="topgrid">
            <h3>{t('useCards')}</h3>
            <div className="loginfo">
              <div className="numbers">
                {totalStats?.usedCards?.total}
              </div>
              <div className={ parseInt(totalStats.usedCards?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
                {totalStats.usedCards?.percentage.toString().replace("-", "")}%
                <img src={ parseInt(totalStats.usedCards?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
              </div>
            </div>
          </div>
          <div className="topgrid">
            <h3>{t('Upsells')}</h3>
            <div className="loginfo">
              <div className="numbers">
                {totalStats?.upsells?.total}
              </div>
              <div className={ parseInt(totalStats.upsells?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
                {totalStats.upsells?.percentage.toString().replace("-", "")}%
                <img src={ parseInt(totalStats.upsells?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
              </div>
            </div>
          </div>
          <div className="topgrid">
            <h3>{t('avgCallMin')}</h3>
            <div className="loginfo">
              <div className="numbers">
                {totalStats?.averageCallLength?.total}
              </div>
              <div className={ parseInt(totalStats.averageCallLength?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
                {totalStats.averageCallLength?.percentage.toString().replace("-", "")}%
                <img src={ parseInt(totalStats.averageCallLength?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
              </div>
            </div>
          </div>
        </div>
        <div className="right-wp">
        <div className="dashboard-right">
          <div className="top-heading">
            <h3>{t("mySuggestedQuestion")}</h3>
            <Link to={ROUTE_NAVIGATION_PATH.SUGGESTION_LIST}>
              {t("viewAll")}
              <img src={IconNext} alt="magicmoment" />
            </Link>
          </div>
          <div className="availble-agent-content">
            {suggestionQuestion.length === 0 ? <NoData /> : null}
            {suggestionQuestion.map((data: any, key: any) => (
            <div className="agent-listing" key={data?.questions?._id}>
              <div className="left-text">
                {/* <div className="_image">
                  <img
                    src={iconProfile}
                    onError={(e: any) => {
                      e.target.src = iconProfile;
                    }}
                    alt="ProfileImg"
                  />
                </div> */}
                <div className="user-info">
                  <div className="name">{data?.question ? truncateText(data?.question) : ''}</div>
                  <div className="email">{t('pathName')}: <span>{data.path?.name ? truncateText(data.path?.name): ''}</span></div>
                </div>
              </div>
              <div className="righttext">
                <div className="connected">
                <span className={`btntxt ${
                                  data.status === SUGGESTION_STATUS.ACTIVE ? 'green' 
                                    : ( data.status === SUGGESTION_STATUS.REJECTED ?'_reject' : 'grey')}
                                `}></span> 
                                {data.status === SUGGESTION_STATUS.ACTIVE ? t('Accepted') : ( data.status === SUGGESTION_STATUS.REJECTED ? t('Declined') : t('Pending'))}
                </div>
                {/* <div className="incall">In a Call</div> */}
                <div className="_date">{formatDate(data.createdAt)}</div>
              </div>
            </div>
            ))}
          </div>
        </div>
        </div>
      </div>
    );
}
