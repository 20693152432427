import React from "react";
import Card from "react-bootstrap/Card";

export default function CardLayout({
  body,
  title,
}: {
  body: React.ReactNode;
  title: React.ReactNode;
}) {
  return (
    <Card className="text-center shadow border border-0 rounded-3">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {body}
      </Card.Body>
    </Card>
  );
}
