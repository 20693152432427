import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { t } from "i18next";
import question from "../../../assets/images/question.jpeg";
import product from "../../../assets/images/product.jpeg";
import team from "../../../assets/images/team.jpeg";
import category from "../../../assets/images/category.jpeg";
import path from "../../../assets/images/path.jpeg";
import upload from "../../../assets/images/master-upload.jpeg";
import { useAuth } from "../../../hooks/useAuth";
import { ROLE } from "../../../constants";
import { useEffect } from "react";
import useCustomInteraction from "../../../hooks/useCustomerInteraction";

export default function ElementInfoScreeen() {

  const auth = useAuth();
  const { stopOnclick } = useCustomInteraction();

  useEffect(() => {
    stopOnclick();
  })

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("ManageYourHub")}</h1>
              {
                auth.user.role == ROLE.TEAM_LEADER ?
                <p>{ t("ElementPlaceholder") }</p>
                :
                null
              }
              
            </div>
            <div className="element_wrapper">
            <div className="element-grid ">
                <Link to={
                  auth.user.role === ROLE.TEAM_LEADER ?
                    auth.user.teamMemberCount > 0 ? ROUTE_NAVIGATION_PATH.MEMBER_LIST : ROUTE_NAVIGATION_PATH.CREATE_TEAM
                    :
                    ROUTE_NAVIGATION_PATH.MEMBER_LIST
                  }
                >

                  <div className="_image">
                    <img src={team} alt="MAGICMOMENT" />
                  </div>

                  <div className="_desc-e">
                    <div className="_heading">{t('Team')}</div>
                    <p>
                      {t("elementTeamDescription")}
                    </p>
                  </div>
                  
                  </Link>
              </div>
              <div className="element-grid ">
                <Link to={
                  auth.user.role === ROLE.TEAM_LEADER ?
                    (auth.user.categoryCount > 0 ? ROUTE_NAVIGATION_PATH.CATEGORY_LIST : ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES)
                    :
                    ROUTE_NAVIGATION_PATH.CATEGORY_LIST
                  }
                >
                  <div className="_image">
                    <img src={category} alt="MAGICMOMENT" />
                  </div>

                  <div className="_desc-e">
                    <div className="_heading">{t('Categories')}</div>
                    <p>
                      {t("elementCategoryDescription")}
                    </p>
                  </div>

                  </Link>
              </div>
              <div className="element-grid ">
                <Link to={
                  auth.user.role === ROLE.TEAM_LEADER ? 
                    (auth.user.productCount > 0 ? ROUTE_NAVIGATION_PATH.PRODUCT_LIST : ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS)
                    :
                    ROUTE_NAVIGATION_PATH.PRODUCT_LIST
                  }
                >
                  <div className="_image">
                    <img src={product} alt="MAGICMOMENT" />
                  </div>
                  <div className="_desc-e">
                    <div className="_heading">{t('Products')}</div>
                    <p>
                      {t("elementProductDescription")}
                    </p>
                  </div>
                  </Link>
              </div>
              <div className="element-grid ">
                <Link to={
                  auth.user.role === ROLE.TEAM_LEADER ?
                    (auth.user.questionCount > 0 ? ROUTE_NAVIGATION_PATH.QUESTION_LIST : ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS)
                    :
                    ROUTE_NAVIGATION_PATH.QUESTION_LIST
                  }
                >
                  <div className="_image">
                    <img src={question} alt="MAGICMOMENT" />
                  </div>
                  <div className="_desc-e">
                    <div className="_heading">{t('Questions')}</div>
                    <p>
                      {t("elementQuestionDescription")}
                    </p>
                  </div>
                  </Link>
              </div>
              
              {
                auth.user.role === ROLE.TEAM_LEADER ?
                  <>
                    <div className="element-grid ">
                      <Link to={
                        auth.user.role === ROLE.TEAM_LEADER ? 
                          (auth.user.pathCount > 0 ? ROUTE_NAVIGATION_PATH.PATH_LIST : ROUTE_NAVIGATION_PATH.CREATE_PATH)
                          :
                          ROUTE_NAVIGATION_PATH.PATH_LIST
                        }
                      >
                        <div className="_image">
                          <img src={path} alt="MAGICMOMENT" />
                        </div>
                        <div className="_desc-e">
                          <div className="_heading">{t('Paths')}</div>
                          <p>
                            {t("elementPathDescription")}
                          </p>
                        </div>
                        </Link>
                    </div>
                    <div className="element-grid ">
                      <Link to={ROUTE_NAVIGATION_PATH.UPLOAD_ELEMENT_INFO}>
                        <div className="_image">
                          <img src={upload} alt="MAGICMOMENT" />
                        </div>
                        <div className="_desc-e">
                          <div className="_heading">{t('masterElements')}</div>
                          <p>
                            {t("elementMasterUploadDescription")}
                          </p>
                        </div>
                        </Link>
                    </div>
                  </>
                  
                  :
                  null
              }
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
