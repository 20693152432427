import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Info({
  title,
  spantitle,
  nextTitle,
  subTitle,
  description1,
  description2,
  description3,
  description4,
  description5,
  buttonText,
  image,
  nextUrl,
}: {
  title?: React.ReactNode | string;
  spantitle?: string;
  nextTitle?: string;
  subTitle?: string;
  description1?: string;
  description2?: string;
  description3?: string;
  description4?: string;
  description5?: string;
  buttonText?: string;
  image?: string;
  nextUrl: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <section className="main-container aligncenter_section resize_container">
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>
                {/* {title} <span>{spantitle ? t(spantitle) : ""} </span>{" "}
              {nextTitle ? t(nextTitle) : ""} */}
                Creating your Team
              </h1>
              <p>
                {/* {subTitle ? t(subTitle) : ""} */}
                In the following steps, you will learn how to create employees
                for your organization.
                <br />
                This is an important step as without employees, tasks cannot be
                matched appropriately. But don't worry, it's very simple!
              </p>
            </div>
            <div className="creating-team-wrapper">
              <Col md={12} lg={5} xxl={6} className="creating-grid">
                <div className="_image">
                  <img src={image} alt="magicmoment" />
                </div>
              </Col>
              <Col md={12} lg={7} xxl={6} className="_creating-content">
                <div className="subheadingtext">
                  There are <span>2 ways</span> to create your employees
                </div>
                <div className="_content-info">
                  <div className="numbers">
                    1 <span></span>
                  </div>
                  <div className="_informationtext">
                    <h3>Get Started</h3>
                    <p>
                      Click <span>"Let's Go"</span> and proceed to fill in the
                      necessary information for each employee. This includes
                      their name, surname, email, position, and picture.
                    </p>
                  </div>

                  {/* {description1 ? <p>{t(description1)}</p> : null}
                  {description2 ? <p>{t(description2)}</p> : null}
                  {description3 ? <p>{t(description3)}</p> : null}
                  {description4 ? <p>{t(description4)}</p> : null}
                  {description5 ? <p>{t(description5)}</p> : null} */}
                </div>
                <div className="_content-info">
                  <div className="numbers">
                    2 <span></span>
                  </div>
                  <div className="_informationtext">
                    <h3>Excel Template</h3>
                    <p>
                      Download the <span> Excel template</span> provided, transfer your
                      employee data from your existing Excel list, and fill in
                      the necessary information. Once you're finished, just
                      upload the template, and the system will automatically add
                      the employees for you.
                    </p>
                  </div>
                </div>
             
                {/* <div className="manage-team-skill-info">
                <div className="skill-info-text">
                  <p>
                    You haven’t created your team yet. Let’s add your team
                    members easily
                  </p>
                  <div className="btn-wrapper ">
                    <Link to={nextUrl}>
                      <Button variant="primary">Add a team member</Button>
                    </Link>
                  </div>
                </div>
              </div> */}
              </Col>
            </div>
            <div className="creating-bottom-info">
            <p>It is important to create all employees correctly so that the software can match tasks appropriately. </p>
            <p>You can also add more employees later or edit existing employees if there are any changes.</p>
          {buttonText ? (
                  <div className="btn-wrapper ">
                    <Link to={nextUrl}>
                      <Button variant="primary">
                        {/* {t(buttonText)} */}
                        Let’s go
                        </Button>
                    </Link>
                  </div>
                ) : null}
                <span>{t('justOne')} <span>more</span> step to finish !</span>
          </div>
          </div>
    
        </section>
      </Row>
    </>
  );
}
