import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Any } from "../../types/global.type";
import EditProduct from "../Product/EditProduct";

export default function EditProductModal({
  detail,
  handleClose,
  updateListItem,
}: {
  detail: any;
  handleClose: VoidFunction;
  updateListItem: (product: Any) => void;
}) {
  const { t } = useTranslation();

  function onSuccess(data: Any) {
    updateListItem(data)
  }

  return (
    <Modal
        show={!!detail}
        onHide={handleClose}
        // backdrop="static"
        dialogClassName={"team-memeber-model"}
        keyboard={false}
    >
        <Modal.Header>
            <Modal.Title>{t("editProduct")}</Modal.Title>
        </Modal.Header>
        <div className="form-diaolog">
        <EditProduct data={detail} successCb={onSuccess}>
          {" "}
          <Modal.Footer>
            <Button className="btndelete cancel" onClick={handleClose}>
                {t("Cancel")}
            </Button>
            <Button className="btndelete " type="submit">
                {t("save")}
            </Button>
          </Modal.Footer>
        </EditProduct>
        </div>
    </Modal>
  );
}
