import { Member } from "../../../types/User";
import { ROLE, USER_STATUS } from "../../../constants";
import Form from "react-bootstrap/Form";
import xIcon from "../../../assets/images/x.svg";
import xIconRed from "../../../assets/images/xred.svg";

import { useState } from "react";
import { team } from "../../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import { useAuth } from "../../../hooks/useAuth";

export default function ManageTeamTile({
  userDetail,
  editMember,
  deleteMember,
  updateListItem,
}: {
  userDetail: Member;
  editMember: VoidFunction;
  deleteMember: VoidFunction;
  updateListItem: (userDetail: Member) => void;
}) {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  const switchValue =
  userDetail.status === USER_STATUS.ACTIVE
      ? true
      : false;
  const [checked, setChecked] = useState(switchValue);
  let { user } = useAuth();

  const handleChange = (event: any) => {
    let status =
      event.target.checked === true ? USER_STATUS.ACTIVE : USER_STATUS.SUSPENDED;
    
    team
      .updateUserStatus(userDetail._id, status)
      .then((res) => {
        updateListItem({ ...userDetail, status });
        showMessage({
          heading: t("UserStatusUpdate"),
          body: <p>{res.message}</p>,
          type: "success",
        });
        setChecked(status == 1 ? true: false);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };


  function formatDate(value:any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return  format(date, 'dd MMM yyyy, h:mm aaa');
  }

  return (
    <tbody>
      <tr>
        <td>
            {userDetail.name} {userDetail.surname}
        </td>
        <td>{userDetail.email}</td>
        <td>
          { user.role === ROLE.TEAM_LEADER ?
            (userDetail.status === USER_STATUS.ACTIVE ?
              <span className="active-s"></span>
              :
              <span className="nonactive-s"></span>
            )
            :
            (userDetail.loginStatus === USER_STATUS.ACTIVE ?
              <span className="active-s"></span>
              :
              <span className="nonactive-s"></span>
            )
          }

          { user.role === ROLE.TEAM_LEADER ?
              (userDetail.status === USER_STATUS.ACTIVE ? t('Active') : t('Inactive'))
            :
            (userDetail.loginStatus === USER_STATUS.ACTIVE ? 'Online' : 'Offline')
          }
        </td>
        <td>
          { user.role === ROLE.TEAM_LEADER ?
            <Form>
              <Form.Check
                type="switch"
                checked={checked}
                onChange={handleChange}
                id="custom-switch"
              />
            </Form>
            :
            user?.position
          }
        </td>
        <td>{ formatDate(userDetail.createdAt)} </td>
        { user.role === ROLE.TEAM_LEADER ?
          <td className="action-btn mm-action">
              <span className="delete" onClick={deleteMember}>
              <img src={xIcon} alt="" className="nonhover" />
              <img src={xIconRed} alt="" className="hover" />
                {t("Remove")}
              </span>
              <span className="edit" onClick={editMember}>
                {t("Edit")}
              </span>
          </td>
          :
          null
        }
      </tr>
    </tbody>
  );
}
