import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import { useTranslation } from "react-i18next";
import iconInfo from "../../assets/images/iconInfo.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import iconError from "../../assets/images/iconError.svg";
import iconRemove from "../../assets/images/iconRemoveRed.svg";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";

const ICON = {
  success: iconSuccess,
  error: iconError,
  info: iconInfo,
  warning: iconRemove,
};

export default function AddQuestionConfimration({
    detail,
    show,
    handleClose,
    updateListItem
}: {
    detail: any;
    show: boolean;
    handleClose: VoidFunction;
    updateListItem: () => void;
}) {
  const { t } = useTranslation();

  function closeDialog() {
    handleClose();
  }

  function discardStep() {
    updateListItem();
  }
 
  return (
    <Modal
      show={show}
     // backdrop="static"
      keyboard={false}
      dialogClassName={"model-common _confirmation-common"}
    >
      <Modal.Header>
          <div className="_icon-img">
            <img src={iconSuccess} alt="info" />
          </div>
        <Modal.Title>{t("addQuestion")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign: "center", marginBottom: "25px"}}>{t("addQuestionInSteps")}</Modal.Body>
      <Modal.Footer>
          <Button variant="primary" type ="button" onClick={discardStep}>
            {t('Discard')}
          </Button>
          
          <Button variant="primary" type ="button" onClick={handleClose}>
            {t("Ok")}
          </Button>
        
      </Modal.Footer>
    </Modal>
  );
}
