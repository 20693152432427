import Button from "react-bootstrap/Button";
import { Form } from "react-final-form";
import {
  composeValidators,
  required,
  validPassword,
  confirmPasswordMatchValidation,
} from "../../../../validations";

import { PasswordField } from "../../../../components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useMessageModal } from "../../../../hooks/useMessage";
import { authetication } from "../../../../api";
import { useParams } from "react-router";
import { encryptPassword } from "../../../../utils";
import { useTranslation } from "react-i18next";
import quotes from "../../../../assets/images/quotes.svg";
import profileimg from "../../../../assets/images/profile-img.png";
import logo from "../../../../assets/images/logo.svg";
import backIcon from "../../../../assets/images/iconBack.svg";
import { useCustomMutation } from "../../../../hooks/useApi";

function ResetForm() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  const mutation = useCustomMutation({
    mutationFn: authetication.doRestPassword,
    onSuccess: () => {
      showMessage({
        heading: t("success"),
        body: <p>Passwort wurde erfolgreich zurückgesetzt</p>,
        type: "success",
        callback: () => {
          navigate(ROUTE_NAVIGATION_PATH.SIGN_IN);
        },
      });
    },
  });

  const onSubmit = (values: any) => {
    const data = {
      resetPasswordToken: params.token,
      password: encryptPassword(values.password),
    };
    mutation.mutate(data);
  };

  return (
    <>
     <div className="_logo">
        <img src={logo} alt="MAGICMOMENT" />
      </div>
      <h2 className="mt-5">
        Passwort zurücksetzen
      </h2>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <PasswordField
              placeholder="Passwort eingeben"
              validations={composeValidators(required, validPassword)}
            />

            <PasswordField
              validations={composeValidators(
                required,
                confirmPasswordMatchValidation(values.password)
              )}
              name="confirmPassword"
              placeholder="Passwort bestätigen"
            />

            <Button variant="primary" type="submit">
              Passwort zurücksetzen
            </Button>
            <Button href="/auth" variant="link2">
              <img src={backIcon} alt="" /> Zurück zur Anmeldung
            </Button>
          </form>
        )}
      />
    </>
  );
}
export default function ResetPassword() {
  return (
    <Row className="bg-authlayout">
      <Col sm={4} className="p-0 left-column">
        <div className="bg-secondary vh-100">
          <div className="welcome-container">
            <div className="welcome-text">
              <h1>
                Ihr smarter Assistant <br />
                im Kundenservice
              </h1>
              <p>
              Intelligente Unterstützung ihrer Call-Center-Mitarbeiter zur Ermittlung von Upsell-Potentialen beim Kunden
              </p>
            </div>
            <div className="testimonials-login">
              <div className="testimonialcontent-bottom">
                <div className="quotes">
                  <img src={quotes} alt="MAGICMOMENT" />
                </div>
                <div className="testimonialstxt">
                  <p>
                    “Das Tool ermöglicht es mir, Kunden basierend auf ihren individuellen Anfragen und Bedürfnissen zum optimalen Zeitpunkt passende Produktvorschläge zu unterbreiten.”
                  </p>
                </div>
                <div className="testimonialprofile">
                  <div className="profiletestim">
                    <img src={profileimg} alt="MAGICMOMENT" />
                  </div>
                  <div className="profile-info">
                    <div className="name">Leslie Alexander</div>
                    <div className="designation">React Developer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col
        sm={8}
        className="d-flex justify-content-center align-items-center login-signup-page-card"
      >
        <div className="outer-form">
          <div className="scrollbar100">
            <div className="login-form">
              <div className="w-100">
                <ResetForm />
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
