
import xIconRed from "../../assets/images/xred.svg";
import xIcon from "../../assets/images/x.svg";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { ROLE } from "../../constants";
import bronzeIcon  from "../../assets/images/bronze.png";
import silverIcon  from "../../assets/images/silver.png";
import goldIcon  from "../../assets/images/gold.png";
import { useEffect, useState } from "react";
import LineChart from "../Charts/LineChart";

export default function CategoryTile({
    category,
    index,
    editCategory,
    deleteCategory
}: {
    category: any;
    index?: number;
    editCategory: VoidFunction;
    deleteCategory: VoidFunction;
}) {
    const { t } = useTranslation();
    const auth = useAuth();
    const [label, setLabel]  = useState<any>([]);
    const [count, setCount] = useState<any>([]);

    useEffect(() => {
        setLabel([]);
        setCount([])
        var frequency = JSON.parse(JSON.stringify(category));
        frequency.frequencies.forEach((element:any) => {
            setLabel((prev:any) => [...prev, element.date])
            setCount((prev:any) => [...prev, element.count])
        });
       
    }, [category]);

    function formatDate(value:any) {
        const format1 = "MMM DD, YYYY"
        const date = new Date(value);
        // Format the date to DD-MM-YYYY
        return  moment(date).format(format1) + ' at ' + moment(date).format('HH:mm A');
    }

    return (
        <> 
            <tr>
                {
                    index  ?
                    (
                        index <= 3 ?
                        <td className="rankth">
                            <div className="rank-txt ">
                              {/* <span>{index}</span> */}
                              <img src={ index ===1 ? goldIcon : index === 2 ? silverIcon : bronzeIcon }  alt="rank"/>
                            </div>
                        </td>
                        :
                    
                        <td className="question _rankqieston">
                            <div className="rank-txt">
                               {index}
                            </div>
                        </td>
                    )
                    :
                    null
                    
                    
                }
                <td>
                    <div className="question">
                        {category.name}
                    </div>
                </td>
                <td>{formatDate(category.updatedAt)}</td>
                <td>
                    {
                        count.length > 0 ?
                            <LineChart labels={label} count={count} />
                        :
                        '-' 
                    }
                </td>
                {
                        auth.user.role === ROLE.TEAM_LEADER ?
                <td>
                  
                        <div className="btnedit-delelte">
                            <button className="btntd" onClick={editCategory}>Edit</button>
                            <button className="btntd delte" onClick={deleteCategory}>
                                <img src={xIconRed} alt="" className="hover" />
                                <img src={xIcon} alt="" className="nonhover" />{" "}
                                {t("Remove")}
                            </button>
                        </div>
                  
                
                
                </td>
                      :
                      null
                  }
            </tr>
        </>
    );
}
