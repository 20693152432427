import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { useNavigate, createSearchParams } from "react-router-dom";

export default function UploadElementInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (path:string) => {
    navigate({
      pathname: ROUTE_NAVIGATION_PATH.UPLOAD_ELEMENT,
          search: createSearchParams({
              redirect: path
          }).toString()
    });
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
              <div className="heading-common">
                  <h1>{t("uploadElements")}</h1>
                  <p>{t("uploadElementsSubHeading")}</p>
              </div>

              <div className="upload-element-page">
                  <div className="grid-ele" onClick={ () => handleClick("team")}>
                     <h2>{t("Create Team")}</h2>
                  </div>
                  <div className="grid-ele" onClick={ () => handleClick("category")}>
                     <h2>{t("Create categories")}</h2>
                  </div>
                  <div className="grid-ele" onClick={ () => handleClick("product")}>
                     <h2>{t("Create Product")}</h2>
                  </div>
                  <div className="grid-ele" onClick={ () => handleClick("question")} >
                     <h2>{t("Create Questions")}</h2>
                  </div>
              </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}