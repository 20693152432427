import { useEffect } from "react";
import {
  composeValidators,
  required,
  validEmail,
  maxlength,
} from "../../validations";
import { FormControl } from "../FormElements/FormControl";
import {
  VALIDATIONS
} from "../../constants";
import IconRemove from "../../assets/images/iconRemoveWhite.svg";
import profileImg from "../../assets/images/icon-profile.png";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import useUploadImage from "../../hooks/useUploadImage";

export default function AddEditMember({
  updateImage,
  isEditPhoto = false,
  editForm,
  photo = "",
  onlyPhoto = false,
  isSurnameNotEditable = false,
  isDeleteButton = true
}: {
  updateImage: (file: File | null | undefined) => void;
  isEditPhoto?: boolean;
  editForm?: boolean;
  photo?: string;
  onlyPhoto?: boolean;
  isSurnameNotEditable?: boolean;
  isDeleteButton?: boolean;
}) {

  
  const { t } = useTranslation();
  const { onImageChange, image, file, errorMsg, setImage } = useUploadImage({
    photo: editForm ? photo : "",
  });

  useEffect(() => {
    updateImage(file);
  }, [file]);

  const removeImage = () => {
    setImage("")
    updateImage(null);
  };
  return (
    <>
      <div className={`upload-photo ${onlyPhoto ? "justify-center" : ""}`}>
        <p className="phototxt">{t('Photo')}</p>
        <div className="btnwrappertext">
          <div className="cover-photo">
            <div className="coverimg">
            {isDeleteButton ? 
              null
              :
              <div className="hover-action">
                <img src={IconRemove} alt="remove" onClick={removeImage} />
              </div>
            }
              
              {image ? (
                <img
                  src={image}
                  onError={(e: any) => {
                    e.target.src = profileImg;
                  }}
                  alt="ProfileImg"
                />
              ) : null}
            </div>
            {errorMsg? <div className="max-img-size-error">{errorMsg}</div> : null}
          </div>
          {/* {!isSuccess ? <div className="max-img-size-error">{ errorMsg }</div> : null } */}
          <div className="right_side">
            <div className="file-input">
              <span>{isEditPhoto ? t("EditPhoto") : editForm ? t("UploadPhoto") : t("AddPhoto")}</span>
              <input
                className="form-control"
                type="file"
                onChange={onImageChange}
              />
            </div>
            {
              isDeleteButton ? 
              <Button variant="delete" type="button" onClick={removeImage}>
                {t('DeletePhoto')}
              </Button>
              :
              null
            }
            <p>{t('photoDescription')}</p>
          </div>
        </div>
      </div>
      {!onlyPhoto ? (
        <>
          <FormControl
            label="firstName"
            name="name"
            type="text"
            validate={composeValidators(
              required,
              maxlength(VALIDATIONS.MAX_NAME)
            )}
            // placeholder="Name"
            // icon={IconUser}
          />
          {!isSurnameNotEditable ? (
            <FormControl
              label="Surname"
              name="surname"
              type="text"
              validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_NAME)
              )}
              // placeholder="Surname"
              // icon={IconUser}
            />
          ) : null}
          <FormControl
            label="Email"
            name="email"
            type="email"
            disabled={editForm ? true : false}
            validate={composeValidators(required, validEmail)}
            // placeholder="Email"
            // icon={IconEmail}
          />
          <FormControl
            label="Position (optinal)"
            name="position"
            type="text"
            // placeholder="Job title"
            // icon={IconPostionBlue}
          />
        </>
      ) : null}
    </>
  );
}
