export default function IconDelete() {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M21.92 7.63636H18.68V6.54545C18.68 5.87036 18.4145 5.22291 17.9419 4.74555C17.4693 4.26818 16.8283 4 16.16 4H11.84C11.1717 4 10.5307 4.26818 10.0581 4.74555C9.5855 5.22291 9.32 5.87036 9.32 6.54545V7.63636H6.08C5.79357 7.63636 5.51886 7.7513 5.31632 7.95588C5.11379 8.16047 5 8.43795 5 8.72727C5 9.0166 5.11379 9.29408 5.31632 9.49866C5.51886 9.70325 5.79357 9.81818 6.08 9.81818H6.44V22.1818C6.44 22.664 6.62964 23.1265 6.96721 23.4675C7.30477 23.8084 7.76261 24 8.24 24H19.76C20.2374 24 20.6952 23.8084 21.0328 23.4675C21.3704 23.1265 21.56 22.664 21.56 22.1818V9.81818H21.92C22.2064 9.81818 22.4811 9.70325 22.6837 9.49866C22.8862 9.29408 23 9.0166 23 8.72727C23 8.43795 22.8862 8.16047 22.6837 7.95588C22.4811 7.7513 22.2064 7.63636 21.92 7.63636ZM11.48 6.54545C11.48 6.44901 11.5179 6.35652 11.5854 6.28832C11.653 6.22013 11.7445 6.18182 11.84 6.18182H16.16C16.2555 6.18182 16.347 6.22013 16.4146 6.28832C16.4821 6.35652 16.52 6.44901 16.52 6.54545V7.63636H11.48V6.54545ZM19.4 21.8182H8.6V9.81818H19.4V21.8182ZM12.92 12.7273V18.5455C12.92 18.8348 12.8062 19.1123 12.6037 19.3168C12.4011 19.5214 12.1264 19.6364 11.84 19.6364C11.5536 19.6364 11.2789 19.5214 11.0763 19.3168C10.8738 19.1123 10.76 18.8348 10.76 18.5455V12.7273C10.76 12.4379 10.8738 12.1605 11.0763 11.9559C11.2789 11.7513 11.5536 11.6364 11.84 11.6364C12.1264 11.6364 12.4011 11.7513 12.6037 11.9559C12.8062 12.1605 12.92 12.4379 12.92 12.7273ZM17.24 12.7273V18.5455C17.24 18.8348 17.1262 19.1123 16.9237 19.3168C16.7211 19.5214 16.4464 19.6364 16.16 19.6364C15.8736 19.6364 15.5989 19.5214 15.3963 19.3168C15.1938 19.1123 15.08 18.8348 15.08 18.5455V12.7273C15.08 12.4379 15.1938 12.1605 15.3963 11.9559C15.5989 11.7513 15.8736 11.6364 16.16 11.6364C16.4464 11.6364 16.7211 11.7513 16.9237 11.9559C17.1262 12.1605 17.24 12.4379 17.24 12.7273Z"
          fill="#B6B8BA"
        />
      </svg>
    </>
  );
}
