import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import iconProfile from "../../../assets/images/icon-profile.png";
import iconBell from "../../../assets/images/bell.svg";
import IconNext from "../../../assets/images/cheveron-right2.svg";
import IconDown from "../../../assets/images/downarrow.svg";
import { useEffect, useRef, useState } from "react";
import { dashboard, notifications, question, team } from "../../../api";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { NList, NoData } from "../../../components";
import { SUGGESTION_STATUS, USER_STATUS } from "../../../constants";
import { Member } from "../../../types/User";
import "chart.js/auto";
import ProgressBar from "../../../components/ProgressBar";
import LeaderDashboardStats from "../../../components/Dashboard/LeaderDashboardStats";
import DonutChart from "../../../components/Charts/DonutChart";


export default function LeaderDashboard() {
  const role = "1";
  const auth = useAuth();
  const { t } = useTranslation();
  const [expendMenu, setExpendMenu] = useState(false);
  const [talkToggle, setTalktoggle] = useState(false);
  const [talkInitialSelection, setTalkInitialSelection] = useState(2);
  const [totalStats, settotalStats] = useState<any>({});
  const effectRan = useRef(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [suggestionQuestion, setSuggestionQuestion] = useState<Member[]>([]);
  const {updateUserData}  = useAuth();
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0);

  useEffect(() => {
    team
      .getOfflineOnlineMembers({
        page: 1,
        count: 2,
        status: USER_STATUS.ALL,
        role: role,
      })
      .then((res: { data: Member[], totalUsersCount: any }) => {
        setTotalUsersCount(res.totalUsersCount)
        setMembers(res.data);
      });
  }, []);

  useEffect(() => {
    if (!effectRan.current) {
      dashboard.getLeaderDashboardStats().then((res: { data: any }) => {
        settotalStats(res.data);
      });
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  useEffect(() => {
    question.getQuestionProposalList({
      page: 1,
      count: 2,
      status: USER_STATUS.ALL,
    }).then((res: { data: Member[] }) => {
      setSuggestionQuestion(res.data);
    });
  }, [])

  useEffect(() => {
    notifications.getNotificationList({
      page: 1,
      count: 2
    }).then((res:any) => {
      setTotalNotificationsCount(res.count);
    });
  }, [])

  const capitalizeFirst = (str: string) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  function toggleMenuExpend() {
    setExpendMenu(!expendMenu);
    if(expendMenu) {
      notifications.bulkRead().then((res:any) => {
        updateUserData({ totalNotificationsCount: 0 });
        setTotalNotificationsCount(0);
      });
    }
  }

  function avgTalkToggele() {
    setTalktoggle(!talkToggle);
  }

  function avgDurationSelection(value: number) {
    setTalkInitialSelection(value);
    avgTalkToggele();
  }

  const truncateText = (text: string) => {
    return text && text?.length > 24 ? text.slice(0, 100) + "..." : text;
  };


  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="dashboard-header">
              <div className="profile-content">
                <div className="image">
                  <img
                    src={auth.user?.image ? auth.user?.image : iconProfile}
                    onError={(e: any) => {
                      e.target.src = iconProfile;
                    }}
                    alt="ProfileImg"
                  />
                </div>
                <div className="profile-info">
                  <div className="name">
                    {t("hi")}{" "}
                    {capitalizeFirst(auth.user.name) +
                      " " +
                      (auth.user.surname
                        ? capitalizeFirst(auth.user.surname)
                        : "")}
                    ,
                  </div>
                  <h2>{t("welcomeBack")} 👋</h2>
                </div>
              </div>
              <div className="notifiactioncontent">
                <div className="bell" onClick={toggleMenuExpend}>
                  {
                  totalNotificationsCount > 0 ? (
                    <span></span>
                  ) : null}
                  <img src={iconBell} alt="magicmoment" />
                </div>
                {expendMenu ? <NList /> : null}
              </div>
            </div>
            <div className="dashboard-wrapper">
              <LeaderDashboardStats totalStats={totalStats} />
              <div className="dashboard-mid-content">
                <div className="dasboard-left">
                  <div className="left-content">
                    {totalStats.top3Questions ? (
                      <DonutChart totalStats={totalStats} />
                    ) : null}

                    <div className="call-chart">
                      <div className="top-heading">
                        <h3>{t("totalCalls")}</h3>
                        {totalStats.top10AgentsCalls?.callers.length > 0 ? (
                          <Link to={ROUTE_NAVIGATION_PATH.CALL_LIST}>
                            {t("seeAllCalls")}{" "}
                            <img src={IconNext} alt="magicmoment" />
                          </Link>
                        ) : null}
                      </div>
                      <div className="progress-content">
                        {totalStats.top10AgentsCalls?.callers.length === 0 ? (
                          <NoData />
                        ) : null}
                        {totalStats?.top10AgentsCalls?.callers.map(
                          (calls: any) => (
                            <ProgressBar
                              key={calls?._id}
                              name={calls.agentName}
                              totalNumber={
                                totalStats?.top10AgentsCalls.totalCallsCount
                              }
                              now={calls?.numberOfCalls}
                              min={0}
                              max={totalStats?.top10AgentsCalls.totalCallsCount}
                              width={
                                (calls?.numberOfCalls /
                                  totalStats?.top10AgentsCalls
                                    .totalCallsCount) *
                                100
                              }
                              background={"#5248DD"}
                            />
                          )
                        )}
                      </div>
                    </div>
                      <div className="dashboard-right">
                        <div className="top-heading">
                          <h3>{t("agentAvailable")} ({totalUsersCount})</h3>
                          {members.length > 0 ? (
                            <Link to={ROUTE_NAVIGATION_PATH.AGENT_LIST}>
                              {t("seeAllAgents")}
                              <img src={IconNext} alt="magicmoment" />
                            </Link>
                          ) : null}
                        </div>
                        <div className="availble-agent-content">
                          {!members.length ? <NoData /> : null}

                          {members.map((member: any, key: any) => (
                            <div className="agent-listing" key={member?._id}>
                              <div className="left-text">
                                <div className="_image">
                                  <img
                                    src={
                                      member?.image
                                        ? member?.image
                                        : iconProfile
                                    }
                                    onError={(e: any) => {
                                      e.target.src = iconProfile;
                                    }}
                                    alt="ProfileImg"
                                  />
                                </div>
                                <div className="user-info">
                                  <div className="name">
                                    {capitalizeFirst(member.name) +
                                      " " +
                                      (member.surname
                                        ? capitalizeFirst(member.surname)
                                        : "")}
                                  </div>
                                  <div className="email">{member.email}</div>
                                </div>
                              </div>
                              <div className="righttext">
                                <div className="connected">
                                  {" "}
                                  {member.loginStatus === USER_STATUS.ACTIVE ? (
                                    <span className="green"></span>
                                  ) : (
                                    <span className="grey"></span>
                                  )}
                                  {member.loginStatus === USER_STATUS.ACTIVE
                                    ? "Online"
                                    : "Offline"}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="top-heading _topspace">
                          <h3>{t("suggestedQuestions")}</h3>
                          {suggestionQuestion.length > 0 ? (
                            <Link to={ROUTE_NAVIGATION_PATH.SUGGESTION_LIST}>
                              {t("viewAll")}
                              <img src={IconNext} alt="magicmoment" />
                            </Link>
                          ) : null}
                        </div>
                        <div className="availble-agent-content">
                          {suggestionQuestion.length === 0 ? <NoData /> : null}

                          {suggestionQuestion.map((data: any, key: any) => (
                            <div className="agent-listing" key={data?._id}>
                              <div className="left-sugesttxt">
                              <div className="pathname2">
                               <p>{data.path?.name}</p> 
                              </div>
                              <div className="patdes2">
                               <p>{truncateText(data.question)}</p> 
                              </div>
                              </div>
                              <div className="righttext">
                                <div className="connected">

                                <span className={`btntxt ${
                                  data.status === SUGGESTION_STATUS.ACTIVE ? 'green' 
                                    : ( data.status === SUGGESTION_STATUS.REJECTED ?'_reject' : 'grey')}
                                `}></span> 
                                {data.status === SUGGESTION_STATUS.ACTIVE ? t('Accepted') : ( data.status === SUGGESTION_STATUS.REJECTED ? t('Declined') : t('Pending'))}
                                </div>
                              </div>
                             
                            </div>
                          ))}
                        </div>
                    </div>
                  </div>

                  <div className="content-fullwidth">
                    <div className="top-heading">
                      <h3>{t("avgTalkDurationByAgent")}</h3>
                      {totalStats?.top10AgentsAverageCalls?.averageCallDuration
                        .length > 0 ? (
                        <div className="d-flex">
                          <div className="in-min">
                            <span
                              className="dropheading"
                              onClick={avgTalkToggele}
                            >
                              {talkInitialSelection === 1 ? "In Hours" : "In Min"}
                              <img src={IconDown} alt="MagicMoment" />
                            </span>
                            {talkToggle ? (
                              <div className="dropdowncontent">
                                <ul>
                                  <li
                                    className={
                                      talkInitialSelection !== 1 ? "active" : ""
                                    }
                                    onClick={() => avgDurationSelection(2)}
                                  >
                                    In Min
                                  </li>
                                  <li
                                    className={
                                      talkInitialSelection === 1 ? "active" : ""
                                    }
                                    onClick={() => avgDurationSelection(1)}
                                  >
                                    In Hours
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>

                          <Link to={ROUTE_NAVIGATION_PATH.TALKING_DURATION}>
                            {t("seeAllDurations")}{" "}
                            <img src={IconNext} alt="magicmoment" />
                          </Link>
                        </div>
                      ) : null}
                    </div>
                    <div className="progress-content">
                      {totalStats?.top10AgentsAverageCalls?.averageCallDuration
                        .length === 0 ? (
                        <NoData />
                      ) : null}
                      {totalStats?.top10AgentsAverageCalls?.averageCallDuration.map(
                        (calls: any) => (
                          <ProgressBar
                            key={calls?._id}
                            name={calls.agentName}
                            totalNumber={
                              talkInitialSelection === 1
                                ? totalStats?.top10AgentsAverageCalls
                                    .totalAvgCallsCountInHours
                                : totalStats?.top10AgentsAverageCalls
                                    .totalAvgCallsCountInMinutes
                            }
                            now={
                              talkInitialSelection === 1
                                ? calls?.avgTimeSpentInHours
                                : calls?.avgTimeSpentInMinutes
                            }
                            min={0}
                            max={
                              talkInitialSelection === 1
                                ? totalStats?.top10AgentsAverageCalls
                                    .totalAvgCallsCountInHours
                                : totalStats?.top10AgentsAverageCalls
                                    .totalAvgCallsCountInMinutes
                            }
                            width={
                              (talkInitialSelection === 1
                                ? calls?.avgTimeSpentInHours /
                                  totalStats?.top10AgentsAverageCalls
                                    .totalAvgCallsCountInHours
                                : calls?.avgTimeSpentInMinutes /
                                  totalStats?.top10AgentsAverageCalls
                                    .totalAvgCallsCountInMinutes) * 100
                            }
                            background={"#fca311"}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
