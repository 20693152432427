import { useTranslation } from "react-i18next";
import iconUp from "../../assets/images/arrow-up2.svg";
import iconDown from "../../assets/images/downred.svg";

import { Any } from "../../types/global.type";

export default function LeaderDashboardStats({
    totalStats
}: {
    totalStats: Any 
}) {
    const { t } = useTranslation();

    return (
        <div className="dashboard-top-content">
        <div className="topgrid">
          <h3>{t("agendLoggedIn")}</h3>
          <div className="loginfo">
            <div className="numbers">
              {totalStats.loggedInAgents?.numberOfLoggedInAgents} 
            </div>
            <div className={ parseInt(totalStats.loggedInAgents?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
              {totalStats.loggedInAgents?.percentage.toString().replace("-", "")}%
              <img src={ parseInt(totalStats.loggedInAgents?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
            </div>
          </div>
        </div>
        <div className="topgrid">
          <h3>{t("callsToday")}</h3>
          <div className="loginfo">
            <div className="numbers">
              {totalStats.callsToday?.numberOfCalls}
            </div>
            <div className={ parseInt(totalStats.callsToday?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
              {totalStats.callsToday?.percentage.toString().replace("-", "")}%
              <img src={ parseInt(totalStats.callsToday?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
            </div>
          </div>
        </div>
        <div className="topgrid">
          <h3>{t("avgCallLength")} (Min)</h3>
          <div className="loginfo">
            <div className="numbers">
              {totalStats.averageCallLength?.numberOfCallLength != 0 ? (totalStats.averageCallLength?.numberOfCallLength / 60).toFixed(2) : 0}
            </div>
            <div className={ parseInt(totalStats.averageCallLength?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
              {totalStats.averageCallLength?.percentage.toString().replace("-", "")}%
              <img src={ parseInt(totalStats.averageCallLength?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
            </div>
          </div>
        </div>
        <div className="topgrid">
          <h3>{t("avgSpeedOfAnswer")} (Min)</h3>
          <div className="loginfo">
            <div className="numbers">
              {totalStats.averageSpeedOfAnswer?.numberOfCallSpeedLength != 0 ?(totalStats.averageSpeedOfAnswer?.numberOfCallSpeedLength / 60).toFixed(2) : 0}
            </div>
            <div className={ parseInt(totalStats.averageSpeedOfAnswer?.percentage) < 0 ? "down-percentage": "growth-percentage"}>
              {totalStats.averageSpeedOfAnswer?.percentage.toString().replace("-", "")}%
              <img src={ parseInt(totalStats.averageSpeedOfAnswer?.percentage) < 0 ? iconDown : iconUp } alt="magicmoment" />
            </div>
          </div>
        </div>
      </div>
    );
}
