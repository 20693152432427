import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import profileImg from "../../../assets/images/icon-profile.png";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { team, myprofile } from "../../../api";
import { Member } from "../../../types/User";
import { TEAM_ADMIN_POSITION } from "../../../constants";

import {
  AppSettings,
  EditTeamMember,
  PasswordField,
} from "../../../components";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { composeValidators, confirmPasswordMatchValidation, required, validPassword } from "../../../validations";
import { encryptPassword } from "../../../utils";

export default function LeaderSetting() {
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [details, setUpdatedDetails] = useState<Member | null>(null);
  const { t } = useTranslation();
  const { user } = useAuth();
  const { signout } = useAuth();

  useEffect(() => {
    team
      .getLeaderProfile()
      .then((res) => {
        res.data.userInfo["position"] = TEAM_ADMIN_POSITION;
        setDetailsToShow(res.data.userInfo);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    setDetailsToShow(updatedData);
    setUpdatedDetails(null);
  };
  function formatDate(value: any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return date.toLocaleDateString("en-GB").split("/").join("-");
  }

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      oldPassword: encryptPassword(values.oldPassword),
      newPassword: encryptPassword(values.newPassword),
    };
    delete data.confirmPassword;
    myprofile
      .changePassword(data)
      .then((res) => {
          showMessage({
            heading: t("ChangePassword"),
            body: <p>{t('ChangePasswordSuccess')}</p>,
            type: "success",
            callback: () => {
              signout();
            }
          });
        
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{t(error?.response?.data?.errorMessage as string) }</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <Row>
        {/* <section className="main-container shrink-width"> */}
          <div className="heading-common">
            <h1>
              {t("My")}
              <span> {t("Setting")}</span>
            </h1>
            <p className="_max-width">{t("updateSettingMsg")}</p>
            {/* <div className="logout" onClick={handleClick}>
              <p className="header">Logout</p>
            </div> */}
          </div>
          {detailsToShow ? (
            <div className="setting-container">
                <Col md={12} className="setting-info-left">
                <div className="setting-info mb-5">
                  <div className="heading">{t("Account")} </div>
                  <div className="task-manage-team-wrapper">
                    <div className="table-content">
                      {user.invoiceId === null ? (
                        "Account not active"
                      ) : (
                        <table>
                          <thead>
                            <tr>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Last Invoice")}</th>
                              <th>{t("Invoice Date")}</th>
                              <th>{t("Permitted Members")}</th>
                              <th>{t("Current Members")}</th>
                              <th>{t("Plan Start Date")}</th>
                              <th>{t("Plan End Date")}</th>
                            </tr>
                          </thead>
                          <tbody style={{ cursor: "pointer" }}>
                            <tr>
                              <td>
                                <div className="profile-info">
                                  <div className="_profile-img">
                                    <img
                                      src={
                                        user?.image
                                          ? user?.image
                                          : profileImg
                                      }
                                      onError={(e: any) => {
                                        e.target.src = profileImg;
                                      }}
                                      alt="ProfileImg"
                                    />
                                    {/* <img
                                      src={badges}
                                      alt="profile"
                                      className="badges"
                                    /> */}
                                  </div>
                                  <p>
                                    {user.name} {user.surname}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p className="emailuser">{user.email}</p>
                              </td>
                              <td>
                                {user.invoiceId.invoiceAmount
                                  ?  user.invoiceId.invoiceAmount + " €"
                                  : ""}
                              </td>
                              <td>
                                {user.invoiceId.invoiceDate
                                  ? formatDate(user.invoiceId.invoiceDate)
                                  : ""}
                              </td>
                              <td>
                                {user.invoiceId.permittedMembers
                                  ? user.invoiceId.permittedMembers
                                  : 0}
                              </td>
                              <td>
                                {user.invoiceId.addedMembers
                                  ? user.invoiceId.addedMembers
                                  : 0}
                              </td>
                              <td>
                                {user.invoiceId.planStartDate
                                  ? formatDate(user.invoiceId.planStartDate)
                                  : ""}
                              </td>
                              <td>
                                {user.invoiceId.planEndDate
                                  ? formatDate(user.invoiceId.planEndDate)
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
                </Col>
              <Col md={12} xl={8} className="setting-info-left">
                <div className="setting-info">
                  <div className="heading">{t("ChangePassword")} </div>
                  <div className="form">
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => (
                      <form onSubmit={handleSubmit}>
                        <PasswordField 
                          name="oldPassword" 
                          validations={composeValidators(required, validPassword)} 
                          placeholder={t("Current password") as string}
                        />
                        <PasswordField
                            name="newPassword"
                            validations={composeValidators(required, validPassword)}
                            placeholder={t("New password") as string}
                        />
                        <PasswordField
                          validations={composeValidators(
                            required,
                            confirmPasswordMatchValidation(values.newPassword)
                          )}
                          placeholder={t("Verify Password") as string}
                          name="confirmPassword"
                        />
                        <div className="btn-privacy">
                        <Button variant="primary" type="submit">
                          {t("saveChanges")}
                        </Button>
                        </div>
                       
                      </form>
                    )}
                  />
                  </div>
                </div>
              </Col>
              <Col md={12} xl={4} className="app-setting-content">
                <AppSettings />
              </Col>
            </div>
          ) : (
            <div className="task-myprofile-wrapper">
              <div className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        {/* </section> */}
      </Row>
      <EditTeamMember
        detail={details}
        handleClose={() => setUpdatedDetails(null)}
        updateListItem={updateListItem}
      />
    </>
  );
}
