import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import iconUpload from "../../../../assets/images/iconUpload.svg";
import iconDownload from "../../../../assets/images/excel.png";
import iconFile from "../../../../assets/images/iconFile.svg";
import iconExcel from "../../../../assets/images/excel.png";
import cross from "../../../../assets/images/x.svg";
import { useTranslation } from "react-i18next";
import { FormEvent, useState } from "react";
import { useMessageModal } from "../../../../hooks/useMessage";
import { api } from "../../../../api/api";
import { ENDPOINTS } from "../../../../api/masterUpload";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { saveAs } from "file-saver";
import { useAuth } from "../../../../hooks/useAuth";

const ACCEPT_TYPE = [
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
const ACCEPT_FILE_SIZE = 5000000; // 5 Mb 5;

const DownLoadButtonForError = ({
  sampleFileUrl,
  downloadSample
}: {
  sampleFileUrl: string;
  downloadSample: VoidFunction
}) => (
  <a className="btn btn-download2" onClick={downloadSample} download>
    <div className="icon-download">
      <img src={iconDownload} alt="Download" />
    </div>
    Download
  </a>
);

export default function UploadElements() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {updateUserData}  = useAuth();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const searchParams = new URLSearchParams(document.location.search).get('redirect')

  const { showMessage } = useMessageModal();
  const sampleFileUrl = searchParams === 'team' ? ENDPOINTS.GET_TEAM_SAMPLE_DATA :  (searchParams === 'category' ? 
    ENDPOINTS.GET_CATEGORY_SAMPLE_DATA : (searchParams === 'product' ? ENDPOINTS.GET_PRODUCT_SAMPLE_DATA : ENDPOINTS.GET_QUESTION_SAMPLE_DATA));

  const uploadFileApiUrl = searchParams === 'team' ? ENDPOINTS.UPLOAD_BULK_TEAM_DATA :  (searchParams === 'category' ? 
    ENDPOINTS.UPLOAD_BULK_CATEGORY_DATA : (searchParams === 'product' ? ENDPOINTS.UPLOAD_BULK_PRODUCT_DATA : ENDPOINTS.UPLOAD_BULK_QUESTION_DATA));
  
  const onFileUpload = (e: any) => {
    const file = e.target.files[0];
    if(selectedFile) {
      setSelectedFile(undefined);
    }
    if (!ACCEPT_TYPE.includes(file.type)) {
      showMessage({
        heading: t("FileTypeError"),
        body: <p>{t("fileTypeErrorExt")}</p>,
        type: "error",
        footer: <DownLoadButtonForError sampleFileUrl={sampleFileUrl}  downloadSample={downloadSample}/>,
      });
      e.target.value = "";
    } else if (file.size > ACCEPT_FILE_SIZE) {
      showMessage({
        heading: t("FileSizeError"),
        body: <p>{t("fileSizeErrorExt")}</p>,
        type: "error",
      });
      e.target.value = "";
    } else {
      setSelectedFile(e.target.files[0]);
      e.target.value = "";
    }
  };

  const downloadSample = () => {
    api.get(sampleFileUrl, { responseType: 'arraybuffer' })
    .then((response) => {
      var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, searchParams+'_report.xlsx');
    });
  }

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); //prevent the form from submitting
    let formData = new FormData();
    setProgress(0);
    formData.append("file", selectedFile as Blob);
    api
      .post(uploadFileApiUrl, formData, {
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(
            Math.round((100 * data.loaded) / (data?.total as number))
          );
        },
      })
      .then((res:any) => {
        (searchParams === 'team' ? updateUserData({ teamMemberCount: +1 }) : 
          searchParams === 'category'? updateUserData({ categoryCount: +1 }) : 
          (searchParams === 'product'  ? updateUserData({ pathCount: +1 }) : updateUserData({ questionCount: +1 }))
        ) ;

        showMessage({
          heading: t("fileUploaded"),
          body: <p>{t("fileUploadSuccess")}</p>,
          type: "success",
          callback: () => {
            navigate(ROUTE_NAVIGATION_PATH.UPLOAD_ELEMENT_INFO);
          },
        });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setProgress(0);
        const errorMsg = error.response?.data?.errorMessage;
        showMessage({
          heading: t("fileCannotBeAdded"),
          body: <p>{`${errorMsg ? errorMsg : ""}`}</p>,
          type: "error",
          footer: <DownLoadButtonForError sampleFileUrl={sampleFileUrl} downloadSample={downloadSample}/>,
        });
      });
  };

  function removeFile() {
    setSelectedFile(undefined);
  }

  function redirectBack() {
    navigate(ROUTE_NAVIGATION_PATH.UPLOAD_ELEMENT_INFO)
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("uploadElements")}</h1>
              <p>{t("uploadElementsSubHeading")}</p>
            </div>

            <div className="upload-element-page2">
              <form onSubmit={submitHandler} className="file-input-browse">
                <div className="dragouter">
                  <div className="file-drag">
                    <div className="drag-content">
                      <div className="_icon-drag">
                        <img src={iconUpload} alt="Upload" />
                      </div>
                      <p>
                        {t("drag&Drophere")} {t("or")}{" "}
                        <span>{t("browse")}</span>
                      </p>
                    </div>
                    <Form.Control
                      type="file"
                      onChange={onFileUpload}
                      name="file"
                      accept={ACCEPT_TYPE.join()}
                      onClick={(e:any) => {
                        e.target.value = ""
                      }}
                    />
                  </div>
                  <div className="download-files">
                    <div className="desc">
                      <span>{t("ImportingRequires")}</span>
                      <img src={iconExcel} alt="" />
                      Microsoft Excel <span>.xlsx format</span>
                    </div>
                    <a 
                      type="button"
                      onClick={downloadSample}
                      className="btn btn-download"
                      download
                    >
                      {t("Download")}
                    </a>
                  </div>
                </div>
                <div className="info-size">{t("OnlyxmlWithmaxSizeOf5Mb")}</div>
                <div className="progress-content-bar">
                  {selectedFile ? (
                    <>
                      <div className="_image">
                        <img src={iconFile} alt="File" />
                      </div>
                      <div className="bar-content">
                        <p>{selectedFile?.name} <img src={cross} alt="cross" className="xcursor" onClick={removeFile} /></p>
                        {progress > 0 ? (
                          <ProgressBar now={progress} label={`${progress}%`} />
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="btn-wrapper">
                  <Button variant="primary cancel" type="button" onClick={redirectBack}>
                    {t("Cancel")}
                  </Button>
                  <Button variant="primary" type="submit" disabled={!selectedFile}>
                    {t("Import")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
