import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function MultiBarChart({
    labels,
    dataset1,
    dataset2,
    toDate,
}: {
    labels?: any;
    dataset1?: any;
    dataset2?: any;
    toDate?:any
}) {
    const {t} = useTranslation();
    let year = moment(toDate).format('Y');
    let month = moment(toDate).format('MMM');
    const options = {
        responsive: true,
        interaction: {
            mode: "index" as "index"
        },
        plugins: {
            legend: {
                position: 'bottom' as const,
                // align: 'start' as const,
                
                labels: {
                    usePointStyle: true,
                    boxWidth: 20,
                    boxHeight: 10,
                    color: 'black',
                    padding: 60
                }
            },
            tooltip: {
                backgroundColor: '#3f3f49',
                // titleFontColor: '#333',
                // bodyFontColor: '#666',
                // displayColors: false,
                callbacks: {
                    title: function(context:any) {
                        return month + ' '+ context[0].label + ', ' + year;
                    },
                    // label: function(context:any) {
                    // //    var dstLabel = d.datasets[t.datasetIndex].label;
                    // //    var yLabel = t.yLabel;
                    // return ['label', "Count : " + 3];
                    // }
                 }
            }
            
        },        
        scales: {
            x: {
                ticks: {
                    display: true,
                    color: 'black'
                },
                border: {
                    display: true
                }, 
                grid: {
                    drawBorder: false,
                    display: false,
                    drawTicks: true,
                    offset: false
                },
            },
            y: {
                ticks: {
                    display: false,
                    beginAtZero: true,
                    grace: '2%'
                },
                border: {
                    display: false
                },
                grid: {
                    drawBorder: false,
                    display: false,
                    drawTicks: true,
                    offset: false
                },
            }
        },
        layout: {
            padding: {
              top: 10,
              bottom: 10,
            }
        }
    };
   

    const barChart = {
        labels: labels,
        datasets: [
          {
            label: t('callsPerDayInsight'),
            data: dataset1,
            backgroundColor: '#111E3B',
            borderRadius: 6,
            categoryPercentage: 0.2,
            barPercentage: 0.5,
          },
          {
            label: t('averageTeam'),
            data: dataset2,
            backgroundColor: '#d2d2d4',
            borderRadius: 6,
            categoryPercentage: 0.2,
            barPercentage: 0.5,
          }
        ],
    };

    return (
        <Bar options={options} data={barChart} />
    );
}
