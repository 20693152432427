import { useEffect, useState } from "react";
import queryClient from "../utils/queryClient";
import { Any } from "../types/global.type";
import { InfiniteData } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

export default function useEditDelete() {
  const [editData, setEditData] = useState<Any>(null);
  const [deleteFor, setDeleteFor] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearchQuery((searchParams.get("query") as string) || "");
  }, [location.search]);

  function updateQueryData(newData: Any, queryKey = "members") {
    queryClient.setQueryData<InfiniteData<Any> | undefined>(
      [queryKey, searchQuery],
      (data) => {
        if (data) {
          data.pages = data.pages.map((page) => {
            page.data = page.data.map((result:Any) => {
              if (result._id === newData.id || result._id === newData._id) {
                result = { ...result, ...newData };
              }
              return result;
            });
            return page;
          });
        }
        return data;
      }
    );
  }
  function deleteQueryData(queryKey = "members") {
    queryClient.refetchQueries({ queryKey: [queryKey], type: "active" });
  }

  function inValidQuery(queryKey = "members") {
    queryClient.invalidateQueries({
      queryKey: [queryKey],
      refetchType: "active",
    });
  }

  function updateNormalQueryData(newData: Any, queryKey = "product") {
    queryClient.setQueryData<Any | undefined>(
      [queryKey, searchQuery],
      (data:Any) => {
        return data;
      }
    );
  }

  return {
    editData,
    setEditData,
    deleteFor,
    setDeleteFor,
    updateQueryData,
    deleteQueryData,
    inValidQuery,
    updateNormalQueryData
  };
}
