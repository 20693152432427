import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/images/logo.svg";


export default function UserSubscription() {
  const { signout } = useAuth();
  const { t } = useTranslation();

  function handleClick() {
    signout();
  }
  return (
    <Row className="bg-authlayout _fixed-authlayout">
      <Col className="d-flex justify-content-center align-items-center  login-signup-page-card h-100vh ">
          <div className="login-form subscription">
              <div className="w-100">
              <div className="_logo">
                  <img src={logo} alt="MAGICMOMENT" />
                </div>
                  <h2>
                      {t('subscription')}
                  </h2>
                  <div className="email-verification-message">
                      {t('subscriptionDescription')}
                      
                  </div>
                  <div className="mt-3">
                    <p>( <span className="email-text">software@kopfspringer.com</span>)</p>
                  </div>
                  <div>
                    <Button variant="primary" className="mt-5" onClick={() => handleClick()}>
                      {t("Signout")}
                    </Button>
                  </div>
              </div>
          </div>
      </Col>
    </Row>
  );
}
