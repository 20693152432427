import { useEffect, useState } from "react";
import {
  composeValidators,
  required,
  maxlength,
  validTime,
} from "../../validations";
import { FormControl } from "../FormElements/FormControl";
import { USER_STATUS, VALIDATIONS} from "../../constants";
import searchIcon from "../../assets/images/searchgrey.svg";
import plusIcon from "../../assets/images/plusgrey.svg";
import closeIcon from "../../assets/images/sliderclose.svg";
import { useLoadItems } from "../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { category } from "../../api";
import { AddCategoryModal, NoData, SearchBar } from "..";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useFormState } from "react-final-form";
import { useCreateEditQuestion }  from "../../hooks/useCreateEditQuestion"
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useNavigate, createSearchParams } from "react-router-dom";
import profileIcon from "../../assets/images/iconprofile.png";

export default function AddEditQuestion({
    updateCategory,
    editForm,
    categoryError,
    fromProposal,
    addPopup = false,
}: {
    updateCategory: (data:any) => void;
    editForm?: boolean;
    categoryError?: string;
    fromProposal?: string;
    addPopup?: boolean;
}) {
    const { t }= useTranslation();
    const navigate = useNavigate();
    const [addCategory, setCatrgory] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const status = USER_STATUS.ALL;
    const location = useLocation();
    const formState = useFormState().values;
    const { deleteQuestionData, updateQuestionData, user } = useCreateEditQuestion();
    let errorCategoryMessage = '';
   
    const { loading, items, hasNextPage, error, addItem, updateItem } =
        useLoadItems({
        loadItems: category.allcategoryList,
        page,
        search,
        status
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage(page + 1),
        disabled: !!error,
        rootMargin: "0px 50px 0px 0px",
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if(searchParams.get("query")) {
            setPage(1);
        }
        setSearch((searchParams.get("query") as string) || "");
    }, [location.search]);

    function showSearchBar()  {
        setShowSearch(!showSearch);
    }

    const onCategoryChange = (index:number) => {       
        errorCategoryMessage = '';
        var checkItem = formState.category.includes(items[index]._id);
        if(checkItem) {
            items[index].selected = false;
            const indexValue = formState.category.indexOf(items[index]._id);
            formState.category.splice(indexValue, 1);
        } else {
            if(formState.category.length == 5) {
                return;
            }
            items[index].selected = !items[index].selected;
            formState.category.push(items[index]._id);
        }
        updateItem(items[index]);
        updateCategory(formState.category);
    };

    useEffect(() => {
        if(user && user.question == "") {
            items.forEach(val => {
                val.selected = false;
            });
        }
    }, [user])

    function redirectToProduct() {
        updateQuestionData(formState);
        if(fromProposal == '1') {
            navigate({
                pathname: ROUTE_NAVIGATION_PATH.PRODUCT_LIST,
                    search: createSearchParams({
                        toQuestion: "1",
                        fromProposal: '1'
                }).toString()
            });
        }  else {
            navigate({
                pathname: ROUTE_NAVIGATION_PATH.PRODUCT_LIST,
                    search: createSearchParams({
                        toQuestion: "1",
                        fromEdit: editForm ? '1': '0'
                }).toString()
            });
        }
       
    }

    function removeProduct(productId:any) {
        deleteQuestionData(productId);
    }

    const refreshCategory =  (isOk: boolean) => {
        if (isOk) {
            setCatrgory(false);
        }
    };

    const updateListItem = (updatedData: any) => {
        addItem(updatedData);
    };

    return (
    <>
        <div className="signupfield _newquestion">
            <FormControl
                name="question"
                label="Question (Max 150 Char)"
                validate={composeValidators(
                    required,
                    maxlength(VALIDATIONS.MAX_QUESTION)
                )}
            />
           
           {/* <input type="text" pattern="^[0-5][0-9]\:[0-5][0-9]\.[0-9]{1,3}$" /> */}
            <FormControl
                name="time"
                label="Time"
                validate={composeValidators(
                    required,
                    validTime
                )}
                placeholder="-- : --"
            />

            <FormControl
                label="Answer (Max 500 Char)"
                name="answer"
                type="textarea"
                validate={composeValidators(
                    required,
                    maxlength(VALIDATIONS.MAX_ANSWER)
                )}
            />
        </div>

        <h2>
            {t('selectCategories')} {localStorage.getItem("lang") == 'en' ? '(Max 5)' : ''}
            <div className="_icon" onClick={() => setCatrgory(true)}>
                <img src={plusIcon} alt="Plus" />
            </div>
            <div className="_icon iconsearch" onClick={showSearchBar}>
                <img src={searchIcon} alt="Search" />
            </div>
            {
                showSearch ? 
                    <SearchBar
                        placeHolder={t("searchCategory")}
                    />
                :
                    null
            }
        </h2>
        <div className="category-listing _hegithaccc" ref={rootRef}>
            <ul>
                {!items.length && !loading ? <NoData /> : null}
                {items.map((data:any, key:any) => (
                    <li key={data._id} onClick={() => onCategoryChange(key)} className={data.selected || user.category.includes(data._id) ? "active" : ""}>
                        {data.name}
                    </li>
                ))}
                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                        {/* <div className="">
                            <Spinner />
                        </div> */}
                    </div>
                )}
            </ul>
        </div>
        {
            categoryError ? 
            <div className="error">{t(categoryError)}</div>
            :
            null
        }

        <h2 className="mt-5">{t("Upsell (Max 5)")}</h2>
        <div className="upload-file-content">
            { user && user.productDetails.length > 0 ? 
                    <>
                    {user.productDetails.map((data:any) => (
                        <div className="uploadedphoto active">
                            <div className="clsoeicon" onClick={() => removeProduct(data.id)}>
                                <img src={closeIcon} alt="close" />
                            </div>
                            <div className="_image">
                                <img src={data.image ? data.image : profileIcon} alt="Profile" />
                            </div>
                            <div className="textinfo">
                                <div className="infoheading">{data.name}</div>
                                <p>
                                    {data.description}
                                </p>
                            </div>
                        </div>           
                    ))}
                    </>
                    
                :
                    null
            }
            
            { user.productDetails.length === 5 ? 
                null 
                :
                <div className="filebrowse" onClick={redirectToProduct}>
                    <div className="_icon-drag" >
                        <img src={plusIcon} alt="Upload" />
                    </div>
                </div>
            }

        </div>

        <AddCategoryModal
            show={!!addCategory}
            handleClose={() => setCatrgory(false)}
            callback={refreshCategory}
            updateListItem={updateListItem}
        />
    </>
  );
}
