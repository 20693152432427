import { useEffect, useRef, useState } from "react";
import plusIcon from "../../../assets/images/path-plus.svg";
import crossIcon from "../../../assets/images/cross.svg"
import { question } from "../../../api";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { useTranslation } from "react-i18next";
import { NoData } from "../../../components";
import { useCreateEditQuestion } from "../../../hooks/useCreateEditQuestion";
import { Tooltip } from 'react-tooltip'

const SuggestionQuestionCard = ({ isDroppable, isDraggable, questnId, step, index, que, stepSequence}: any) => {

  const { t } = useTranslation();
  const { droppedQuestion = [], questionId = [], drag, dropped, onDragStart, removeDroppedQuestion} = useDragAndDrop();
  const [onRemoveQuestion, setRemoveQuestion] = useState(false);
  const { questionDetail, updateQuestionDetail, deleteQuestionDetail } = useCreateEditQuestion();
  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {
      if(questnId) {
        question
        .getQuestionById(questnId as string)
        .then((res) => {
          updateQuestionDetail(res.data);
        });
      }
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  function dropProcess(ev:any) {

    if(step != stepSequence) {
      return;
    }

    ev.preventDefault();
    
    const ques = JSON.parse(ev.dataTransfer.getData("questionDetail"));
    setRemoveQuestion(true);
    // reftech the processes for department or member for modal
    dropped(ques, step,  index);
    deleteQuestionDetail(ques._id)
  }

  function removeDroppedQue(que:any) {  
      updateQuestionDetail(que);
      
      removeDroppedQuestion(que, step, index);    
  }

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }
  
  return (
    <>
      {isDraggable ?
      <div className="bottom-pay-wrapper">
        <div className="ul">
        
            <>
              {!questionDetail.length  ? <NoData /> : null}
              {questionDetail.map((questnDetail: any, key: any) => (
                <div className="bottomgrid" style={{'cursor':'pointer'}} key={questnDetail?._id}>
                    <div
                      className="paygrid-common"
                      draggable
                      onDragStart={(ev) => drag(ev, questnDetail)}
                    >
                      <div className="payment-info">
                        <div className="paytxt">
                          <div className="category">{t('Category')}</div>
                          <div className="payment">
                            { questnDetail?.category ? questionArray(questnDetail.category) : '' }
                          </div>
                        </div>
                        <div className="timetxt">
                          <div className="category">{t('avgTime')}</div>
                          <div className="time">{questnDetail?.time}</div>
                        </div>
                      </div>
                      <div className="heading-info" data-tooltip-id={questnDetail?._id}>{questnDetail?.question}</div>
                      <Tooltip id={questnDetail?._id} place="top">
                        <div className="toolquestion">
                          <strong> Question:</strong>  {questnDetail?.question}
                          </div>
                          <div className="toolquestion">
                          <strong>Answer:</strong>  {questnDetail?.answer}
                          </div>
                      </Tooltip>
                    </div>
                </div>
              ))}
            </>
        </div>
      </div>
      :
      null
      }
   

      {isDroppable && (
        
          que._id  ?
          <div className={`path-common-file question-dropped-card`}>
            <div className="startarrow">
              <div className="line">
              </div>
              <div className="circle">
                <span></span>
              </div>
            </div>
            {
                stepSequence == step ?
                  <div className="cross-icon" onClick={() => removeDroppedQue(que)}>
                      <img src={crossIcon} alt="MagicMoment" />
                  </div>
                  :
                  null
            }
            <div className="file-input">
                <div className={`bottomgrid ${stepSequence == step ? '' : 'opaque'}`}>
                  <div className="paygrid-common">
                    <div className="payment-info">
                      <div className="paytxt">
                        <div className="category">{t('Category')}</div>
                        <div className="payment">{que?.category ? questionArray(que?.category) : ''}</div>
                      </div>
                      <div className="timetxt">
                        <div className="category">{t('avgTime')}</div>
                        <div className="time">{que?.time}</div>
                      </div>
                    </div>
                    <div className="heading-info" data-tooltip-id={que?._id}>{que?.question}</div>
                    <Tooltip id={que?._id} place="top">
                    <div className="toolquestion">
                       <strong>Question:</strong>    {que?.question}
                        </div>
                        <div className="toolquestion">

                        <strong>Answer:</strong>    {que?.answer}
                        </div>
                    </Tooltip>
                  </div>
                </div>
            </div>
          </div>
          :
          <div
            className={`path-common-file`}
            onDrop={dropProcess}
            onDragOver={onDragStart}
          >
            <div className="startarrow">
              <div className="line">
              </div>
              <div className="circle">
                <span></span>
              </div>
            </div>
            <div className={`file-input ${stepSequence == step ? '' : 'opaque'}`}>
                <span>
                    <div>
                      <img src={plusIcon} alt="magicmoment" />
                      <p>
                        <p>Drag & Drop</p>
                      </p>
                    </div>
                </span>
            </div>
          </div>
      )}
    </>
  );
};

export default SuggestionQuestionCard;
