import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { SearchBar } from "../../../../components";
import { useEffect, useState } from "react";
import useEditDelete from "../../../../hooks/useEditDelete";
import { ROLE, USER_STATUS } from "../../../../constants";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { product } from "../../../../api";
import useInfiniteScroll from "react-infinite-scroll-hook";
import DeleteProduct from "../../../../components/Modal/DeleteProduct";
import ProductTile from "../../../../components/Product/ProductTile";
import { Spinner } from "react-bootstrap";
import { NoData } from "../../../../components";
import EditProductModal from "../../../../components/Modal/EditProductModal";
import { useAuth } from "../../../../hooks/useAuth";

export default function ProductList() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const auth = useAuth();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const status = USER_STATUS.ACTIVE;
  const { deleteFor, editData, setEditData, setDeleteFor } = useEditDelete();
  const { loading, items, hasNextPage, error, deleteItem, updateItem } =
    useLoadItems({
      loadItems: product.topProductList,
      page,
      search,
      status
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const deleteProduct = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");

  }, [location.search]);

  const updateListItem = (updatedData: any) => {
    updatedData._id = editData?._id as string;
    updateItem(updatedData);
    setEditData(null);
  };

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width" > */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("topProducts")}</h1>
              <p>{auth.user.role === ROLE.TEAM_LEADER ? t("productHeader") : ""}</p>
            </div>
            <div  className={`search-wth-content2 w-100`}>
              <SearchBar
                placeHolder={t("searchProduct")}
              />
            </div>
            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent _categorydetail _productlistingcontent">
                {!items.length && !loading ? <NoData /> : null}
                {items.length ?
                  <table className="_producttable">
                    <thead>
                      <tr>
                        <th className="rankth">{t("Rank")}</th>
                        <th style={{minWidth: '250px'}}>{t("Products")}</th>
                        <th>{t("Description")}</th>
                        <th>{t("lastEdited")}</th>
                        <th>{t("last7Days")}</th>
                        {/* <th></th> */}

                      </tr>
                    </thead>
                    <tbody>

                      {items.map((product: any, key: number) => (
                        <ProductTile
                          key={product._id}
                          product={product}
                          index={key+1}
                          editProduct={() => setEditData(product)}
                          deleteProduct={() => setDeleteFor(product._id)}
                        />
                      ))}

                    </tbody>
                  </table>
                  :
                  null
                }
                {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <EditProductModal
        detail={editData}
        handleClose={() => setEditData(null)}
        updateListItem={updateListItem}
      />
      <DeleteProduct
        show={!!deleteFor}
        handleClose={() => setDeleteFor(null)}
        id={deleteFor}
        callback={deleteProduct}
      />
    </>
  );
}
