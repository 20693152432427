import "chart.js/auto";
import { Line } from "react-chartjs-2";

export const options = {
    responsive: true,
    tension: 0.6,
    plugins: {
        legend: { display: false },
    },
    scales: {
        x: {
            ticks: {
                display: false,
            },
            border: {
            display: false
            },
            // to remove the x-axis grid
            grid: {
                drawBorder: false,
                display: false,
                drawTicks: true,
            },
        },
        y: {
            ticks: {
                display: false
            },
            border: {
                display: false
            },
              // to remove the x-axis grid
            grid: {
                drawBorder: false,
                display: false,
                drawTicks: true,
            }
        }
    }
};
export default function LineChart({
    labels,
    count,
}: {
    labels?: any;
    count?: any;
}) {
    const lineChart = {
        labels: labels ?  labels :['', '', '', '', '', '', ''],
        datasets: [
            {
                label: '',
                data: count ? count: [0, 0, 0, 0, 0, 0, 0],
                backgroundColor: "#f5dbb0",
                hoverBackgroundColor: '#c8be8e',
                lineTension: 0.2,
                fill: true,
                borderColor: "#FCA311",
            }
        ]
    };

    return (
        <Line data={lineChart} options={options}/>
    );
}
