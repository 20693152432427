import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";
import { product } from "../../api";
import { useTranslation } from "react-i18next";
import { Any } from "../../types/global.type";
import { useCustomMutation } from "../../hooks/useApi";
import AddEditProduct from "../FormElements/AddEditProduct";
import { useState } from "react";

export default function AddProductModal({
  show,
  handleClose,
  addListItem,
  callback
}: {
  show: boolean;
  handleClose: VoidFunction;
  addListItem?:(data: any | undefined) => void;
  callback?: (data?: any) => void;
}) {
  const { t } = useTranslation();
  const onOk = (isOk: boolean) => {
    callback && callback(isOk);
    !isOk && handleClose();
  };
  const [file, setFile] = useState<File | null>();

  const { mutate } = useCustomMutation({
      mutationFn: product.createProduct,
      onSuccess: (res:any) => {
        if (addListItem) addListItem(res.data);
        handleClose();
        onOk(true);
      }
  });

  const updateImage = (file: File | null| undefined) => {
    file ? setFile(file) : setFile(null);
  };

  const onSubmit = (values: Any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    if(values.tags && values.tags.length > 0) {
      for (var i = 0; i < values.tags.length; i++) {
        formData.append('tags[]', values.tags[i]);
      }
    }
    if (file) {
      formData.append("image", file);
    }
    formData.append("description", values.description);
    formData.append("conditions", values.conditions);
    mutate(formData);
  };

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      //backdrop="static"
      dialogClassName={"team-memeber-model"}
      keyboard={false}
    >
        <Modal.Header>
          <Modal.Title>{t("addProduct")}</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form-diaolog">
                <AddEditProduct updateImage={updateImage} addPopup={true}/>
                <Modal.Footer>
                  <Button variant="primary cancel" type="button" onClick={handleClose}>
                    {t("Cancel")}
                  </Button>
                  <Button variant="primary" type="submit">
                    {t("add")}
                  </Button>
              </Modal.Footer>
            </form>
          )}
        />
        
    </Modal>
  );
}
