import { api } from "../api";
import { User, AuthType } from "../../types/User";

const ENDPOINTS = {
  POST_SIGN_UP: "v1/user/register",
  POST_SIGN_IN: "v1/user/sign-in",
  POST_SIGN_OUT: "v1/user/logout",
  POST_GOOGLE_AUTH: "v1/user/auth/google",
  POST_ADMIN_SIGN_OUT: "v1/admin/logout",
  POST_FORGOT: "v1/user/forgot-password",
  POST_RESET: "v1/user/reset-password",
  POST_VERIFY: "v1/user/verify-email",
  MEMBER_SIGN_UP: "v1/user/team-member-onboard/",
  GET_MEMBER_DETAILS: "v1/user/"
};

async function doSignup(data: User, token?: string) {
  return api
    .post(
      token ? ENDPOINTS.MEMBER_SIGN_UP + token : ENDPOINTS.POST_SIGN_UP,
      data
    )
    .then((res) => res.data);
}

async function doSignIn(data: AuthType) {
  return api.post(ENDPOINTS.POST_SIGN_IN, data).then((res) => res.data);
}

async function doGoogleAuth(googleOAuthToken: string) {
  return api
    .post(ENDPOINTS.POST_GOOGLE_AUTH, { googleOAuthToken })
    .then((res) => res.data);
}

async function doSignOut() {
  return api.patch(ENDPOINTS.POST_SIGN_OUT).then((res) => res.data);
}

async function doForgot(data: string) {
  return api.post(ENDPOINTS.POST_FORGOT, data).then((res) => res.data);
}

async function doRestPassword(data: string) {
  return api.post(ENDPOINTS.POST_RESET, data).then((res) => res.data);
}

async function doVerifyEmail(data: any) {
  return api.post(ENDPOINTS.POST_VERIFY, data).then((res) => res.data);
}

async function getInvitedUserData(token: string) {
  return api
    .get(ENDPOINTS.GET_MEMBER_DETAILS + token + "/invitee-details")
    .then((res) => res.data);
}

export {
  doSignup,
  doSignIn,
  doSignOut,
  doForgot,
  doRestPassword,
  doVerifyEmail,
  getInvitedUserData,
  doGoogleAuth
};
