import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import { useTranslation } from "react-i18next";
import iconInfo from "../../assets/images/iconInfo.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import iconError from "../../assets/images/iconError.svg";
import iconRemove from "../../assets/images/iconRemoveRed.svg";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";

const ICON = {
  success: iconSuccess,
  error: iconError,
  info: iconInfo,
  warning: iconRemove,
};

export default function AddSuggestionMessage({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: VoidFunction;
}) {
  const { t } = useTranslation();

  function closeDialog() {
    handleClose();
  }

  return (
    <Modal
      show={show}
      // backdrop="static"
      keyboard={false}
      dialogClassName={"model-common _confirmation-common _addsuggestiondialog"}
    >
      <Modal.Header>
        <div className="_icon-img">
          <img src={iconSuccess} alt="info" />
        </div>
        <Modal.Title>{t("questionProposal")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("NoteForPropasal")}</p>
        <p>{t("ThankyouProposal")}</p>
        <strong>{t("Shortguide")}</strong>
        <ul>
          <li>{t("listenCarefully")}</li>
          <li>{t("useExpertise")}</li>
          <li>{t("importantDetailProposal")}</li>
        </ul>
        <strong>{t("typicalPhrases")}</strong>
        <ul className="_square">
          <li>{t("findASolution")}</li>
          <li>{t("informedAnswer")}</li>
          <li>{t("impQuestionForYou")}</li>
          <li>{t("stepByStepAspects")}</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>

        <Button variant="primary" type="button" onClick={handleClose}>
          {t("Continue")}
        </Button>

      </Modal.Footer>
    </Modal>
  );
}
