import { api } from "../api";

export const ENDPOINTS = {
  POST_CREATE_TEAM: "v1/user/create-team-member",
  GET_TEAM_MEMBERS: "v1/user/all-team-members",
  GET_PUT_DELETE_MEMBER: "v1/user/team-member/",
  GET_SAMPLE_DATA: "v1/user/download-members-sample",
  GET_ALL_TEAM_MEMBERS: "v1/user/all-team-members",
  UPLOAD_BULK_DATA: "v1/user/upload-file-members",
  GET_LEADER_PROFILE: "v1/user/team-leader/profile",
  POST_PHOTO: "/v1/user/team-member-registration-api",
  PATCH_UPDATE_SALES_EMAIL: "/v1/user/update-sales-email",
  GET_ONLINE_OFFLINE_MEMBERS : "/v1/user/offline-online-members"
};

async function createTeam(data: any) {
  return api.post(ENDPOINTS.POST_CREATE_TEAM, data).then((res) => res.data);
}

async function editMember(data: any, id: string) {
  return api
    .patch(ENDPOINTS.GET_PUT_DELETE_MEMBER + id, data)
    .then((res) => res.data);
}

async function deleteMember(id: string) {
  return api
    .delete(ENDPOINTS.GET_PUT_DELETE_MEMBER + id + "/delete")
    .then((res) => res.data);
}

async function getTeamMembers(data: Record<string, any>) {
  return api.get(ENDPOINTS.GET_TEAM_MEMBERS, { params: data }).then((res) => {
    const rData = res.data;
    return {
      data: rData.data.users,
      hasNextPage: rData.data.totalUsersCount > data.page * data.count,
    };
  });
}

async function inviteMember(id: string) {
  return api
    .get(ENDPOINTS.GET_PUT_DELETE_MEMBER + id + "/invitation-email")
    .then((res) => res.data);
}

async function updateUserStatus(id: string, status: number) {
  return api
    .patch(ENDPOINTS.GET_PUT_DELETE_MEMBER + id + "/update/" + status)
    .then((res) => res.data);
}

async function getAllTeamMembersByStatus(data: Record<string, any>) {
  delete data.role;
  if (data.sortBy) {
    data.sortBy = JSON.stringify(data.sortBy);
  }
  return api
    .get(ENDPOINTS.GET_ALL_TEAM_MEMBERS, { params: data })
    .then((res) => {
      const rData = res.data;
      return {
        data: rData.data.users,
        hasNextPage: rData.data.totalUsersCount > data.page * data.count,
      };
    });
}
async function sendInviteToAll() {
  return api
    .get(ENDPOINTS.GET_PUT_DELETE_MEMBER + "invitation-email")
    .then((res) => res.data);
}

async function sendReminderEmail(id: string) {
  return api
    .get(ENDPOINTS.GET_PUT_DELETE_MEMBER + id + "/question-reminder-email")
    .then((res) => res.data);
}

async function getLeaderProfile() {
  return api.get(ENDPOINTS.GET_LEADER_PROFILE).then((res) => res.data);
}


async function uploadPhoto(data: any, id: string) {
  return api
    .post(ENDPOINTS.POST_PHOTO, data)
    .then((res) => res.data);
}

async function updateSaleEmail(data: any) {
  return api
    .patch(ENDPOINTS.PATCH_UPDATE_SALES_EMAIL, data)
    .then((res) => res.data);
}

async function getOfflineOnlineMembers(data: Record<string, any>) {
  return api.get(ENDPOINTS.GET_ONLINE_OFFLINE_MEMBERS, { params: data }).then((res) => {
    const rData = res.data;
    return {
      data: rData.data.users,
      hasNextPage: rData.data.totalUsersCount > data.page * data.count,
      totalUsersCount: rData.data.totalLoggedInUser
    };
  });
}

export {
  createTeam,
  getTeamMembers,
  editMember,
  deleteMember,
  inviteMember,
  updateUserStatus,
  getAllTeamMembersByStatus,
  sendInviteToAll,
  sendReminderEmail,
  getLeaderProfile,
  uploadPhoto,
  updateSaleEmail,
  getOfflineOnlineMembers
};
