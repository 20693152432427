import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import SuggestionPathStepper from "../../../components/Dashboard/Suggestion/SuggestionPathStepper";
import SuggestionQuestionCard from "../../../components/Dashboard/Suggestion/SuggestionQuestionCard";
import { useEffect, useRef, useState } from "react";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { path } from "../../../api"; 
import { useCreateEditQuestion } from "../../../hooks/useCreateEditQuestion";


export default function EditSuggestion() {
  const { t } = useTranslation();
  const pathId = new URLSearchParams(document.location.search).get('id');
  const questnId = new URLSearchParams(document.location.search).get('questionId');
  const stepSequence = new URLSearchParams(document.location.search).get('stepSequence');
  const [showQuestions, setShowQuestions] = useState(false);
  const { questionId = [], setQuestionItem, dropped, addNewStep, resetState} = useDragAndDrop();
  const [pathDetail, setPathDetail] = useState({});
  const { updateQuestionDetail } = useCreateEditQuestion();
  const newQuestion = [
    {_id: "", category: {}, time: "", question: ""}, 
    {_id: "", category: {}, time: "", question: ""},
    {_id: "", category: {}, time: "", question: ""}
  ];

  useEffect(() => {
      if(questionId.length > 0) {
        setShowQuestions(true);
      }
  }, [questionId])

  useEffect(() => {
    setQuestionItem("");
    resetState();
  }, [])

  const effectRan = useRef(false);

  useEffect(() => {
    if (!effectRan.current) {        
        path
        .getPathById(pathId as string)
        .then((res) => {
          setPathDetail({
            name: res.data.name,
            description: res.data.description
          });
          var stepsData = JSON.parse(JSON.stringify(res.data.steps));
          if(stepsData.length > 0) {
              Object.entries(stepsData).forEach(([key, val]) => {
                  const value  = JSON.parse(JSON.stringify(val));
                  const ques = JSON.parse(JSON.stringify(value.questions));
                  Object.entries(ques).forEach(([k, v]) => {            
                      const index = parseInt(k);
                      const que  = JSON.parse(JSON.stringify(v));
                      if(parseInt(key) >= 4) {
                        const stepsData  = {
                          name: value.name,
                          sequence: value.sequence,
                          questions: newQuestion
                        }
                        addNewStep(stepsData);
                        setTimeout(() => {
                          dropped(que, value.sequence,  index);
                        }, 2000)
                      } else {
                        dropped(que, value.sequence,  index);
                      }
                  })               
              });
          }  
        });
    }
    return () => {
      effectRan.current = true;
    };

  }, []);


  useEffect(() => {
    updateQuestionDetail({});
  }, [])

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
              <div className="path-container">
                {
                  Object.keys(pathDetail).length > 0 ? 
                    <SuggestionPathStepper questnId={questnId} pathId={pathId} detail={pathDetail} stepSequence={stepSequence} /> 
                  :
                    null
                }
                {
                    showQuestions ?
                        <SuggestionQuestionCard isDraggable questnId={questnId}/>
                    :
                    null
                }
              </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
