import { useEffect, useState } from "react";
import plusIcon from "../../assets/images/path-plus.svg";
import crossIcon from "../../assets/images/cross.svg"
import { USER_STATUS } from "../../constants";
import { useLoadItems } from "../../hooks/useLoadItems";
import { question } from "../../api";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation } from "react-router-dom";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import { useMessageModal } from "../../hooks/useMessage";
import { useTranslation } from "react-i18next";
import { NoData } from "../../components";
import { Tooltip } from 'react-tooltip'

const QuestionCard = ({ isDroppable, isDraggable, filterByCategory, step, index, que, editForm}: any) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  let status = USER_STATUS.ACTIVE;
  const { droppedQuestion = [], questionId = [], drag, dropped, onDragStart, removeDroppedQuestion} = useDragAndDrop();
  const [search, setSearch] = useState("");
  const [onRemoveQuestion, setRemoveQuestion] = useState(false);
  const location = useLocation();
  const { showMessage } = useMessageModal();
  const { loading, items, hasNextPage, error, removeItem  } = useLoadItems({
    loadItems: question.allQuestionsList,
    page,
    status,
    search,
    filterByCategory,
    questionId,
    onRemoveQuestion
  });


  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 60px 0px 0px",
  });

  useEffect(() => {
    if(questionId.length > 0 && onRemoveQuestion) {
      questionId.forEach((el) => {
        removeItem(el)
      })
    }
  }, [questionId])

  function onDragStartProcess(ev:any) {
    ev.preventDefault();
    if(ev.target.nodeName === 'SPAN') {
      ev.target.parentNode.classList.add("file-hover-border")
    } else {
      ev.target.nodeName.classList.add("file-hover-border")
    }
  }

  function onDragLeave(ev:any) {
    ev.preventDefault();
    if(ev.target.nodeName !== 'SPAN' || ev.target.nodeName !== 'div') {
      ev.target.parentNode.classList.remove("file-hover-border")
    }
  }


  function dropProcess(ev:any) {
    ev.preventDefault();
    
    if(step !== 1) {
      var indx = droppedQuestion.findIndex((el:any) => el.sequence === step-1);
      let checkEmpty = droppedQuestion[indx].questions.every((element:any) => element._id === '');

      if(checkEmpty) {
        var cusid_ele = document.getElementsByClassName('file-input');
        for (var i = 0; i < cusid_ele.length; ++i) {
            cusid_ele[i]?.classList.remove('file-hover-border');
        }
        showMessage({
          heading: t("stepRequired"),
          body: <p>{t("stepRequiredMessage")}</p>,
          type: "error",
        });
        return true;
      }
    }
    
    const ques = JSON.parse(ev.dataTransfer.getData("questionDetail"));
    setRemoveQuestion(true);
    // reftech the processes for department or member for modal
    dropped(ques, step,  index);
  }

  function removeDroppedQue(que:any) {
    // var currentIndx = droppedQuestion.findIndex((el:any) => el.sequence === step);
    // let checkcurrentEmpty = droppedQuestion[currentIndx].questions.filter((val:any) => {
    //   return val._id
    // });

    // var indx = droppedQuestion.findIndex((el:any) => el.sequence === (step == 1 ? step : step-1));
    
    // let checkEmpty = droppedQuestion[indx].questions.every((element:any) => element._id === '');

    
    // if(checkcurrentEmpty.length === 1 && checkEmpty) {
    //   showMessage({
    //     heading: t("stepRequired"),
    //     body: <p>{t("eachStepRequiredMessage")}</p>,
    //     type: "error",
    //   });
    
    // } else {
      removeDroppedQuestion(que, step, index);
    // }
    
  }

  const truncateText = (text: string) => {
    return text && text?.length > 24 ? text.slice(0,45) + "..." : text;
  };


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
    
  }, [location.search]);

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }

  return (
    <>
      {isDraggable ?
      <div className="bottom-pay-wrapper" ref={rootRef}>
        <div className="ul">
        
            <>
              {!items.length && !loading ? <NoData /> : null}
              {items.map((questionDetail: any, key: any) => (
                <>
                  <div className="bottomgrid" style={{'cursor':'pointer'}} key={questionDetail?._id}>
                    <div
                      className="paygrid-common"
                      draggable
                      onDragStart={(ev) => drag(ev, questionDetail)}
                    >
                      <div className="payment-info">
                        <div className="paytxt">
                          <div className="category">{t('Category')}</div>
                          <div className="payment">
                            { questionDetail?.category ? questionArray(questionDetail.category) : '' }
                          </div>
                        </div>
                        <div className="timetxt">
                          <div className="category">{t('avgTime')}</div>
                          <div className="time">{questionDetail?.time}</div>
                        </div>
                      </div>
                      <div className="heading-info" data-tooltip-id={questionDetail?._id}>{truncateText(questionDetail?.question)}</div>
                      <Tooltip id={questionDetail?._id} place="top">
                          <div className="toolquestion">
                           <strong>Question: </strong> {questionDetail?.question}
                          </div>
                          <div className="toolquestion">
                          <strong>Answer:</strong>   {questionDetail?.answer}
                          </div>
                      </Tooltip>
                    </div>
                  </div>
                </>
              ))}
            </>
            
          {hasNextPage && (
            <div ref={sentryRef} className="spinner-wrap">
              <div className="">
                  </div>
            </div>
          )}
            
        </div>
      </div>
      :
      null
      }
   

      {isDroppable && (
         que._id  ?
          <div className={`path-common-file ${que._id ? "question-dropped-card" : ""}`}>
            <div className="startarrow">
              <div className="line">
              </div>
              <div className="circle">
                <span></span>
              </div>
            </div>
            <div className="cross-icon" onClick={() => removeDroppedQue(que)}>
              <img src={crossIcon} alt="MagicMoment" />
            </div>
            <div className="file-input">
                <div className="bottomgrid">
                  <div
                    className="paygrid-common"
                  >
                    <div className="payment-info">
                      <div className="paytxt">
                        <div className="category">{t('Category')}</div>
                        <div className="payment">{que?.category ? questionArray(que?.category) : ''}</div>
                      </div>
                      <div className="timetxt">
                        <div className="category">{t('avgTime')}</div>
                        <div className="time">{que?.time}</div>
                      </div>
                    </div>
                    <div className="heading-info" data-tooltip-id={que._id}>{truncateText(que?.question)}</div>
                      <Tooltip id={que._id} place="top">
                      <div className="toolquestion">
                           <strong>Question:</strong>  {que?.question}
                          </div>
                          <div className="toolquestion">
                           <strong>Answer:</strong>  {que?.answer}
                          </div>
                      </Tooltip>
                  </div>
                </div> 
            </div>
          </div>
          :
          <div
            className={`path-common-file ${que._id ? "question-dropped-card" : ""}`}
            onDrop={dropProcess}
            onDragOver={onDragStartProcess}
            onDragLeave={onDragLeave}
          >
              <div className="file-input">
                  <span className={step}>
                    <div>
                      <img src={plusIcon} alt="magicmoment" />
                      <p>
                        <p>Drag & Drop</p>
                      </p>
                    </div>
                  </span>
              </div>
          </div>
         
      )}
    </>
  );
};

export default QuestionCard;
