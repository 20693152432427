import { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";
import { product } from "../../api";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useCustomMutation } from "../../hooks/useApi";
import { Any } from "../../types/global.type";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import AddEditProduct from "../FormElements/AddEditProduct";
import { useAuth } from "../../hooks/useAuth";

export default function AddProduct() {
  const [file, setFile] = useState<File | null>();
  const navigate = useNavigate();
  const { t }= useTranslation();
  const queryClient = useQueryClient();
  const {updateUserData}  = useAuth();

  const { mutate } = useCustomMutation({
    mutationFn: product.createProduct,
    onSuccess: async () => {
      await queryClient.invalidateQueries(["products", null]);
      updateUserData({ productCount: +1 });

      navigate({
        pathname: ROUTE_NAVIGATION_PATH.PRODUCT_LIST,
            search: createSearchParams({
                redirect: "1"
            }).toString()
      });
    },
  });

  const updateImage = (file: File | null| undefined) => {
    file ? setFile(file) : setFile(null);
  };

  const onSubmit = (values: Any) => {
    var formData = new FormData();
    formData.append("name", values.name);
    if(values.tags && values.tags.length > 0) {
      for (var i = 0; i < values.tags.length; i++) {
        formData.append('tags[]', values.tags[i]);
      }
    }
    if (file) {
      formData.append("image", file);
    }
    formData.append("description", values.description);
    if(values.conditions) {
      formData.append("conditions", values.conditions);
    }

    mutate(formData);
  };

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES);
  }

  return (
    <Form
      
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <AddEditProduct updateImage={updateImage} />
          <div className="btn-wrapper2">
            <Button variant="primary cancel" onClick={handleClick}>
              {t('Cancel')}
            </Button>
            <Button variant="primary" type="submit">
            {t('save')}
            </Button>
            </div>
        </form>
      )}
    />
  );
}
