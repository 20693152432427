import { Link, useNavigate, createSearchParams } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";
import { category } from "../../api"; 
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useCustomMutation } from "../../hooks/useApi";
import { Any } from "../../types/global.type";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import AddEditCategory from "../FormElements/AddEditCategory";
import { useAuth } from "../../hooks/useAuth";

export default function AddCategory({
    data,
    showContinue = false,
    fromQuestion = false
}: {
    data: any,
    showContinue: boolean
    fromQuestion?: boolean
}) {
    const navigate = useNavigate();
    const { t }= useTranslation();
    const queryClient = useQueryClient();
    const {updateUserData}  = useAuth();

    const { mutate } = useCustomMutation({
        mutationFn: category.createCategory,
        onSuccess: async () => {
        await queryClient.invalidateQueries(["categories", null]);
            updateUserData({ categoryCount: +1 });

            if(!fromQuestion) {
                navigate({
                    pathname: ROUTE_NAVIGATION_PATH.CATEGORY_LIST,
                    search: createSearchParams({
                        redirect: "1"
                    }).toString()
                });
            }
        },
    });

    const onSubmit = (values: Any) => {
        mutate(values);
    };
    return (
        <Form
        initialValues={data}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <div className="signupfield">
                    <AddEditCategory />
                </div>
                <div className="btn-wrapper ">
                    <Link to={ROUTE_NAVIGATION_PATH.FAQ_CREATION_INFO}>
                        <Button variant="primary cancel" type="button">
                            {t("Cancel")}
                        </Button>
                    </Link>
                    {/* {showContinue ? 
                        <Link to={ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS}>
                            <Button variant="primary" type="button">
                                {t("Continue")}
                            </Button>
                        </Link>
                        : */}
                        <Button variant="primary" type="submit">
                            {t("add")}
                        </Button>
                        {/* } */}
                </div>
            </form>
        )}
        />
    );
}
