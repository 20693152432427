import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { TeamMembersRow } from "../../";
import { Link } from "react-router-dom";
import { team } from "../../../api";
import { Member } from "../../../types/User";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { ROLE, USER_STATUS } from "../../../constants";
import { useTranslation } from "react-i18next";
import ActiveTeamMemberTile from "./ActiveTeamMemberTile";
import NonActiveTeamMemberTile from "./NonActiveTeamMemberTile";
import readMore from "../../../assets/images/ic_round-read-more.svg";
import { useAuth } from "../../../hooks/useAuth";

function SeeAll() {
  return (
    <div className="active-list-grid align-items-center">
      <Link
        to={
          ROUTE_NAVIGATION_PATH.ACTIVE_MEMBER_LIST + "/" + USER_STATUS.INVITEE
        }
      >
        <Button variant="seeAll">See All users</Button>
      </Link>
    </div>
  );
}
export function NonActiveTeamMembers() {
  const [members, setMembers] = useState<Member[]>([]);
  
  const [hasNextPage, setHasNextPage] = useState(false);
  const { t } = useTranslation();
  let {user} = useAuth();

  useEffect(() => {
    if(user.role === ROLE.TEAM_MEMBER) {
      team
      .getOfflineOnlineMembers({
        page: 1,
        count: 10,
        status: USER_STATUS.INVITEE,
        role: user.role
      })
      .then((res: { data: Member[] }) => {
        setMembers(res.data);
      });
    } else {
      team
        .getAllTeamMembersByStatus({
          page: 1,
          count: 10,
          status: USER_STATUS.INVITEE,
        })
        .then((res: { data: Member[]; hasNextPage: boolean }) => {
          setMembers(res.data);
          setHasNextPage(res.hasNextPage);
        });
    }
  }, [user.role]);

  
  return (
    <TeamMembersRow
      className="non-active-user-content-wrapper"
      heading={user.role === ROLE.TEAM_MEMBER ? t("OfflineUsers") : t("PendingUsers")}
    >
      {members.map((member) => (
        <NonActiveTeamMemberTile user={member} key={member._id} />
      ))}
      {hasNextPage ? <SeeAll /> : null}
      {members.length === 0 ? (
        <div className="dashboard-no-data">
          <p>{t("NoResultFound")}</p>
        </div>
      ) : null}
    </TeamMembersRow>
  );
}

export function ActiveTeamMembers() {
  const [members, setMembers] = useState<Member[]>([]);
  let {user} = useAuth();
  const { t } = useTranslation();
  useEffect(() => {
    if(user.role === ROLE.TEAM_MEMBER) {
      team
      .getOfflineOnlineMembers({
        page: 1,
        count: 10,
        status: USER_STATUS.ACTIVE,
        role: user.role
      })
      .then((res: { data: Member[] }) => {
        setMembers(res.data);
      });
    } else {
      team
      .getAllTeamMembersByStatus({
        page: 1,
        count: 10,
        status: USER_STATUS.ACTIVE,
      })
      .then((res: { data: Member[] }) => {
        setMembers(res.data);
      });
    }
  }, [user.role]);
    
  return (
    <TeamMembersRow
      heading={user.role === ROLE.TEAM_MEMBER ? t("OnlineUsers") : t("ActiveUsers")}
      leftButton={
        (
          <Link
            to={
              ROUTE_NAVIGATION_PATH.MANAGE_TEAM_MEMBERS
            }
          >
            <img src={readMore} alt="MAGICMOMENT" className="menumore" />
          </Link>
        ) 
      }
    >
      {members.map((member) => (
        <ActiveTeamMemberTile userDetails={member} key={member._id} />
      ))}
      {members.length === 0 ? (
        <div className="dashboard-no-data">
          <p>{t("NoResultFound")}</p>
        </div>
      ) : null}
    </TeamMembersRow>
  );
}

export default function MyProfileManageTeam() {
  const { t } = useTranslation();
  const [loader, setLoadingInvite] = useState<boolean>(false);
  const { showMessage } = useMessageModal();
  let { user } = useAuth();
  const sendInviteToAll = () => {
    setLoadingInvite(true);
    team
      .sendInviteToAll()
      .then((res) => {
        showMessage({
          heading: t("success"),
          body: <p>{t('InviteAllSuccess')}</p>,
          type: "success",
        });
        setLoadingInvite(false);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <>
      <div className="form_inner_wrapper">
        <div className="_active-noactive">
          <ActiveTeamMembers />
          <NonActiveTeamMembers />
          { user.role === ROLE.TEAM_LEADER ? 
            <div className="btn-wrapper-resend">
              <Button variant="secondary" onClick={sendInviteToAll} disabled={loader}>
                {t("ResendInvitation")}
              </Button>
            </div>
            :
            null
          }
        </div>
      </div>
    </>
  );
}
