
export const required = (value: string) => (value ? undefined : "Required");

export const mustBeNumber = (value: number) =>
  isNaN(value) ? "Must be a number" : undefined;

export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const maxlength = (max: number) => (value: string) =>
  value?.length > max
    ? (localStorage.getItem("lang") == 'en'? `The entered character count exceeds the allowable limit. Please enter a maximum of ${max} characters.`:
    `Die Anzahl der eingegebenen Zeichen überschreitet die zulässige Grenze. Bitte gebe maximal ${max} Zeichen ein.`)
    : undefined;

export const validEmail = (value?: string) =>
  value &&
  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)
    ? null
    : (localStorage.getItem("lang") == 'en'? `Oops! The email address you have entered does not exist. Please check your email and try again.`:
    `Oops! Die von Dir eingegebene E-Mail-Adresse existiert nicht. Bitte überprüfe deine E-Mail und versuche es erneut.`);

export const validPassword = (value?: string) =>
  value &&
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/.test(
    value
  ) ? null : (
    <ul className="password-ul">
      <li>At least 6 characters in length</li>
      <li>Contain at least one uppercase character (A-Z)</li>
      <li>Contain at least one lowercase character (a-z)</li>
      <li>Contain at least one numeric character (0-9)</li>
      <li>Contain at least one special character</li>
    </ul>
  );

  export const validPhoneNumber = (value?: { mobileNo: string }) =>
  value && /^\d{10,11}$/.test(value.mobileNo)
    ? null
    : `Mobile number should consist at least 10 and at most 11 number`;


export const validTime = (value?: string) =>
    value && /^[0-5]?\d:[0-5]\d$/.test(value)
      ? null
      : `Time should be in mm:ss`;

export const confirmPasswordMatchValidation =
  (password: string) => (value?: string) =>
    value && password && password !== value
      ? `Password confirmation failed. The entered password does not match with the previous password. Please check your password and try again.`
      : null
  ;

export const minDateCompare =
  (minDate: string) => (value?: string) =>
    value && minDate && minDate <= value
      ? null
      : `End Date Can't be less than start Date`;

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
  );

export const requiredWithMessage = (message: string) => (value: string) =>
  (value ? undefined : message);
