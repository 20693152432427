import React from "react";
import "./scss/custom.scss";
import { RouterProvider } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Suspense } from "react";
import { router } from "./routes";
import { AuthProvider } from "./hooks/useAuth";
import { MessageModalProvider } from "./contexts/MessageModalProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { EditProvider } from "./hooks/useCreateEditQuestion";
import './i18n/config';
import queryClient from "./utils/queryClient";
import { DragAndDropProvider } from "./contexts/DragAndDropProvider";

function App() {
  return (
    <MessageModalProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
            <Container fluid>
              <EditProvider>
                <DragAndDropProvider>
                  <Suspense fallback={"Loading"}>
                    <RouterProvider router={router} />
                  </Suspense>
                </DragAndDropProvider>
              </EditProvider>
            </Container>
        </AuthProvider>
      </QueryClientProvider>
    </MessageModalProvider>
  );
}

export default App;
