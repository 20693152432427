import { notifications } from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import iconProfile from "../../assets/images/icon-profile.png";
import { useAuth } from "../../hooks/useAuth";

export default function NTitle({ 
    detail,
    state
}: { 
    detail: any;
    state :number;
}) {
    const { showMessage } = useMessageModal();
    const { updateUserData, user } = useAuth();
    //const date = new Date(detail.createdAt);
    //const currentDate = new Date();
    const [key, setKey] = useState(-1);
    const { t } = useTranslation()

    // const compareDate = (createdDate:any, currentDate:any) => {
    //     //var currentDateVal = MONTHS[currentDate.getMonth()]+', '+date.getDate();
    //     var dateVal = MONTHS[createdDate.getMonth()]+', ' +createdDate.getDate();
    //     return dateVal;
    // }

    function handleRead(Ndetail:any, index:number) {
        setKey(index)
        notifications.readNotification(Ndetail?._id)
        .then((res) => {
            let count = user ? user?.totalNotificationsCount : 0;
            if(count && count > 0) {
                updateUserData({totalNotificationsCount: count - 1})
            }
        })
        .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
            showMessage({
              heading: t("error"),
              body: <p>{error.response?.data?.errorMessage}</p>,
              type: "error",
            });
        });
    }

    return (
        <>
        
            {/* <div className="nlist-main">
                <h4>{compareDate(date, currentDate)}</h4>
                <div className={`masseges ${detail?.isRead === true || key === state ? "grey" : "yellowlight"}`}>
                    <div className={`left-content ${detail.isRead === true ? "leftfull-width" : ""}`}>
                        <p>{detail?.title}</p>
                        <div className="sizes-info-notification">
                            <div className="text">{detail?.body}</div>
                        </div>
                    </div>
                    <div className="btn-accepted">
                    {
                        (detail.isRead || key === state) ? 
                            null
                        : 
                            <Button variant="accept" onClick={() => handleRead(detail, state)}>
                            {t('Got it')}
                            </Button>
                    
                        
                    }
                    </div>
                </div>
            </div> */}


            <div className="notification-listing">
                <div className="left-text">
                    <div className="_image">
                        <img
                            src={detail.userImageUrl ? detail.userImageUrl : iconProfile}
                            onError={(e: any) => {
                            e.target.src = iconProfile;
                            }}
                            alt="ProfileImg"
                        />
                    </div>
                    <div className="user-info">
                        <div className="name">{detail?.title}</div>
                        <div className="email">{detail?.body}</div>
                    </div>
                </div>
                {/* {
                    (detail.isRead || key === state) ? 
                        null
                    : 
                    <div className="righttext">
                        <button className="n-btn goit" onClick={() => handleRead(detail, state)}>Got it</button>
                    </div>
                } */}
          </div>
        </>
    );
}