import React, { useMemo } from "react";

interface EditContextType {
  user: any;
  questionDetail:any;
  updateQuestionData: (data: any) => void;
  deleteQuestionData: (data: any) => void;
  updateQuestionDetail: (data: any) => void;
  deleteQuestionDetail: (data: any) => void;
}

let EditContext = React.createContext<EditContextType>(null!);

export function EditProvider({ children }: { children: React.ReactNode }) {

  let [user, setUser] = React.useState<any>({
    question: "",
    time: "",
    answer: "",
    category: [],
    productDetails: []
  });

  let [questionDetail, setQuestionDetail] = React.useState<any>([]);

  const updateQuestionData = (data: any) => {
    setUser(data);
  }

  const deleteQuestionData = (productId: any) => {
    const index = user.productDetails.findIndex((item: any) => item['id'] === productId);
    user.productDetails.splice(index, 1);
    setUser((prev: any) => {
      return {
        ...prev, productDetails: user.productDetails
      }
    });
  }

  const updateQuestionDetail = (data: any) => {
    if(Object.keys(data).length === 0) {
      setQuestionDetail([]);

    } else {
      setQuestionDetail((prev:any) => [...prev, data]);
    }
  }

  const deleteQuestionDetail = (id: any) => {
    setQuestionDetail((prev: any) => {
      const index = prev.findIndex((item:any) => item['_id'] === id);
      if(prev[index]) {
        prev.splice(index, 1);
      }
      return prev;
    });
  }

  const value = useMemo(
    () => ({
      user,
      questionDetail,
      updateQuestionData,
      deleteQuestionData,
      updateQuestionDetail,
      deleteQuestionDetail
    }),
    [user, questionDetail]
  );

  return <EditContext.Provider value={value}>{children}</EditContext.Provider>;
}

export function useCreateEditQuestion() {
  return React.useContext(EditContext);
}
