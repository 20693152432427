import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";

import {
  composeValidators,
  maxlength,
  required,
  validEmail,
  requiredWithMessage,
  validPhoneNumber
} from "../../validations";
import { FormControl } from "../../components";
import { VALIDATIONS } from "../../constants";
import { support } from "../../api";
import { useMessageModal } from "../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { MobilePhone } from "../../components/FormElements/MobilePhone";
import { useTranslation } from "react-i18next";
import IconA from "../../assets/images/a-1.png";
import IconB from "../../assets/images/a-2.png";
import IconC from "../../assets/images/a-3.png";
import IconLine from "../../assets/images/Line.svg";
import IconMessage1 from "../../assets/images/customer-service.jpeg";
import IconMessage2 from "../../assets/images/expert-service.jpeg";
import { useCustomMutation } from "../../hooks/useApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Any } from "../../types/global.type";

function ContactUsForm() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = (values: Any) => {
    const input = {
      countryCode: "+" + values.mobileNumber.countryCode,
      mobileNo: values.mobileNumber.mobileNo,
    };

    const data = {
      ...values,
      ...input,
    };

    delete data.mobileNumber;
    return support.contactUs(data);
  };

  const mutation = useCustomMutation({
    mutationFn: onSubmit,
    onSuccess: () => {
      showMessage({
        heading: t("Thanksforyourthoughts!"),
        body: <p>{t("contactUsMsg")}</p>,
        type: "success",
        callback: () => {
          navigate(ROUTE_NAVIGATION_PATH.FAQ, { replace: true });
        },
      });
    },
  });

  const [initData] = useState({
    name: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    message: "",
  });


  return (
    <>
      <Form
         initialValues={initData}
         onSubmit={(values) => mutation.mutate(values)}
         render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              label={t("YourName") as string}
              name="name"
              type="Name"
              placeholder={t("EnterYourFullName")}
              // icon={IconUser}
              validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_NAME)
              )}
            />
            <FormControl
              label={t("EmailAddress") as string}
              name="email"
              type="email"
              placeholder={t("EnterYourEmailAddress")}
              validate={composeValidators(required, validEmail)}
              // icon={IconEmail}
            />

            <MobilePhone
              label={t("mobilePhone") as string}
              name="mobileNumber"
              country={"us"}
              validate={composeValidators(required, validPhoneNumber)}
              // onChange={handleOnChange}
            />

            <FormControl
              label={t("Message") as string}
              name="message"
              type="textarea"
              validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_DESCRIPTION)
              )}
              placeholder={t("WriteAMessage")}
            />
            <Button variant="primary" type="submit">
              {t("sendRequest")}
            </Button>
            <p className="mm">Magic Moment</p>
          </form>
        )}
      />
    </>
  );
}
export default function ContactUs() {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="contactus-wrapper">
              <Col md={12} lg={10} xl={6} className="contactus-form">
                <ContactUsForm />
              </Col>
              <Col md={12} lg={10} xl={6} className="socialInfo">
                <div className="social-content">
                  <h2>{t('Use')} Magic Moment</h2>
                  <div className="userprofilesText">
                    <div className="_image">
                      <img src={IconA} alt="MAGICMOMENT" />
                    </div>
                    <div className="_image _mid">
                      <img src={IconB} alt="MAGICMOMENT" />
                    </div>
                    <div className="_image">
                      <img src={IconC} alt="MAGICMOMENT" />
                    </div>
                  </div>
                  {/* <div className="agentsnumbers">
                    {t("See what other")} <span>4600+ {t("agents")}</span> {t("are doing")}
                  </div> */}
                  <div className="lines">
                    <img src={IconLine} alt="MAGICMOMENT" />
                  </div>
                  <div className="messages">
                    <div className="m-icons">
                      <img src={IconMessage1} alt="MAGICMOMENT" />
                    </div>
                    <div className="m-text">
                      <span>{t("immediateSupport")}: </span> {t("immediateSupportDescription")}
                    </div>
                  </div>
                  <div className="messages">
                    <div className="m-icons">
                      <img src={IconMessage2} alt="MAGICMOMENT" />
                    </div>
                    <div className="m-text">
                      <span>{t("exppertHighestLevel")}: </span> {t("expertLevelDescription")}
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
