import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-final-form";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  composeValidators,
  maxlength,
  required,
  validEmail,
  validPassword,
  confirmPasswordMatchValidation,
} from "../../../validations";

import { AuthLayout, CheckboxControl, FormControl, PasswordField } from "../../../components";
import { VALIDATIONS, MESSAGES } from "../../../constants";
import { authetication } from "../../../api";
import { useMessageModal } from "../../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { encryptPassword } from "../../../utils";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import IconEmail from "../../../assets/images/iconEmail.svg";
import logo from "../../../assets/images/logo.svg";
import IconUser from "../../../assets/images/iconUser.svg";
import GoogleSignin from "../GoogleSignin";

function SignUpForm() {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { saveToken } = useAuth();
  const { token } = useParams();
  const { t } = useTranslation();
  const [errorInfo, setErrorInfo] = useState(false);
  const [errorCode, setErrorCode] = useState(400);

  const [initData, setInitData] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    displayName: "",
    displaySurname: "",
  });

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      password: encryptPassword(values.password),
      name: token ? initData.displayName : values.name,
      surname: token ? initData.displaySurname: values.surname
    };
    delete data.confirmPassword;
    delete data.displayName;
    delete data.displaySurname;
    authetication
      .doSignup(data, token)
      .then((res) => {
        if (token) {
          saveToken(res);
          navigate(ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD, { replace: true });
        } else {
          showMessage({
            heading: "Bitte überprüfe dein E-Mail Postfach",
            body: <p>Wir haben einen Verifizierungslink an deine E-Mail gesendet. Überprüfe dein E-Mail Postfach und klicke auf den Link, um deine E-Mail zu verifizieren und dich anzumelden.</p>,
            type: "success",
            callback: () => {
              navigate(ROUTE_NAVIGATION_PATH.SIGN_IN, { replace: true });
            },
          });
        }
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{t(error?.response?.data?.errorMessage as string) }</p>,
          type: "error",
          callback: () => {
            navigate(ROUTE_NAVIGATION_PATH.SIGN_IN, { replace: true });
          },
        });
      });
  };

  useEffect(() => {
    if (token) {
      authetication
        .getInvitedUserData(token)
        .then((res: any) => {
          setInitData({
            email: res.data.email,
            name: res.data.name,
            surname: res.data.surname,
            password: "",
            confirmPassword: "",
            displayName: res.data.name,
            displaySurname: res.data.surname,
          });
        })
        .catch(
          (error: AxiosError<{ errorMessage: string; status: number }>) => {
            if(error?.response?.status === 406 || error?.response?.status === 422) {
              setErrorInfo(true);
              setErrorCode(error?.response?.status);
            } else {
              showMessage({
                heading: t("error"),
                body: <p>{t(error?.response?.data?.errorMessage as string)}</p>,
                type: "error",
              });
            }
            
          }
        );
    }
  }, [token]);

  return (
    <>
     <div className="_logo">
        <img src={logo} alt="MAGICMOMENT" />
      </div>
      { errorInfo ? 
        ( errorCode === 422 ? 
          <>
            <div className="mt-5">
              
                <h3>
                  <strong>Magic Moment die einladungs-URL ist abgelaufen!</strong>
                </h3>
                <div className="mt-5">
                  <p>
                  Sie haben die Einladung bereits angenommen. Bitte melden Sie sich an, um fortzufahren.
                  </p>
                </div>
                <Button variant="primary" className="mt-5" onClick={() => navigate(ROUTE_NAVIGATION_PATH.SIGN_IN)}>
                  Anmelden
                </Button>
            </div>
          </>
          
          : 
          
          <>
            <div className="mt-5">
              
                <h3>
                  <strong>Magic Moment invitation url  has been expired!</strong>
                </h3>
                <div className="mt-5">
                  <p>
                    If you are not able to complete your onboarding, you will need to request a new invitation link from adminstrator. 
                  </p>
                  <p>( <span className="email-text">software@kopfspringer.com</span>)</p>
                </div>
                <Button variant="primary" className="mt-5" onClick={() => navigate(ROUTE_NAVIGATION_PATH.SIGN_IN)}>
                  Anmelden
                </Button>
            </div>
          </>
        )
        
        : 
        <>
          <div className="logintabs">
            <div className="tabs" onClick={() => navigate(ROUTE_NAVIGATION_PATH.SIGN_IN)}>Anmelden</div>
            <div className="tabs active">Registrieren</div>
          </div>
          {
            token ? null : <GoogleSignin />
          }
          <div className="_or">
            <span>or</span>
          </div>
          <Form
            initialValues={initData}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="signupfield">
                <FormControl
                  name="name"
                  type="Name"
                  placeholder={t('Vorname')}
                  icon={IconUser}
                  validate={composeValidators(
                    required,
                    maxlength(VALIDATIONS.MAX_NAME)
                  )}
                  disabled={!!token}
                />
                <FormControl
                  name="surname"
                  type="LastName"
                  placeholder={t('Nachname')}
                  icon={IconUser}
                  validate={composeValidators(
                    required,
                    maxlength(VALIDATIONS.MAX_NAME)
                  )}
                  disabled={!!token}
                />
                </div>
                <FormControl
                  name="email"
                  type="email"
                  placeholder="E-Mail-Adresse eingeben"
                  validate={composeValidators(required, validEmail)}
                  icon={IconEmail}
                  disabled={!!token}
                />
                <PasswordField
                  placeholder="Passwort eingeben"
                  validations={composeValidators(required, validPassword)}
                />
                {/* <span className="info">
                  Password should consist at least 6 symbols
                </span> */}
                <PasswordField
                  validations={composeValidators(
                    required,
                    confirmPasswordMatchValidation(values.password)
                  )}
                  placeholder="Passwort bestätigen"
                  name="confirmPassword"
                />
                <Button variant="primary" type="submit" className="mt-5">
                  Registrieren
                </Button>
                <div className="termstext">
                  <CheckboxControl name="isTermsAndConditionChecked" validations={composeValidators(required)}/>
                </div>
              </form>
            )}
          />
        </>
      }
    </>
  );
}
export default function SignUp() {
  return (
    <AuthLayout
      leftContent={
        <>
          <h1>
            Ihr smarter Assistant <br />
            im Kundenservice
          </h1>
          <p>Intelligente Unterstützung ihrer Call-Center-Mitarbeiter zur Ermittlung von Upsell-Potentialen beim Kunden</p>
        </>
      }
      rightContent={<SignUpForm />}
    />
  );
}
