import Button from "react-bootstrap/Button";
import { Member } from "../../../types/User";
import { useTranslation } from "react-i18next";
import AddEditMember from "../../FormElements/AddEditMember";
import { useState } from "react";
import { Form } from "react-final-form";
import { team } from "../../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { useAuth } from "../../../hooks/useAuth";

export default function LeftProfileMenu({
  user
}: {
  user: Member
}) {
  const [file, setFile] = useState<File | null>();
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  const editable = true;
  const updateImage = (file: File | null | undefined) => {
    file ? setFile(file) : setFile(null);
  };
  const { updateUserData } = useAuth();

  const onSubmit = (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("surname", (values.surname ? values.surname : ''));
    if (file) {
      formData.append("image", file);
    }
    formData.append("position", values.position);
    team
      .editMember(formData, user?._id as string)
      .then((res) => {
        updateUserData({ image: res.data.image });
        showMessage({
          heading: t("Profile"),
          body: <p>{t("SavedProfileSuccess")}</p>,
          type: "success"
        });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <Form
    initialValues={user}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <AddEditMember 
          updateImage={updateImage}
          editForm={editable}
          photo={user?.image}
          isDeleteButton={false}
        />
        <Button variant="primary fixwidth" type="submit">
          {t('save')}
        </Button>
      </form>
    )}
  />
  );
}
