import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { SearchBar } from "../../../../components";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { useEffect, useState } from "react";
import useEditDelete from "../../../../hooks/useEditDelete";
import { ROLE, USER_STATUS } from "../../../../constants";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { product } from "../../../../api";
import useInfiniteScroll from "react-infinite-scroll-hook";
import DeleteProduct from "../../../../components/Modal/DeleteProduct";
import ProductTile from "../../../../components/Product/ProductTile";
import { Spinner } from "react-bootstrap";
import { NoData } from "../../../../components";
import EditProductModal from "../../../../components/Modal/EditProductModal";
import AddProductModal from "../../../../components/Modal/AddProductModal";
import { useAuth } from "../../../../hooks/useAuth";
import { useCreateEditQuestion } from "../../../../hooks/useCreateEditQuestion";

export default function ProductList() {
  const { t } = useTranslation();
  const params = new URLSearchParams(document.location.search).get('redirect');
  const isQuestion = new URLSearchParams(document.location.search).get('toQuestion');
  const fromEdit = new URLSearchParams(document.location.search).get('fromEdit');
  const fromProposal = new URLSearchParams(document.location.search).get('fromProposal');
  const navigate = useNavigate();
  const [addProduct, setProduct] = useState(false);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [search, setSearch] = useState("");
  const status = USER_STATUS.ACTIVE;
  const { user } = useCreateEditQuestion();
  const { deleteFor, editData, setEditData, setDeleteFor } = useEditDelete();
  const auth = useAuth();
  const { loading, items, hasNextPage, error, deleteItem, updateItem, addItem } =
    useLoadItems({
      loadItems: product.allProductList,
      page,
      search,
      status
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const deleteProduct = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");

  }, [location.search]);

  const refreshProduct = (isOk: boolean) => {
    if (isOk) {
      setProduct(false);
    }
  };

  const updateListItem = (updatedData: any) => {
    updatedData._id = editData?._id as string;
    updateItem(updatedData);
    setEditData(null);
  };

  const addListItem = (updatedData: any) => {
    addItem(updatedData);
  }

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS)
  }

  function goBackToQuestion() {
    if(fromEdit === '1') {
      navigate({
        pathname: ROUTE_NAVIGATION_PATH.EDIT_QUESTION,
            search: createSearchParams({
                id: user.id,
                fromProduct: "1",
        }).toString()
      });
    } else if(fromProposal == '1') {
      navigate({
        pathname: ROUTE_NAVIGATION_PATH.EDIT_PROPOSAL,
            search: createSearchParams({
                id: user.id,
                fromProduct: "1",
                fromProposal: "1"
        }).toString()
      });
    } else {
      let url = auth.user.questionCount > 0 ? ROUTE_NAVIGATION_PATH.ADD_QUESTION : ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS;
      navigate({
        pathname: url,
            search: createSearchParams({
                fromProduct: "1",
        }).toString()
      });
    }
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width" > */}
          <div className="mid_outer_content">
            <div className="heading-common">
              {/* {params ? <BackIcon /> : null} */}
              <h1>{t("Products")}</h1>
              <p>{ auth.user.role === ROLE.TEAM_LEADER ? t("productHeader") : ""}</p>
            </div>
            <div  className={`search-wth-content2 ${ (params || isQuestion)? "search-with-continue": auth.user.role !== ROLE.TEAM_LEADER
              ? "w-100" : "search-with-add"}
            `}>
              <SearchBar
                placeHolder={t("searchProduct")}
              />
              {
                auth.user.role === ROLE.TEAM_LEADER ?
                  <>
                    <div className="add-btn" onClick={() => setProduct(true)}>{t("addProduct")}</div>
                    {params ?
                      <div className="continue-btn" onClick={handleClick}>{t("Continue")}</div>
                      :
                      null
                    }
                    
                  </>
                  :
                  null
              }
              {
                      isQuestion ?
                        <div className="continue-btn" onClick={() => goBackToQuestion()}>{t("Back")}</div>
                      :
                        null
                    }

            </div>
            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent _categorydetail _productlistingcontent">
                {!items.length && !loading ? <NoData /> : null}
                {items.length ?
                  <table className="_producttable">
                    <thead>
                      <tr>
                        <th>{t("Products")}</th>
                        <th>{t("Description")}</th>
                        <th>{t("lastEdited")}</th>
                        {
                          auth.user.role === ROLE.TEAM_LEADER ?
                          <th></th>
                          :
                          null
                        }
                      </tr>
                    </thead>
                    <tbody>

                      {items.map((product: any) => (
                        <ProductTile
                          key={product._id}
                          product={product}
                          editProduct={() => setEditData(product)}
                          deleteProduct={() => setDeleteFor(product._id)}
                        />
                      ))}

                    </tbody>
                  </table>
                  :
                  null
                }
                {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <EditProductModal
        detail={editData}
        handleClose={() => setEditData(null)}
        updateListItem={updateListItem}
      />
      <DeleteProduct
        show={!!deleteFor}
        handleClose={() => setDeleteFor(null)}
        id={deleteFor}
        callback={deleteProduct}
      />
      <AddProductModal
        show={!!addProduct}
        handleClose={() => setProduct(false)}
        callback={refreshProduct}
        addListItem={addListItem}
      />
    </>
  );
}
