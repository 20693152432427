import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Navigate } from "react-router-dom";
import { ROLE } from "../constants";
import { useAuth } from "../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../routes/routes";
import quotes from "../assets/images/quotes.svg";
import profileimg from "../assets/images/profile-img.png";
export default function AuthLayout({
  leftContent,
  rightContent,
}: {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}) {
  const auth = useAuth();
  var date =
    auth.user && auth.user.invoiceId != null
      ? new Date(auth.user.invoiceId.planEndDate)
      : null;
  if (auth.user) {
    return auth.user.role === ROLE.TEAM_MEMBER ? (
      <Navigate to={ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD} replace />
    ) : auth.user && auth.user.role === ROLE.TEAM_LEADER ? (
      auth.user.invoiceId === null || (date && date < new Date()) ? (
        <Navigate to={ROUTE_NAVIGATION_PATH.SUBSCRIPTION} replace />
      ) : 
      auth.user.teamMemberCount > 0 ? (
        <Navigate to={ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD} replace />
      ) : (
        <Navigate to={ROUTE_NAVIGATION_PATH.PRE_CREATION} replace />
      )
    ) : (
      <Navigate to={ROUTE_NAVIGATION_PATH.MANAGE_ADMIN} replace />
    );
  }
  return (
    <Row className="bg-authlayout">
      <Col sm={4} className="p-0 left-column">
        <div className="bg-secondary vh-100">
          <div className="welcome-container">
            <div className="welcome-text">{leftContent}</div>
            <div className="testimonials-login">
              <div className="testimonialcontent-bottom">
                <div className="quotes">
                  <img src={quotes} alt="MAGICMOMENT" />
                </div>
                <div className="testimonialstxt">
                  <p>
                    “Das Tool ermöglicht es mir, Kunden basierend auf ihren individuellen Anfragen und Bedürfnissen zum optimalen Zeitpunkt passende Produktvorschläge zu unterbreiten.”
                  </p>
                </div>
                <div className="testimonialprofile">
                  <div className="profiletestim">
                    <img src={profileimg} alt="MAGICMOMENT" />
                  </div>
                  <div className="profile-info">
                    <div className="name">Leslie Alexander</div>
                    <div className="designation">React Developer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col
        sm={8}
        className="d-flex justify-content-center align-items-center login-signup-page-card"
      >
        <div className="outer-form">
          <div className="scrollbar100">
            <div className=" login-form">{rightContent}</div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
