import Button from "react-bootstrap/Button";
import { Form } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import { composeValidators, required, validEmail } from "../../../validations";
import { AuthLayout, FormControl, PasswordField } from "../../../components";
import IconEmail from "../../../assets/images/iconEmail.svg";
import logo from "../../../assets/images/logo.svg";
import { useAuth } from "../../../hooks/useAuth";
import { AuthType } from "../../../types/User";
import { encryptPassword } from "../../../utils";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { ROLE } from "../../../constants";
import GoogleSignin from "../GoogleSignin";

function SignInForm() {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const onSubmit = (values: AuthType) => {
    const data = { ...values };
    data.password = encryptPassword(data.password);
    signin(data, (res) => {
      if (res.user.role === ROLE.TEAM_MEMBER) {
        navigate(ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD, { replace: true });
      } else if (res.user.role === ROLE.TEAM_LEADER) {
        var date =
          res.user.invoiceId != null
            ? new Date(res.user.invoiceId.planEndDate)
            : null;
        if (res.user.invoiceId == null || (date && date < new Date())) {
          navigate(ROUTE_NAVIGATION_PATH.SUBSCRIPTION);
        } else {
          res.user?.teamMemberCount > 0
            ? navigate(ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD, {
                replace: true
              })
            : navigate(ROUTE_NAVIGATION_PATH.PRE_CREATION, { replace: true });
        }
      } else {
        navigate(ROUTE_NAVIGATION_PATH.MANAGE_ADMIN, { replace: true });
      }
    });
  };

  return (
    <>
      <div className="_logo">
        <img src={logo} alt="MAGICMOMENT" />
      </div>
      <div className="logintabs">
        <div className="tabs active">Anmelden</div>
        <div
          className="tabs"
          onClick={() => navigate(ROUTE_NAVIGATION_PATH.SIGN_UP)}
        >
          Registrieren
        </div>
      </div>
      <GoogleSignin />
      <div className="_or">
        <span>or</span>
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              name="email"
              type="email"
              placeholder="E-Mail-Adresse eingeben"
              validate={composeValidators(required, validEmail)}
              icon={IconEmail}
            />
            <PasswordField placeholder="Passwort eingeben" validations={required} />
            <Link to={ROUTE_NAVIGATION_PATH.FORGOT_PASSWORD}>
              <Button variant="link">Passwort vergessen?</Button>
            </Link>
            <Button variant="primary" type="submit">
              Anmelden
            </Button>
          </form>
        )}
      />
    </>
  );
}
export default function SignIn() {
  return (
    <AuthLayout
      leftContent={
        <>
          <h1>
            Ihr smarter Assistant <br />
            im Kundenservice
          </h1>
          <p>Intelligente Unterstützung ihrer Call-Center-Mitarbeiter zur Ermittlung von Upsell-Potentialen beim Kunden</p>
        </>
      }
      rightContent={<SignInForm />}
    />
  );
}
