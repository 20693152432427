import { Any } from "../../types/global.type";
import { api } from "../api";

export const ENDPOINTS = {
    GET_PATH_FIRST_QUESTIONS_LIST: "v1/path/getPathFirstStepQuestionByCategory",
    GET_TOP_PRODUCT_LIST: "v1/product/getTopProductList",
    POST_CALL_UPDATE: "v1/call/add-or-edit",
    POST_UPSELL_INFO: "v1/customer-info/add",
    GET_PATH_BY_ID_AND_STEP: "v1/path/getPathByIdAndStep",
    GET_CALL_STATS: "v1/statistics/call-stats",
    GET_TALK_DURATION: "v1/call/talk-duration",
    GET_CALL_LIST: "v1/call/total-calls"
};

async function getPathFirstStepQuestion(categoryId: Any, search:Any) {
    const data = {
        categoryId: categoryId,
        search: search
    }
    return api.get(ENDPOINTS.GET_PATH_FIRST_QUESTIONS_LIST, { params: data }).then((res) => res.data);
}

async function getPathByIdAndStep(data: Any) {
    return api.get(ENDPOINTS.GET_PATH_BY_ID_AND_STEP, { params: data }).then((res) => res.data);
}

async function callUpdate(data?: Any) {
    if(data) {
        return api.post(ENDPOINTS.POST_CALL_UPDATE, data).then((res) => res.data);

    } else {
        return api.post(ENDPOINTS.POST_CALL_UPDATE).then((res) => res.data);
    }
}

async function customerInfoForUpsell(data?: Any) {
    return api.post(ENDPOINTS.POST_UPSELL_INFO, data).then((res) => res.data);
}

async function customerCallStats(agentId?: Any) {
    return api.get(ENDPOINTS.GET_CALL_STATS + '/' + agentId ).then((res) => res.data);
}

async function getTalkDuration(data: Record<string, any>) {
    return api.get(ENDPOINTS.GET_TALK_DURATION, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.averageCallDuration,
          hasNextPage: rData.data.totalCount > data.page * data.count,
          totalInHours: rData.data.totalAvgCallsCountInHours,
          totalInMinutes: rData.data.totalAvgCallsCountInMinutes,
        };
    });
}

async function getTotalCall(data: Record<string, any>) {
    return api.get(ENDPOINTS.GET_CALL_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.callers,
          hasNextPage: rData.data.totalCount > data.page * data.count,
          totalInMinutes: rData.data.totalCallsCount
        };
    });
}


export {
    getPathFirstStepQuestion,
    getPathByIdAndStep,
    callUpdate,
    customerInfoForUpsell,
    customerCallStats,
    getTotalCall,
    getTalkDuration
};
