import {
  composeValidators,
  required,
  maxlength,
} from "../../validations";
import { FormControl } from "../FormElements/FormControl";
import {
  VALIDATIONS,
} from "../../constants";

export default function AddEditCategory({
  editForm,
  updateFilterCategoryId
}: {
  editForm?: boolean;
  updateFilterCategoryId?: (data: any | undefined) => void;
}) {
 
  return (
    <>
        <FormControl
            name="name"
            label="Category Name"
            type="Name"
            updateFilterCategoryId={updateFilterCategoryId}
            validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_NAME)
            )}
        />
        <FormControl
            label="Description"
            name="description"
            type="textarea"
            validate={composeValidators(
                required,
                maxlength(VALIDATIONS.MAX_DESCRIPTION)
            )}
        />
    </>
  );
}
