import { api } from "../api";
import { Any } from "../../types/global.type";

export const ENDPOINTS = {
    GET_CATEGORY_LIST: "v1/category/list",
    POST_CREATE_TEAM: "v1/category/add",
    DELETE_CATEGORY: "/v1/category/delete",
    EDIT_CATEGORY: "/v1/category/edit",
    GET_CATEGORY_DEFAULT_LIST : 'v1/category/template-list'
};

async function defaultcategoryList(data: Record<string, any>) {
    return api.get(ENDPOINTS.GET_CATEGORY_DEFAULT_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.categoryTemplates,
          hasNextPage: rData.data.count > data.page * data.count
        };
    });
}

async function createCategory(data: Any) {
    return api.post(ENDPOINTS.POST_CREATE_TEAM, data).then((res) => res.data);
}

async function allcategoryList(data: Record<string, any>) {
    return api.get(ENDPOINTS.GET_CATEGORY_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.categories,
          hasNextPage: rData.data.count > data.page * data.count,
          totalCount: rData.data.count
        };
    });
}

async function topcategoryList(data: Record<string, any>) {
    data.showTopCategories = true;
    return api.get(ENDPOINTS.GET_CATEGORY_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.categories,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function editCategory(data:Any, id:Any) {
    delete data.id;
    return api
    .put(ENDPOINTS.EDIT_CATEGORY + '/' + id, data)
    .then((res) => res.data);
}

async function deleteCategory(categoryId:string) {
    return api
    .delete(ENDPOINTS.DELETE_CATEGORY + '/' + categoryId)
    .then((res) => res.data);
}

export {
    defaultcategoryList,
    topcategoryList,
    createCategory,
    allcategoryList,
    editCategory,
    deleteCategory
};
