import { FormControl } from "./FormControl";

export function CheckboxControl({
  validations,
  name = "isTermsAndConditionChecked",
}: {
  validations: any;
  name?: string;
})  {
  return (
    <>
      <FormControl
        name={name}
        type="checkbox"
        validate={validations}
      />
      <p>Mit der Erstellung eines Kontos erklärst du dich mit unseren Nutzungsbedingungen und unserer Datenschutz- und Cookie-Erklärung einverstanden.</p>
      {/* <p>By creating an account, you agree to our <a href="#">Terms of Service</a>  and <a href=""> Privacy & Cookie Statement</a>.</p> */}
    </>
  );
}