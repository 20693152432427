import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";
import { admin} from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { AddEditInvoice } from "..";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";


export default function EditInvoice({
  detail,
  handleClose,
  updateListItem
}: {
  detail: any;
  handleClose: VoidFunction;
  updateListItem: (invoice: any) => void
}) {
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  const params = useParams();
  function formatDate(value:any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return  date;
  }

  const editData = {
    planStartDate: detail ? formatDate(detail?.planStartDate): null,
    planEndDate: detail ? formatDate(detail?.planEndDate) : null,
    permittedMembers: detail ? detail?.permittedMembers :'',
    invoiceAmount: detail ? detail?.invoiceAmount: '',
    invoiceDate: detail ? formatDate(detail?.invoiceDate): null
  };

  const onSubmit = (values: any) => {
    const data = {
        planStartDate: new Date(values.planStartDate),
        planEndDate: new Date(values.planEndDate),
        permittedMembers: values.permittedMembers,
        invoiceAmount: values.invoiceAmount,
        teamLeaderId: params.teamLeaderId,
        addedMembers: detail ? detail.addedMembers : 0,
        invoiceDate: values.invoiceDate
    };
    admin
      .updateInvoice(data, detail?._id)
      .then((res: any) => {
        updateListItem(data);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={!!detail}
      onHide={handleClose}
      dialogClassName={"model-common"}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>{t("Edit")} {t('InvoiceDetails')}</Modal.Title>
      </Modal.Header>
      <Form
        initialValues={{...editData}}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body className="_visible">
                <AddEditInvoice values={values}/>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary full" type="submit">
                {t('save')}
              </Button>
              <Button variant="cancel full" onClick={handleClose}>
                {t('Cancel')}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
}
