import moment from "moment";
import { Any } from "../../types/global.type";
import { api } from "../api";

export const ENDPOINTS = {
    GET_LEADER_DASHBOARD_STATS: "v1/statistics/dashboard",
    GET_MEMBER_DASHBOARD_STATS: "v1/statistics/member/dashboard",
    GET_MEMBER_CALL_HISTORY: "v1/call/call-history",
    DOWNLOAD_MEMBER_CALL_DETAIL: "v1/call/download-call-details-file",
    GET_MEMBER_INSIGHTS_STATS: "v1/statistics/member/insights",
    GET_MEMBER_INSIGHTS_GRAPH: "v1/statistics/graph-data"
};

async function getLeaderDashboardStats() {
    return api.get(ENDPOINTS.GET_LEADER_DASHBOARD_STATS).then((res) => res.data);
}

async function getMemberDashboardStats() {
    return api.get(ENDPOINTS.GET_MEMBER_DASHBOARD_STATS).then((res) => res.data);
}

async function getMemberCallHistory(data: Record<string, any>) {
    return api.get(ENDPOINTS.GET_MEMBER_CALL_HISTORY, { params: data }).then((res:Any) => {
        const rData = res;
        return {
          data: rData.data.calls,
          hasNextPage: rData.data.totalCount > data.page * data.count
        };
    })
}

async function downloadMemberCallDetail() {
    return api.get(ENDPOINTS.DOWNLOAD_MEMBER_CALL_DETAIL).then((res) => res.data);
}

async function getMembetInsights() {
    return api.get(ENDPOINTS.GET_MEMBER_INSIGHTS_STATS).then((res) => res.data);
}

async function getMembetInsightGraph(fromDate:any, endDate:any) {
    const data = {
        fromDate: moment(fromDate).format('MM-DD-YYYY'),
        toDate: moment(endDate).format('MM-DD-YYYY')
    }
    return api.get(ENDPOINTS.GET_MEMBER_INSIGHTS_GRAPH, { params: data }).then((res) => res.data);
}


export {
    getLeaderDashboardStats,
    getMemberDashboardStats,
    getMemberCallHistory,
    downloadMemberCallDetail,
    getMembetInsights,
    getMembetInsightGraph
};
