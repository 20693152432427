import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { NoData, SearchBar } from "../../../components";
import Iconcalendar from "../../../assets/images/calendar.svg";
import profileIcon from "../../../assets/images/icon-profile.png";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { team } from "../../../api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ROLE, USER_STATUS } from "../../../constants";
import moment from 'moment';
import DateRangePicker from "rsuite/esm/DateRangePicker";
import { useAuth } from "../../../hooks/useAuth";

export default function AllAgents() {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [page, setPage] = useState(0);
  const auth = useAuth();
  const [search, setSearch] = useState("");
  const [fromDate, setFromdate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const role = auth.user.role === ROLE.TEAM_LEADER ? '1' : '0';
  const location = useLocation();
  const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: team.getOfflineOnlineMembers,
      page,
      search,
      role,
      fromDate,
      toDate
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
  }, [location.search]);

  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  const changeDate = (date: any) => {
    if(date) {
      setPage(1);
      setFromdate(date[0]);
      setToDate(date[1]);
    }
  }

  const onClear = () => {
    setShowDatePicker(false)
  }

  function formatDate(value: any) {
    const format1 = "MMM DD"
    const date = new Date(value);
    return moment(date).format(format1);
  }

  return (
    <Row>
      {/* <section className="main-container aligncenter_section resize_container shrink-width" > */}
        <div className="mid_outer_content">
          <div className="heading-common">
            <h1>{t('allAgents')}</h1>
            {/* <p>{t('allAgentsSubHeading')}</p> */}
          </div>
          <div className="search-wth-content2 w-100">
              <SearchBar placeHolder={t("seachAnAgent")} />
          </div>

          <div className="all-agent-list-wrapper" ref={rootRef}>
            <div className="select-category-wrapper">
              <div className="all-agent-heading">
                <h3>{t('OverviewAgents')}</h3>
                <div className="calendar-wrpper">
                  <div className="table-calendar">

                    <div className="calenderinfo">
                      <div className="date" onClick={() => setShowDatePicker(true)}>
                        <img src={Iconcalendar} alt="" />
                        
                      </div>
                      {
                        showDatePicker ?
                          <div className="dropinfo">
                            <div className="dwon"></div>
                            <DateRangePicker
                              value={[fromDate, toDate]}
                              onChange={(update: any) => {
                                changeDate(update);

                              }}
                              appearance="default"
                              placeholder='yyyy-mm-dd - yyyy-mm-dd'
                              format="yyyy-MM-dd"
                              onOk={() => setShowDatePicker(false)}
                              defaultCalendarValue={[new Date(), new Date()]}
                              onClean={() => onClear()}
                            />
                          </div>
                          :
                          <>
                            {
                              fromDate ?
                          
                                <span onClick={() => setShowDatePicker(true)}>
                                  {formatDate(fromDate) + '  -  ' + (toDate ? formatDate(toDate) : formatDate(fromDate))}
                                </span>
                              :
                                null
                            }
                          </>
                         
                      }
                    </div>
                  </div>
                </div>
                 
               
              </div>
              <div className="tableouter">
                {!items.length && !loading ? <NoData /> : null}

                <table className="_producttable">
                  {items.length > 0 ? (
                    <thead>
                      <tr>
                        <th>{t('memberName')}</th>
                        <th>{t('Calls')}</th>
                        <th>{t('useCards')}</th>
                        <th>{t('Upsells')}</th>
                        <th>{t('talkTime')}</th>
                        <th>{t('avgTalkTime')}</th>
                        <th>{t('Position')}</th>
                        <th>{t('Status')}</th>
                      </tr>
                    </thead>
                  ) :
                    null
                  }
                  {items.map((member:any, key:any) => (
                    <tbody>
                      <tr key={member?._id}>
                        <td>
                          <div className="user-info">
                            <div className="img">
                              <img
                                 src={member?.image ? member?.image : profileIcon}
                                 onError={(e: any) => {
                                   e.target.src = profileIcon;
                                 }}
                                alt="MagicMoment" />
                            </div>
                            <p>{capitalizeFirst(member.name) + ' ' + (member.surname ? capitalizeFirst(member.surname) : '')}</p>
                          </div>
                        </td>
                        <td>{member.calls}</td>
                        <td>{member.usedCards}</td>
                        <td>{member.upsells}</td>
                        <td>{member.talkTime}</td>
                        <td>{member.averageTalkTime}</td>
                        <td>{member?.position ? member.position : '-'}</td>
                        <td>
                          <button type="button" className={`btntxt ${member?.loginStatus === USER_STATUS.ACTIVE ? "online" : "offline"}`}>
                            {member.loginStatus === USER_STATUS.ACTIVE ? 'Online' : 'Offline'}
                          </button>
                        </td>
                        
                      </tr>
                    </tbody>
                  ))}
                  {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      {/* </section> */}
    </Row>
  );
}
