import { Member } from "../../types/User";
import profileImg from "../../assets/images/icon-profile.png";
import IconEdit from "../Icon/IconEdit";
import IconDelete from "../Icon/IconDelete";
import { useAuth } from "../../hooks/useAuth";
import { ROLE } from "../../constants";

export default function TeamMemberTile({
  user,
  status,
  editMember,
  deleteMember,
}: {
  user: Member;
  status: any;
  editMember: VoidFunction;
  deleteMember: VoidFunction;
}) {
  const auth = useAuth();
  const truncateText = (text: string) => {
    return text && text?.length > 24 ? text.slice(0, 24) + "..." : text;
  };

  return (
    <div className="list-grid ">
      <div className="member-profile">
        {/* <Link to={`${ROUTE_NAVIGATION_PATH.MEMBER_DASHBOARD}/${user._id}`}> */}
          <div className="_image">
            <img
              src={user?.image ? user?.image : profileImg}
              onError={(e: any) => {
                e.target.src = profileImg;
              }}
              alt="ProfileImg"
            />
          </div>
          <div className="usertextInfo">
            <div className="name">
              {/* {truncateText(user?.name)} {user?.surname} */}
              {truncateText(user.name + " " + user.surname)}
            </div>
            <div className="position">
              {/* {(user?.position ? "(" + user?.position + ")" : null)} */}
              {user?.position ? truncateText("" + user?.position) : null}
             
            </div>
          </div>
        {/* </Link> */}
      </div>
      {
        auth.user.role === ROLE.TEAM_LEADER ? 
          <div className="action-btn">
            <div className="edit" onClick={editMember}>
              <IconEdit />
            </div>
            <div className="delete" onClick={deleteMember}>
              <IconDelete />
            </div>
          </div>
        :
          null
      }
     
    </div>
  );
}
