import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Form } from "react-final-form";
import { 
  composeValidators,
  required,
  validEmail,
} from "../../../validations";
import { FormControl, AuthLayout } from "../../../components";
import IconEmail from "../../../assets/images/iconEmail.svg";
import back from "../../../assets/images/iconBack.svg";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useMessageModal } from "../../../hooks/useMessage";
import { authetication } from "../../../api";
import { useTranslation } from "react-i18next";
import { useCustomMutation } from "../../../hooks/useApi";


function ForgotForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showMessage } = useMessageModal();

  const { mutate } = useCustomMutation({
    mutationFn: authetication.doForgot,
    onSuccess: () => {
      showMessage({
        heading: "Passwort wiederherstellen",
        body: <p>Wir haben einen Link zum Zurücksetzen des Passworts an deine E-Mail geschickt.</p>,
        type: "success",
        callback: () => {
          navigate(ROUTE_NAVIGATION_PATH.SIGN_IN);
        }
      });
    },
  });

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.SIGN_IN);
  }

  const onSubmit = (values: { email: string }) => {
    mutate(values);
  };

  return (
    <>
    <div className="heading-with-back">
    <div className="_back2" onClick={handleClick}>
        <img src={back} alt="back" />
      </div>
      <h2>Passwort wiederherstellen</h2>
    </div>
      <Card.Text>
        Gib deine E-Mail Adresse ein, um dein Passwort zurückzusetzen. Wir senden Dir einen Link zum Zurücksetzen des Passworts.
      </Card.Text>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              label="Email"
              name="email"
              type="email"
              placeholder="E-Mail-Adresse eingeben"
              validate={composeValidators(required, validEmail)}
              icon={IconEmail}
            />

            <Button variant="primary" type="submit" className="mt-5">
              Passwort zurücksetzen
            </Button>
          </form>
        )}
      />
    </>
  );
}
export default function ForgetPassword() {
  return (
  //   <Row>
  //     <Col
  //       className="d-flex justify-content-center align-items-center p-0 login-signup-page-card h-100vh"
  //     >
  //       <div className="login-form">
  //         <div className="w-100">
  //           <ForgotForm />
  //         </div>
  //       </div>
  //     </Col>
  //   </Row>
  // );
  <AuthLayout
      leftContent={
        <>
          <h1>
            Ihr smarter Assistant <br />
            im Kundenservice
          </h1>
          <p>Intelligente Unterstützung ihrer Call-Center-Mitarbeiter zur Ermittlung von Upsell-Potentialen beim Kunden</p>
        </>
      }
      rightContent={<ForgotForm />}
    />
  );
}
