import React from "react";
import { FormControl } from "../FormElements/FormControl";
import { composeValidators, minDateCompare, required } from "../../validations";
import { DatePickerControl } from "../FormElements/DatePicker";
import { useTranslation } from "react-i18next";

export default function AddEditInvoice({values}: any) {
  const { t } = useTranslation();
  return (
    <>
      <DatePickerControl
        label={t("Invoice Date") as string}
        name="invoiceDate"
        validate={composeValidators(required)}
      />

      <DatePickerControl
        label={t("Plan Start Date") as string}
        name="planStartDate"
        validate={composeValidators(required)}
      />

      <DatePickerControl
        label={t("Plan End Date") as string}
        name="planEndDate"
        validate={composeValidators(required, minDateCompare(values.planStartDate))}
        minDate={values.planStartDate}
      />

      <FormControl
        label={t("Permitted Members") as string}
        name="permittedMembers"
        type="number"
        validate={composeValidators(required)}
        className="spacing-equal"
      />
      <FormControl
        label={t("Invoice Amount") as string}
        name="invoiceAmount"
        type="number"
        placeholder=""
        validate={composeValidators(required)}
        className="spacing-equal"
      />
    </>
  );
}
