import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import faqImg from "../../../assets/images/faq-img-left.png";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FaqCreationInfo() {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="tabs-top">
              <ul>
                <li>1. {t("Create categories")}</li>
                <li>2. {t("Create Product")}</li>
                <li>3. {t("Create Questions")}</li>
                <li>4. {t("Create Path")}</li>
              </ul>
            </div>
            <div className="heading-common">
              <h1>{t("CreatingFirstFAQ")}</h1>
              <p>
                {t("CreateFirstFAQHeading")}
                <br />
                {t("CreateFirstFAQSubHeading")}
              </p>
            </div>
            <div className="creating-team-wrapper _faqcreations-wrapper">
              <Col md={12} lg={6} xxl={7} className="creating-grid">
                <div className="_image">
                  <img src={faqImg} alt="MAGICMOMENT" />
                </div>
              </Col>
              <Col md={12} lg={6} xxl={5} className="_creating-content">
                <div className="subheadingtext">Necessary Steps</div>
                <div className="_content-info _active">
                  <Link to={`${ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES}`}>
                    <div className="numbers">1</div>
                    <div className="_informationtext">
                      <h3>{t("Create categories")}</h3>
                      <p>
                        {t("elementCategoryDescription")}
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="_content-info">
                  <div className="numbers">2</div>
                  <div className="_informationtext">
                    <h3>{t("Create Product")}</h3>
                    <p>
                      {t("elementProductDescription")}
                    </p>
                  </div>
                </div>
                <div className="_content-info">
                  <div className="numbers">3</div>
                  <div className="_informationtext">
                    <h3>{t("Create Questions")}</h3>
                    <p>
                      {t("elementQuestionDescription")}
                    </p>
                  </div>
                </div>
                <div className="_content-info">
                  <div className="numbers">4</div>
                  <div className="_informationtext">
                    <h3>{t("Create Path")}</h3>
                    <p>
                      {t("elementPathDescription")}
                    </p>
                  </div>
                </div>
              </Col>
            </div>
            <div className="creating-bottom-info">
              <div className="btn-wrapper ">
                <Link to={ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES}>
                  <Button variant="primary">{t("LetsGo")}</Button>
                </Link>
              </div>

              {/* <span>
                {t("justOne")} <span>{t("more")}</span> {t("stepToFinish")}
              </span> */}
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
