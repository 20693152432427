
import xIconRed from "../../assets/images/xred.svg";
import xIcon from "../../assets/images/x.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { ROLE } from "../../constants";

export default function PathTile({
    path,
    deletePath
}: {
    path: any;
    deletePath: VoidFunction;
}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const auth = useAuth();

    function formatDate(value:any) {
        const format1 = "MMM DD, YYYY"
        const date = new Date(value);
        // Format the date to DD-MM-YYYY
        return  moment(date).format(format1) + ' at ' + moment(date).format('HH:mm A');
    }

    function handleClick (pathId:string) {
        navigate({
            pathname: ROUTE_NAVIGATION_PATH.EDIT_PATH,
                search: createSearchParams({
                    id: pathId
            }).toString()
        });
    }

    return (
        <> 
            <tr>
                <td>
                    {path.name}
                </td>
                <td className="_spacing">
                    <p className="desc">{path.description} </p>
                </td>
                <td>{formatDate(path.updatedAt)}</td>
                <td className="qu-count">
                    {path.questionCount}
                </td>
                {
                    auth.user.role === ROLE.TEAM_LEADER ?
                        <td className="_act-agn">
                            <div className="btnedit-delelte _agnright">
                                <button className="btntd" onClick={() => handleClick(path._id)}>{t("Edit")}</button>
                                <button className="btntd delte" onClick={deletePath}>
                                    <img src={xIconRed} alt="" className="hover" />
                                    <img src={xIcon} alt="" className="nonhover" />{" "}
                                    {t("Remove")}
                                </button>
                            </div>
                        </td>
                    :
                    null
                }
            </tr>
        </>
    );
}
