import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Spinner from "react-bootstrap/Spinner";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Member } from "../../../types/User";
import { team } from "../../../api";
import {
  SearchBar,
  EditTeamMember,
  NoData,
  DeleteMember,
} from "../../../components";
import { Link, useLocation } from "react-router-dom";
import ManageTeamTile from "../../../components/TeamMember/ManageTeam/ManageTeamTile";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { ROLE } from "../../../constants";
import useEditDelete from "../../../hooks/useEditDelete";

export default function ManageTeam() {
  const { t } = useTranslation();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  let { user } = useAuth();
  const location = useLocation();
  const role = (user.role === ROLE.TEAM_LEADER) ? " ": '0';
  const { deleteFor, setDeleteFor } = useEditDelete();
  const { loading, items, hasNextPage, error, deleteItem, updateItem } =
    useLoadItems({
      loadItems: (user.role === ROLE.TEAM_LEADER) ? team.getAllTeamMembersByStatus: team
      .getOfflineOnlineMembers,
      page,
      search,
      role
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
  }, [location.search]);

  const deleteMember = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  return (
    <>
      <Row>
        {/* <section className="main-container shrink-width" > */}
          <div className="heading-common">
          { user.role === ROLE.TEAM_LEADER ?
            <h1>
              {t("manageTeamheading")} <span> {t("Team")}</span>!
            </h1>
            :
            <h1>
              {t('viewYourTeam')}
            </h1>
          }
            <p>{user.role === ROLE.TEAM_LEADER ? '': t('OverviewTeamMembers')}</p>
          
          </div>

          {/* {items.length && loading ? */}
          <div className="task-manage-team-wrapper">
            <div className="taks-search">
              <SearchBar
                placeHolder={ user.role === ROLE.TEAM_LEADER ? t("SearchCustomers"): t('Search...')}
              />
              { user.role === ROLE.TEAM_LEADER ?
                <Link to={ROUTE_NAVIGATION_PATH.CREATE_TEAM}>
                  <div className="add-btn absolute">{t("AddTeamMember")}</div>
                </Link>
                :
                null
              }
            </div>

            <div className="table-scrollbar scrollbar" ref={rootRef}>
              <div className="table-content">
                {!items.length && !loading ? <NoData /> : null}
                <table className="manageteamtable">
                  {items.length ? (
                    <thead>
                      <tr>
                        <th style={{ width: "12%" }}>
                          {t("MemberName")}
                        </th>
                        <th>{t("Email")}</th>
                        <th>
                          {t("Status")}
                        </th>
                        { user.role === ROLE.TEAM_LEADER ?
                            <th>{t("Activate")}</th>
                          :
                          <th>{t("Position")}</th>
                        }
                        <th className="createddate">{t("CreatedDate")}</th>
                        { user.role === ROLE.TEAM_LEADER ?
                            <th className="mm-th">{t("Actions")}</th>
                          :
                          null
                        }
                      </tr>
                    </thead>
                  ) : null}

                  {items.map((member) => (
                    <ManageTeamTile
                      key={member._id}
                      userDetail={member}
                      editMember={() => setDetailsToShow(member)}
                      deleteMember={() => setDeleteFor(member._id)}
                      updateListItem={updateItem}
                    />
                  ))}
                  {/* <div className="bottom-graidient" ></div> */}
                  {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* : <NoData /> } */}
        {/* </section> */}
      </Row>
      <EditTeamMember
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <DeleteMember
         show={!!deleteFor}
         handleClose={() => setDeleteFor(null)}
         id={deleteFor}
         callback={deleteMember}
      />
    </>
  );
}
