import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import dummyImgBig from "../../../assets/images/team.jpeg";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function TeamCreationInfo() {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content _for-middlewrapper">
            <div className="w-100">
            <div className="heading-common">
              <h1>
                {t('teamCreation')}
              </h1>
              <p>
                {t('teamCreateInfoSubHeadingDes1')}
                <br />
                {t('teamCreateInfoSubHeadingDes2')}
              </p>
            </div>
            <div className="creating-team-wrapper">
              <Col md={12} lg={5} xxl={6} className="creating-grid">
                <div className="_image">
                  <img src={dummyImgBig} alt="MAGICMOMENT" />
                </div>
              </Col>
              <Col md={12} lg={7} xxl={6} className="_creating-content">
                <div className="subheadingtext">
                  {t('thereAre')} <span>{t('twoWays')}</span> {t('toCreateYourEmployees')}
                </div>
                <div className="_content-info">
                  <div className="numbers">
                    1 <span></span>
                  </div>
                  <div className="_informationtext">
                    <h3>{t('Get Started')}</h3>
                    <p>
                      {t("Click")} <span>"{t("LetsGo")}"</span> {t('teamInfoFirstPoint')}
                    </p>
                  </div>
                </div>
                <div className="_content-info">
                  <div className="numbers">
                    2 <span></span>
                  </div>
                  <div className="_informationtext">
                    <h3>{t("ExcelTemplate")}</h3>
                    <p>
                      {t("downloadThe")} <span> {t("excelTemplate")}</span> {t("teamInfoSecondPoint")}
                    </p>
                  </div>
                </div>
             
              </Col>
            </div>
            <div className="creating-bottom-info">
              <p>{t('creatingBottomInfo1')}</p>
              <p>{t('creatingBottomInfo2')}</p>
              <div className="btn-wrapper ">
                <Link to={ROUTE_NAVIGATION_PATH.CREATE_TEAM}>
                  <Button variant="primary">
                      {t('LetsGo')}
                  </Button>
                </Link>
              </div>
                
              <span>{t('justOne')} <span>{t('More')}</span> {t('stepToFinish')}</span>
            </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
