import Row from "react-bootstrap/Row";
import Iconcalendar from "../../../../assets/images/calendar.svg";
import { path } from "../../../../api"
import { SearchBar } from "../../../../components";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useEditDelete from "../../../../hooks/useEditDelete";
import { useTranslation } from "react-i18next";
import { ROLE, USER_STATUS } from "../../../../constants";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { NoData } from "../../../../components";
import PathTile from "../../../../components/Path/PathTile";
import DeletePath from "../../../../components/Modal/DeletePath";
import moment from 'moment';
import { useAuth } from "../../../../hooks/useAuth";
import DateRangePicker from 'rsuite/DateRangePicker';
import { Spinner } from "react-bootstrap";


export default function PathList() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { deleteFor, setDeleteFor } = useEditDelete();
  const [search, setSearch] = useState("");
  let status = USER_STATUS.ACTIVE;
  const location = useLocation();
  const [fromDate, setFromdate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const auth =  useAuth();
  const { loading, items, hasNextPage, error, deleteItem } = useLoadItems({
    loadItems: path.allPathList,
      page,
      status,
      search,
      fromDate,
      toDate
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
    
  }, [location.search]);

  const deletePath = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };

  const changeDate = (date:any) => {
    if(date) {
      setPage(1);
      setFromdate(date[0]);
      setToDate(date[1]);
    }
  }

  function formatDate(value:any) {
    const format1 = "MMMM DD"
    const date = new Date(value);
    return  moment(date).format(format1);
  }

  const onClear = () => {
    setShowDatePicker(false)
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("Paths")}</h1>
              <p>{t("pathInfo")}</p>
            </div>
            <div  className={`search-wth-content2 ${ auth.user.role !== ROLE.TEAM_LEADER ? 'search-with-calendar' : "search-with-calendar2"}
            `}>
              <SearchBar
                  placeHolder={t("Search")}
              />
              {
                auth.user.role === ROLE.TEAM_LEADER ?
                  <Link to={ROUTE_NAVIGATION_PATH.CREATE_PATH}>
                    <div className="add-btn _maxp">{t("addPath")}</div>
                  </Link>
                :
                  null
              }
             
              <div className="table-calendar">
                  <div className="calenderinfo">
                    
                    <div className="date" onClick={() => setShowDatePicker(true)}>
                      <img src={Iconcalendar} alt="" />
                      
                    </div>
                    {
                      showDatePicker ? 
                        <div className="dropinfo">
                          <div className="dwon"></div>   
                          <DateRangePicker
                            value={[fromDate, toDate]}
                            onChange={(update:any) => {
                                changeDate(update);
                                
                            }}
                            placeholder='yyyy-mm-dd - yyyy-mm-dd'
                            appearance="default"
                            format="yyyy-MM-dd"
                            onOk={() => setShowDatePicker(false)}
                            defaultCalendarValue={[new Date(), new Date()]}
                            onClean={() => onClear()}
                          />
                        </div>
                      :
                        <>
                            {
                              fromDate ?
                              <span onClick={() => setShowDatePicker(true)}>
                                {formatDate(fromDate) + '  -  ' + (toDate ? formatDate(toDate) : formatDate(fromDate))}
                              </span>
                              :
                              null
                            }
                        </>
                    }
                  </div>
              </div>
            </div>
            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent _categorydetail _productlistingcontent">
               
                {!items.length && !loading ? <NoData /> : null}
                { items.length ? 
                  <table className="_producttable">
                    <thead>
                      <tr>
                        <th>{t("pathName")}</th>
                        <th className="_spacing">{t("Description")}</th>
                        <th>{t("pathCreation")}</th>
                        <th className="qu-count">{t("Question")}</th>
                        {
                          auth.user.role === ROLE.TEAM_LEADER ? 
                            <th className="_act-agn">{t("Actions")}</th>
                          :
                            null
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((path:any) => (
                          <PathTile
                            key={path._id}
                            path={path}
                            deletePath={() => setDeleteFor(path._id)}
                          />
                      ))}
                    </tbody>
                  </table>
                  :
                  null
                }
                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <DeletePath
          show={!!deleteFor}
          handleClose={() => setDeleteFor(null)}
          id={deleteFor}
          callback={deletePath}
      />
    </>
  );
}
