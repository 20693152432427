import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Link, useLocation, useParams } from "react-router-dom";
import { authetication, team } from "../../../api";
import {
  TeamMemberTile,
  SearchBar,
  EditTeamMember,
  NoData,
  AddSalesDetail,
  DeleteMember,
} from "../../../components";
import { useLoadItems } from "../../../hooks/useLoadItems";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Member } from "../../../types/User";
import { ROLE, USER_STATUS } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import useEditDelete from "../../../hooks/useEditDelete";

export default function MembersList() {
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [saleEmail, setsaleEmailDetail] = useState("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  const params = useParams();
  let { user } = useAuth();
  const location = useLocation();
  const status = params.status ? USER_STATUS.ACTIVE : USER_STATUS.ALL;
  const { deleteFor, setDeleteFor } = useEditDelete();
  const url = params.status
    ? team.getAllTeamMembersByStatus
    : team.getTeamMembers;
  const { loading, items, hasNextPage, error, deleteItem, updateItem } =
    useLoadItems({
      loadItems: url,
      page,
      search,
      status,
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
  }, [location.search]);

  const deleteMember = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              {/* {params ? <BackIcon /> : null} */}
              <h1>
                {t("Your")}
                <span> {t("Team")}</span>
              </h1>
              { user.role === ROLE.TEAM_LEADER ? 
                <p>{t("manageTeamMsg")}</p>
                :
                null
              }
            
            </div>
            <div className="member-list-wrapper">
              <div  className={`search-wth-content2 ${user.role !== ROLE.TEAM_LEADER
              ? "w-100" : user.salesEmail == null ? "search-with-continue": "search-with-add"}
            `}>
                <SearchBar
                  placeHolder={t("searchMember")}
                />
                {
                  user.role === ROLE.TEAM_LEADER ?
                  <>
                    <Link to={ROUTE_NAVIGATION_PATH.CREATE_TEAM}>
                    <div className="add-btn">{t("addAMember")}</div>
                    </Link>
                    {
                      user.salesEmail == null ?
                        <div className="continue-btn" onClick={() => setsaleEmailDetail("true")}>{t("Continue")}</div>
                      : null
                    }
                  </>
                  :
                  null
                }
                
              </div>
            
              <div className="member-list-content scrollbar" ref={rootRef}>
                {!items.length && !loading ? <NoData /> : null}
                {items.map((member) => (
                  <TeamMemberTile
                    key={member._id}
                    user={member}
                    status={status}
                    editMember={() => setDetailsToShow(member)}
                    deleteMember={() => setDeleteFor(member._id)}
                  />
                ))}
                {/* <div className="bottom-graidient" ></div> */}
                {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <EditTeamMember
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <DeleteMember
        show={!!deleteFor}
        handleClose={() => setDeleteFor(null)}
        id={deleteFor}
        callback={deleteMember}
      />
      <AddSalesDetail
        detail={saleEmail}
        handleClose={() => setsaleEmailDetail("")}
      />
    </>
  );
}
