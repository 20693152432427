import prevIcon from "../../assets/images/prev-1.svg";
import nextIcon from "../../assets/images/next-2.svg";
import { useEffect, useRef, useState } from "react";
import { category } from "../../api";
import { USER_STATUS } from "../../constants";
import { Any } from "../../types/global.type";

export default function PathCategoryList({
    filterByCategory,
    categoryId = "",
    updateFilterCategoryId,
}:{
    filterByCategory: any;
    categoryId?: any;
    updateFilterCategoryId: (query: any) => void;
}) {
  const [pageNo, setPageNo] = useState<number>(1);

  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState<Any[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [noOfpages, setNoOfPage] = useState<any>(0);

  const effectRan = useRef(false);


  useEffect(() => {
    if (!effectRan.current) {        
      getCategory(pageNo);
    }
    return () => {
      effectRan.current = true;
    };

  }, []);

  const getPrevious  = () => {
    let page = pageNo === 1 ? pageNo :  pageNo - 1;
    setPageNo(page)
    if(page === 1) {
      setDisablePrev(true);
      setDisableNext(false);
    }
    getCategory(page);
  }
 
  const getNext  = () => {
    if(noOfpages == pageNo) {
      setDisableNext(true);
    } else {
      let page = pageNo + 1;
      setPageNo(page);
      setDisablePrev(false);
      getCategory(page);
    }
  }

  const getCategory = (page:number, fromFilter:any=false) => {
    category
      .allcategoryList({
        page: page,
        count: 4,
        status: USER_STATUS.ALL,
        sort: 'ASC'
      })
      .then((res: { data: []; hasNextPage:boolean, totalCount: number }) => {  
        setHasNextPage(res.hasNextPage);

        setTotalCount(res.totalCount);
        if(filterByCategory && !fromFilter) {
          res.data.forEach((val:any) => {
            val.selected = (val._id === filterByCategory) ? true : false;
            
          });

        } else {
          res.data.forEach((val:any) => {
            val.selected = (val.name === "ALL") ? true : false;
            
          });
        }
        if(page == 1) {
            setNoOfPage(Math.ceil(res.totalCount/4));
        }
        
        setItems(res.data);        
      });
  }


  function updateCategory(catId:string, index:any) {
    let id = "";
    let name = "";
    if(categoryId == catId) {
      if(pageNo > 1) {
        id = '-1';
        name = "ALL"
        setItems([]);
        setPageNo(1);
        getCategory(1, true);
      } else {
        items[index].selected = false;
      
        var indx = items.findIndex((el:any) => el.name === 'ALL');
        items[indx].selected = true;
        id = '-1';
        name = "ALL"
       
      }
      
    } else {
      items.forEach(val => {
            val.selected = false;
        });
        
        items[index].selected = true;
        id = catId;
        name= items[index].name;
    }
    const data = {
      name: name,
      id: id
    }
    updateFilterCategoryId(data);
    
  }

  useEffect(() => {
    if(pageNo === 1) {
      items.forEach(val => {
        if(val.name === 'ALL') {
          val.selected = true
        }
      });
    }
  }, [items])

  return (
    <> 
        <div className="_tags-path">
            <div className="category-listing">
            <ul>
                {items.map((data: any, key: any) => (
                  <li
                    onClick={() => updateCategory(data._id, key)}
                    className={data.selected ? "active" : ""}
                    key={data?.name}
                  >
                    {data.name}
                  </li>
                ))}
            </ul>
            {totalCount > 4 ?
              <div className="actionslide">
                  <button  type="button" disabled={disablePrev}>
                    <div className="prev" onClick={getPrevious}>
                      <img src={prevIcon} alt="MagicMoment" />
                    </div>
                  </button>
                  <button  type="button" disabled={disableNext}>
                    <div className="next" onClick={getNext}>
                      <img src={nextIcon} alt="MagicMoment" />
                    </div>
                  </button>
              </div>
            :
              null 
            }
            </div>
        </div>
    </>
  );
}
