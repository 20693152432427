import profileImg from "../../assets/images/dummy-img.png";
import moment from 'moment'
import { useEffect, useState } from "react";
import xIconRed from "../../assets/images/xred.svg";
import xIcon from "../../assets/images/x.svg";
import { useCreateEditQuestion } from "../../hooks/useCreateEditQuestion";
import { useNavigate, createSearchParams } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useMessageModal } from "../../hooks/useMessage";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { ROLE } from "../../constants";
import ProductDetailModal from "../Modal/ProductDetailModal";
import bronzeIcon  from "../../assets/images/bronze.png";
import silverIcon  from "../../assets/images/silver.png";
import goldIcon  from "../../assets/images/gold.png";
import 'chart.js/auto';
import LineChart from "../Charts/LineChart";

export default function ProductTile({
    product,
    index,
    editProduct,
    deleteProduct
}: {
    product: any;
    index?: number;
    editProduct: VoidFunction;
    deleteProduct: VoidFunction;
}) {
    const [label, setLabel]  = useState<any>([]);
    const [count, setCount] = useState<any>([]);
    const [detail, setProductDetail]  = useState(null)
    function formatDate(value:any) {
        const format1 = "MMM DD, YYYY"
        const date = new Date(value);
        // Format the date to DD-MM-YYYY
        return  moment(date).format(format1) + ' at ' + moment(date).format('HH:mm A');
    }
    const isQuestion = new URLSearchParams(document.location.search).get('toQuestion');
    const fromEdit = new URLSearchParams(document.location.search).get('fromEdit');
    const fromProposal = new URLSearchParams(document.location.search).get('fromProposal');
    const navigate = useNavigate();
    const { showMessage } = useMessageModal();
    const { t } = useTranslation();
    const { user, updateQuestionData } = useCreateEditQuestion();
    const auth = useAuth();

    useEffect(() => {
        var newObject = JSON.parse(JSON.stringify(product));
        tags(newObject.tags ? newObject.tags : []);
    }, []);

    useEffect(() => {
        setLabel([]);
        setCount([])
        var frequency = JSON.parse(JSON.stringify(product));
        if(frequency.frequencies && frequency.frequencies.length > 0) {
            frequency.frequencies.forEach((element:any) => {
                setLabel((prev:any) => [...prev, element.date])
                setCount((prev:any) => [...prev, element.count])
            });
        }
       
    }, [product]);

    function tags(value:any) {
        if(value.length > 0) {
            value.forEach(function(elem:any, idx:any, array:any){
                if (array.length > 1 && idx === array.length - 1){
                    if(!elem.includes("and")) {
                        value[idx] = " and " + elem;
                    }
                }
            });        
    
            let final = value.join(', ')
            const after_ = final.split('and')[1];
            const lastElement = (final.substring(0, final.indexOf("and")));
            if(after_) {
                product.finaltags = lastElement.replace(/,(?=[^,]*$)/, '') + 'and ' + after_;
            } else {
                product.finaltags = final;
            }
        }
    }

    function goBackToQuestion(productDetail: any) {
        const isExist = user.productDetails.some((el:any) => el.id === productDetail._id);
        if (isExist) {
            showMessage({
                heading: t("error"),
                body: <p>{t("alreadyProductExists")}</p>,
                type: "success",
            });
            return;
        }
        const data = {
            image: productDetail?.image ? productDetail?.image : profileImg,
            description: productDetail.description,
            name: productDetail.name,
            _id: productDetail._id
        }
        user.productDetails.unshift(data);
        updateQuestionData(user);
        if(fromEdit === '1') {
            navigate({
              pathname: ROUTE_NAVIGATION_PATH.EDIT_QUESTION,
                  search: createSearchParams({
                      id: user.id,
                      fromProduct: "1",
              }).toString()
            });
        } else if(fromProposal === '1') {
            navigate({
                pathname: ROUTE_NAVIGATION_PATH.EDIT_PROPOSAL,
                    search: createSearchParams({
                        id: user.id,
                        fromProduct: "1",
                        fromProposal: "1"
                }).toString()
              });
        } else {
            let url = auth.user.questionCount > 0 ? ROUTE_NAVIGATION_PATH.ADD_QUESTION : ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS;
            navigate({
                pathname: url,
                    search: createSearchParams({
                        fromProduct: "1"
                    }).toString()
            });
        }
    }

    return (
        <> 
            <tr>
                {
                    index  ?
                    (
                        index <= 3 ? 
                        <td className="rankth">
                            <div className="rank-txt">
                            {/* <span>{index}</span> */}
                            <img src={ index ===1 ? goldIcon : index === 2 ? silverIcon : bronzeIcon }  alt="rank"/>
                            </div>
                        </td>
                        :
                        <td className="question rankth">
                            <div className="rank-txt">
                            {index}
                            </div>
                        </td>
                    )
                    :
                    null
                }
                <td className="_spacing">
                    <div className="profileinfotable" onClick={ () => setProductDetail(product)}>
                        <div className="td-image">
                            <img
                                src={product?.image ? product?.image : profileImg}
                                onError={(e: any) => {
                                    e.target.src = profileImg;
                                }}
                                alt="ProfileImg"
                            />
                        </div>
                        <div className="td-info-profile">
                            <div className="name">
                                {product.name}
                            </div>
                            {product.finaltags ? <p>{product.finaltags}</p> : null}
                        </div>
                    </div>
                </td>
                <td className="_spacing">
                    <p className="desc" title={product.description}>
                        {product.description}
                    </p>
                </td>
                <td>{formatDate(product.updatedAt)}</td>
                {
                    index && product.frequencies.length > 0 ?
                        (
                            count.length > 0 ?
                            <td>
                                <LineChart labels={label} count={count} />
                            </td>
                            :
                            <td>-</td>

                        )
                        
                    :

                       null
                }
                {
                    isQuestion || auth.user.role === ROLE.TEAM_LEADER ?
                
                    <td>
                        {
                            isQuestion ? 
                                <div className="btnedit-delelte">
                                    <button className="btntd" onClick={() => goBackToQuestion(product)}>Select</button>
                                </div>
                            :
                            auth.user.role === ROLE.TEAM_LEADER ?
                                <div className="btnedit-delelte _center">
                                    <button className="btntd" onClick={editProduct}>{t("Edit")}</button>
                                    <button className="btntd delte" onClick={deleteProduct}>
                                            <img src={xIconRed} alt="" className="hover" />
                                            <img src={xIcon} alt="" className="nonhover"/> {t("Remove")}
                                    </button>
                                </div>
                            :
                            null
                        }
                    </td>
                    :
                     null
                }
               
            </tr>
            <ProductDetailModal
                detail={detail}
                handleClose={() => setProductDetail(null)}
            />
        </>
    );
}
