import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { SearchBar } from "../../../../components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { category, question } from "../../../../api";
import { useEffect, useState } from "react";
import { ROLE, USER_STATUS } from "../../../../constants";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useEditDelete from "../../../../hooks/useEditDelete";
import DeleteQuestion from "../../../../components/Modal/DeleteQuestion";
import { Spinner } from "react-bootstrap";
import { NoData } from "../../../../components";
import QuestionTile from "../../../../components/Question/QuestionTile";
import QuestionCategoryList from "../../../../components/Question/QuestionCategoryList";
import { useAuth } from "../../../../hooks/useAuth";
import QuestionDetailModal from "../../../../components/Modal/QuestionDetailModal";

export default function QuestionList() {
  const { t } = useTranslation();
  const params = new URLSearchParams(document.location.search).get('redirect')
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [filterByCategory, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const { deleteFor, setDeleteFor } = useEditDelete();
  const [questionDetail, setQuestionDetail] = useState(null);
  const [search, setSearch] = useState("");
  let status = USER_STATUS.ACTIVE;
  const location = useLocation();
  const auth = useAuth();
  const { loading, items, hasNextPage, error, deleteItem } = useLoadItems({
    loadItems: question.allQuestionsList,
    page,
    status,
    search,
    filterByCategory
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });
  const isSuggestion = params ? false : true;

  const updateFilterCategoryId = (id:string) => {
    setCategoryId(id);
    (id && id !== '-1') ? setCategory(id) : setCategory("");
    setPage(1);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
    
  }, [location.search]);

  function handleClick() {
    navigate(ROUTE_NAVIGATION_PATH.CREATE_PATH)
  }

  const deleteQuestion = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };  

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("Questions")}</h1>
              <p>{ auth.user.role === ROLE.TEAM_LEADER ? t("questionListSubHeading") : ""}</p>
            </div>
            <div  className={`search-wth-content2 ${auth.user.role !== ROLE.TEAM_LEADER
              ? "w-100" : params || isSuggestion ? "search-with-continue": "search-with-add"}
            `}>
              <SearchBar
                  placeHolder={t("Search")}
              />
              {
                auth.user.role === ROLE.TEAM_LEADER ?
                  <>
                    {
                      isSuggestion ?
                      <Link to={ROUTE_NAVIGATION_PATH.SUGGESTION_LIST}>
                        <div className="suggestion-btn">{t("Suggestion")}</div>
                      </Link>
                      :
                      null
                    }
                    <Link to={ROUTE_NAVIGATION_PATH.ADD_QUESTION}>
                      <div className="add-btn">{t("addQuestion")}</div>
                    </Link>
                    { params ? 
                      <div className="continue-btn" onClick={handleClick}>{t("Continue")}</div>
                      :
                      null
                    }
                  </>
                :
                  null
              }
                
            </div>
            { items.length !== 0 || categoryId ? 
              <QuestionCategoryList fromCustomerInteraction={false} categoryId={filterByCategory} updateFilterCategoryId={updateFilterCategoryId} />
              :
              null
            }

            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent _categorydetail _productlistingcontent _category-ques-detail">
                {!items.length && !loading ? <NoData /> : null}
                { items.length ? 
                  <table className="_producttable question-listtable">
                    <thead>
                      <tr>
                        <th style={{minWidth: '170px'}}>{t("Question")}</th>
                        <th>{t("createAt")}</th>
                        <th>{t("frequency")}</th>
                        {
                          auth.user.role === ROLE.TEAM_LEADER ?
                          <th></th>
                          :
                          null
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((question:any) => (
                          <QuestionTile
                            key={question._id}
                            question={question}
                            deleteQuestion={() => setDeleteFor(question._id)}
                            questionDetail={() => setQuestionDetail(question)}
                          />
                      ))}
                    </tbody>
                  </table>
                  :
                  null
                }
                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <DeleteQuestion
          show={!!deleteFor}
          handleClose={() => setDeleteFor(null)}
          id={deleteFor}
          callback={deleteQuestion}
      />
      <QuestionDetailModal
        show={!!questionDetail}
        data={questionDetail}
        handleClose={() => setQuestionDetail(null)}
      />
    </>
  );
}
