import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { category } from "../../../../api";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useState } from "react";
import AddCategory from "../../../../components/Category/AddCategory";
import { ELEMENT_LIST, USER_STATUS } from "../../../../constants";
import { useAuth } from "../../../../hooks/useAuth";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";

export default function CreateCategory() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [showContinue, setShowContinue] =  useState(false);
  const status = USER_STATUS.ACTIVE;
  const auth = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const count = 18;
  const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: category.defaultcategoryList,
      page,
      status,
      count
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleClick = (index:number) => {
    // This is probably a good place to use useReduce

    items.forEach(val => {
      val.selected = false;
    });
    items[index].selected = true;
    setData({
      name: items[index].name,
      description: items[index].description,
    })
    setShowContinue(!showContinue);
  };

  function backTo(pageNo: number) {
      if(pageNo === ELEMENT_LIST.CATEGORY) {
        navigate(ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES);
      } 
      else if(auth.user.productCount > 0 && pageNo === ELEMENT_LIST.PRODUCT){
        navigate(ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS);
      } 
      else if(auth.user.productCount > 0 && pageNo === ELEMENT_LIST.QUESTION) {
        navigate(ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS)
      } else if(auth.user.questionCount >= 5 && pageNo === ELEMENT_LIST.PATH)  {
        navigate(ROUTE_NAVIGATION_PATH.CREATE_PATH)
      }
      else {
        return;
      }
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="tabs-top">
              <ul>
                <li className="active">1. {t("Create categories")}</li>
                <li className={` ${auth.user.questionCount > 0 ? 'completed': ''}`} onClick={() => backTo(2)}>2. {t("Create Product")}</li>
                <li className={` ${auth.user.questionCount > 0 ? 'completed': ''}`} onClick={() => backTo(3)}>3. {t("Create Questions")}</li>
                <li className={` ${auth.user.questionCount > 0 ? 'completed': ''}`} onClick={() => backTo(4)}>4. {t("Create Path")}</li>
              </ul>
            </div>
            <div className="stepheading">1. {t("Create categories")}</div>
            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent">
                <h2>{t('selectCategories')}</h2>
                <p>
                  {t('createCategorySubHeading')}
                </p>
                <div className="category-listing">
                  <ul>
                    {items.map((data:any, key:any) => (
                        <li key={key} onClick={() => handleClick(key)} className={data.selected ? "active" : ""}>{data.name}</li>
                    ))}
                  </ul>
                  {hasNextPage && (
                      <div ref={sentryRef} className="spinner-wrap">
                        {/* <div className="">
                          <Spinner />
                        </div> */}
                      </div>
                    )}
                </div>
                <div className="form-category">
                  <p>
                    {t('addCategoryManually')}
                  </p>
                  <AddCategory data={data} showContinue={showContinue} />
                </div>
              </div>

            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
