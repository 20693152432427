import { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoadItems } from "../../hooks/useLoadItems";
import { category } from "../../api";

import { USER_STATUS } from "../../constants";

export default function QuestionCategoryList({
  fromCustomerInteraction = false,
  categoryId,
  updateFilterCategoryId,
}:{
  fromCustomerInteraction?: boolean;
  categoryId: any;
  updateFilterCategoryId: (query: string) => void;
}) {
    const [page, setPage] = useState(0);
    const status = USER_STATUS.ALL;
    const all = true;
    const sort = 'ASC';
    let { loading, items, hasNextPage, error } =
        useLoadItems({
        loadItems: category.allcategoryList,
        page,
        status,
        sort,
        all
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage(page + 1),
        disabled: !!error,
        rootMargin: "0px 50px 0px 0px",
    });

    function updateCategory(data:any, index:any) {
      let id = "";
      if(categoryId == data._id) {
        items[index].selected = false;
        var indx = items.findIndex((el:any) => el.name === 'ALL');
        items[indx].selected = true;
        id = '-1';
      } else {
        items.forEach(val => {
          val.selected = false;
        });
        
        items[index].selected = true;
        id = data.name == 'ALL' ? '-1' : data._id;
      }

      updateFilterCategoryId(id);

    }

    useEffect(() => {
      if(page === 1) {
        items.forEach(val => {
          if(val.name === 'ALL') {
            val.selected = true
          }
        });
      }
    }, [items])


    return (
        <> 
            <div className="category-listing _question-category _hegithaccc _wmax" ref={rootRef}>
              <ul>
                {items.map((data: any, key: any) => (
                  <li key={data?._id}
                    onClick={() => updateCategory(data, key)}
                    className={data.selected ? "active" : ""}
                  >
                    {data.name !== 'ALL' ? data.name : (localStorage.getItem("lang") !== 'en' ? 'Alle': data.name)}
                  </li>
                ))}
                {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                        </div>
                  </div>
                )}
              </ul>
            </div> 
        </>
        
    );
}
