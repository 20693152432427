import { AxiosError } from "axios";
import * as React from "react";
import { USER_STATUS } from "../constants";
import moment from 'moment';

const DEFAULT_OFFSET = 12;

interface Response {
  data: any[];
  hasNextPage: boolean;
  totalInHours?: any;
  totalInMinutes?: any;
  error?: AxiosError;
}

interface LoadItems {
  page: number;
  count: number;
  status: number;
  search?: string;
  searchByMember?: string;
  taskFilter?: string;
  id?: string;
  role?: string;
  filterByCategory?: string;
  all?:boolean;
  fromDate?: string;
  toDate?: string;
  questionId?: any;
  onRemoveQuestion?: boolean;
  sort?: any;
}

export function useLoadItems({
  loadItems,
  page,
  search = "",
  sort,
  count = DEFAULT_OFFSET,
  searchByMember = "",
  status = USER_STATUS.ALL,
  id ="",
  role = "",
  filterByCategory= "",
  all= false,
  fromDate,
  toDate,
  questionId,
  onRemoveQuestion = false
}: {
  loadItems: (data: LoadItems) => Promise<Response>;
  page: number;
  status?: number;
  search?: string;
  count?: number;
  sort?: any;
  searchByMember?: string;
  id?: string,
  role?: string;
  filterByCategory?: string;
  all?: boolean;
  fromDate?: Date;
  toDate?: Date;
  questionId?: any;
  onRemoveQuestion?: boolean;
}) {
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any[]>([]);
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(true);
  const [totalInMinutes, setTotalInMinutes] = React.useState<any>(null);
  const [totalInHours, setTotalInHours] = React.useState<any>(null);
  const [updatedQuestions, setupdatedQuestions] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const allData = {
    _id: '-1',
    name:"All",
    selected: true
  }

  async function loadMore() {
    setLoading(true);
    let obj:any = {
      page,
      count: count ? count : DEFAULT_OFFSET,
      status,
      search,
      role,
      id,
      filterByCategory,
      fromDate,
      toDate,
      sort
    }
    if (!id) delete obj.id;
    if(!filterByCategory) delete obj.filterByCategory;
    if(!sort) delete obj.sort;
    if(fromDate) {
      obj.fromDate = (moment(obj.fromDate).format('MM-DD-YYYY'));
    } else {
      delete obj.fromDate
    }
    if(toDate) {
      obj.toDate =moment(obj.toDate).format('MM-DD-YYYY');
    }else {
      delete obj.toDate;
    }
      
    if(!role) delete obj.role;
    if(!questionId) delete obj.questionId;
    try {
      const { data, hasNextPage, totalInHours, totalInMinutes } = await loadItems(obj);
      const filteredData = questionId && questionId.length > 0 ? data.filter((el:any) => !questionId.includes(el._id)): [...data];
      setItems((current) => (page === 1 ? filteredData : [...current, ...filteredData]));

      //setItems((current) => (page === 1 ? [...filteredData] : [...current, ...filteredData]) );  
      setHasNextPage(hasNextPage);
      setTotalInMinutes(totalInMinutes);
      setTotalInHours(totalInHours);
      setupdatedQuestions(true);
    } catch (err) {
      setError(err as Error);
      setItems((current) => current);
      setLoading(false);
      setupdatedQuestions(false);
    } finally {
      setLoading(false);
    }
  }

  function updateItem(itemToUpdate: any, uniqueIdName = "_id") {
    setItems((prev) => {
      const index = prev.findIndex(
        (item) => item[uniqueIdName] === itemToUpdate[uniqueIdName]
      );
      prev[index] = { ...prev[index], ...itemToUpdate };
      return [...prev];
    });
  }

  function addItem(item:any) {
    setItems((prev) => {
      return [item , ...prev];
    })
  }

  function deleteItem(deleteId: any, uniqueIdName = "_id") {
    setItems((prev) => {
      const index = prev.findIndex((item) => item[uniqueIdName] === deleteId);
      if(prev[index]) {
        prev.splice(index, 1);
      }
      return prev;
    });
  }

  function removeItem(deleteId: any, uniqueIdName = "_id") {
    const fil =  items.filter((item) =>  item[uniqueIdName] !== deleteId);

    setItems([...fil])
  }

  React.useEffect(() => {
    page > 0 && loadMore();
  }, [page, search, status, filterByCategory, id, role, fromDate, toDate, questionId]);

  return {
    loading,
    items,
    hasNextPage,
    totalInMinutes,
    totalInHours,
    error,
    updatedQuestions,
    loadMore,
    updateItem,
    deleteItem,
    addItem,
    removeItem
  };
}
