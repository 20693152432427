export const VALIDATIONS = {
  MAX_NAME: 50,
  MAX_TITLE: 150,
  MAX_DESCRIPTION: 250,
  MAX_ANSWER: 500,
  MIN_DESCRIPTION: 0,
  MAX_PHONE: 10,
  MAX_QUESTION: 150
};

export const ROLE = {
  TEAM_MEMBER: 0,
  TEAM_LEADER: 1,
  ADMIN: 2,
};

export const TASK_TYPE = {
  S: 1,
  M: 2,
  L: 3,
};

export const USER_STATUS = {
  ALL: -1,
  INVITEE: 0,
  ACTIVE: 1,
  SUSPENDED: 2,
  DELETED: 3,
  PENDING: 4,
  REJECTED: 5
};

export const ELEMENT_LIST = {
  CATEGORY: 1,
  PRODUCT: 2,
  QUESTION: 3,
  PATH: 4
}

export const SUGGESTION_STATUS = {
  ALL: -1,
  INACTIVE: 0,
  ACTIVE: 1,
  SUSPENDED: 2,
  DELETED: 3,
  PENDING: 4,
  REJECTED: 5
}

export const INVOICE_STATUS = {
  ACTIVE: 0,
  EXPIRED: 1,
  INACTIVE: 2
};


export const USER_STATUS_LIST = ["Active", "Not Active"];

export const TASK_TYPE_LIST = ["S", "M", "L"];

export const QUESTIONS_TYPE = {
  LIKERT: 'likertScale',
  SELECTION: 'selection'
} 

export const MESSAGES = {
  VERIFICATION_INFO: "A verification link is sent into your email. Check your email & click the link to verify your email to login.",
  FORGOT_EMAIL: "A reset password link is sent into your email.",
  USER_STATUS_UPDATE: "User status updated successfully!",
  EMAIL_VERIFICATION_FAILURE: "The email verification link has been expired!",
  CONTACT_US: "We appreciate it and we will contact your as soon as possible to answer your questions.",
  FILE_SIZE_ERROR:"File size is greater than maximum limit i.e. 5MB."
};

export const TEAM_ADMIN_POSITION = "ADMIN";

export const DEFAULT_TEAM_MEMBER_COUNT = 1;

export const MAX_FILE_SIZE = 5120; // 5MB;

export const FILE_SIZE =1024;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

//export const BASE_URL = "http://localhost:3000/";
export const BASE_URL = process.env.REACT_APP_API_URL || "http://144.126.252.228:3000"; //dev
//export const BASE_URL = process.env.REACT_APP_API_URL || "http://146.190.10.208:3000/"; //qa
//export const BASE_URL = process.env.REACT_APP_API_URL || "http://188.166.136.114:3000/"; //stg


export const NOTIFICATION_TYPE = {
  acceptInvitation: 1,
  newFeatureAndUpdates: 2,
};

export const PROFILE_VIEW = {
  PROFILE : 1,
  SETTINGS: 2,
  SIGNOUT: 3
}

export const DEFAULT_PATH = [
  {
    name: "Step 1",
    sequence: 1,
    questions: [{_id: "", category: {}, time: "", question: ""}] 
  },
  {
    name: "Step 2",
    sequence: 2,
    questions: [
      {_id: "", category: {}, time: "", question: ""}, 
      {_id: "", category: {}, time: "", question: ""},
      {_id: "", category: {}, time: "", question: ""}
    ] 
  },
  {
    name: "Step 3",
    sequence: 3,
    questions: [
      {_id: "", category: {}, time: "", question: ""}, 
      {_id: "", category: {}, time: "", question: ""},
      {_id: "", category: {}, time: "", question: ""}
    ] 
  },
  {
    name: "Step 4",
    sequence: 4,
    questions: [
      {_id: "", category: {}, time: "", question: ""}, 
      {_id: "", category: {}, time: "", question: ""},
      {_id: "", category: {}, time: "", question: ""}
    ] 
  }
];