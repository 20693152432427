import Row from "react-bootstrap/Row";
import { NoData, SearchBar } from "../../../components";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import IconDown from "../../../assets/images/downarrow.svg";
import Iconcalendar from "../../../assets/images/calendar.svg";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { customerInteraction } from "../../../api";
import { Spinner } from "react-bootstrap";
import { USER_STATUS } from "../../../constants";
import ProgressBar from "../../../components/ProgressBar";
import DatePicker from "react-datepicker";
import { default as dayjs } from "dayjs";
import ScrollMenu from "react-horizontal-scroll-menu";

type Select = string | number | null;

export default function TalkingDuration() {
  const { t } = useTranslation();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [talkInitialSelection, setTalkInitialSelection] = useState(1);
  const [talkToggle, setTalktoggle] = useState(false);
  const [fromDate, setFromdate] = useState<any>(new Date());
  const [toDate, setToDate] = useState<any>(new Date());
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const location = useLocation();
  let status = USER_STATUS.ACTIVE;
  const [daysOfweek, setDaysOfWeek] = useState<dayjs.Dayjs[]>([]);
  let today = moment().format("DD");
  const [selected, setSelected] = useState<Select>(parseInt(today, 10));
  const [customFormat, setCustomFormat] = useState<string>("ddd");
  const currentDay = dayjs().format("D");
  const { loading, items, hasNextPage, totalInHours, totalInMinutes, error } = useLoadItems({
    loadItems: customerInteraction.getTalkDuration,
    page,
    search,
    status,
    fromDate,
    toDate
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");

  }, [location.search]);

  function avgTalkToggele() {
    setTalktoggle(!talkToggle);
  }

  function avgDurationSelection(value: number) {
    setTalkInitialSelection(value);
    avgTalkToggele();
  }

  const changeDate = (date: any) => {
    setPage(1);
    setFromdate(date);
    setToDate(date);
    daysOfweek.map((day: any) => {
      let input = moment(day);
      let now = moment(date);
      const isThisWeek = (now.isoWeek() === input.isoWeek())
      if(isThisWeek) {
        setSelected(parseInt(now.format('DD'), 10))
      } else {
        setSelected(-1);
      }
    })
    setShowDatePicker(false)
  }

  const setDatePicker = () => {
    setShowDatePicker(!showDatePicker)
  }

  function formatDate(value: any) {
    const format1 = "MMM DD YYYY"
    const date = new Date(value);
    return moment(date).format(format1);
  }


  const getCurrentWeekDays = () => {
    const weekStart = dayjs().startOf("week");

    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(dayjs(weekStart).add(i, "days"));
    }

    return days;
  };

  useEffect(() => {
    setDaysOfWeek(getCurrentWeekDays());
  }, []);

  const MenuItem = ({ title, text, selected, key }: any) => {
    return (
      <div
        className={`menu-item dayItem ${selected ? "active" : currentDay === text ? "today" : null}`}
        key={key}
      >
        <h5 className="title">{title}</h5>
        <span className="text"> {text}</span>
      </div>
    );
  };

  const Menu = (selected: any) =>
    daysOfweek.map((day: any) => {
      return (
        <MenuItem
          title={day.format(customFormat)}
          text={day.format("D")}
          key={day.format("D")}
          selected={selected}
        />
      );
  });

  const onSelect = (key: Select) => {
    setSelected(key);
    let date = new Date(moment().format('MM')+ '/'+ key+ '/'+moment().format('yyyy'));
    setFromdate(date);
    setToDate(date);
    setPage(1);
  };

  const menu = Menu(selected);

  return (
    <>
      <Row>
        {/* <section className="main-container resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="talking-duration-wrapper">
              <div className="heading-common">
                <h1>{t("talkingDuration")}</h1>
                <p>{t("talkingDurationHeading")}</p>
              </div>
              <div className="search-wth-content2 w-100">
                <SearchBar placeHolder={t('seachAnAgent')} />
              </div>
              <div className="calendar-wrpper">
                {/* <div className="calenderheading">June 2023</div> */}

                <div className="table-calendar">
                  {/* <div className="relative">
                      <span className="dropheading">
                        All <img src={IconDown} alt="MagicMoment" />
                      </span>
                      <div className="dropdowncontent">
                        <ul>
                          <li className="active">Questions</li>
                          <li>Product</li>
                          <li>Category</li>
                        </ul>
                      </div>
                    </div> */}

                  <div className="heading-withcalendar">
                    <div className="calenderheading">
                      { moment().format('MMMM')  + ' ' + moment().format('YYYY')}
                    </div>
                    <div className="calenderinfo _calls-c">
                      <div className="date" onClick={() => setDatePicker()}>
                        <img src={Iconcalendar} alt="" />
                        
                      </div>
                      {
                        showDatePicker ?
                          <div className="dropinfo">
                            <div className="dwon"></div>
                            <DatePicker
                              selected={fromDate}
                              onChange={(update: any) => {
                                changeDate(update);
                              }}
                              dateFormat="MMM dd yyyy"
                              //onOk={() => setShowDatePicker(false)}
                              open={showDatePicker}
                            />
                            
                          </div>
                          :
                          <span onClick={() => setDatePicker()}>
                            {formatDate(fromDate)}
                          </span>
                      }
                    </div>
                  </div>
                  <div className="calender-horizontal">
                    <ScrollMenu
                      data={menu}
                      // arrowLeft={ArrowLeft}
                      // arrowRight={ArrowRight}
                      selected={selected as string}
                      onSelect={onSelect}
                      scrollToSelected={true}
                    />
                  </div>
                </div>
              </div>
              <div className="content-fullwidth">
                <div className="h1">
                  {t("Agents")}
                </div>
                {!items.length && !loading ? <NoData /> : null}
                {
                  items.length ?
                    <>
                      <div className="top-heading">
                        <h3>{t('avgTalkDurationByAgent')}</h3>
                        <div className="d-flex ">
                          <div className="relative">
                            <span className="dropheading" onClick={avgTalkToggele}>
                              {talkInitialSelection === 1 ? 'In Hours' : 'In Min'}
                              <img src={IconDown} alt="MagicMoment" />
                            </span>
                            {
                              talkToggle ?
                                <div className="dropdowncontent _right">
                                  <ul>
                                    <li className={talkInitialSelection === 1 ? "active" : ""} onClick={() => avgDurationSelection(1)}>In Hours</li>
                                    <li className={talkInitialSelection !== 1 ? "active" : ""} onClick={() => avgDurationSelection(2)}>In Min</li>
                                  </ul>
                                </div>
                                :
                                null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="progress-content" ref={rootRef}>
                        {items.map((calls:any, key:number) => (
                            <ProgressBar
                                key={calls?._id}
                                name={calls.agentName}
                                totalNumber={talkInitialSelection === 1 ? totalInHours : totalInMinutes}
                                now={talkInitialSelection === 1 ? calls?.avgTimeSpentInHours : calls?.avgTimeSpentInMinutes}
                                min={0}
                                max={talkInitialSelection === 1 ? totalInHours : totalInMinutes}
                                width={(
                                  (
                                    talkInitialSelection === 1 ? (calls?.avgTimeSpentInHours/totalInHours) : (calls?.avgTimeSpentInMinutes/totalInMinutes)) * 100
                                  ) }
                                background={'#000'}
                            />
                            
                        ))}
                      </div>
                    </>
                    :
                    null
                }

                {hasNextPage && (
                  <div ref={sentryRef} className="spinner-wrap">
                    <div className="">
                      <Spinner />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
