import Row from "react-bootstrap/Row";
import iconProfile from "../../../assets/images/icon-profile.png";
import downIcon from "../../../assets/images/arrow-down2.svg";
import profileImg from "../../../assets/images/dummy-img.png";
import { useAuth } from "../../../hooks/useAuth";
import TopCategoryList from "../../../components/Category/TopCategoryList";
import { useEffect, useState } from "react";
import { NoData, SearchBar } from "../../../components";
import { useTranslation } from "react-i18next";
import { customerInteraction, product } from "../../../api";
import { USER_STATUS } from "../../../constants";
import { Any } from "../../../types/global.type";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import useCustomInteraction from "../../../hooks/useCustomerInteraction";
import ProductDetailModal from "../../../components/Modal/ProductDetailModal";
import CallStartModal from "../../../components/Modal/CallStartModal";
import { Spinner } from "react-bootstrap";

export default function CustomerInteraction() {
  const auth = useAuth();
  const { t } = useTranslation();
  const [filterByCategory, setfilterByCategory] = useState("-1");
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [expendMenu, setExpendMenu] = useState(false);
  const [expendProduct, setExpendProduct] = useState(false);
  const { stopOnclick } = useCustomInteraction();
  const [detail, setProductDetail]  = useState(null);
  const [questionDetail, setQuestionDetail]  = useState<any>(null);
  const [showUpsell, setShowUpsellPopup] = useState(false);

  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  const updateFilterCategoryId = (id: string) => {
    id && id !== "-1" ? setfilterByCategory(id) : setfilterByCategory("-1");
  };

  useEffect(() => {
    setLoading(true);
    customerInteraction.getPathFirstStepQuestion(filterByCategory, search)
    .then((res: { data: Any }) => {
      const slicedArray = res.data.slice(0, 3);
      setQuestions(slicedArray);
      setLoading(false);
    });
  }, [search, filterByCategory]);

  useEffect(() => {
    stopOnclick();
  }, [location]);

  useEffect(() => {
    product
      .topProductList({
        page: 1,
        count: 10,
        status: USER_STATUS.ACTIVE,
      })
      .then((res: { data: Any }) => {
        if(res.data.length > 0) {
          const slicedArray = res.data.slice(0, 3);
          setProducts(slicedArray);
        } else {
          getTopProductList();
        }
    });
  }, []);

  const getTopProductList = () => {
    product
      .allProductList({
        page: 1,
        count: 10,
        status: USER_STATUS.ACTIVE,
    })
    .then((res: { data: Any }) => {
        const slicedArray = res.data.length > 0 ? res.data.slice(0, 3) : res.data;
        setProducts(slicedArray);
    });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearch((searchParams.get("query") as string) || "");
  }, [location.search]);

  function startProcess(question?: any) {
    let pathId = question && question?._id ? question?._id : null;
    if(pathId) {
      navigate({
        pathname: ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION_PROCESS,
            search: createSearchParams({
                pathId: pathId
            }).toString()
      });
    } else {
      navigate(ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION_PROCESS);
    }

  }

  function toggleCategoryExpend() {
    setExpendMenu(!expendMenu);
    !expendMenu
      ? document
          .getElementsByClassName("category-toggle")[0]
          ?.classList.add("togglehide")
      : document
          .getElementsByClassName("category-toggle")[0]
          ?.classList.remove("togglehide");
  }

  function toggleProductExpend () {
    setExpendProduct(!expendProduct);
    !expendProduct
      ? document
          .getElementsByClassName("product-toggle")[0]
          ?.classList.add("togglehide")
      : document
          .getElementsByClassName("product-toggle")[0]
          ?.classList.remove("togglehide");
  }

  const truncateText = (text: string) => {
    return text && text?.length > 24 ? text.slice(0, 90) + "..." : text;
  };

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }

  function showUpsellPopup(questionDetails:any) {
    setQuestionDetail(questionDetails);
    setShowUpsellPopup(true);
  }

  function closeUpsellPopup() {
    setQuestionDetail(null);
    setShowUpsellPopup(false);
  }

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content customer-interaction-wrapper">
            <div className="customer-header">
              <div className="profile-content">
                <div className="image">

                  {/* <div className="addicon">
                    <img src={iconPlus} alt="magicmoment" />
                  </div> */}
                  <img
                    src={auth.user?.image ? auth.user?.image : iconProfile}
                    onError={(e: any) => {
                      e.target.src = iconProfile;
                    }}
                    alt="ProfileImg"
                  />
                </div>
                <div className="profile-info">
                  <div className="name">{t("hi")} { capitalizeFirst(auth.user.name) + ' ' + ( auth.user.surname ? capitalizeFirst(auth.user.surname) : '') },</div>
                  <h2>{t('letStartCall')} 👋</h2>
                </div>
              </div>
              {
                questions.length ?
                <div className="btnstart" onClick={() => startProcess()}>
                  <button className="startbtn">{t('start')}</button>
                </div>
                : 
                null
              }

            </div>
            {/* <div className="banner">
              <div className="bannertext">
                <h3>Campaign</h3>
                <p>This is just a placeholder for the campaign banner.</p>
                <button className="bnnerbtn">Check Active Campaigns</button>
              </div>
              <div className="img">
                <img src={iconBanner} alt="Magicmoment" />
              </div>
              <div className="cross">
                <img src={crossIcon} alt="" />
              </div>
            </div> */}
            <div className="_search-with-filter">
              <div className="search-wth-content2 w-100">
                <SearchBar
                  placeHolder={t("Search")}
                />
              </div>
              {/* <div className="filter">
                <img src={filterIcon} alt="MagicMoment" />
              </div> */}
            </div>
            <div className="customer-mid-content">
              <div className="category-info-outer">
                <div className="heading">
                  <h2>{t('topCategories')}</h2>
                  <span className="toggleclick" onClick={toggleCategoryExpend}>
                    <img src={downIcon} alt="MagicMoment" />
                  </span>
                </div>
                <div className="toggle-content category-toggle">
                  <TopCategoryList allFilter={true} categoryId={filterByCategory} updateFilterCategoryId={updateFilterCategoryId}/>
                  
                  <div className="heading">
                    <h2>{t('topQuestions')}</h2>
                  </div>
                  <div className="bottom-pay-wrapper">
                    { loading ?
                        <Spinner /> 
                        :
                        <>
                          {questions.length ? 
                            <>
                              {questions.map((question:any) => (
                                <div className="bottomgrid" key={question._id} onClick={() => showUpsellPopup(question)}>
                                  <div className="paygrid-common">
                                    <div className="payment-info">
                                      <div className="paytxt">
                                        <div className="category">{t('Category')}</div>
                                        <div className="payment"> { question.question?.category ? questionArray(question.question.category) : '' }</div>
                                      </div>
                                      {
                                        question.question.upsellCount > 0 ?
                                        <div className="timetxt">
                                          <div className="category">{t('Upsell')}</div>
                                          <div className="time">{question.question.upsellCount}</div>
                                        </div>
                                        :
                                        null
                                      }
                                    </div>
                                    <div className="heading-info" title={question.question.question}>
                                      {truncateText(question.question.question)}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                            :
                            <NoData />
                          }
                        </>
                    }
                  </div>
                </div>
              </div>
              <div className="category-info-outer">
                <div className="heading">
                  <h2>{t('topProducts')}</h2>
                  <span className="toggleclick" onClick={toggleProductExpend}>
                    <img src={downIcon} alt="MagicMoment" />
                  </span>
                </div>
                <div className="top-product-content">
                <div className="toggle-content product-toggle">
                  <div className="top-productcontent">
                  { products.length == 0 ?
                        <Spinner /> 
                        :
                        <>
                      {!products.length ? <NoData /> :
                        <>
                          {products.map((data:any) => (
                            <div className="topgrid" key={data._id} onClick={ () => setProductDetail(data)}>
                              <div className="_image">
                                <img
                                  src={data?.image ? data?.image : profileImg}
                                  onError={(e: any) => {
                                      e.target.src = profileImg;
                                  }}
                                  alt="ProfileImg"
                                />
                              </div>
                              <div className="usertextInfo">
                                <div className="name">{data.name}</div>
                                <div className="position">{truncateText(data.description)}</div>
                              </div>
                            </div>
                          ))}
                        </>
                      }
                    </>
                  }
                  </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <ProductDetailModal
          detail={detail}
          handleClose={() => setProductDetail(null)}
      />
      <CallStartModal
        show={showUpsell}
        detail={questionDetail}
        handleClose={() => startProcess(questionDetail)}
        close={() => closeUpsellPopup()}
      />
    </>
  );
}
