import "chart.js/auto";
import { Bar } from "react-chartjs-2";


export const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false ,
      }
    },
    scales: {
        x: {
            ticks: {
                display: false,
            },
            border: {
                display: false
            }, 
        },
        y: {
            ticks: {
                display: true,
                color: '#fff'
            },
            border: {
                display: true
            },
        }
    },
    layout: {
        padding: {
          top: 35,
          bottom: 20
        }
    }
};
export default function BarChart({
    labels,
    count,
}: {
    labels?: any;
    count?: any;
}) {

    const barChart = {
        labels: labels ?  labels :['', '', '', '', '', '', ''],
        datasets: [
          {
            label: 'Total Call: ',
            data: count ? count: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: '#fff',
            barThickness: 5,
            borderRadius: 5
          }
        ],
    };

    return (
        <Bar options={options} data={barChart} />
    );
}
