import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { ELEMENT_LIST } from "../../../../constants";
import PathStepper from "../../../../components/Path/PathStepper";
import QuestionCard from "../../../../components/Path/QuestionCard";
import { SearchBar } from "../../../../components";
import PathCategoryList from "../../../../components/Path/PathCategoryList";
import { useEffect, useState } from "react";
import { useDragAndDrop } from "../../../../hooks/useDragAndDrop";

export default function CreatePath() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterByCategory, setCategory] = useState("");
  const { questionId = [], droppedQuestion = [], setQuestionItem, resetState} = useDragAndDrop();


  function backTo(pageNo: number) {
    pageNo === ELEMENT_LIST.CATEGORY ? navigate(ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES) : 
    (
      pageNo === ELEMENT_LIST.PRODUCT ? 
      navigate(ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS) : navigate(ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS)
    );
  }

  useEffect(() => {
    setQuestionItem("");
    resetState();
  }, [])


  const updateFilterCategoryId = (data: any) => {
    data.name === "ALL" ? setCategory("") : setCategory(data.id);
  };

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="tabs-top">
              <ul>
                <li className="completed" onClick={() => backTo(1)}>
                  1. {t("Create categories")}
                </li>
                <li className="completed" onClick={() => backTo(2)}>
                  2. {t("Create Product")}
                </li>
                <li className="completed" onClick={() => backTo(3)}>
                  3. {t("Create Questions")}
                </li>
                <li className="active">4. {t("Create Path")}</li>
              </ul>
            </div>
            
            <div className="path-container"> 
              <PathStepper/>
              <div className="search-with-tags-path">
                <SearchBar placeHolder={t("Search")} />
                <PathCategoryList filterByCategory={filterByCategory}
                  categoryId={filterByCategory}
                  updateFilterCategoryId={updateFilterCategoryId}
                />
              </div>

              <QuestionCard isDraggable filterByCategory={filterByCategory} />
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
