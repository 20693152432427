import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MessageModalType } from "../../types/global.type";
import iconInfo from "../../assets/images/iconInfo.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import iconError from "../../assets/images/iconError.svg";
import iconRemove from "../../assets/images/iconRemoveRed.svg";
import useCustomInteraction from "../../hooks/useCustomerInteraction";

const ICON = {
    success: iconSuccess,
    error: iconError,
    info: iconInfo,
    warning: iconRemove,
};

export default function SignOutConfirmation({
    heading,
    body,
    show,
    handleClose,
    type = "success",
    buttonMain = "Yes",
    buttonSecondary = "No",
    hideIcon = false,
    callback,
  }: MessageModalType) {
    const { stopOnclick } = useCustomInteraction();
    const onOk = (isOk: boolean) => {
        callback && callback(isOk);
        !isOk && handleClose();
      };
    const { t } = useTranslation();
    const { signout } = useAuth();

    const onSubmit = () => {
        stopOnclick();
        signout()
    };
    return (
        <Modal
            show={show}
            onHide={handleClose}
            // backdrop="static"
            keyboard={false}
            dialogClassName={"model-common _confirmation-common"}
        >
        <Modal.Header>
            <div className="_icon-img">
                <img src={ICON[type]} alt="info" />
            </div>
            <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onSubmit}>
            {t(buttonMain)}
            </Button>
            <Button className="btn-cancel" onClick={() => onOk(false)}>
            {t(buttonSecondary)}
            </Button>
        </Modal.Footer>
        </Modal>
    );
}