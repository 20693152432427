import { Field, FieldProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface FormControlProps extends FieldProps<any, any, any, any> {
  label?: string;
//   icon?: string;
}

export function MobilePhone({
  label,
  ...rest
}: FormControlProps) {
  const { t } = useTranslation();

  return (
    <Field
      {...rest}
      render={({ input, meta }) =>
       
          <div className="text-start form-field">
            <label className="form-label">{label}</label>
            <div className="input-group2">
             
            <PhoneInput
              country={"us"}
              onChange={(e, data: any) => {
                let mobile = null;
                if (e) {
                  mobile = {
                    countryCode: data.dialCode,
                    mobileNo: e.replace(data.dialCode, ""),
                  };
                }
                input.onChange(mobile);
              }}
              defaultErrorMessage="invalid"
            />
            </div>

            {meta.touched && meta.error && (
              <span className="error">{ t(meta.error)}</span>
            )}
          </div>
      }
    />
  );
}
