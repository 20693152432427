import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";
import { team } from "../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../hooks/useMessage";
import { useTranslation } from "react-i18next";
import { composeValidators, required, validEmail } from "../../validations";
import IconEmail from "../../assets/images/iconEmail.svg";
import { FormControl } from "..";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useAuth } from "../../hooks/useAuth";

export default function AddSalesDetail({
  detail,
  handleClose
}: {
  detail: any;
  handleClose: VoidFunction;
}) {
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateUserData } = useAuth();

  const onSubmit = (values: any) => {
    const data = { ...values };
    team
      .updateSaleEmail(data)
      .then((res) => {
        updateUserData({ salesEmail: data.salesEmail });
        navigate(ROUTE_NAVIGATION_PATH.FAQ_CREATION_INFO);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <Modal
      show={!!detail}
      onHide={handleClose}
      backdrop="static"
      dialogClassName={"team-memeber-model _invite-dialog"}
      keyboard={false}
    >
      <div className="invite-mid-content _invitecenter">
        <Modal.Header closeButton>
          <Modal.Title>{t("EnterSalesEmail")}</Modal.Title>
        </Modal.Header>
        <p className="subtxt">
          {t("SalesEmailHeading")}
        </p>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="editteamform">
              <Modal.Body>
                <FormControl
                  name="salesEmail"
                  type="email"
                  placeholder={t("Email")}
                  validate={composeValidators(required, validEmail)}
                  icon={IconEmail}
                />
                
                <Button variant="primary" type="submit">
                  {t("Continue")}
                </Button>
      
              </Modal.Body>
            </form>
          )}
        />
      </div>

    </Modal>
  );
}
