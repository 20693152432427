import { api } from "../api";
import { Any } from "../../types/global.type";

export const ENDPOINTS = {
    PRODUCT_LIST: "v1/product/list",
    CREATE_PRODUCT: "v1/product/add",
    DELETE_PRODUCT: "/v1/product/delete",
    EDIT_PRODUCT: "/v1/product/edit"
};

async function createProduct(data: Any) {
    return api.post(ENDPOINTS.CREATE_PRODUCT, data).then((res) => res.data);
}

async function allProductList(data: Record<string, any>) {
    return api.get(ENDPOINTS.PRODUCT_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.products,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function topProductList(data: Record<string, any>) {
    data.showTopProducts = true;
    return api.get(ENDPOINTS.PRODUCT_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.products,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function editProduct(data:Any, id:Any) {
    delete data.id;
    return api
    .put(ENDPOINTS.EDIT_PRODUCT + '/' + id, data)
    .then((res) => res.data);
}

async function deleteProduct(productId:string) {
    return api
    .delete(ENDPOINTS.DELETE_PRODUCT + '/' + productId)
    .then((res) => res.data);
}

export {
    createProduct,
    allProductList,
    topProductList,
    editProduct,
    deleteProduct
};
