import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Any } from "../../types/global.type";
import { composeValidators, maxlength, required } from "../../validations";
import { VALIDATIONS } from "../../constants";
import { FormControl } from "..";

export default function AddPathDetailModal({
  show,
  detail,
  handleClose,
  updateListItem,
  callback
}: {
  show: boolean;
  detail: any;
  handleClose: VoidFunction;
  updateListItem?:(data: any) => void;
  callback?: (data?: any) => void;
}) {
  const { t } = useTranslation();

  const onOk = (isOk: boolean) => {
    callback && callback(isOk);
    !isOk && handleClose();
  };

  const onSubmit = (values: Any) => {
    if (updateListItem) updateListItem(values);
    onOk(true);
  };

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      //backdrop="static"
      dialogClassName={"team-memeber-model"}
      keyboard={false}
    >
        <Modal.Header>
          <Modal.Title>Edit Path Details</Modal.Title>
        </Modal.Header>
        <p className="subtxt">
          {t("Add, Edit and manage your paths.")}
        </p>
        <Form
          initialValues={detail}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <FormControl
                  name="name"
                  label="Name"
                  type="Name"
                  validate={composeValidators(
                      required,
                      maxlength(VALIDATIONS.MAX_NAME)
                  )}
                />
                <FormControl
                  label="Description"
                  name="description"
                  type="textarea"
                  validate={composeValidators(
                      required,
                      maxlength(VALIDATIONS.MAX_DESCRIPTION)
                  )}
                />
                <Modal.Footer>
                  <Button variant="primary cancel" type="button" onClick={handleClose}>
                    {t("Cancel")}
                  </Button>
                  <Button variant="primary" type="submit">
                    {t("save")}
                  </Button>
              </Modal.Footer>
            </form>
        )}
        />
    </Modal>
  );
}
