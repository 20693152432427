import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { team } from "../../../api";
import { Member } from "../../../types/User";
import { TEAM_ADMIN_POSITION } from "../../../constants";
import { MyProfileManageTeam, LeftProfileMenu, FormControl } from "../../../components";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { Button } from "react-bootstrap";
import { composeValidators, required, validEmail } from "../../../validations";
import IconEmail from "../../../assets/images/iconEmail.svg";


export default function LeaderProfile() {
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  
  const {t} = useTranslation();

  useEffect(() => {
    team
      .getLeaderProfile()
      .then((res) => {
        res.data.userInfo["position"] = TEAM_ADMIN_POSITION;
        setDetailsToShow(res.data.userInfo);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  const onSubmit = (values: any) => {
    
    const data = {
      salesEmail: values.salesEmail
    }
    team
      .updateSaleEmail(data)
      .then((res) => {
        showMessage({
          heading: t("Profile"),
          body: <p>{t("SavedProfileSuccess")}</p>,
          type: "success"
        });
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: "Error",
          body: <p>{error.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <Row>
        {/* <section className="main-container shrink-width"> */}
          <div className="heading-common">
            <h1>
              {t("My")}<span> {t("Profile")}</span>
            </h1>
            <p>{t("myLeaderProfileDes")}</p>
          </div>
          {detailsToShow ? (
            <div className="task-myprofile-wrapper">
              <Col md={12} xl={5} className="profile-left-content">
                <div className="teamcreateheading">
                  <h2>{t('personalDetails')}</h2>
                  {/* <p><p>{t('teamCreationSubTitile')}</p></p> */}
                </div>
                <div className="outer-with-form">
                  <div className="form_inner_wrapper">
                    <LeftProfileMenu
                      user={detailsToShow}
                    />
                  </div>
                  <div className="myprofileleader">
                    <Form
                      initialValues={detailsToShow}
                      onSubmit={onSubmit}
                      render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                          <FormControl
                            name="salesEmail"
                            type="email"
                            label="Sales mail address"
                            validate={composeValidators(required, validEmail)}
                            icon={IconEmail}
                          />
                          <Button variant="primary" type="submit" className="mt-5">
                            {t('save')}
                          </Button>
                        </form>
                    )}
                  />
                  </div>
                </div>
              </Col>
              <Col md={12} xl={7} className="profile-right-content">
              <div className="teamcreateheading">
                <h2>{t('ManageYourTeam')}</h2>
                {/* <p>{t('teamCreationSubTitile')}</p> */}
              </div>
              <MyProfileManageTeam />
              </Col>
            </div>
          ) : (
            <div className="task-myprofile-wrapper">

              <div className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        {/* </section> */}
      </Row>
    </>
  );
}
