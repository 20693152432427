
import iconTimer from "../../../assets/images/timer-icon.svg";
import { useTranslation } from "react-i18next";

export default function ReadQuestions({
    readQuestions,
    totalTime
}: {
    readQuestions:any,
    totalTime:any
}) {
  const { t } = useTranslation();
  const truncateText = (text: string) => {
    return text && text?.length > 24 ? text.slice(0, 70) + "..." : text;
  };

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }
 
  return (
    <>
        <div className="_relative">
            <div className="timerline">
            <div className="line"></div>
            <div className="itme-circle">
                <img src={iconTimer} alt="Magicmoment" /> {totalTime}
            </div>
            </div>
            <div className="startarrow">
                <div className="line"></div>
                <div className="circle"><span></span></div>
            </div>
            <div className="bottom-pay-wrapper _cuslistwrapper">
            <div className="ul">
                {readQuestions.map((data:any) => (
                    <div className="bottomgrid">
                    <div className="paygrid-common">
                        <div className="timebottom">
                        <img src={iconTimer} alt="Magicmoment" /> {data.endTime}
                        </div>
                        <div className="payment-info">
                        <div className="paytxt">
                            <div className="category">{t('Category')}</div>
                            <div className="payment">{data.category ? questionArray(data.category) : ''}</div>
                        </div>
                        <div className="timetxt">
                            <div className="category">{t('avgTime')}</div>
                            <div className="time">{data.time}</div>
                        </div>
                        </div>
                        <div className="heading-info" title={data.question}>
                            {truncateText(data.question)}
                        </div>
                    </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
    </>
  );
}
