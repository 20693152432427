import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { AxiosError } from "axios";
import { myprofile } from "../../../api";
import { Member } from "../../../types/User";
import { MyProfileManageTeam, LeftProfileMenu } from "../../../components";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";
import { useMessageModal } from "../../../hooks/useMessage";
import useCustomInteraction from "../../../hooks/useCustomerInteraction";
import { ROLE } from "../../../constants";


export default function MemberProfile() {
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const { user } = useAuth();
  const { showMessage } = useMessageModal();
  const { stopOnclick } = useCustomInteraction();
  
  const {t} = useTranslation();

  useEffect(() => {
    stopOnclick();
    myprofile
      .getMemberProfile(user.id as string)
      .then((res) => {
        setDetailsToShow(res.data.userInfo);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  }, []);

  return (
    <>
      <Row>
        {/* <section className="main-container shrink-width"> */}
          <div className="heading-common">
            <h1>
              {t("My")}<span> {t("Profile")}</span>
            </h1>
            <p>{t('overViewTeamMember')}</p>
            {/* <div className="logout" onClick={handleClick}>
              <p className="header">Logout</p>
            </div> */}
          </div>
          {detailsToShow ? (
            <div className="task-myprofile-wrapper">
              <Col md={12} xl={5} className="profile-left-content">
              <div className="teamcreateheading">
              <h2>{t('personalDetails')}</h2>
              <p>{t("memberProfileDes")}</p>
              </div>
              <div className="form_inner_wrapper">
                <LeftProfileMenu
                  user={detailsToShow}
                />
                
              </div>
              </Col>
              <Col md={12} xl={7} className="profile-right-content">
              <div className="teamcreateheading">
                <h2>{t('viewYourTeam')}</h2>
                <p>{t('OverviewTeamMembers')}</p>
              </div>
              <MyProfileManageTeam />
              </Col>
            </div>
          ) : (
            <div className="task-myprofile-wrapper">

              <div className="spinner-wrap">
                <div className="">
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        {/* </section> */}
      </Row>
    </>
  );
}
