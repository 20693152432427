import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { SearchBar } from "../../../../components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { question } from "../../../../api";
import { useEffect, useState } from "react";
import { ROLE, USER_STATUS } from "../../../../constants";
import { useLocation } from "react-router-dom";
import useEditDelete from "../../../../hooks/useEditDelete";
import DeleteQuestion from "../../../../components/Modal/DeleteQuestion";
import { Spinner } from "react-bootstrap";
import { NoData } from "../../../../components";
import QuestionTile from "../../../../components/Question/QuestionTile";
import QuestionDetailModal from "../../../../components/Modal/QuestionDetailModal";
import { useAuth } from "../../../../hooks/useAuth";

export default function TopQuestions() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const auth = useAuth();
  const { deleteFor, setDeleteFor } = useEditDelete();
  const [questionDetail, setQuestionDetail] = useState(null);
  const [search, setSearch] = useState("");
  let status = USER_STATUS.ACTIVE;
  const location = useLocation();
  const { loading, items, hasNextPage, error, deleteItem } = useLoadItems({
    loadItems: question.topQuestionList,
    page,
    status,
    search
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
    
  }, [location.search]);


  const deleteQuestion = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };  

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("topQuestions")}</h1>
              <p>{ auth.user.role === ROLE.TEAM_LEADER ? t("questionListSubHeading") : ""}</p>
            </div>
            <div  className={`search-wth-content2 w-100`}>
              <SearchBar
                  placeHolder={t("Search")}
              />
                
            </div>
            
            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent _categorydetail _productlistingcontent _category-ques-detail _leaderboard">
                {!items.length && !loading ? <NoData /> : null}
                { items.length ? 
                  <table className="_producttable question-listtable">
                    <thead>
                      <tr>
                        <th className="rankth">{t("Rank")}</th>
                        <th style={{minWidth: '170px'}}>{t("Question")}</th>
                        <th>{t("createAt")}</th>
                        <th>{t("frequency")}</th>
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((question:any, key:number) => (
                          <QuestionTile
                            key={question._id}
                            question={question}
                            index={key+1}
                            deleteQuestion={() => setDeleteFor(question._id)}
                            questionDetail={() => setQuestionDetail(question)}
                          />
                      ))}
                    </tbody>
                  </table>
                  :
                  null
                }
                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <DeleteQuestion
          show={!!deleteFor}
          handleClose={() => setDeleteFor(null)}
          id={deleteFor}
          callback={deleteQuestion}
      />
      <QuestionDetailModal
        show={!!questionDetail}
        data={questionDetail}
        handleClose={() => setQuestionDetail(null)}
      />
    </>
  );
}
