import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { DeleteCategory, EditCategoryModal, SearchBar } from "../../../../components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoadItems } from "../../../../hooks/useLoadItems";
import { category } from "../../../../api";
import { useEffect, useState } from "react";
import { ROLE, USER_STATUS } from "../../../../constants";
import { useLocation } from "react-router-dom";
import useEditDelete from "../../../../hooks/useEditDelete";
import { Spinner } from "react-bootstrap";
import { NoData } from "../../../../components";
import { useAuth } from "../../../../hooks/useAuth";
import CategoryTile from "../../../../components/Category/CategoryTile";
import { Any } from "../../../../types/global.type";

export default function TopCategories() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  let status = USER_STATUS.ACTIVE;
  const location = useLocation();
  const auth = useAuth();
  const { deleteFor, editData, setEditData, setDeleteFor } = useEditDelete();
  const { loading, items, hasNextPage, error, deleteItem, updateItem } =
    useLoadItems({
    loadItems: category.topcategoryList,
    page,
    search,
    status
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
    
  }, [location.search]);

  const deleteCategory = (isOk: boolean) => {
    if (isOk) {
      deleteItem(deleteFor);
      setDeleteFor(null);
    }
  };


  const updateListItem = (updatedData: Any) => {
    updatedData._id = editData?._id as string;
    updateItem(updatedData);
    setEditData(null);
  };

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("topCategories")}</h1>
              <p>{ auth.user.role === ROLE.TEAM_MEMBER? "": t("questionListSubHeading")}</p>
            </div>
            <div  className={`search-wth-content2 w-100`}>
                <SearchBar
                    placeHolder={t("Search")}
                />
            </div> 
            <div className="select-category-wrapper" ref={rootRef}>
              <div className="mid-categorycontent _categorydetail _productlistingcontent _category-ques-detail _categorytable2">
                {!items.length && !loading ? <NoData /> : null}
                { items.length ? 
                  <table className="_producttable question-listtable ">
                    <thead>
                      <tr>
                        <th className="rankth">{t("Rank")}</th>
                        <th style={{minWidth: '200px'}}>{t("Category")}</th>
                        <th>{t("createAt")}</th>
                        <th>{t("frequency")}</th>
                        {
                          auth.user.role === ROLE.TEAM_LEADER ?
                          <th></th>
                          :
                          null
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((category:any, key:number) => (
                          <CategoryTile
                            key={category._id}
                            category={category}
                            index={key+1}
                            editCategory={() => setEditData(category)}
                            deleteCategory={() => setDeleteFor(category._id)}
                          />
                      ))}
                    </tbody>
                  </table>
                  :
                  null
                }
                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <EditCategoryModal
          detail={editData}
          handleClose={() => setEditData(null)}
          updateListItem={updateListItem}
      />
      <DeleteCategory
          show={!!deleteFor}
          handleClose={() => setDeleteFor(null)}
          id={deleteFor}
          callback={deleteCategory}
      />
    </>
  );
}
