import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import { MemberInfo, NoData } from "../../../components";
import { useAuth } from "../../../hooks/useAuth";
import { customerInteraction, dashboard, myprofile, team } from "../../../api";
import useCustomInteraction from "../../../hooks/useCustomerInteraction";
import { useTranslation } from "react-i18next";
import iconProfile from "../../../assets/images/icon-profile.png";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import MemberDashboardStats from "../../../components/Dashboard/MemberDashboardStats";
import CallHistory from "./CallHistory";
import { Any } from "../../../types/global.type";
import AgentList from "./AgentList";

export default function MemberDashboard() {
  const [info, setInfo] = useState(false);
  const auth = useAuth();
  let { user, updateUserData } = useAuth();
  const { stopOnclick } = useCustomInteraction();
  const { t } = useTranslation();
  const effectRan = useRef(false);
  const [totalStats, settotalStats] = useState<any>({});
  const [questions, setQuestions] = useState<any[]>([]);

  useEffect(() => {
    if (user.isFirstTimeLogin === true) {
      setInfo(true);
      updateUserData({ isFirstTimeLogin: false });
    } else {
      stopOnclick();
    }

    myprofile.updateFirstTimeLoginStatus();
  }, []);

  useEffect(() => {
    customerInteraction.getPathFirstStepQuestion("-1", "")
    .then((res: { data: Any }) => {
      const slicedArray = res.data.slice(0, 3);
      setQuestions(slicedArray);
    });
  }, []);


  useEffect(() => {
    if (!effectRan.current) {
      dashboard.getMemberDashboardStats().then((res: { data: any }) => {
        settotalStats(res.data);
      });
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  return (
    <>
      <Row>
        {/* <section className="main-container resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="dashboard-header">
              <div className="profile-content">
                <div className="image">
                  <img
                    src={auth.user?.image ? auth.user?.image : iconProfile}
                    onError={(e: any) => {
                      e.target.src = iconProfile;
                    }}
                    alt="ProfileImg"
                  />
                </div>
                <div className="profile-info">
                  <div className="name">
                    {t("hi")} { capitalizeFirst(auth.user.name) + ' ' + ( auth.user.surname ? capitalizeFirst(auth.user.surname) : '') },
                  </div>
                  <h2>{t("welcomeBack")} 👋</h2>
                </div>
              </div>
              {
                questions.length ?
                  <Link to={ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION_PROCESS}>
                    <button className="startbtn">{t('start')}</button>
                  </Link>
                  :
                  null
              }
            </div>
            <div className="dashboard-wrapper">
              <div className="callparday-teammember">
                <MemberDashboardStats totalStats={totalStats} />

                <div className="dashboard-right">
                  <div className="top-heading">
                    <h3>{t("agentAvailable")}</h3>
                    {/* <Link to={ROUTE_NAVIGATION_PATH.AGENT_LIST}>
                      {t("seeAllAgents")}
                      <img src={IconNext} alt="magicmoment" />
                    </Link> */}
                  </div>

                  <AgentList />
                </div>
              </div>
             <CallHistory />
            </div>
          </div>
        {/* </section> */}
      </Row>
      <MemberInfo
        heading="Introduction"
        body="we invite"
        show={info}
        handleClose={() => setInfo(false)}
        type="success"
        buttonMain="Start"
      />
    </>
  );
}
