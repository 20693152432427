import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useCustomMutation } from "../../hooks/useApi";
import { product } from "../../api";
import { Any } from "../../types/global.type";
import { useMessageModal } from "../../hooks/useMessage";
import { ReactNode, useState } from "react";
import AddEditProduct from "../FormElements/AddEditProduct";

export default function EditProduct({
    data,
    successCb,
    children,
}: {
  data: Any;
  successCb?: (data: Any) => void;
  children: ReactNode;
}) {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  const [file, setFile] = useState<File | null | undefined>();

  const { mutate } = useCustomMutation({
    mutationFn: (req:Any) => {
      return product.editProduct(req, data._id);
    },
    onSuccess: (res:Any) => {
        showMessage({
          heading: t("success"),
          body: <p>{res.message}</p>,
          type: "success",
          // callback: () => {
          //   window.location.reload();
          // },
        });

        successCb && successCb(res.data);
    },
  });

  function onSubmit(values: Any) {
    const formData = new FormData();
    formData.append("name", values.name);
    if(values.tags.length > 0) {
      for (var i = 0; i < values.tags.length; i++) {
        formData.append('tags[]', values.tags[i]);
      }
    }
    if (file) {
      formData.append("image", file);
    }
    formData.append("description", values.description);
    if(values.conditions) {
      formData.append("conditions", values.conditions);
    }

    mutate(formData);
  }

  const updateImage = (file: File | null | undefined) => {
    file ? setFile(file) : setFile(null);
  };

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <AddEditProduct
                updateImage={updateImage}
                isEditPhoto={!!data?.image}
                photo={data?.image}
                editForm={true}
                tags= {data?.tags}
            />
            {children}
        </form>
      )}
    />
  );
}
