import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "..";
import { question } from "../../api";
import { SUGGESTION_STATUS } from "../../constants";
import { useCustomMutation } from "../../hooks/useApi";
import { Any } from "../../types/global.type";
import EditCategory from "../Category/EditCategory";


export default function RejetSuggestionModal({
  detail,
  handleClose,
  updateListItem,
}: {
  detail: any;
  handleClose: VoidFunction;
  updateListItem?: (category: Any) => void;
}) {

  const { t } = useTranslation();
  const { mutate } = useCustomMutation({
    mutationFn: question.updateQuestionStatus,
    onSuccess: (data:any) => {
        detail.status = SUGGESTION_STATUS.REJECTED
        //updateListItem(detail);
        handleClose();
    },
  });

  function onSubmit() {
    
    let data = {
        questionId: detail?._id ? detail?._id : detail?.id,
        status: SUGGESTION_STATUS.REJECTED
    };
    mutate(data);
  }

  return (
    <DeleteModal
      heading={t("rejectProposal")}
      body={<p>{t("rejectProposalInfo")}</p>}
      show={!!detail}
      handleClose={handleClose}
      type="success"
      callback={(isDelete) => isDelete && onSubmit()}
      buttonMain="Reject"
      buttonSecondary="Cancel"
    />
  );
}
