import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Spinner from "react-bootstrap/Spinner";

import { admin } from "../../../api";
import {
  NoData,
  IconEdit,
  CreateInvoice,
} from "../../../components";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditInvoice from "../../../components/Invoice/EditInvoice";


export default function ManageInvoice() {
  const { t } = useTranslation();
  const params = useParams();
  const [page, setPage] = useState(0);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState<any>(null);
  const id = params.teamLeaderId;
  const { loading, items, hasNextPage, error, updateItem } =
    useLoadItems({
      loadItems: admin.getLeaderInvoices,
      page,
      id
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  function formatDate(value:any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return  date.toLocaleDateString('en-GB').split('/').join('-');
  }

  const updateListItem = (updatedData: any) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };
  

  return (
    <>
      <Row>
        {/* <section className="main-container shrink-width" > */}
          <div className="heading-common" >
            <h1>
              {t('PaymentOveriview')}
            </h1>
            {/* <p>{t('See payments')}</p> */}
           
            <div className="add-btn absolute _right" onClick={() => setCreateInvoice(true)}>{t('Add Invoice')}</div>
            
          </div>

          <div className="task-manage-team-wrapper" ref={rootRef}>

            <div className="table-scrollbar scrollbar">
              <div className="table-content">
                {!items.length && !loading ? <NoData /> : null}
                
                <table className="historytable">
                  {items.length ? (
                      <thead>
                        <tr>
                          <th>{t("Last Invoice")}</th>
                          <th>{t('Invoice Date')}</th>
                          <th>{t('Permitted Members')}</th>
                          <th>{t('Current Members')}</th>
                          <th>{t("Plan Start Date")}</th>
                          <th>{t("Plan End Date")}</th>
                          <th>{t("Actions")}</th>
                        </tr>
                      </thead>
                  ) : null}

                  {items.map((user) => (
                    <tbody>
                      <tr>
                        <td>{ user.invoiceAmount ? user.invoiceAmount + ' €': ''}</td>
                        <td>{user.invoiceDate ? formatDate(user.invoiceDate) : ''}</td>
                        <td>{user.permittedMembers ? user.permittedMembers: 0}</td>
                        <td>{user.addedMembers ? user.addedMembers: 0}</td>
                        <td>{user.planStartDate ? formatDate(user.planStartDate): ''}</td>
                        <td>{user.planEndDate ? formatDate(user.planEndDate): ''}</td>
                        <td className="action-btn">
          
                          <span className="edit2" onClick={() => setDetailsToShow(user)}>
                              <IconEdit />
                          </span>
                      </td>
                      </tr>
                    </tbody>
                  ))}
                  
                </table>
                {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                )}
              </div>
              
            </div>
          </div>
        {/* </section> */}
      </Row>
      <CreateInvoice
        detail={createInvoice}
        handleClose={() => setCreateInvoice(false)}
      />
      <EditInvoice
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
    </>
  );
}
