import "chart.js/auto";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { NoData } from "..";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import IconDown from "../../assets/images/downarrow.svg";
import { Link } from "react-router-dom";
import IconNext from "../../assets/images/cheveron-right2.svg";
import zIndex from "@mui/material/styles/zIndex";


class DynamicTextPlugin {
  text: string;
  //text2: string;
  constructor() {
    this.text =  "" ;
    //this.text2= "";
  }
  id = "dynamic-text-plugin";
  beforeDraw(chart:any) {
    const { ctx } = chart;
    const { width } = chart;
         const { height } = chart;
    //const { width, height } = chart.canvas;
    ctx.font = 'bold 22px Lato, sans-serif';
    ctx.fillStyle = "black"
    ctx.textBaseline = "middle";
    var text = this.text+'%',
    textX = Math.round((width - ctx.measureText(text).width) / 2),
    textY = height / 2;

    // var text1 = this.text2,
    //       textX1 = textX + 5,
    //       textY1 = (textY + 30);

    ctx.fillText(text, textX, textY);
    // ctx.font = '14px Lato sans-serif';
    // ctx.fillStyle = "grey"
    //ctx.fillText(text1, textX1, textY1);
    ctx.save();
    //ctx.fillText(text, textX, textY);
  }
}

const dynamicTextPlugin = new DynamicTextPlugin();

export default function DonutChart({
  totalStats,
}: {
  totalStats:any;
}) {
  const { t } = useTranslation();
  const [chartToggle, setCharTtoggle] = useState(false);
  const [chartIndex, setChartIndex] = useState(0);
  const [chartData, setChartData] = useState<any>([
    {
      name: t("Total Questions"),
      text: t("See all Questions"),
      url: ROUTE_NAVIGATION_PATH.TOP_QUESTIONS,
      data: [],
      sequence: 0,
      type: t("Question"),
      label: [],
    },
    {
      name: t("Total Products"),
      text: t("See all Products"),
      url: ROUTE_NAVIGATION_PATH.TOP_PRODUCTS,
      data: [],
      sequence: 1,
      type: t("Product"),
      label: [],
    },
    {
      name: t("Total Categories"),
      text: t("See all Categories"),
      url: ROUTE_NAVIGATION_PATH.TOP_CATEGORIES,
      data: [],
      sequence: 2,
      type: t("Category"),
      label: [],
    },
  ]);
  const [donutChart, setDonutChart] = useState({
    name: "React",
    data: {
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ["#fca311", "#FFD5D5", "#FEF2F2"],
          hoverBackgroundColor: ["#fca311", "#FFD5D5", "#FEF2F2"],
          borderWidth: 8,
          hoverBorderColor: ["#fca311", "#FFD5D5", "#FEF2F2"]
        },
      ],
      labels: [
        chartData[chartIndex].type + " 1",
        chartData[chartIndex].type + " 2",
        chartData[chartIndex].type + " 3",
      ],
    },
  });

  useEffect(() => {
    setData(totalStats)
  }, [])

  function setData(response: any) {
    setChartData((prev: any) =>
      prev.map((item: any) => {
        if (item.sequence === 0 && item.data.length === 0) {
          response.top3Questions.forEach((element: any) => {
            item.data.push(element.frequency);
            item.label.push(element.question);
          });
        }

        if (item.sequence === 1 && item.data.length === 0) {
          response.top3Products.forEach((element: any) => {
            item.data.push(element.frequency);
            item.label.push(element.name);
          });
        }

        if (item.sequence === 2 && item.data.length === 0) {
          response.top3Categories.forEach((element: any) => {
            item.data.push(element.frequency);
            item.label.push(element.name);
          });
        }
        return item;
      })
    );
    setDonutChart((prevState: any) => ({
      ...prevState,
      data: {
        datasets: [
          {
            data: chartData[chartIndex].data,
            backgroundColor: ["#fca311", "#FFD5D5", "#FEF2F2"],
            borderWidth: 8,
            hoverBackgroundColor: ["#fca311", "#FFD5D5", "#FEF2F2"],
            hoverBorderColor: ["#fca311", "#FFD5D5", "#FEF2F2"]  
          },
        ],
        labels: chartData[chartIndex].label,
      },
    }));
    dynamicTextPlugin.text = chartData[chartIndex]?.data[0];
    // setTimeout(() => {
    //   dynamicTextPlugin.text2 = chartData[chartIndex]?.data[0] + '% of direct'
    // }, 1000)
    
  }

  function chartDropdown() {
    setCharTtoggle(!chartToggle);
  }

  function chartSelection(value: number) {
    setChartIndex(value);
    setDonutChart((prevState: any) => ({
      ...prevState,
      data: {
        datasets: [
          {
            data: chartData[value].data,
            backgroundColor: ["#fca311", "#FFD5D5", "#FEF2F2"],
            hoverBackgroundColor: ["#fca311", "#FFD5D5", "#FEF2F2"],
            borderWidth: 8,
            hoverBorderColor: ["#fca311", "#FFD5D5", "#FEF2F2"]   
          },
        ],
        labels: chartData[value].label,
      },
    }));
    dynamicTextPlugin.text = chartData[value].data[0];
    //dynamicTextPlugin.text2 = chartData[value].data[0] + '% of direct'

    chartDropdown();
  }

  const getOrCreateTooltip = (chart:any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      tooltipEl.style.width = 'auto';
      tooltipEl.style.minWidth = '100px';
      tooltipEl.style.lineHeight = '30px';
      tooltipEl.style.height = 'auto';
      tooltipEl.style.zIndex = '99';
      tooltipEl.style.fontSize = '10px';
      tooltipEl.style.marginLeft = '30px';



  
      const table = document.createElement('table');
      table.style.margin = '0px';
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };
  
  const externalTooltipHandler = (context:any) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b:any) => b.lines);
  
      const tableHead = document.createElement('thead');
  
      titleLines.forEach((title:any) => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = '0';
  
        const th = document.createElement('th');
        th.style.borderWidth = '0';
        th.style.lineHeight = 'normal';
        th.style.letterSpacing = '-0.15px';
        th.style.wordBreak = 'break-word';
        
        const text = document.createTextNode(title);
  
        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
  
      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body:any, i:any) => {
        const colors = tooltip.labelColors[i];
  
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';
  
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';
  
        const td = document.createElement('td');
        td.style.borderWidth = '0';
  
        const text = document.createTextNode(body);
  
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
  
      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  return (
    <div className="chart-grid">
    <div className="top-heading">
      <h3>
        <span className="dropheading" onClick={chartDropdown}>
          {chartData[chartIndex].name}
          <img src={IconDown} alt="MagicMoment" />
        </span>
        {chartToggle ? (
          <div className="dropdowncontent">
            <ul>
              <li
                className={
                  chartData[0].sequence === chartIndex
                    ? "active"
                    : ""
                }
                onClick={() => chartSelection(0)}
              >
                {t("Questions")}
              </li>
              <li
                className={
                  chartData[1].sequence === chartIndex
                    ? "active"
                    : ""
                }
                onClick={() => chartSelection(1)}
              >
                {t("Product")}
              </li>
              <li
                className={
                  chartData[2].sequence === chartIndex
                    ? "active"
                    : ""
                }
                onClick={() => chartSelection(2)}
              >
                {t("Category")}
              </li>
            </ul>
          </div>
        ) : null}
      </h3>
      { chartData[chartIndex].data.length > 0 ?
        <Link to={chartData[chartIndex].url}>
          {chartData[chartIndex].text}{" "}
          <img src={IconNext} alt="magicmoment" />
        </Link>
        :
        null
      }
    </div>

    {chartData[chartIndex].data.length > 0 ? (
      <div className="chartheight"
        style={{
          minHeight: 300,
          paddingTop: "33.33333%",
          position: "relative",
          fontFamily: "Lato, sans-serif",
          
        }}
      >
        <div 
          style={{
            position: "absolute",
            inset: 0,
          }}
        >
          <Doughnut
            data={donutChart.data}
            width={299}
            height={299}
            options={{
              responsive: false,
              maintainAspectRatio: false,
              cutout: 90,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false,
                  position: 'nearest',
                  external: externalTooltipHandler,
                  callbacks: {
                    title: function(context:any) {
                      return context?.label;
                    },
                    label: function(context) {
                      return context.parsed + '';
                    }
                  }

                }
                
              },
              onHover: (e:any, activeHover:any ,chart:any) => {
                if(activeHover[0]?.index !== undefined ) {
                  let count = activeHover[0]?.index+1;
                  // dynamicTextPlugin.text = chartData[chartIndex].type + ' ' + count;
                  dynamicTextPlugin.text = chartData[chartIndex]?.data[activeHover[0]?.index];

                }
              }
            }}
            plugins={[dynamicTextPlugin]}
          />
        </div>
      </div>
    ) : (
      <NoData />
    )}
  </div>
  );
}
