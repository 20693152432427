import Nav from "react-bootstrap/Nav";
import profileImg from "../../assets/images/icon-profile.png";
import profileDp from "../../assets/images/icon-profile.svg";
import logo from "../../assets/images/logo2.svg";
import arrowProfile from "../../assets/images/profile-arrow-menu.svg";

import settingIcon from "../../assets/images/settingIcon.svg";
import logoutPro from "../../assets/images/icon-logout.svg";
import { useAuth } from "../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useEffect, useState } from "react";
import { LEADER_MENUS, ADMIN_MENUS, MEMBER_MENUS } from "../../constants/menu";
import { PROFILE_VIEW, ROLE } from "../../constants";
import { useTranslation } from "react-i18next";
import nextIcon from "../../assets/images/next.svg";
import logoIcon from "../../assets/images/logo-icon.png";
import { SignOutConfirmation } from "..";
import { useCreateEditQuestion } from "../../hooks/useCreateEditQuestion";

function Menu({
  icon,
  title,
  url,
  profileImg,
  callback,
  activeOn,
}: {
  icon: string;
  title: string;
  url: string;
  callback: (e: any) => void;
  profileImg: boolean;
  activeOn: string[];
}) {
  const auth = useAuth();
  const location = useLocation();
  let redirectionUrl = url;

  if(title == 'Dashboard') {
    if(auth.user.teamMemberCount == 0) {
      redirectionUrl = ROUTE_NAVIGATION_PATH.PRE_CREATION
    } else {
      redirectionUrl = url
    }
  }
  if(title == 'Elements' || title == 'Hauptmenü') {
    if(auth.user.teamMemberCount == 0) {
      redirectionUrl = ROUTE_NAVIGATION_PATH.PRE_CREATION
    }
    else if(auth.user.categoryCount == 0) {
      redirectionUrl = ROUTE_NAVIGATION_PATH.FAQ_CREATION_INFO
    } else {
      redirectionUrl = url
    }
  }

  return (
    <Link
      to={redirectionUrl}
      className={`nav-link ${
        activeOn.includes(location.pathname.split("/").slice(0, 3).join("/"))
          ? "active"
          : ""
      }`}
      onClick={callback}
    >
      {profileImg ? (
        <span className="icon-nav">
          <div className="_image">
            <img className="_icons" src={icon} alt={title} />
          </div>
        </span>
      ) : (
        <span className="icon-nav">
          <img className="_icons" src={icon} alt={title} />
        </span>
      )}

      <div className="_nav-hover">{title}</div>
    </Link>
  );
}
export default function ({
  toggleMenuExpend,
  expendMenu = true,
}: {
  toggleMenuExpend: () => void;
  expendMenu: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSignout, setSignOut] = useState(false);
  const { updateQuestionData } = useCreateEditQuestion();
  const  auth = useAuth();
  const [profileOpen, setProfileOpen] = useState(false);
  const profileUrl =
    auth.user.role === ROLE.TEAM_LEADER
      ? ROUTE_NAVIGATION_PATH.LEADER_MY_PROFILE
      : ROUTE_NAVIGATION_PATH.MEMBER_MY_PROFILE;

  function handleClick(type: number) {
    if (type === PROFILE_VIEW.SIGNOUT) {
      setSignOut(true);
    } else if (type === PROFILE_VIEW.PROFILE) {
      navigate(profileUrl);
    } else {
      //setting
    }
  }

  // function toggleMenuExpend() {
  //   setExpendMenu(!expendMenu);
  //   !expendMenu
  //     ? document
  //         .getElementsByClassName("main-container")[0]
  //         ?.classList.add("shrink-width")
  //     : document
  //         .getElementsByClassName("main-container")[0]
  //         ?.classList.remove("shrink-width");
  // }

  useEffect(() => {
    toggleMenuExpend();
    updateQuestionData({
      question: "",
      time: "",
      answer: "",
      category: [],
      productDetails: []
    });
  }, []);

  const toggleProfileOpen = () => {
    setProfileOpen(!profileOpen);
  }

  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  return (
    <aside className="navbar">
       <div
        className={`expend-menu ${expendMenu ? "active" : ""}`}
        onClick={toggleMenuExpend}
      >
        <img src={nextIcon} alt="magicmoment" />
      </div>
      <div className={`nav-content ${expendMenu ? "_expend-width" : ""}`}>
        <Nav
          defaultActiveKey={ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD}
          className=""
        >
          <div className="logo">
            <img src={logo} alt="Logo"  className="biglogo"/>
            <img className="logoicon" src={logoIcon} alt="Logo" />
          </div>
          <div className="_menu">
          {(auth.user.role === ROLE.TEAM_LEADER
            ? LEADER_MENUS
            : auth.user.role === ROLE.ADMIN
            ? ADMIN_MENUS
            : MEMBER_MENUS
          ).map((menu) => (
            <Menu
              key={menu.title}
              url={menu.url}
              title={t(menu.title)}
              icon={menu.icon}
              profileImg={false}
              callback={(e) => {}}
              activeOn={menu.activeOn}
            />
          ))}
          </div>
          <div className="profile-info" >
          {profileOpen ?
            <div className="profile-dropdown">
              <div className="linetop"></div>
          
              {auth.user.role !== ROLE.ADMIN ? (
                <ul>
                  <li onClick={() => navigate(profileUrl)}>
                    <span className="icon-dp">
                      <img src={profileDp} alt="Profile" />
                    </span>
                    <p>{t("Profile")}</p>
                  </li>
                  <li
                    onClick={() =>
                      navigate(
                        auth.user.role === ROLE.TEAM_LEADER
                          ? ROUTE_NAVIGATION_PATH.LEADER_SETTING
                          : ROUTE_NAVIGATION_PATH.MEMBER_SETTING
                      )
                    }
                  >
                    <span className="icon-dp">
                      <img src={settingIcon} alt="Setting" />
                    </span>
                    <p>{t("Settings")}</p>
                  </li>
                  <li onClick={() => handleClick(PROFILE_VIEW.SIGNOUT)}>
                    <span className="icon-dp">
                      <img src={logoutPro} alt="Profile" />
                    </span>
                    <p>{t("Signout")}</p>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li
                    onClick={() =>
                      navigate(ROUTE_NAVIGATION_PATH.ADMIN_SETTING)
                    }
                  >
                    <span className="icon-dp">
                      <img src={settingIcon} alt="Setting" />
                    </span>
                    <p>{t("Settings")}</p>
                  </li>
                  <li onClick={() => handleClick(PROFILE_VIEW.SIGNOUT)}>
                    <span className="icon-dp">
                      <img src={logoutPro} alt="Profile" />
                    </span>
                    <p>{t("Signout")}</p>
                  </li>
                </ul>
              )}
            </div>: null }
            <div className="profilecontent" onClick={toggleProfileOpen}>
              <div className="_image">
                <img
                  src={auth.user?.image ? auth.user?.image : profileImg}
                  onError={(e: any) => {
                    e.target.src = profileImg;
                  }}
                  alt="ProfileImg"
                />
            </div>
            <div className="_nav-hover">{ auth.user?.name ? capitalizeFirst(auth.user.name) + ' ' + ( auth.user.surname ? capitalizeFirst(auth.user.surname) : '')  : "ADMIN"}</div>
             <div className="arrow">
               <img src={arrowProfile} alt="Setting" />
             </div>
            </div>
           
          </div>
        </Nav>
      </div>
      <SignOutConfirmation
        heading={t("Signout")}
        body={<p>{t("signoutDescription")}</p>}
        show={!!isSignout}
        handleClose={() => setSignOut(false)}
        type="success"
        buttonMain="Ok"
        buttonSecondary="Cancel"
      />
    </aside>
    
  );
}