import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { t } from "i18next";
import { MemberInfo } from "../../../components";
import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { myprofile } from "../../../api";

export default function PreCreationScreen() {
  const [info, setInfo] = useState(false);
  let { user, updateUserData } = useAuth();

  useEffect(() => {
    if (user.isFirstTimeLogin === true) {
      setInfo(true);
      updateUserData({ isFirstTimeLogin: false });
    }

    myprofile.updateFirstTimeLoginStatus();

  }, [])

  return (
    <>
      <Row className="contentMiddlerow">
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content _for-middlewrapper">
            <div className="w-100">
              <div className="heading-common">
                <h1>
                  {t('LetsCreate')}
                </h1>
                <p>{t('preCreationSubtitle')}</p>
              </div>
              <div className="pre-creation-wrapper">
                <div className="creation-grid">
                  <h2>{t('Team')}</h2>
                  <p>
                    {t('createAmemberInfo')}
                  </p>
                  <Link to={ROUTE_NAVIGATION_PATH.TEAM_CREATION_INFO}>
                    <Button variant="primary withoutbg fixwidth">{t('createANewTeam')}</Button>
                  </Link>
                </div>
                <div className="creation-grid faqbg">
                  <h2>{t('FAQ')}</h2>
                  <p>
                    {t('createAFaqInfo')}
                  </p>
                  {/* <Link to={ROUTE_NAVIGATION_PATH.TEAM_CREATION_INFO}> */}
                    <Button variant="primary fixwidth">{t('createANewFaq')}</Button>
                  {/* </Link> */}
                </div>
              </div>
              <div className="pre_info_bottom">
                <p>{t('preConditionSubFooter')}</p>
                <p><span>{t('letsTalk')} </span> {t('preConditionFooter')}</p>
                <p>( <span className="_emailtext">software@kopfspringer.com</span>)</p>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <MemberInfo
        heading="Information"
        body="we invite"
        show={info}
        handleClose={() => setInfo(false)}
        type="success"
        buttonMain='Start'
      />
    </>
  );
}
