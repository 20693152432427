import iconLine from "../../assets/images/dashed-img.svg";
import QuestionCard from "./QuestionCard";
import editIcon from "../../assets/images/editdark.svg";
import countIcon from "../../assets/images/countIcon.svg";
import { memo, useEffect, useRef, useState } from "react";
import AddPathDetailModal from "../Modal/AddPathDetailModal";
import plusIcon  from "../../assets/images/plusgrey.svg";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import { t } from "i18next";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { path } from "../../api";
import { useMessageModal } from "../../hooks/useMessage";
import { useAuth } from "../../hooks/useAuth";
import { AxiosError } from "axios";

function PathStepper({
  editForm = false,
  pathId,
  detail,
}: {
  editForm?: boolean;
  pathId?: any;
  detail?: any;
}) {
  const [pathDetail, setPathDetail] = useState({
      name:  (detail && detail?.name) ?  detail.name : t('pathName'),
      description: (detail && detail?.description) ?  detail.description : t("pathDescription")
  });
  const { updateUserData }  = useAuth();
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState(false);
  const { showMessage } = useMessageModal();
  const { droppedQuestion =[] ,questionId = [], addNewStep} = useDragAndDrop();
  const newQuestion = [
      {_id: "", category: {}, time: "", question: ""}, 
      {_id: "", category: {}, time: "", question: ""},
      {_id: "", category: {}, time: "", question: ""}
  ];

  const updateListItem = (updatedData: any | undefined) => {
    setPathDetail(updatedData);
    setShowEdit(false);
  };

  const pushPath = () => {
    if(droppedQuestion.length < 21) {
      const stepsData  = {
        name: "Step " + (droppedQuestion.length + 1),
        sequence: (droppedQuestion.length + 1),
        questions: newQuestion
      }
      addNewStep(stepsData);
    }
  }

  const onSubmit = () => {
      let ids:any = [];
      const savedQuestion = JSON.parse(JSON.stringify(droppedQuestion));
      savedQuestion.forEach((el:any, i:number) => {
        let queId:any = [];
        el.questions.forEach(function(v:any){ 
          delete v.time;
          delete v.categories;
          delete v.question;
          if(v._id) {
            queId.push(v._id)
          }
        });
        el.questions = queId;
        if(savedQuestion[i].questions.length === 0) {
          ids.push(el.sequence);
        }
      })

      if(ids.length === 4) {
        showMessage({
          heading: t("stepRequired"),
          body: <p>{t("stepRequiredError")}</p>,
          type: "error",
        });
        return;
      }

      
      if(ids.length > 0) {
        ids.forEach((el:any) =>{
          const idex = savedQuestion.findIndex((item:any) => item.sequence === el);
          savedQuestion.splice(idex, 1);
        });
      }

      const saveData ={
        name: pathDetail.name,
        description: pathDetail.description,
        steps: savedQuestion
      };

      let url = editForm? path.editPath(saveData, pathId) :  path.createPath(saveData);

      url
      .then((res: { message: string }) => {
        updateUserData({ pathCount: +1 });
        navigate(ROUTE_NAVIGATION_PATH.PATH_LIST);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{error?.response?.data?.errorMessage}</p>,
          type: "error"
        });
      });
  };
  
  return (
    <>
     <div className="stepheading uppercase">
        {!editForm ?
          <div>4. {t("Create Path")}</div>
          :
          <div className="heading-common edittext"><h1>{t("editPath")}</h1></div>
        }
      </div>

      <div className="path-subinfo-content">
        <div className="txt-info">
          <div className="txtbold">
              {pathDetail?.name} 
              <img src={editIcon} alt="" onClick={ () => setShowEdit(true)} />
          </div>
        
          <div className="rightxt">
          <button className="continue-btn" onClick={onSubmit}>{t('save')}</button>
          </div>
        </div>
        <div className="txt-info-detail">
          <div className="lefttxt">
            <p>
              {pathDetail?.description}
            </p>
            <img src={editIcon} alt=""  onClick={ () => setShowEdit(true)}/>
          </div>
          <div className="rightxt">
            <div className="counter">
              <img src={countIcon} alt="" />
              <span className="counter-text">{t('counter')}</span>
              {questionId?.length}
            </div>
          
          </div>
        </div>
      </div>
      <div className="path-mid-stepper">
        <div className="path-grid ">
          <div className="step-count">{droppedQuestion[0].name}</div>
            <div className="w-100">
              <QuestionCard isDroppable step={droppedQuestion[0].sequence} index={0}  que={droppedQuestion[0].questions[0]}/>
              <div className="bottom-info">
                <div className="leftxt">
                  {t('High Priority')} <span></span>
                </div>
                <div className="righttxt">
                  {t('Options')} <img src={iconLine} alt="MagicMoment" />
                </div>
              </div>
            </div>
        </div>
        {droppedQuestion.filter((selected_field:any) => selected_field.sequence !== 1)
          .map((data: any, key: any) => (
            <div className="path-grid" key={data?.sequence}>
              <div className="step-count">{data.name}</div>
              <div className="pathinnertext">
                {
                data.questions.every((element:any) => element._id !== '') ?
                  <div className="beforeline"></div>
                  :
                  null
                }
                {
                  data.questions[1]._id ? 
                  <div className="lineblue"></div>
                  :
                  null
                }
                {data.questions.map((que: any, key: any) => (
                  <div className="w-100" key={key + '_'+que?._id}>
                    <QuestionCard isDroppable step={data.sequence} index={key} que={que}/>
                  </div>
                ))}
                
              </div>
            </div>
        ))}
        { droppedQuestion.length < 21 ? 
          <div className="addmoregrid" onClick={pushPath}>
            <div className="_icon">
              <img src={plusIcon} alt="addQuestion" />
            </div>
          </div>
          :
          null
        }
            </div>


      <AddPathDetailModal
        show={!!showEdit}
        detail={pathDetail}
        handleClose={() => setShowEdit(false)}
        updateListItem={updateListItem}
      />
    </>
  );
}

export default memo(PathStepper);

