import { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "react-bootstrap/Button";
import { question } from "../../api";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import { useCustomMutation } from "../../hooks/useApi";
import { Any } from "../../types/global.type";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import AddEditQuestion from "../FormElements/AddEditQuestion";
import { useCreateEditQuestion } from "../../hooks/useCreateEditQuestion";
import { useAuth } from "../../hooks/useAuth";
import AddQuestionConfimration from "../Modal/AddQuestionConfirmation";
import RejetSuggestionModal from "../Modal/RejectSuggestionModal";
import { useMessageModal } from "../../hooks/useMessage";

export default function AddQuestion({
  createForm = false,
  editForm = false
}: {
  createForm?: boolean,
  editForm?: boolean
}) {
  const auth = useAuth();
  const { showMessage } = useMessageModal();
  const [detail, setDetail] = useState(localStorage.hasOwnProperty("steps") ? JSON.parse(localStorage.getItem("steps") as string)[0] : []);
  const searchParams = new URLSearchParams(document.location.search).get('fromProduct');
  const fromProposal = new URLSearchParams(document.location.search).get('fromProposal');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { updateQuestionData, user } = useCreateEditQuestion();
  const [category, setCategory] = useState<any>(user.category);
  const { updateUserData } = useAuth();
  const [nextStep, setNextStep] = useState(false);
  const [removeProposal, setRemoveProposal ] = useState<any>(null);
  const [categoryError, setCategoryError] = useState<any>('');

  const { mutate } = useCustomMutation({
    mutationFn: (req: Any) => {
      return !editForm ? question.createQuestion(req) : question.editQuestion(req, user.id);
    },

    onSuccess: async (res: Any) => {
      await queryClient.invalidateQueries(["products", null]);
      emptyForm();
      updateUserData({ questionCount: auth.user.questionCount+1 });

      if (localStorage.hasOwnProperty("steps") && JSON.parse(localStorage.getItem("steps") as string).length > 0) {
        let latest = JSON.parse(localStorage.getItem("steps") as string);
        latest.shift();
        updateSteps(latest);
      }
      else if (createForm) {
        if(auth.user.questionCount >= 4) {
            navigate({
              pathname: ROUTE_NAVIGATION_PATH.QUESTION_LIST,
              search: createSearchParams({
                redirect: "1"
              }).toString()
            });
          
         
        } else {
          if(auth.user.questionCount == 0) {
            showMessage({
              heading: t("success"),
              body: <p>{t('atLeast5Questions')}</p>,
              type: "success",
            });
          }
        }
       
      } else if (localStorage.hasOwnProperty("topQuestion")) {
        localStorage.removeItem('topQuestion');
        navigate(ROUTE_NAVIGATION_PATH.TOP_QUESTIONS);
      } else if (fromProposal) {

        navigate({
          pathname: ROUTE_NAVIGATION_PATH.EDIT_SUGGESTION,
          search: createSearchParams({
            id: res.data.pathId,
            questionId: res.data._id,
            stepSequence: res.data.stepSequence
          }).toString()
        });
      }
      else {
        navigate(ROUTE_NAVIGATION_PATH.QUESTION_LIST);
      }
    },
  });

  const updateCategory = (data: any[]) => {
    data ? setCategory(data) : setCategory(category);
  };

  const updateListItem = () => {
    setNextStep(false);
    let latest = JSON.parse(localStorage.getItem("steps") as string);
    latest.shift();
    updateSteps(latest);
  };

  const updateSteps = (latest: any) => {
    if (latest.length > 0) {
      localStorage.setItem('steps', JSON.stringify(latest));
      setDetail(latest[0]);
      setNextStep(true);
    } else {
      showMessage({
        heading: t("thankSuggestion"),
        body: <p>{t('thankSuggestionMessage')}</p>,
        type: "success",
        callback: () => {
          navigate(ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION);
        },
      });
      localStorage.removeItem('pathId');
      localStorage.removeItem('steps');
    }
  }

  function emptyForm() {
    updateQuestionData({
      question: "",
      time: "",
      answer: "",
      category: [],
      productDetails: []
    });
  }

  const onSubmit = (values: Any) => {
    setCategoryError('');
    if(user.category.length == 0) {
      setCategoryError('Required');
      return;
    }
    const products = [];

    if (user.productDetails.length > 0) {
      for (var j = 0; j < user.productDetails.length; j++) {
        products.push(user.productDetails[j]._id);
      }
    }


    var data: any = {
      question: values.question,
      answer: values.answer,
      time: values.time,
      categoryId: user.category ? user.category : category,
      upsell: products
    }

    if (localStorage.hasOwnProperty("steps") && JSON.parse(localStorage.getItem("steps") as string).length > 0) {
      data['stepSequence'] = JSON.parse(localStorage.getItem("steps") as string)[0]
      data['pathId'] = localStorage.getItem('pathId');
      data['status'] = 4
    }

    mutate(data);
  };

  function handleClick() {
    if (localStorage.hasOwnProperty("steps")) {
      navigate(ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION);
    } else {
      navigate(ROUTE_NAVIGATION_PATH.QUESTION_LIST);
    }
  }

  useEffect(() => {
    if (!searchParams && localStorage.hasOwnProperty("steps")) {
      setNextStep(true);
    }
  }, [])

  function deleteSuggestion() {
      setRemoveProposal(null);
      navigate(ROUTE_NAVIGATION_PATH.SUGGESTION_LIST);
  }

  return (
    <>
      <Form
        initialValues={user}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AddEditQuestion editForm={editForm} categoryError={categoryError} fromProposal={fromProposal ? fromProposal : '0'} updateCategory={updateCategory} />
            {
              fromProposal ?
                <div className="btn-wrapper2">
                  <Button variant="primary cancel" onClick={() => setRemoveProposal(user)}>
                    {t('Reject')}
                  </Button>
                  <Button variant="primary" type="submit">
                    {t('Accept')}
                  </Button>
                </div>
                :
                <div className="btn-wrapper2">
                  <Button variant="primary cancel" onClick={handleClick}>
                    {t('Cancel')}
                  </Button>
                  <Button variant="primary" type="submit">
                    {t('save')}
                  </Button>
                </div>
            }

          </form>
        )}
      />
      <AddQuestionConfimration
        detail={detail}
        show={nextStep}
        handleClose={() => setNextStep(false)}
        updateListItem={updateListItem}
      />
      <RejetSuggestionModal
        detail={removeProposal}
        handleClose={() => deleteSuggestion()}
      />
    </>


  );
}
