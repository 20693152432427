import React, { useEffect, useRef, useState } from "react";
import { dashboard } from "../../../api";
import { useTranslation } from "react-i18next";
import IconDown from "../../../assets/images/downarrow.svg";
// import printIcon from "../../../assets/images/icon-print.svg";
import Iconcalendar from "../../../assets/images/calendar.svg";
import downloadIcon from "../../../assets/images/icon-download-fat.svg";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import moment from 'moment';
import DateRangePicker from "rsuite/esm/DateRangePicker";
import { NoData } from "../../../components";
import { Spinner } from "react-bootstrap";
import { api } from "../../../api/api";
import { ENDPOINTS } from "../../../api/dashboard";
import { saveAs } from "file-saver";

export default function CallHistory() {
    const { t } = useTranslation();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [page, setPage] = useState(0);
    const search = "";
    const status = 1;
    const [fromDate, setFromdate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);
    const { loading, items, hasNextPage, error } =
        useLoadItems({
            loadItems: dashboard.getMemberCallHistory,
            page,
            status,
            search,
            fromDate,
            toDate
        });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage(page + 1),
        disabled: !!error,
        rootMargin: "0px 0px 400px 0px",
    });

    const changeDate = (date: any) => {
        if (date) {
            setPage(1);
            setFromdate(date[0]);
            setToDate(date[1]);
        }
    }

    const onClear = () => {
        setShowDatePicker(false)
    }

    function formatDate(value: any) {
        const format1 = "MMM DD"
        const date = new Date(value);
        return moment(date).format(format1);
    }

    const downloadCallHistory = () => {
        api.get(ENDPOINTS.DOWNLOAD_MEMBER_CALL_DETAIL, { responseType: 'arraybuffer' })
        .then((response) => {
          var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'Call_history_report.xlsx');
        });
    }

    function products(value:any) {
        if(value.length > 0) {
            let finalProduct:any = [];
            value.forEach(function(elem:any){
                finalProduct.push(elem.name);
            });        
    
            return finalProduct.join(', ')
        }
    }

    return (
        <>
            <div className="call-history-wrapper" >
                <div className="call-hs-header">
                    <div className="heading">
                        <span className="dropheading">
                            {t('callHistory')}
                            {/* <img src={IconDown} alt="MagicMoment" /> */}
                        </span>
                        <p> {t("historyofCalls")} </p>
                    </div>
                    {/* <div className="_hs-search">
                        <div className="search-wth-content2 w-100">
                            <SearchBar placeHolder={t("Search")} />
                        </div>
                    </div> */}
                    <div className="hs-action">
                        {/* <div className="print">
                            <img src={printIcon} alt="Print" />
                        </div> */}
                        <div className="download" onClick={downloadCallHistory}>
                            <img src={downloadIcon} alt="download" />
                        </div>
                        <div className="_date-select">
                            <div className="calenderinfo">
                                <div className="date" onClick={() => setShowDatePicker(true)}>
                                    <img src={Iconcalendar} alt="" />
                                </div>
                                {
                                    showDatePicker ?
                                        <div className="dropinfo">
                                            <div className="dwon"></div>
                                            <DateRangePicker
                                                value={[fromDate, toDate]}
                                                onChange={(update: any) => {
                                                    changeDate(update);

                                                }}
                                                appearance="default"
                                                placeholder='yyyy-mm-dd - yyyy-mm-dd'
                                                format="yyyy-MM-dd"
                                                onOk={() => setShowDatePicker(false)}
                                                defaultCalendarValue={[new Date(), new Date()]}
                                                onClean={() => onClear()}
                                            />
                                        </div>
                                        :
                                        <>
                                            {
                                                fromDate ?

                                                    <span onClick={() => setShowDatePicker(true)}>
                                                        {formatDate(fromDate) + '  -  ' + (toDate ? formatDate(toDate) : formatDate(fromDate))}
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hs-tableouter" ref={rootRef}>
                    {!items.length && !loading ? <NoData /> : null}

                    <table className="hs-table">
                        {items.length > 0 ? (
                            <thead>
                                <tr>
                                    {/* <th className="tble-status">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck1"
                                            />
                                            <label className="form-check-label"></label>
                                        </div>
                                    </th> */}
                                    <th>{t('callId')}</th>
                                    <th>{t('useCards')}</th>
                                    {/* <th>Category</th> */}
                                    <th>{t('Duration')} (Min)</th>
                                    <th>{t('Upsells')}</th>
                                    <th>{t('Product')}</th>
                                </tr>
                            </thead>
                        )
                            :
                            null
                        }
                        {items.map((data:any, key:any) => (
                            <tbody>
                                <tr>
                                    {/* <td className="tble-status">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="defaultCheck1"
                                            />
                                            <label className="form-check-label"></label>
                                        </div>
                                    </td> */}
                                    <td>{data.callId}</td>
                                    <td>{data.usedCards}</td>
                                    {/* <td><button className="payment-hs">Payment</button></td> */}
                                    <td>{ (data.duration / 60).toFixed(2)}</td>
                                    <td className={ (data.isUpsell === 'No' || data.isUpsell === "Nein") ? "_no": "_yes"}>{t(data.isUpsell)}</td>
                                    <td className="productlastchild">{data.products.length > 0  ? products(data.products) : '-'}</td>
                                </tr>
                            </tbody>
                        ))}
                        {hasNextPage && (
                          <div ref={sentryRef} className="spinner-wrap">
                            <div className="">
                              <Spinner />
                            </div>
                          </div>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
}
