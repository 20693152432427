import Row from "react-bootstrap/Row";
import iconExpend from "../../../assets/images/expend.svg";
import plusIcon from "../../../assets/images/plusgrey.svg";
import UpSellDetailModal from "../../../components/Modal/UpsellDetailModal";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoData, SearchBar } from "../../../components";
import QuestionCategoryList from "../../../components/Question/QuestionCategoryList";
import { useLocation, useNavigate } from "react-router-dom";
import { Any } from "../../../types/global.type";
import { customerInteraction, path } from "../../../api";
import useCustomInteraction from "../../../hooks/useCustomerInteraction";
import { useMessageModal } from "../../../hooks/useMessage";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { Spinner } from "react-bootstrap";
import { AxiosError } from "axios";
import { Carousel, Stack } from "react-bootstrap";
import CustomerInteractionFinishModal from "../../../components/Modal/CustomerInteractionFinishModal";
import moment from "moment";
import ReadQuestions from "./ReadQuestions";
import liveCallIcon from "../../../assets/images/video-camera.png";
import AddSuggestionMessage from "../../../components/Modal/AddSuggestionMessageModal";

export default function CustomerInteractionProcess() {
  const { t } = useTranslation();
  const [upsell, setUpsell] = useState(null);
  const [filterByCategory, setfilterByCategory] = useState("-1");
  const [questionEndTimer, setquestionEndTimer] = useState("");
  const [search, setSearch] = useState("");
  const [questions, setQuestions] = useState<any[]>([]);
  const [callUpdate, setCallUpdate] = useState<any>({});
  const [readQuestions, setReadQuestions] = useState<any[]>([]);
  const [stepCount, setStepCount] = useState(0);
  const location = useLocation();
  const {
    timer,
    interval,
    counter,
    pathId,
    startOnclick,
    stopOnclick,
    setCounterValue,
    setPathvalue,
  } = useCustomInteraction();
  const effectRan = useRef(false);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useMessageModal();
  const navigate = useNavigate();
  const [isFinished, setIsFinished] = useState(false);
  const [totalTime, setTotalTime] = useState(timer);
  const [recordSteps, setRecordSteps] = useState(false);
  const [firstExpand, setFirstExpand] = useState(true);
  const [questiondata, setQuestiondata] = useState<any>({});
  const [showSuggestionPopup, setShowSuggestionPopup] = useState(false);
  const [showUpsell, setShowUpsellPopup] = useState(false);
  const updateFilterCategoryId = (id: string) => {
    id && id !== "-1" ? setfilterByCategory(id) : setfilterByCategory("-1");
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearch((searchParams.get("query") as string) || "");
  }, [location.search]);

  useEffect(() => {
    if (!effectRan.current) {
      localStorage.removeItem("pathId");
      localStorage.removeItem("steps");
      setLoading(true);
      if (!pathId) {
        startOnclick();
        callUpdateApi({
          endTime: timer,
        });
      } else {
        getPathById();
      }
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  useEffect(() => {
    if (!pathId) {
      setLoading(true);
      getAllFirstPathQuestions();
    }
  }, [search, filterByCategory]);

  function getAllFirstPathQuestions() {
    customerInteraction
      .getPathFirstStepQuestion(filterByCategory, search)
      .then((res: { data: Any }) => {
        setLoading(false);
        let response = [...res.data];
        let resPonseLength = Math.ceil(res.data.length / 3);
        let i = 1;
        var first2: Any = [];
        setQuestions([]);
        res.data.forEach((val: any) => {
          if (i <= resPonseLength) {
            const data = {
              question: [],
            };
            first2 = response.splice(0, 3);
            data.question = first2;
            setQuestions((prev: any) => [...prev, data]);
            i++;
          }
        });
      });
  }

  function callUpdateApi(data?: any, finish?: boolean) {
    customerInteraction.callUpdate(data).then((res: any) => {
      setCallUpdate(res.data);
      if (finish) {
        setIsFinished(true);
        setLoading(false);
      }
    });
  }

  function firstContinue(questionDetail: any) {
    setLoading(true);
    setStepCount(questionDetail.stepCount);
    setPathvalue(questionDetail._id);
    const data = {
      callId: callUpdate?._id,
      pathId: questionDetail._id,
      question: {
        questionId: questionDetail.question._id,
        timeSpent: timer,
      },
      endTime: timer,
    };
    questionDetail.question["endTime"] = data.endTime;
    setReadQuestions((prev: any) => [...prev, questionDetail.question]);
    if (questionDetail.stepCount > 1) {
      callUpdateApi(data);
      setquestionEndTimer(data.endTime);
      setTotalTime(data.endTime);
      setQuestions([]);
      getPath(questionDetail._id);
    } else {
      setLoading(false);
      //setQuestions([]);
      endCall(data);
    }
  }

  function nextContinue(questionDetail: any) {
    setLoading(true);
    const data = {
      callId: callUpdate?._id,
      question: {
        questionId: questionDetail._id,
        timeSpent: getTimeDiff(),
      },
      endTime: timer,
    };
    if (stepCount < counter) {
      //setQuestions([]);
      setReadQuestions((prev: any) => [...prev, questionDetail]);
      finishCall(data);
    } else {
      callUpdateApi(data);
      setquestionEndTimer(data.endTime);
      setTotalTime(getTimeSum());
      questionDetail["endTime"] = data.endTime;
      setReadQuestions((prev: any) => [...prev, questionDetail]);
      getPath(pathId);
    }
  }

  function getPathById() {
    startOnclick();
    path
      .getPathById(pathId as string)
      .then((res) => {
        let data = {
          pathId: res.data._id,
          endTime: timer,
          question: {
            questionId: res.data.steps[0].questions[0]._id,
            timeSpent: timer,
          },
        };
        setStepCount(res.data.steps.length);
        setPathvalue(res.data._id);
        setCounterValue(res.data.steps[0].sequence);
        res.data.steps[0].questions[0]["endTime"] = data.endTime;
        setReadQuestions(res.data.steps[0].questions);
        setquestionEndTimer(data.endTime);
        if (res.data.steps.length > 1) {
          callUpdateApi(data);
          setTotalTime(data.endTime);
          getPath(pathId);
        } else {
          if (
            res.data.steps[0].questions[0].upsell &&
            res.data.steps[0].questions[0].upsell.length > 0
          ) {
            callUpdateApi(data);
            expandCard(
              res.data.steps[0].questions[0],
              res.data.steps[0].questions[0],
              false
            );
          } else {
            setLoading(false);
            //setQuestions([]);
            endCall(data);
          }
        }
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
      });
  }

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }

  function addSteps() {
    setRecordSteps(true);
    localStorage.setItem("pathId", pathId as string);
    const arr = localStorage.getItem("steps")
      ? JSON.parse(localStorage.getItem("steps") as string)
      : [];
    // if(!arr.includes(counter -1)) {
    arr.push(counter - 1);
    localStorage.setItem("steps", JSON.stringify(arr));
    if (arr.length > 0) {
      setShowSuggestionPopup(true);
    }
    //}
  }

  function getPath(pathId?: any) {
    const data = {
      pathId: pathId,
      stepSequence: counter,
    };
    customerInteraction
      .getPathByIdAndStep(data)
      .then((res) => {
        console.log(res.data[0].steps[0]);
        setPathvalue(res.data[0]._id);
        setCounterValue(res.data[0].steps[0].sequence);
        setQuestions(res.data[0].steps[0].questions);
        setLoading(false);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
      });
  }

  function finishCall(data?: any) {
    if (readQuestions.length === 0) {
      openDialog();
    } else {
      endCall(data);
    }
  }

  function endCall(data: any) {
    let detail = data
      ? data
      : {
          callId: callUpdate?._id,
          endTime: timer,
        };
    stopOnclick(interval);
    callUpdateApi(detail, true);
  }

  function openDialog() {
    showMessage({
      heading: t("endCall"),
      body: <p>{t("endCallMessage")}</p>,
      type: "success",
      callback: () => {
        stopOnclick(interval);
        let detail = {
          callId: callUpdate?._id,
          endTime: timer,
        };
        callUpdateApi(detail);
        navigate(ROUTE_NAVIGATION_PATH.CUSTOMER_INTERACTION);
      },
    });
  }

  function getTimeDiff() {
    var startTime = moment(questionEndTimer, "mm:ss");
    var endTime = moment(timer, "mm:ss");
    var duration = moment.duration(endTime.diff(startTime));
    var min = Math.floor(duration.minutes());
    return (
      (min < 9 ? "0" + min : min) +
      ":" +
      (duration.seconds() < 9 ? "0" + duration.seconds() : duration.seconds())
    );
  }

  function getTimeSum() {
    let endTime = questionEndTimer;
    let seconds = endTime.split(":")[1];
    let minutes = endTime.split(":")[0];
    let time = moment(totalTime, "mm:ss")
      .add(seconds, "seconds")
      .add(minutes, "minutes")
      .format("mm:ss");
    return time;
  }

  function expandCard(d: any, question: any, firsExpand: boolean) {
    setQuestiondata(d);
    setFirstExpand(firsExpand);
    setUpsell(question);
    setShowUpsellPopup(true);
  }

  function callNextPath() {
    setShowUpsellPopup(false);
    if (firstExpand) {
      firstContinue(questiondata);
      setUpsell(null);
    } else {
      nextContinue(questiondata);
      setUpsell(null);
    }
  }

  function close() {
    setUpsell(null);
    setShowUpsellPopup(false);
  }

  const truncateText = (text: string) => {
    return text && text?.length > 24 ? text.slice(0, 70) + "..." : text;
  };

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content customer-interaction-wrapper">
            <div className="customer-header">
              <div className="callstxt">
                <img src={liveCallIcon} alt="" />
                {t('liveCall')}
              </div>
              <div className="btnstart _timerstart">
                <div className="timer">{timer}</div>
                <button className="finishtbtn" onClick={() => finishCall()}>
                  {t("finish")} <span></span>
                </button>
              </div>
            </div>
            <div className="_search-with-filter">
              {readQuestions.length ? null : (
                <div className="search-wth-content2 w-100">
                  <SearchBar placeHolder={t("Search")} />
                </div>
              )}
            </div>
            <div className="customer-mid-content">
              <div className="category-info-outer category-info-outer-list">
                {readQuestions.length ? null : (
                  <QuestionCategoryList fromCustomerInteraction={true}
                    categoryId={filterByCategory}
                    updateFilterCategoryId={updateFilterCategoryId}
                  />
                )}

                <div className="bottom-pay-wrapper">
                  {readQuestions.length ? (
                    <>
                      {questions.length == 0 ? (
                        <Spinner />
                      ) : (
                        <>
                          {!questions.length ? (
                            <NoData />
                          ) : (
                            <>
                              {questions.map((data: any) => (
                                <div className="bottomgrid" key={data._id}>
                                  <div className="paygrid-common _customer-inter-list">
                                    <div
                                      className="payment-info"
                                      onClick={() =>
                                        expandCard(data, data, false)
                                      }
                                    >
                                      <div className="paytxt">
                                        <div className="category">
                                          {t("Category")}
                                        </div>
                                        <div className="payment">
                                          {data.category
                                            ? questionArray(data.category)
                                            : ""}
                                        </div>
                                      </div>
                                      <div className="timetxt">
                                        <div className="category">
                                          {t("avgTime")}
                                        </div>
                                        <div className="time">{data.time}</div>
                                      </div>
                                    </div>
                                    <div className="heading-info _description">
                                      <h3
                                        onClick={() =>
                                          expandCard(data, data, false)
                                        }
                                      >
                                        {truncateText(data.question)}
                                      </h3>
                                      <p
                                        onClick={() =>
                                          expandCard(data, data, false)
                                        }
                                      >
                                        {truncateText(data.answer)}
                                      </p>
                                      <div className="btn-info">
                                        <button
                                          className="btn-continues"
                                          onClick={() =>
                                            expandCard(data, data, false)
                                          }
                                        >
                                          {t("Continue")}
                                        </button>
                                        <button
                                          className="btn-expend"
                                          onClick={() =>
                                            expandCard(data, data, false)
                                          }
                                        >
                                          <img
                                            src={iconExpend}
                                            alt="MagicMoment"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="addmoregrid" onClick={addSteps}>
                                <div className="_icon">
                                  <img src={plusIcon} alt="MagicMoment" />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      
                      <>
                        <div className="bg-dark bg-opacity-25 container-fluid">
                          
                          {loading ? (
                           <div className="sppiner-carousel"><Spinner /></div> 
                          ) : (
                            <>
                                {!questions.length && !loading ? <NoData /> : null}
                                <Carousel style={{ height: 500 }} interval={null}>
                                  {questions.map((data, index) => (
                                    <Carousel.Item style={{ height: 500 }}>
                                      <Stack
                                        direction="horizontal"
                                        className="h-100 justify-content-center align-items-center"
                                        gap={3}
                                      >
                                        {data.question.map((d: any) => (
                                          <div className="bottomgrid" key={d._id}>
                                            <div className="paygrid-common _customer-inter-list">
                                              <div
                                                className="payment-info"
                                                onClick={() =>
                                                  expandCard(d, d?.question, true)
                                                }
                                              >
                                                <div className="paytxt">
                                                  <div className="category">
                                                    {t("Category")}
                                                  </div>
                                                  <div className="payment">
                                                    {d?.question?.category
                                                      ? questionArray(d?.question?.category)
                                                      : ""}
                                                  </div>
                                                </div>
                                                <div className="timetxt">
                                                  <div className="category">
                                                    {t("avgTime")}
                                                  </div>
                                                  <div className="time">
                                                    {d?.question?.time}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="heading-info _description" title={d?.question?.question}>
                                                <h3
                                                  onClick={() =>
                                                    expandCard(d, d?.question, true)
                                                  }
                                                >
                                                  {truncateText(
                                                    d?.question?.question
                                                  )}
                                                </h3>
                                                <p
                                                  onClick={() =>
                                                    expandCard(d, d?.question, true)
                                                  }
                                                >
                                                  {truncateText(
                                                    d?.question?.answer
                                                  )}
                                                </p>
                                                <div className="btn-info">
                                                  <button
                                                    className="btn-continues"
                                                    onClick={() =>
                                                      expandCard(
                                                        d,
                                                        d?.question,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    {t("Continue")}
                                                  </button>
                                                  <button
                                                    className="btn-expend"
                                                    onClick={() =>
                                                      expandCard(
                                                        d,
                                                        d?.question,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={iconExpend}
                                                      alt="MagicMoment"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </Stack>
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                            </>
                           
                          )}
                        </div>
                      </>
                    </>
                  )}
                </div>
              </div>
              {readQuestions.length ? (
                <ReadQuestions
                  readQuestions={readQuestions}
                  totalTime={totalTime}
                />
              ) : null}
            </div>
          </div>
        {/* </section> */}
      </Row>
      <UpSellDetailModal
        detail={upsell}
        show={showUpsell}
        callUpdate={callUpdate}
        buttonName={
          stepCount !== 0 && stepCount < counter ? "finish" : "ContinuetoNext"
        }
        handleClose={() => callNextPath()}
        close={() => close()}
      />
      <CustomerInteractionFinishModal
        heading={t("niceWork")}
        body={<p>{t("niceWorkDescription")}</p>}
        show={!!isFinished}
        handleClose={() => setIsFinished(false)}
        type="success"
        buttonMain={recordSteps ? "goToSuggestion" : "thanks"}
      />
      <AddSuggestionMessage
        show={showSuggestionPopup}
        handleClose={() => setShowSuggestionPopup(false)}
      />
    </>
  );
}
