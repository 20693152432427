import profileIcon from "../../../assets/images/iconprofile.png";
import { Member } from "../../../types/User";
import { ROLE, USER_STATUS } from "../../../constants";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { team } from "../../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { useAuth } from "../../../hooks/useAuth";

export default function ActiveTeamMemberTile({ userDetails }: { userDetails: Member }) {
  const {t} = useTranslation();
  const { showMessage } = useMessageModal();
  let { user } = useAuth();

  const handleChange = () => {
    let status = USER_STATUS.SUSPENDED;
    team
      .updateUserStatus(userDetails._id, status)
      .then((res) => {
        showMessage({
          heading: t("UserStatusUpdate"),
          body: <p>{res.message}</p>,
          type: "success",
          callback: () => {
            window.location.reload();
          },
        });
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };

  return (
    <div className="active-list-grid">
      <div className="_profile-img">
        {/* <img src={badges} alt="Badges" className="badges" /> */}
        <img
          src={userDetails?.image ? userDetails?.image : profileIcon}
          onError={(e: any) => {
            e.target.src = profileIcon;
          }}
          alt="ProfileImg"
        />
      </div>
      <div className="name" title={userDetails.name + ' ' + userDetails.surname}>
        {userDetails.name} {userDetails.surname}
      </div>
      <div className="designation">{userDetails.position}</div>
      {
        user.role === ROLE.TEAM_LEADER ?
      
        <Button variant="secondary" onClick={handleChange}>
          {t("Deactivate")}
        </Button>
        :
        null
      }
    </div>
  );
}
