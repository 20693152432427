import { AxiosError } from "axios";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { authetication } from "../api/";
import { AuthType, User } from "../types/User";
import { useMessageModal } from "./useMessage";
import i18next, { BE_LANG_CODE, LANG_CODE } from "../i18n/config";

type BE_Type = keyof typeof  BE_LANG_CODE;


interface AuthContextType {
  user: User;
  signin: (user: AuthType, callback?: (response: any) => void) => void;
  signout: (callback?: VoidFunction) => void;
  saveToken: (user: any) => void;
  updateUserData: (data: any) => void;
  deleteToken: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();
  let [user, setUser] = React.useState<any>(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user") as string)
      : null
  );

  const signin = (newUser: AuthType, callback?: (response: any) => void) => {
    return authetication
      .doSignIn(newUser)
      .then((response: { user: any }) => {
        saveToken(response.user);
        setTimeout(() => {
          callback && callback(response);
        }, 0);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{t(error.response?.data?.errorMessage as string)}</p>,
          type: "error",
        });
      });
  };

  const signout = (callback?: VoidFunction) => {
    return authetication.doSignOut().then(() => {
      localStorage.clear();
      setUser(null);
      localStorage.setItem("lang", LANG_CODE.DE);
      i18next.changeLanguage(LANG_CODE.DE);
      callback && callback();
    });
  };

  const saveToken = (user: any) => {
    localStorage.setItem("token", user.activeToken);
    localStorage.setItem("user", JSON.stringify(user));
    const userLang = user.language as BE_Type;
    const lang: string =  BE_LANG_CODE[userLang];
    localStorage.setItem("lang", lang);
    i18next.changeLanguage(lang);
    setUser(user);
  }

  const updateUserData =(data: any) => {
    const updatedUserData = {...user, ...data}
    localStorage.setItem("user", JSON.stringify(updatedUserData));
    setUser(updatedUserData);
  }

  const deleteToken = () => {
    localStorage.clear();
    setUser(null);
    localStorage.setItem("lang", LANG_CODE.US);
    i18next.changeLanguage(LANG_CODE.US);
  };

  const value = useMemo(
    () => ({
      user,
      signin,
      signout,
      saveToken,
      updateUserData,
      deleteToken
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
