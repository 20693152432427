import React, { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import { dashboard } from "../../../api";
import { useTranslation } from "react-i18next";
import MemberDashboardInsights from "../../../components/Dashboard/MemberDashboardInsights";
import Iconcalendar from "../../../assets/images/calendar.svg";
import MultiBarChart from "../../../components/Charts/MultiBarChart";
import DateRangePicker from "rsuite/esm/DateRangePicker";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { NoData } from "../../../components";

export default function MemberInsights() {
  const { t } = useTranslation();
  const effectRan = useRef(false);
  const [totalStats, settotalStats] = useState<any>({});
  const [fromDate, setFromdate] = useState<any>(moment().subtract(14,'d').toDate());
  const [toDate, setToDate] = useState<any>(moment().toDate());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [label, setLabel] = useState<any>([]);
  const [dataset1, setDataSet1] = useState<any>([]);
  const [dataset2, setDataSet2] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!effectRan.current) {
      setLoader(true);
      dashboard.getMembetInsights().then((res: { data: any }) => {
        settotalStats(res.data);
        getChartData(fromDate, toDate);
      });
    }
    return () => {
      effectRan.current = true;
    };
  }, []);

  
  
  function getChartData(fromDate: any, toDate: any) {
    setLabel([]);
    setDataSet1([]);
    setDataSet2([]);
    dashboard.getMembetInsightGraph(fromDate, toDate).then((res: { data: any }) => {
      var stats = res?.data?.totalCalls;
      if (stats) {
        stats.forEach((element: any, index: any) => {
          const date = index == 0 ? moment(element.date).format('DD MMM') : moment(element.date).format("DD");
          setLabel((prev: any) => [...prev, date])
          setDataSet1((prev: any) => [...prev, element.count])
        });
        setLoader(false);

        res.data?.avgTotalCalls.forEach((el: any) => {
          setDataSet2((prev: any) => [...prev, el.count])
        })
        
      }
    });
  }

  function formatDate(value: any) {
    const format1 = "MMM DD"
    const date = new Date(value);
    return moment(date).format(format1);
  }

  const changeDate = (date: any) => {
    if (date) {
      setFromdate(date[0]);
      setToDate(date[1]);
      getChartData(date[0], date[1]);
    }
  }

  const onClear = () => {
    setShowDatePicker(false)
  }

  return (
    <>
      <Row>
        {/* <section className="main-container resize_container shrink-width"> */}
        <div className="mid_outer_content">
          <div className="heading-common">
            <h1>{t('Insights')}</h1>
            <p>{t('placeholderInfo')}</p>
          </div>
          <div className="insight-dashboard-wrapper">
            <MemberDashboardInsights totalStats={totalStats} />
            <div className="insight-graph-data">
              <div className="graph-info-wrapper">
                <div className="top-heading">
                  <h3>{t("callReports")}</h3>
                  <div className="table-calendar">
                    <div className="calenderinfo">

                      <div className="date" onClick={() => setShowDatePicker(true)}>
                        <img src={Iconcalendar} alt="" />

                      </div>
                      {
                        showDatePicker ?
                          <div className="dropinfo">
                            <div className="dwon"></div>
                            <DateRangePicker
                              value={[fromDate, toDate]}
                              onChange={(update: any) => {
                                changeDate(update);
                              }}
                              placeholder='yyyy-mm-dd - yyyy-mm-dd'
                              appearance="default"
                              format="yyyy-MM-dd"
                              onOk={() => setShowDatePicker(false)}
                              defaultCalendarValue={[new Date(), new Date()]}
                              onClean={() => onClear()}
                            />
                          </div>
                          :
                          <span onClick={() => setShowDatePicker(true)}>
                            {formatDate(fromDate) + '  -  ' + (toDate ? formatDate(toDate) : formatDate(fromDate))}
                          </span>
                      }
                    </div>
                  </div>
              
                </div>
                {
                    loader ?
                    <div className="insight-sppiner"><Spinner /></div>  
                      :
                      (
                        label.length > 0 ?
                          <MultiBarChart labels={label} dataset1={dataset1} dataset2={dataset2} toDate={toDate} />
                          :
                          <NoData />
                      )

                  }
              </div>
              <div className="call-tabs-right">
                <ul>
                  <li className="active">Calls</li>
                  <li>Upsells</li>
                  <li>Cards</li>
                </ul>
                <div className="tabs-content-ins active">
                  <div className="_image"></div>
                  <div className="txt-info">
                    <div className="badgename">Badge Name</div>
                    <p>Description for the badge here.</p>
                  </div>
                  <div className="count-with-txt">
                    <div className="count">673</div>
                    <div className="prtxt">30%</div>
                  </div>
                  <div className="progress-content">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={60}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ maxWidth: "60%", background: "#18181b" }}
                      >
                        {/* <span className="title">60%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </section> */}
      </Row>
    </>
  );
}
