import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Spinner from "react-bootstrap/Spinner";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import profileImg from "../../../assets/images/icon-profile.png";
import { INVOICE_STATUS, USER_STATUS } from "../../../constants";
import { admin } from "../../../api";
import {
  SearchBar,
  NoData,
} from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function ManageLeaders() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const navigate  = useNavigate();
  const location = useLocation();
  const { loading, items, hasNextPage, error } =
    useLoadItems({
      loadItems: admin.adminList,
      page,
      search,
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if(searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");
  }, [location.search]);

  function formatDate(value:any) {
    const date = new Date(value);
    // Format the date to DD-MM-YYYY
    return  date.toLocaleDateString('en-GB').split('/').join('-');
  }

  function handleClick(value:any, leaderId:any) {
    const invoiceId = value ? value._id  : 0;
    navigate(ROUTE_NAVIGATION_PATH.MANAGE_INVOICE+'/'+invoiceId+'/'+leaderId);
  }

  const truncateText = (text: string) => {
    return text && text?.length > 10 ? text.slice(0, 15) + "..." : text;
  };

  return (
    <>
      <Row>
        {/* <section className="main-container shrink-width" > */}
          <div className="heading-common" >
            <h1>
              {t("CustomerOverview")}
            </h1>
            {/* <p>{t("mngLeaderHeading")}</p> */}
          </div>

          {/* {items.length && loading ? */}
          <div className="task-manage-team-wrapper" ref={rootRef}>
            <div className="taks-search">
                <SearchBar
                  placeHolder={t("Search")}
                />
            </div>

            <div className="table-scrollbar scrollbar">
              <div className="table-content">
                {!items.length && !loading ? <NoData /> : null}
                <table className="manageteamtable">
                  {items.length ? (
                    <thead>
                      <tr>
                        <th>{t('Name')}</th>
                        <th>{t('Email')}</th>
                        <th>{t("Last Invoice")}</th>
                        <th>{t('Invoice Date')}</th>
                        <th>{t('Permitted Members')}</th>
                        <th>{t('Current Members')}</th>
                        <th>{t("Status")}</th>
                        <th className="createddate">{t("Plan Start Date")}</th>
                        <th className="createddate">{t("Plan End Date")}</th>
                      </tr>
                    </thead>
                  ) : null}

                  {items.map((user) => (
                    <tbody style={{'cursor':'pointer'}}>
                      <tr onClick={()=>handleClick(user.invoiceId, user._id)}>
                        <td>
                          <div className="profile-info">
                            <div className="_profile-img">
                              <img
                                src={user?.image ? user?.image : profileImg}
                                onError={(e: any) => {
                                  e.target.src = profileImg;
                                }}
                                alt="ProfileImg"
                              />
                              {/* <img src={badges} alt="profile" className="badges" /> */}
                            </div>
                            <p>{user.name} {user.surname}</p> 
                          </div>
                        </td>
                        <td><p className="emailuser" title={user.email}>{ truncateText(user.email)}</p></td>
                        <td>{ user.invoiceAmount ? user.invoiceAmount + '€': ''}</td>
                        <td>{user.invoiceDate ? formatDate(user.invoiceDate) : ''}</td>
                        <td>{user.permittedMembers ? user.permittedMembers: 0}</td>
                        <td>{user.addedMembers ? user.addedMembers: 0}</td>
                        <td>
                            {
                              user.planStatus === INVOICE_STATUS.ACTIVE ?
                                <span className="active-s"></span>
                                :
                                (user.planStatus === INVOICE_STATUS.INACTIVE 
                                  ?
                                <span className="nonactive-s"></span>
                                :
                                <span className="expired-s"></span>
                                )
                              }

                            {
                              user.planStatus === INVOICE_STATUS.ACTIVE ? 'Active' : (user.planStatus == INVOICE_STATUS.INACTIVE ? 'Inactive' : 'Expired')
                            }
                        </td>
                        <td>{user.planStartDate ? formatDate(user.planStartDate): ''}</td>
                        <td>{user.planEndDate ? formatDate(user.planEndDate): ''}</td>
                      </tr>
                  </tbody>
                  ))}
                  {/* <div className="bottom-graidient" ></div> */}
                  {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* : <NoData /> } */}
        {/* </section> */}
      </Row>
    </>
  );
}
