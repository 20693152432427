import { api } from "../api";
import { Any } from "../../types/global.type";

export const ENDPOINTS = {
    QUESTION_LIST: "v1/question/list",
    CREATE_QUESTION: "v1/question/add",
    DELETE_QUESTION: "v1/question/delete",
    EDIT_QUESTION: "v1/question/edit",
    GET_QUESTION: "v1/question/getQuestionById",
    GET_PROPOSAL_LIST: "v1/question/proposal-list",
    UPDATE_QUESTION_STATUS: "v1/question/update-status",
    GET_QUESTION_STATS: "v1/question/getQuestionStats"
};

async function createQuestion(data: Any) {
    return api.post(ENDPOINTS.CREATE_QUESTION, data).then((res) => res.data);
}

async function allQuestionsList(data: Record<string, any>) {
    return api.get(ENDPOINTS.QUESTION_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.questions,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function topQuestionList(data: Record<string, any>) {
    data.showTopQuestions = true;
    return api.get(ENDPOINTS.QUESTION_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.questions,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function editQuestion(data:Any, id:Any) {
    delete data.id;
    return api
    .put(ENDPOINTS.EDIT_QUESTION + '/' + id, data)
    .then((res) => res.data);
}

async function deleteQuestion(questionId:string) {
    return api
    .delete(ENDPOINTS.DELETE_QUESTION + '/' + questionId)
    .then((res) => res.data);
}

async function updateQuestionStatus(data:Any) {
    let questionId= data.questionId;
    delete data.questionId;
    return api
    .patch(ENDPOINTS.UPDATE_QUESTION_STATUS + '/' + questionId, data)
    .then((res) => res.data);
}

async function getQuestionById(questionId:string) {
    return api
    .get(ENDPOINTS.GET_QUESTION + '/' + questionId)
    .then((res) => res.data);
}

async function getQuestionProposalList(data: Record<string, any>) {
    return api.get(ENDPOINTS.GET_PROPOSAL_LIST, { params: data }).then((res) => {
        const rData = res.data;
        return {
          data: rData.data.questions,
          hasNextPage: rData.data.count > data.page * data.count,
        };
    });
}

async function getQuestionStats(questionId:string) {
    return api
    .get(ENDPOINTS.GET_QUESTION_STATS + '/' + questionId)
    .then((res) => res.data);
}

export {
    createQuestion,
    allQuestionsList,
    editQuestion,
    deleteQuestion,
    getQuestionById,
    topQuestionList,
    getQuestionProposalList,
    updateQuestionStatus,
    getQuestionStats
};
