import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Iconcategory from "../../assets/images/dummy-img.png";

export default function ProductDetailModal({
  detail,
  handleClose,
}: {
  detail: any;
  handleClose: VoidFunction;
}) {
  const { t } = useTranslation();

  function tags(value: any) {
    if (value.length > 0) {
      value.forEach(function (elem: any, idx: any, array: any) {
        if (array.length > 1 && idx === array.length - 1){
          if (!elem.includes("and")) {
            value[idx] = " and " + elem;
          }
        }
      });
    }
  }

    return (
        <Modal
        show={!!detail}
        onHide={handleClose}
        // backdrop="static"
        dialogClassName={"team-memeber-model upsell-detail-dialog"}
        keyboard={false}
        > 
            <div className="content-info">
                <Modal.Header>
                    <Modal.Title>{t("ProductDetail")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="image-with-content">
                        <div className="_image">
                            <div className="_image">
                                <img
                                    src={detail?.image ? detail?.image : Iconcategory}
                                    onError={(e: any) => {
                                        e.target.src = Iconcategory;
                                    }}
                                    alt="ProfileImg"
                                />
                            </div>
                        </div>
                        <div className="_content-info">
                            <div className="hadingtxt">
                                {t('Product')} : <span> {detail?.name}</span>
                            </div>
                            {
                                detail?.tags ?
                                <div className="subheadingtxt">
                                    {t('Tag')} : <span> {detail?.finaltags  ? detail?.finaltags : tags(detail?.tags)} </span> 
                                </div>
                                :
                                null 
                            }
                            
                            <div className="subheadingtxt">
                                {t('Description')}: <span> {detail?.description} </span>  
                            </div>
                            {
                                detail?.conditions ? 
                                <div className="subheadingtxt">
                                    {t('Conditions')}: <span> {detail?.conditions} </span>
                                </div>
                                :
                                null
                            }
                            
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="cancel" type="button" onClick={handleClose}>
                        {t("Cancel")}
                    </Button> */}
                    <Button variant="primary" type="button" onClick={handleClose}>
                        {t("Ok")}
                    </Button>
                </Modal.Footer>
            </div>  
        </Modal>
    );
}
