import { createBrowserRouter, Outlet } from "react-router-dom";
import { RequireAuth } from "../components";
import {
  SignIn,
  SignUp,
  CreateTeam,
  Main,
  MemberProfile,
  MembersList,
  PreCreationScreen,
  TeamCreationInfo,
  LeaderProfile,
  ForgetPassword,
  ResetPassword,
  VerifyEmail,
  Faq,
  ContactUs,
  ManageTeam,
  MemberDashboard,
  LeaderSetting,
  ManageInvoice,
  ManageLeaders,
  ElementInfoScreeen,
  CustomerInteraction,
  AdminSetting,
  CategoryList,
  CreateProduct,
  ProductList,
  CreateCategory,
  CreateQuestion,
  App,
  CreatePath,
  PathList,
  CustomerInteractionProcess
} from "../pages";
import LeaderDashboard from "../pages/Leader/Dashboard";
import FaqCreationInfo from "../pages/Leader/FAQ/FaqCreationInfo";
import QuestionList from "../pages/Leader/FAQ/Questions/QuestionList";
import MemberSetting from "../pages/Member/Setting";
import UserSubscription from "../pages/Auth/SignIn/Subscription";
import { ROUTE_PATH } from "./routes";
import QuestionAdd from "../pages/Leader/FAQ/Questions/AddQuestion";
import EditQuestion from "../pages/Leader/FAQ/Questions/EditQuestion";
import UploadElementInfo from "../pages/Leader/FAQ/UploadElements";
import UploadElements from "../pages/Leader/FAQ/UploadElements/UploadElements";
import EditPath from "../pages/Leader/FAQ/Path/EditPath";
import AllAgents from "../pages/Leader/Dashboard/AllAgents";
import TalkingDuration from "../pages/Leader/Dashboard/TalkingDuration";
import Calls from "../pages/Leader/Dashboard/Calls";
import TopCategories from "../pages/Leader/Dashboard/TopData/TopCategories";
import TopProducts from "../pages/Leader/Dashboard/TopData/TopProducts";
import TopQuestions from "../pages/Leader/Dashboard/TopData/TopQuestions";
import SuggestionsList from "../pages/Leader/Dashboard/SuggestionsList";
import EditSuggestion from "../pages/Leader/Dashboard/EditSuggestion";
import MemberInsights from "../pages/Member/Dashboard/insight";
import EditProposal from "../pages/Leader/Dashboard/EditProposal";

export const router = createBrowserRouter([
  {
    path: ROUTE_PATH.ROOT,
    element: <Main />,
    children: []
  },
  {
    path: ROUTE_PATH.LEADER,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.SUBSCRIPTION,
        element: <UserSubscription />
      },
      {
        path: ROUTE_PATH.TEAM_CREATION_INFO,
        element: <TeamCreationInfo />,
      },
      {
        path: ROUTE_PATH.CREATE_TEAM,
        element: <CreateTeam />,
      },
      {
        path: ROUTE_PATH.MEMBER_LIST,
        element: <MembersList />,
      },
      {
        path: ROUTE_PATH.MY_PROFILE,
        element: <LeaderProfile />,
      },
      {
        path: ROUTE_PATH.PRE_CREATION,
        element: <PreCreationScreen />,
      },
      {
        path: ROUTE_PATH.TEAM_PRE_CREATION,
        element: <PreCreationScreen />,
      },
      {
        path: ROUTE_PATH.FAQ_CREATION_INFO,
        element: <FaqCreationInfo />,
      },
      {
        path: ROUTE_PATH.ELEMENTS_INFO,
        element: <ElementInfoScreeen />,
      },
      {
        path: ROUTE_PATH.MANAGE_TEAM,
        element: <ManageTeam />,

      },
      {
        path: ROUTE_PATH.ACTIVE_MEMBER_LIST + '/:status',
        element: <MembersList />,
      },
      {
        path: ROUTE_PATH.SETTING,
        element: <LeaderSetting/>
      },
      {
        path: ROUTE_PATH.MEMBER_PROFILE + '/:userId',
        element: <MemberProfile />
      },
      {
        path: ROUTE_PATH.LEADER_DASHBOARD,
        element: <LeaderDashboard />,

      },
      {
        path: ROUTE_PATH.CREATE_CATEGORIES,
        element: <CreateCategory />,
      },
      {
        path: ROUTE_PATH.CATEGORY_LIST,
        element: <CategoryList />,
      },
      {
        path: ROUTE_PATH.CREATE_PRODUCTS,
        element: <CreateProduct />,
      },
      {
        path: ROUTE_PATH.PRODUCT_LIST,
        element: <ProductList />,
      },
      {
        path: ROUTE_PATH.CREATE_QUESTIONS,
        element: <CreateQuestion />,
      },
      {
        path: ROUTE_PATH.ADD_QUESTION,
        element: <QuestionAdd />,
      },
      {
        path: ROUTE_PATH.EDIT_QUESTION,
        element: <EditQuestion />,
      },
      {
        path: ROUTE_PATH.QUESTION_LIST,
        element: <QuestionList />,
      },
      {
        path: ROUTE_PATH.CREATE_PATH,
        element: <CreatePath />,
      },
      {
        path: ROUTE_PATH.EDIT_PATH,
        element: <EditPath />,
      },
      {
        path: ROUTE_PATH.PATH_LIST,
        element: <PathList />,
      },
      {
        path: ROUTE_PATH.UPLOAD_ELEMENT_INFO,
        element: <UploadElementInfo />,
      },
      {
        path: ROUTE_PATH.UPLOAD_ELEMENT,
        element: <UploadElements />,
      },
      {
        path: ROUTE_PATH.AGENT_LIST,
        element: <AllAgents />
      },
      {
        path: ROUTE_PATH.TALKING_DURATION,
        element: <TalkingDuration />
      },
      {
        path: ROUTE_PATH.CALL_LIST,
        element: <Calls />
      },
      {
        path: ROUTE_PATH.SUGGESTION_LIST,
        element: <SuggestionsList />
      },
      {
        path: ROUTE_PATH.TOP_CATEGORIES,
        element: <TopCategories />
      },
      {
        path: ROUTE_PATH.TOP_PRODUCTS,
        element: <TopProducts />
      },
      {
        path: ROUTE_PATH.TOP_QUESTIONS,
        element: <TopQuestions />
      },
      {
        path: ROUTE_PATH.EDIT_SUGGESTION,
        element: <EditSuggestion />
      },
      {
        path: ROUTE_PATH.EDIT_PROPOSAL,
        element: <EditProposal />
      }
    ],
  },
  {
    path: ROUTE_PATH.SUPPORT,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.FAQ,
        element: <Faq />,
      },
      {
        path: ROUTE_PATH.CONTACT_US,
        element: <ContactUs />,
      },
      
    ]
  },
  {
    path: ROUTE_PATH.MEMBER,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.MY_PROFILE,
        element: <MemberProfile />,
      },
      {
        path: ROUTE_PATH.MEMBER_DASHBOARD,
        element: <MemberDashboard/>,
      },
      {
        path: ROUTE_PATH.SETTING,
        element: <MemberSetting/>
      },
      {
        path: ROUTE_PATH.CUSTOMER_INTERACTION,
        element: <CustomerInteraction/>
      },
      {
        path: ROUTE_PATH.CUSTOMER_INTERACTION_PROCESS,
        element: <CustomerInteractionProcess/>
      },
      {
        path: ROUTE_PATH.MEMBER_INSIGHTS,
        element: <MemberInsights/>
      }
    ],
  },{
    path: ROUTE_PATH.ADMIN,
    element: <RequireAuth />,
    children: [
      {
        path: ROUTE_PATH.MANAGE_ADMIN,
        element: <ManageLeaders />,
      },
      {
        path: ROUTE_PATH.MANAGE_INVOICE + '/:invoiceId' + '/:teamLeaderId',
        element: <ManageInvoice />,
      },
      {
        path: ROUTE_PATH.SETTING,
        element: <AdminSetting/>
      },
    ]
  },
  {
    path: ROUTE_PATH.AUTH,
    element: <Outlet />,
    children: [
      {
        element: <SignIn />,
        index: true,
      },
      {
        path: ROUTE_PATH.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: ROUTE_PATH.FORGOT_PASSWORD,
        element: <ForgetPassword />,
      },
      {
        path: ROUTE_PATH.RESET_PASSWORD + "/:token",
        element: <ResetPassword />,
      },
      {
        path: ROUTE_PATH.VERIFY_EMAIL + "/:token",
        element: <VerifyEmail />,
      },
      {
        path: ROUTE_PATH.INVITE + "/:token",
        element: <SignUp />,
      }
    ],
  },
  {
    path: '*',
    element: <App />,
    children: []
  }
]);
