import iconLine from "../../../assets/images/dashed-img.svg";
import countIcon from "../../../assets/images/countIcon.svg";
import { memo, useState } from "react";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { t } from "i18next";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { path, question } from "../../../api";
import { useMessageModal } from "../../../hooks/useMessage";
import { useAuth } from "../../../hooks/useAuth";
import { AxiosError } from "axios";
import SuggestionQuestionCard from "./SuggestionQuestionCard";
import { SUGGESTION_STATUS } from "../../../constants";

function SuggestionPathStepper({
  questnId,
  pathId,
  stepSequence,
  detail,
}: {
  questnId?:any;
  pathId?: any;
  stepSequence?: any;
  detail?: any;
}) {
  const [pathDetail, setPathDetail] = useState({
      name:  (detail && detail?.name) ?  detail.name : t('pathName'),
      description: (detail && detail?.description) ?  detail.description : t("pathDescription")
  });
  const { updateUserData }  = useAuth();
  const navigate = useNavigate();
  const { showMessage } = useMessageModal();
  const { droppedQuestion =[] ,questionId = []} = useDragAndDrop();


  const onSubmit = () => {
      let ids:any = [];
      const savedQuestion = JSON.parse(JSON.stringify(droppedQuestion));
      savedQuestion.forEach((el:any, i:number) => {
        let queId:any = [];
        el.questions.forEach(function(v:any){ 
          delete v.time;
          delete v.categories;
          delete v.question;
          if(v._id) {
            queId.push(v._id)
          }
        });
        el.questions = queId;
        if(savedQuestion[i].questions.length === 0) {
          ids.push(el.sequence);
        }
      })

      if(ids.length === 4) {
        showMessage({
          heading: t("stepRequired"),
          body: <p>{t("stepRequiredError")}</p>,
          type: "error",
        });
        return;
      }

      
      if(ids.length > 0) {
        ids.forEach((el:any) =>{
          const idex = savedQuestion.findIndex((item:any) => item.sequence === el);
          savedQuestion.splice(idex, 1);
        });
      }

      const saveData ={
        name: pathDetail.name,
        description: pathDetail.description,
        steps: savedQuestion
      };

      let url = path.editPath(saveData, pathId);

      url
      .then((res: { message: string }) => {
        updateUserData({ pathCount: +1 });
        updateQuestionStatus();
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{error?.response?.data?.errorMessage}</p>,
          type: "error"
        });
      });
  };

  function updateQuestionStatus() {
    let data = {
      questionId: questnId,
      status: SUGGESTION_STATUS.ACTIVE
    };

    question.updateQuestionStatus(data).then((res: { message: string }) => {
      navigate({
        pathname: ROUTE_NAVIGATION_PATH.SUGGESTION_LIST,
            search: createSearchParams({
                fromEdit: "1"
        }).toString()
      });
      
    })
  }
  
  return (
    <>
     <div className="stepheading uppercase">
        
          <div className="heading-common edittext"><h1>{t("Suggestion")}</h1></div>
        
      </div>

      <div className="path-subinfo-content">
        <div className="txt-info">
          <div className="txtbold">
              {pathDetail?.name}
          </div>
        
          <div className="rightxt">
            <button className="continue-btn" onClick={onSubmit}>{t('save')}</button>
          </div>
        </div>
        <div className="txt-info-detail">
          <div className="lefttxt">
            <p>
              {pathDetail?.description}
            </p>
          </div>
          <div className="rightxt">
            <div className="counter">
              <img src={countIcon} alt="" />
              <span className="counter-text">{t('counter')}</span>
              {questionId?.length}
            </div>
          
          </div>
        </div>
      </div>
      <div className="path-mid-stepper">
        <div className="path-grid ">
          <div className="step-count">{droppedQuestion[0].name}</div>
            <div className="w-100">
              <SuggestionQuestionCard isDroppable step={droppedQuestion[0].sequence} index={0}  que={droppedQuestion[0].questions[0]} stepSequence={stepSequence}/>
              <div className="bottom-info">
                <div className="leftxt">
                  {t('High Priority')} <span></span>
                </div>
                <div className="righttxt">
                  {t('Options')} <img src={iconLine} alt="MagicMoment" />
                </div>
              </div>
            </div>
        </div>
        {droppedQuestion.filter((selected_field:any) => selected_field.sequence !== 1)
          .map((data: any, key: any) => (
            <div className="path-grid" key={data?.sequence}>
              <div className="step-count">{data.name}</div>
              <div className="pathinnertext">
                {
                data.questions.every((element:any) => element._id !== '') ?
                  <div className="beforeline"></div>
                  :
                  null
                }
                {
                  data.questions[1]._id ? 
                  <div className="lineblue"></div>
                  :
                  null
                }
                {data.questions.map((que: any, key: any) => (
                  <div className="w-100" key={key + '_'+que?._id}>
                    <SuggestionQuestionCard isDroppable step={data.sequence} index={key} que={que} stepSequence={stepSequence}/>
                  </div>
                ))}
                
              </div>
            </div>
        ))}
        </div>
    </>
  );
}

export default memo(SuggestionPathStepper);

