import { api } from "../api";

export const ENDPOINTS = {
  GET_MEMBER_PROFILE: "v1/user/team-member/userId/profile",
  LANG_UPDATE: "/v1/user/language",
  PATCH_CHANGE_PASSWORD: "v1/user/change-password",
  PATCH_UPDATE_LOGIN_STATUS: "v1/user/update-first-time-login-status"
};

async function getMemberProfile(id: string) {
  const url = ENDPOINTS.GET_MEMBER_PROFILE.replace("userId", id);
  return api.get(url).then((res) => res.data);
}

async function updateLang(lang: string) {
  return api.post(ENDPOINTS.LANG_UPDATE, {
    "language": lang
  }).then((res) => res.data);
}

async function changePassword(data: any) {
  return api
    .patch(ENDPOINTS.PATCH_CHANGE_PASSWORD, data)
    .then((res) => res.data);
}

async function updateFirstTimeLoginStatus() {
  return api
  .patch(ENDPOINTS.PATCH_UPDATE_LOGIN_STATUS)
  .then((res) => res.data);
}

export {
  getMemberProfile,
  updateLang,
  changePassword,
  updateFirstTimeLoginStatus
};
