import { useState } from "react";
import { Field, FieldProps } from "react-final-form";
import { useTranslation } from "react-i18next";
//import { TagsInput } from "react-tag-input-component";
import TagInput from 'rsuite/TagInput';


interface FormControlProps extends FieldProps<any, any, any, any> {
  label?: string;
//   icon?: string;
}

export function TagsInput({
  label,
  ...rest
}: FormControlProps) {
    const { t } = useTranslation();

    const [ tagSelected, setTagSelected ] = useState<any>(rest?.tags ? rest?.tags: []);
    let [errorMessage, setErrorMessage] = useState('');

    return (
        <Field
        {...rest}
        render={({ input, meta }) =>
            <div className="text-start form-field">
                <label className="form-label">{t(label as string)}</label>
                <div className="input-group2">
                <TagInput
                    value={tagSelected}
                    trigger={['Enter', 'Space', 'Comma']}
                    onChange={(data:any, event) => {
                        console.log(event);
                        console.log("tagSelected", tagSelected)
                        if(tagSelected.length > 9 && event.type != 'click') {
                            console.log("sdfsdf");
                            return;
                        }
                        setTagSelected(data)
                        input.onChange(data)

                    }}
                    onClean={(data:any) => {
                        console.log("dd");
                    }}
                />
                </div>

                {meta.touched && meta.error && (
                    <span className="error">{meta.error}</span>
                )}
                {

                    errorMessage ? <span className="error">{errorMessage}</span> : null
                }
            </div>
        }
        />
  );
}
