import { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLoadItems } from "../../hooks/useLoadItems";
import { category } from "../../api";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { NoData } from "../../components";
import { USER_STATUS } from "../../constants";
import { useTranslation } from "react-i18next";

export default function DefaultCategoryList({
    updateFilterCategoryId,
    selectedData
}:{
    updateFilterCategoryId?: (query: object) => void;
    selectedData?: any;
}) {
    const [page, setPage] = useState(0);
    const location = useLocation();
    const [search, setSearch] = useState("");
    const status = USER_STATUS.ACTIVE;
    const { t } = useTranslation();
    const sort = 'DESC';
    const count = 18;
    const [indexing, setIndexing] = useState(-1);

    const { loading, items, hasNextPage, error } =
        useLoadItems({
        loadItems: category.defaultcategoryList,
        page,
        search,
        status,
        count
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => setPage(page + 1),
        disabled: !!error,
        rootMargin: "0px 0px 50px 0px",
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if(searchParams.get("query")) {
            setPage(1);
          }
        setSearch((searchParams.get("query") as string) || "");
    }, [location.search]);

    useEffect(() => {      
        if(Object.keys(selectedData).length == 0) {
            var indx = items.findIndex((el:any) => el.selected === true);
            setIndexing(indx);
            if(indx != -1) {
                setCategorySelect(indx);
            }
        }
    }, [selectedData])

    function onCategoryChange(index:any) {
        items.forEach(val => {
          val.selected = false;
        });
        setIndexing(index);
        items[index].selected = true;
        if(updateFilterCategoryId) updateFilterCategoryId(items[index]);
    }

    function setCategorySelect(index:any) {
        items.forEach(val => {
            val.selected = false;
        });
    }

    return (
        <> 
            <div className="all-categorycontent" >
                <h2>{t('selectCategories')}</h2>
                <p>
                  {t('createCategorySubHeading')}
                </p>
                <div className="category-listing _dialog-category" ref={rootRef}>
                        <ul >
                            {!items.length && !loading ? <NoData /> : null}
                            {items.map((data, key) => (
                                <li key={data?._id} onClick={() => onCategoryChange(key)} className={Object.keys(selectedData).length !== 0  && key == indexing ? "active" : ""}>
                                    {data.name} 
                                </li>
                            ))}
                            {hasNextPage && (
                                <div ref={sentryRef} className="spinner-wrap">
                                    <div className="">
                                    <Spinner />
                                    </div>
                                </div>
                            )}
                        </ul>
                </div>
            </div>
        </>
    );
}
