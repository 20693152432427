import Button from "react-bootstrap/Button";
import { FormEvent, useState } from "react";
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import { AxiosError } from "axios";

import iconUpload from "../../assets/images/iconUpload.svg";
import iconDownload from "../../assets/images/excel.png";
import iconFile from "../../assets/images/iconFile.svg";
import iconExcel from "../../assets/images/excel.png";
import cross from "../../assets/images/x.svg";

import { api } from "../../api/api";

import { useMessageModal } from "../../hooks/useMessage";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

const ACCEPT_TYPE = [
  ".csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
const ACCEPT_FILE_SIZE = 5000000; // 5 Mb 5;

const DownLoadButtonForError = ({
  sampleFileUrl,
  downloadSample
}: {
  sampleFileUrl: string;
  downloadSample: VoidFunction
}) => (
  <a className="btn btn-download2" onClick={downloadSample} download>
    <div className="icon-download">
      <img src={iconDownload} alt="Download" />
    </div>
    Download
  </a>
);
export default function UploadFile({
  onComplete,
  sampleFileUrl,
  uploadFileApiUrl,
}: {
  onComplete: VoidFunction;
  sampleFileUrl: string;
  uploadFileApiUrl: string;
}) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const { showMessage } = useMessageModal();
  const { t } = useTranslation();

  const onFileUpload = (e: any) => {
    const file = e.target.files[0];
    if(selectedFile) {
     setSelectedFile(undefined);
    }
    if (!ACCEPT_TYPE.includes(file.type)) {
      showMessage({
        heading: t("FileTypeError"),
        body: <p>{t("fileTypeErrorExt")}</p>,
        type: "error",
        footer: <DownLoadButtonForError sampleFileUrl={sampleFileUrl} downloadSample={downloadSample}/>,
      });
      e.target.value = "";
    } else if (file.size > ACCEPT_FILE_SIZE) {
      showMessage({
        heading: t("FileSizeError"),
        body: <p>{t("fileSizeErrorExt")}</p>,
        type: "error",
      });
      e.target.value = "";
    } else {
      setSelectedFile(e.target.files[0]);
      e.target.value = ""
    }
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); //prevent the form from submitting
    let formData = new FormData();
    setProgress(0);
    formData.append("file", selectedFile as Blob);
    api
      .post(uploadFileApiUrl, formData, {
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(
            Math.round((100 * data.loaded) / (data?.total as number))
          );
        },
      })
      .then((res) => {
        onComplete();
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setProgress(0);
        const errorMsg = error.response?.data?.errorMessage;
        showMessage({
          heading: t("fileCannotBeAdded"),
          body: <p>{`${errorMsg ? errorMsg : ""}`}</p>,
          type: "error",
          footer: <DownLoadButtonForError sampleFileUrl={sampleFileUrl} downloadSample={downloadSample}/>,
        });
      });
  };

  function removeFile() {
    setSelectedFile(undefined);
  }

  const downloadSample = () => {
    api.get(sampleFileUrl, { responseType: 'arraybuffer' })
    .then((response) => {
      var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'team_report.xlsx');
    });
  }

  return (
    <>
      <div className="download-files">
        <div className="desc">
          <span>{t("ImportingRequires")}</span>
          <img src={iconExcel} alt="" />
          Microsoft Excel <span>.xlsx format</span>
        </div>

        <a
          type="button"
          className="btn btn-download"
          onClick={downloadSample}
          download
        >
          {t("Download")}
        </a>
      </div>

      <form onSubmit={submitHandler} className="file-input-browse">
        <div className="file-drag">
          <div className="drag-content">
            <div className="_icon-drag">
              <img src={iconUpload} alt="Upload" />
            </div>
            <p>{t("drag&Drophere")} {t("or")} <span>{t("browse")}</span></p>
          </div>
          <Form.Control
            type="file"
            onChange={onFileUpload}
            name="file"
            accept={ACCEPT_TYPE.join()}
            onClick={(e:any) => {
              e.target.value = ""
            }}
          />
        </div>
        <div className="info-size">{t("OnlyxmlWithmaxSizeOf5Mb")}</div>
        <div className="progress-content-bar">
          {selectedFile ? (
            <>
              <div className="_image">
                <img src={iconFile} alt="File" />
              </div>
              <div className="bar-content">
                <p>{selectedFile?.name} <img src={cross} alt="cross" className="xcursor" onClick={removeFile} /></p>
                {progress > 0 ? (
                  <ProgressBar now={progress} label={`${progress}%`} />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <Button variant="primary fixwidth2" type="submit" disabled={!selectedFile}>
          {t("Upload")}
        </Button>
      </form>
    </>
  );
}
