import { api } from "../api";

export const ENDPOINTS = {
  GET_NOTIFICATIONS:  "v1/notifications/list",
  READ_NOTIFICATION: "v1/notifications/",
  BULK_READ_NOTIFICATION: "v1/notifications/bulk-read"
};

async function getNotificationList(data: Record<string, any>) {
  return api.get(ENDPOINTS.GET_NOTIFICATIONS, { params: data }).then((res) => {
      const rData = res.data;
      return {
        data: rData.data.result,
        hasNextPage: rData.data.count > data.page * data.count,
        count: rData.data.count
      };
  });
}

async function readNotification(id:any) {
  return api
    .patch(ENDPOINTS.READ_NOTIFICATION + id + '/read')
    .then((res) => res.data);
}

async function bulkRead() {
  return api
    .patch(ENDPOINTS.BULK_READ_NOTIFICATION)
    .then((res) => res.data);
}

export { getNotificationList, readNotification, bulkRead };
