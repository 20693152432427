import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import AddQuestion from "../../../../components/Question/AddQuestion";
import { useEffect } from "react";
import { useCreateEditQuestion } from "../../../../hooks/useCreateEditQuestion";


export default function QuestionAdd() {
  const { t } = useTranslation();
  const { updateQuestionData } = useCreateEditQuestion();
  const searchParams = new URLSearchParams(document.location.search).get('fromProduct')

  useEffect(() => {
    if(!searchParams) {
      updateQuestionData({
        question: "",
        time: "",
        answer: "",
        category: [],
        productDetails: []
      });
    }
  }, []);

  
  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="stepheading questionwrapper">
                {t("addQuestion")}
            </div>
            <div className="select-category-wrapper questionwrapper">
              <div className="mid-categorycontent _addquestionscrollbar"> 
                <div className="form-category _questioncategory">
                    <AddQuestion/>
                </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
