import { useEffect, useState } from "react";
import iconSearch from "../../assets/images/iconSearch.svg";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Any } from "../../types/global.type";

export default function SearchBar({
  placeHolder,
}: {
  placeHolder: string;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const searchData = (e: Any) => {
    e.preventDefault();
    searchParams.set('query', query);
    setSearchParams(searchParams);
  };
  const setSearchData = (value: string) => {
    if (!value) {
      searchParams.delete('query');
      setSearchParams(searchParams);
    }
    setQuery(value);
  };
  useEffect(() => {
    const searchValue = searchParams.get("query");
    searchValue && setQuery(searchValue);
  }, []);
  
  return (
    <div className="search-bar">
      <form className="">
        <input
          className="form-control"
          type="text"
          placeholder={placeHolder}
          aria-label="Search"
          onChange={(e) => setSearchData(e.target.value)}
        />
        <button className="_search" onClick={searchData}>
          <img src={iconSearch} alt="searchIcon" />
        </button>
        <button className="searchbtn" onClick={searchData}>{t("Search")}</button>
      </form>
    </div>
  );
}
