import moment from 'moment'
import xIconRed from "../../../assets/images/xred.svg";
import xIcon from "../../../assets/images/x.svg";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import 'chart.js/auto';
import { ROLE, SUGGESTION_STATUS } from "../../../constants";
import AcceptIcon from "../../../assets/images/tickx.svg";
import eyesIcon from "../../../assets/images/eye.svg";

import { ROUTE_NAVIGATION_PATH } from '../../../routes/routes';
import { useAuth } from '../../../hooks/useAuth';

export default function SuggestionTile({
    data,
    fromDate,
    toDate,
    deleteSuggestion,
    questionDetail
}: {
    data: any;
    fromDate: any;
    toDate: any;
    deleteSuggestion: VoidFunction;
    questionDetail: VoidFunction;
}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const auth = useAuth();
    function formatDate(value: any) {
        const format1 = "MMM DD, YYYY"
        const date = new Date(value);
        // Format the date to DD-MM-YYYY
        return moment(date).format(format1) + ' at ' + moment(date).format('HH:mm A');
    }

    function handleClick(pathId: string, questionId: string, stepSequence: string) {
        localStorage.setItem('fromDate', fromDate);
        localStorage.setItem('toDate', toDate);
        navigate({
            pathname: ROUTE_NAVIGATION_PATH.EDIT_SUGGESTION,
            search: createSearchParams({
                id: pathId,
                questionId: questionId,
                stepSequence: stepSequence
            }).toString()
        });
    }

    function gotToEditProposal(questionId: string) {
        localStorage.setItem('fromDate', fromDate);
        localStorage.setItem('toDate', toDate);
        navigate({
            pathname: ROUTE_NAVIGATION_PATH.EDIT_PROPOSAL,
            search: createSearchParams({
                id: questionId,
                fromProposal: '1'
            }).toString()
        });
    }

    return (
        <>
            <tr>
                <td style={{ cursor: 'pointer' }} onClick={questionDetail}>
                    {data.path?.name}
                </td>
                <td>
                    {data.question}
                </td>
                <td>
                    <p>{data.answer}</p>
                </td>
                <td className="tble-status">
                    {data.agentName}
                </td>
                <td>
                    {formatDate(data.createdAt)}
                </td>
                <td className='tble-status'>
                    <button className={`btntxt ${data.status === SUGGESTION_STATUS.ACTIVE ? 'accepted'
                            : (data.status === SUGGESTION_STATUS.REJECTED ? 'declined' : '')}
                    `}>
                        {data.status === SUGGESTION_STATUS.PENDING ? t('Pending') : (data.status === SUGGESTION_STATUS.REJECTED ? t('Declined') : t('Accepted'))}
                    </button>
                </td>
                {
                    auth.user.role === ROLE.TEAM_LEADER ?
                        data.status === SUGGESTION_STATUS.PENDING
                            ?
                            <td className='actionth'>
                                <div className="action-btn">
                                    {/* <span className="delete" onClick={deleteSuggestion}>
                                            <img src={xIcon} alt="Magicmonent" className="nonhover" />
                                            <img src={xIconRed} alt="Magicmonent" className="hover" />
                                            {t("Remove")}
                                        </span>
                                        <span className="edit" onClick={() => handleClick(data.path._id, data._id, data.stepSequence)}>
                                            <img src={AcceptIcon} alt="Magicmonent" />
                                            {t("Accept")}
                                        </span> */}
                                    <span className="vieweyes" onClick={() => gotToEditProposal(data._id)}>
                                        <img src={eyesIcon} alt="Magicmonent" />
                                        {/* {t("View")} */}
                                    </span>
                                </div>
                            </td>
                            :
                            <td className='actionth'>
                                -
                            </td>
                        :
                        null
                }

            </tr>
        </>
    );
}
