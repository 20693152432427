import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useCustomMutation } from "../../hooks/useApi";
import { category } from "../../api";
import { Any } from "../../types/global.type";
import { useMessageModal } from "../../hooks/useMessage";
import AddEditCategory from "../FormElements/AddEditCategory";
import { ReactNode } from "react";

export default function EditCategory({
  data,
  successCb,
  children
}: {
  data: Any;
  successCb?: (data: Any) => void;
  children: ReactNode;
}) {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();

  const { mutate } = useCustomMutation({
    mutationFn: (req:Any) => {
      return category.editCategory(req, req.id);
    },
    onSuccess: (res:Any) => {
        showMessage({
          heading: t("success"),
          body: <p>{res.message}</p>,
          type: "success",
        });

        successCb && successCb(res.data);
    },
  });

  function onSubmit(values: Any) {
    const data = {
      name: values.name,
      description: values.description,
      id: values._id
    }
    mutate(data);
  }

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <AddEditCategory
              editForm={true}
            />
            {children}
        </form>
      )}
    />
  );
}
