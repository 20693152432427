import { useState } from "react";
import { Any } from "../types/global.type";

export default function useCustomInteraction() {
    const [timer, setTimer] = useState("00:00");
    var seconds = 0o0;
    var minutes = 0o0;
    const [interval, setInterval1] = useState();
    let intervalId: Any;
    var timeSecond: Any;
    var timerMinutes: Any;
    const [counter, setCounter] = useState(2);
    const [pathId, setPathId] = useState(new URLSearchParams(document.location.search).get('pathId'));

    const startOnclick = () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
        intervalId = setInterval(startTimer, 1000);
        setInterval1(intervalId);
    }

    const stopOnclick = function (id?: Any) {
        if(id) {
            clearInterval(id);
        } else {
            for (var i = 1; i < 999; i++) {
                window.clearInterval(i);
            }   
        }
        setTimer("00:00");
    }

    function startTimer() {
        seconds++;

        if (seconds <= 9) {
            timeSecond = "0" + seconds;
        }

        if (seconds > 9) {
            timeSecond = seconds;
        }

        if (seconds > 59) {
            minutes++;
            timerMinutes = "0" + minutes;
            seconds = 0;
            timeSecond = "0" + 0;
        }
        if (minutes > 9) {
            timerMinutes = minutes;
        }
        setTimer((timerMinutes ? timerMinutes : "00") + ':' + timeSecond);
    }

    const setCounterValue = (value:any) => {
        setCounter(value+1);
    }
    const setPathvalue = (value:string) => {
        setPathId(value);
    }

    return {
        startOnclick,
        stopOnclick,
        setCounterValue,
        setPathvalue,
        timer,
        interval,
        counter,
        pathId
    };
}
