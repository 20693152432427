import Row from "react-bootstrap/Row";
import { NoData, SearchBar } from "../../../components";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Iconcalendar from "../../../assets/images/calendar.svg";
import DateRangePicker from "rsuite/DateRangePicker";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { useLoadItems } from "../../../hooks/useLoadItems";
import { question } from "../../../api";
import { ROLE, SUGGESTION_STATUS } from "../../../constants";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useEditDelete from "../../../hooks/useEditDelete";
import { Spinner } from "react-bootstrap";
import SuggestionTile from "../../../components/Dashboard/Suggestion/SuggestionTitle";
import RejetSuggestionModal from "../../../components/Modal/RejectSuggestionModal";
import QuestionDetailModal from "../../../components/Modal/QuestionDetailModal";
import { useAuth } from "../../../hooks/useAuth";

export default function SuggestionsList() {
  const fromEdit = new URLSearchParams(document.location.search).get('fromEdit')
  const { t } = useTranslation();
  const [questionDetail, setQuestionDetail] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [fromDate, setFromdate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const [status, setStatus] = useState(SUGGESTION_STATUS.ALL);
  const { editData, setEditData } = useEditDelete();
  const auth = useAuth();
  const { loading, items, hasNextPage, error, updateItem } = useLoadItems({
    loadItems: question.getQuestionProposalList,
    page,
    search,
    status,
    fromDate,
    toDate
  });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 50px 0px 0px",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("query")) {
      setPage(1);
    }
    setSearch((searchParams.get("query") as string) || "");

  }, [location.search]);

  const changeDate = (date: any) => {
    if(date) {
      setPage(1);
      setFromdate(date[0]);
      setToDate(date[1]);
    }
  }

  function formatDate(value: any) {
    const format1 = "MMMM DD"
    const date = new Date(value);
    return moment(date).format(format1);
  }

  function filterByStatus(value:number) {
    setPage(1);
    setStatus(value);
  }

  const updateListItem = (updatedData: any) => {
    updatedData._id = editData?._id as string;
    updateItem(updatedData);
    setEditData(null);
  };

  useEffect(() => {
    if(localStorage.hasOwnProperty("fromDate")) {
      localStorage.removeItem("fromDate");
    }
    if(localStorage.hasOwnProperty("toDate")) {
      localStorage.removeItem("toDate");
    }
  }, [])

  const onClear = () => {
    setShowDatePicker(false)
  }

  return (
    <>
      <Row>
        {/* <section className="main-container resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("allSuggestions")}</h1>
              <p>{ auth.user.role == ROLE.TEAM_LEADER ? t("suggestionHeading") : ""}</p>
            </div>

            <div className="suggestion-wrapper">
              <div className="suggestion-search">
                <div className="search-wth-content2 _searchsuggestbar">
                  <SearchBar placeHolder={t('Search...')} />
                  <div className="table-calendar">
                    {/* <div className="selected">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>This Month</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div> */}
                    <div className="calenderinfo">
                      <div
                        className="date"
                        onClick={() => setShowDatePicker(true)}
                      >
                        <img src={Iconcalendar} alt="" />
                      </div>
                      {
                        showDatePicker ?
                          <div className="dropinfo">
                            <div className="dwon"></div>
                            <DateRangePicker
                              value={[fromDate, toDate]}
                              onChange={(update: any) => {
                                changeDate(update);

                              }}
                              appearance="default"
                              placeholder='yyyy-mm-dd - yyyy-mm-dd'
                              format="yyyy-MM-dd"
                              onOk={() => setShowDatePicker(false)}
                              defaultCalendarValue={[new Date(), new Date()]}
                              onClean={() => onClear()}
                            />
                          </div>
                          :
                          <>
                            {
                              fromDate ?
                          
                                <span onClick={() => setShowDatePicker(true)}>
                                  {formatDate(fromDate) + '  -  ' + (toDate ? formatDate(toDate) : formatDate(fromDate))}
                                </span>
                              :
                                null
                            }
                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-suggestion-wrapper" >
                
                  <div className="suggestion-tabs">
                    <ul>
                      <li className={status === SUGGESTION_STATUS.ALL ? 'active' : ''} onClick={() => filterByStatus(SUGGESTION_STATUS.ALL)}>
                        {t("All")}
                      </li>
                      <li className={status === SUGGESTION_STATUS.ACTIVE ? 'active' : ''} onClick={() => filterByStatus(SUGGESTION_STATUS.ACTIVE)}>
                        {t("Accepted")}
                      </li>
                      <li className={status === SUGGESTION_STATUS.REJECTED ? 'active' : ''} onClick={() => filterByStatus(SUGGESTION_STATUS.REJECTED)}>
                        {t("Declined")}
                      </li>
                      <li className={status === SUGGESTION_STATUS.PENDING ? 'active' : ''} onClick={() => filterByStatus(SUGGESTION_STATUS.PENDING)}>
                        {t("Pending")}
                      </li>
                    </ul>
                  </div>
                  
                  <div className="tableouter" ref={rootRef}>
                    <table className="_producttable _suggtable2">
                    {!items.length && !loading ? <NoData /> : null}
                      {
                        items.length > 0 ?
                          <thead>
                            <tr>
                              <th>{t('pathName')}</th>
                              <th>{t("Title")}</th>
                              <th>{t("Description")}</th>
                              <th className="tble-status">{t("Employee")}</th>
                              <th>{t("Date")}</th>
                              <th className="tble-status">{t("Status")}</th>
                              {
                                auth.user.role === ROLE.TEAM_LEADER ?
                                <th className="actionth">{t("Actions")}</th>
                                :
                                null
                              }
                            </tr>
                          </thead>
                        :
                          null
                      }
                      <tbody >
                        {items.map((suggestion:any, key:any) => (
                            <SuggestionTile
                              key={suggestion._id}
                              data={suggestion}
                              fromDate={fromDate}
                              toDate={toDate}
                              deleteSuggestion={() => setEditData(suggestion)}
                              questionDetail={() => setQuestionDetail(suggestion)}
                            />
                        ))}
                       </tbody>
                      {hasNextPage && (
                        <div ref={sentryRef} className="spinner-wrap">
                          <div className="">
                            <Spinner />
                          </div>
                        </div>
                      )}
                    </table>
                  </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
      <RejetSuggestionModal
        detail={editData}
        handleClose={() => setEditData(null)}
        updateListItem={updateListItem}
      />
      <QuestionDetailModal
        show={!!questionDetail}
        data={questionDetail}
        handleClose={() => setQuestionDetail(null)}
      />
    </>
  );
}
