import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  AppSettings,
  PasswordField
} from "../../../components";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { encryptPassword } from "../../../utils";
import { myprofile } from "../../../api";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { composeValidators, confirmPasswordMatchValidation, required, validPassword } from "../../../validations";
import { Form } from "react-final-form";
import { useAuth } from "../../../hooks/useAuth";

export default function AdminSetting() {
  const { showMessage } = useMessageModal();
  const {t} = useTranslation();
  const { signout } = useAuth();

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      oldPassword: encryptPassword(values.oldPassword),
      newPassword: encryptPassword(values.newPassword),
    };
    delete data.confirmPassword;
    myprofile
      .changePassword(data)
      .then((res) => {
          showMessage({
            heading: t("ChangePassword"),
            body: <p>{t('ChangePasswordSuccess')}</p>,
            type: "success",
            callback: () => {
              signout();
            }
          });
        
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: t("error"),
          body: <p>{t(error?.response?.data?.errorMessage as string) }</p>,
          type: "error",
        });
      });
  };

  return (
    <>
      <Row>
        <section className="main-container">
          <div className="heading-common">
            <h1>
              {t("My")}<span> {t("Setting")}</span>
            </h1>
            <p className="_max-width">{t("updateSettingMsg")}</p>
          </div>
            <div className="setting-container">
            <Col md={12} xl={8} className="setting-info-left">
                <div className="setting-info">
                  <div className="heading">{t("ChangePassword")} </div>
                  <div className="form">
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => (
                      <form onSubmit={handleSubmit}>
                        <PasswordField 
                          name="oldPassword" 
                          validations={composeValidators(required, validPassword)} 
                          placeholder={t("Current password") as string}
                        />
                        <PasswordField
                            name="newPassword"
                            validations={composeValidators(required, validPassword)}
                            placeholder={t("New password") as string}
                        />
                        <PasswordField
                          validations={composeValidators(
                            required,
                            confirmPasswordMatchValidation(values.newPassword)
                          )}
                          placeholder={t("Verify Password") as string}
                          name="confirmPassword"
                        />
                        <Button variant="primary" type="submit">
                          {t('saveChanges')}
                        </Button>
                      </form>
                    )}
                  />
                  </div>
                </div>
              </Col>
              <Col md={12} xl={4} className="app-setting-content">
                <AppSettings />
              </Col>
            </div>
        </section>
      </Row>
    </>
  );
}
