import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../routes/routes";
import Accordion from "react-bootstrap/Accordion";
import IconA from "../../assets/images/a-1.png";
import IconB from "../../assets/images/a-2.png";
import IconC from "../../assets/images/a-3.png";

import { useLoadItems } from "../../hooks/useLoadItems";
import { support } from "../../api";
import { NoData } from "../../components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import useCustomInteraction from "../../hooks/useCustomerInteraction";

export default function Faq() {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { stopOnclick } = useCustomInteraction();
  
  const { loading, items, hasNextPage, error } = useLoadItems({
    loadItems: support.getFaq,
    page
  });

  useEffect(() => {
    stopOnclick();
  }, []);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      <Row>
        {/* <section
          className="main-container aligncenter_section resize_container shrink-width"
          
        > */}
          <div className="mid_outer_content">
            <div className="heading-common">
              <h1>{t("FrequentlyAskedQuestions")}</h1>
              <p>
                {t("FrequentAskedQuestionsSub")}
              </p>
            </div>
            <div className="faq-wrapper" ref={rootRef}>
              <div className="faq-content">
                {!items.length && !loading ? <NoData /> : null}
                {items.length ? 
                <Accordion defaultActiveKey={
                  items.slice(0, 1).map((data) => {
                    return data._id;
                  })
                }>
                  {items.map((detail) => (
                    <Accordion.Item eventKey={detail._id}>
                      <Accordion.Header>
                        <div className="heading">{detail.question}</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {detail.answer}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                : null }
                {hasNextPage && (
                <div ref={sentryRef} className="spinner-wrap">
                  <div className="">
                    <Spinner />
                  </div>
                </div>
              )}
                <div className="bottom-profile-with-content">
                  <div className="userprofilesText">
                    <div className="_image">
                      <img src={IconA} alt="MAGICMOMENT" />
                    </div>
                    <div className="_image _mid">
                      <img src={IconB} alt="MAGICMOMENT" />
                    </div>
                    <div className="_image">
                      <img src={IconC} alt="MAGICMOMENT" />
                    </div>
                  </div>
                  <div className="q-heading">{t("StillQuestions")}</div>
                  <p>
                   {t("StllQuestionsSub")}
                  </p>
                  <Link to={ROUTE_NAVIGATION_PATH.CONTACT_US}>
                    <Button variant="primary">{t("Get in touch")}</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
