import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import googleIcon from "../../../assets/images/google.svg";
import { authetication } from "../../../api";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";

export default function GoogleSignin() {
  const navigate = useNavigate();
  const { showMessage } = useMessageModal();
  const { saveToken } = useAuth();
  const { t } = useTranslation();

  const createGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement("div");
    // Or you can simple hide it in CSS rule for custom-google-button
    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");

    // Add the wrapper to body
    document.body.appendChild(googleLoginWrapper);

    // Use GSI javascript api to render the button inside our wrapper
    // You can ignore the properties because this button will not appear
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: "icon",
      width: 200
    });

    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      "div[role=button]"
    ) as HTMLElement;

    return {
      click: () => {
        googleLoginWrapperButton?.click();
      }
    };
  };

  const googleButtonWrapper = createGoogleWrapper();


  const googleLoginCallback = useCallback(
    (response: google.accounts.id.CredentialResponse): void => {
      authetication
        .doGoogleAuth(response.credential)
        .then((res:any) => {
          saveToken(res.user);
          localStorage.setItem("isFirstLogin", "1");
          navigate(ROUTE_NAVIGATION_PATH.LEADER_DASHBOARD, { replace: true });
        })
        .catch(
          (error: AxiosError<{ errorMessage: string; status: number }>) => {
            showMessage({
              heading: t("error"),
              body: <p>{t(error?.response?.data?.errorMessage as string)}</p>,
              type: "error"
            });
          }
        );
    },
    []
  );

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
        ux_mode: "popup",
        callback: googleLoginCallback
      });
    }

    // window.google.accounts.id.prompt()
  }, [googleLoginCallback]);

  return(
    <>
      <div className="otherlogin">
        <button className="login-otherbtn" onClick={() => googleButtonWrapper.click()}>
          <img src={googleIcon} alt="MAGICMOMENT" />
            Weiter mit Google
        </button>
      </div>
    </>
  )
}
