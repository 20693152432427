import { FILE_SIZE, MAX_FILE_SIZE } from "../constants";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Any } from "../types/global.type";

const ACCEPT_TYPE = [
  "image/png",
  "image/jpeg",
  "image/jpg",
];

export default function useUploadImage({ photo }: { photo: string }) {
  const [errorMsg, setErrorMsg] = useState<Any>("");
  const [file, setFile] = useState<File | null | undefined>();
  const [image, setImage] = useState(photo);
  const { t } = useTranslation();

  const updateImage = (file: File | null | undefined) => {
    file ? setFile(file) : setFile(null);
  };

  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage("");
      updateImage(null);
      setErrorMsg("");
      const file = event.target.files[0];
      const selectedFile = file.size;
      const fileSizeKiloBytes = selectedFile / FILE_SIZE;
      if (!ACCEPT_TYPE.includes(file.type)) {
        setErrorMsg(t("FileTypeError"));
        event.target.value = "";
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setErrorMsg(t("FileSizeError"));
        event.target.value = "";
      } else {
        updateImage(file);
        setImage(URL.createObjectURL(file));
      }
    }
  };

  useEffect(() => {
    setImage(photo);
  }, [photo])
  return {
    onImageChange,
    image,
    file,
    errorMsg,
    setImage
  };
}
