import { useTranslation } from "react-i18next";
import useEditDelete from "../../hooks/useEditDelete";
import { useCustomMutation } from "../../hooks/useApi";
import { category } from "../../api";
import { DeleteModal } from "..";
import { Any } from "../../types/global.type";

export default function DeleteCategory({
    show,
    handleClose,
    deleteCb,
    id,
    callback,
  }: {
    show: boolean;
    handleClose: VoidFunction;
    deleteCb?: (data: unknown) => void;
    id: Any;
    callback?: (data?: any) => void;
}) {
    const onOk = (isOk: boolean) => {
      callback && callback(isOk);
      !isOk && handleClose();
    };

    const { t } = useTranslation();
    const { deleteQueryData } = useEditDelete();

    const { mutate } = useCustomMutation({
      mutationFn: category.deleteCategory,
      onSuccess: () => {
        deleteQueryData();
        deleteCb && deleteCb(id);
        onOk(true);
      },
    });
  return (
    <DeleteModal
      heading={t("deleteCategory")}
      body={<p>{t("deleteCategoryInfo")}</p>}
      show={show}
      handleClose={handleClose}
      type="success"
      callback={(isDelete) => isDelete && mutate(id)}
      buttonMain="Remove"
      buttonSecondary="Cancel"
    />
  );
}