import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Iconcategory from "../../assets/images/dummy-img.png";
import iconStar from "../../assets/images/starIcon.svg";
import iconUpArrow from "../../assets/images/arrow-up2.svg";
import { Spinner } from "react-bootstrap";
import iconDown from "../../assets/images/downred.svg";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { question } from "../../api";
import { NoData } from "..";
import { useAuth } from "../../hooks/useAuth";
import { ROLE } from "../../constants";
export default function QuestionDetailModal({
  show,
  data,
  handleClose,
}: {
  show: boolean;
  data: any;
  handleClose: VoidFunction;
}) {
  const { t } = useTranslation();
  const [detail, setDetail] = useState<any>({})
  //const [ques, setQues] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    setLoading(true);
    if (data) {
      //questionDetail();
      question.getQuestionStats(data._id)
        .then((res: any) => {
          setDetail(res.data);
          setLoading(false);
      });
    }
  }, [show])

  function questionDetail() {
    question.getQuestionById(data._id)
      .then((res: any) => {
        //setQues(res.data);
      });
  }

  function tags(value: any) {
    if (value.length > 0) {
      value.forEach(function (elem: any, idx: any, array: any) {
        if (array.length > 1 && idx === array.length - 1) {
          if (!elem.includes("and")) {
            value[idx] = " and " + elem;
          }
        }
      });

      let final = value.join(", ");
      const after_ = final.split("and")[1];
      const lastElement = final.substring(0, final.indexOf("and"));
      if (after_) {
        return lastElement.replace(/,(?=[^,]*$)/, "") + "and " + after_;
      } else {
        return final;
      }
    }
  }

  const questionArray = (value:any) => {
    if (value.length > 0) {
      let categories:any = [];
      value.forEach(function (elem: any) {
        categories.push(elem.name)
        
      });
      let final = categories.join(", ");
      return final;
    }
  }

  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      // backdrop="static"
      dialogClassName={
        "team-memeber-model upsell-detail-dialog _talking-duration"
      }
      keyboard={false}
    >
      <div className="bottom-pay-wrapper">
        <div className="bottomgrid">
          <div className="top-circle">
            <img src={iconStar} alt="" />
          </div>
          <div className="line-with-circle">
            <span></span>
          </div>
          <div className="paygrid-common _customer-inter-list">
            <div className="payment-info">
              <div className="paytxt">
                <div className="category">{t("Category")}</div>
                <div className="payment">{data?.category ? questionArray(data.category) : ''}</div>
              </div>
              <div className="timetxt">
                <div className="category">{t("avgTime")}</div>
                <div className="time">
                  {data?.time}
                </div>
              </div>
            </div>
            <div className="heading-info _description">
              <h3>
                {data?.question}
              </h3>
              <p>
                {data?.answer}
              </p>
              {/* <div className="btn-info">
                <button className="btn-continues disable">
                  {t("Continue")}
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="content-info">
        <div className="talking-content">
          <div className="grid">
            <div className="infotxt">{t('Used')}</div>
            <div className="times-info">
              <div className="timestxt">{detail.questionUsed?.total} {t('Times')}</div>
              <div className={parseInt(detail.questionUsed?.percentage) < 0 ? "downarrow" : "updown"}>
                {detail.questionUsed?.percentage}%
                <img src={parseInt(detail.questionUsed?.percentage) < 0 ? iconDown : iconUpArrow} alt="magicmoment" />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="infotxt">{t('totalTimeSpent')}</div>
            <div className="times-info">
              <div className="timestxt">{detail.totalTimeSpentOn?.totalSpent} {t('Sec')}</div>
              <div className={parseInt(detail.totalTimeSpentOn?.percentage) < 0 ? "downarrow" : "updown"}>
                {detail.totalTimeSpentOn?.percentage}%
                <img src={parseInt(detail.totalTimeSpentOn?.percentage) < 0 ? iconDown : iconUpArrow} alt="magicmoment" />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="infotxt">{t('timePeriod')}</div>
            <div className="times-info">
              <div className="timestxt">{detail.allocatedTime?.totalAllocatedTime} {t('Sec')}</div>
              {/* <div className="updown">
                {detail.allocatedTime?.percentage ? detail.allocatedTime.percentage : 0}% <img src={iconUpArrow} alt="MagicMoment" />
              </div> */}
            </div>
          </div>

        </div>

        {
          loading ? <Spinner /> :
          <>
            {
              auth.user.role === ROLE.TEAM_LEADER ?
                <div className="talking-content-50">
                  <div className="grid w-100">
                    <h3>{t('topUsageOfAgents')}</h3>
                    <div className="progress-txt">
                      {
                        detail?.agentsTimeSpent?.length == 0 ? <NoData /> : null
                      }
                      {

                        detail?.agentsTimeSpent?.map((agent: any) => (
                          <>
                            {
                              agent.agents.map((d: any) => (
                                <>
                                  <div className="name-with-number">
                                    <div className="name">{d?.agentName}</div>
                                    <div className="number">{d?.totalTimeSpent}</div>
                                  </div>
                                  <div className="progress-content">
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={d?.totalTimeSpent}
                                        aria-valuemin={0}
                                        aria-valuemax={agent?.totalTimeSpentCount}
                                        style={{
                                          maxWidth: (d?.totalTimeSpent /
                                            agent?.totalTimeSpentCount) *
                                            100 + "%"
                                        }}
                                      >
                                        <span className="title"></span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))
                            }
                          </>

                        ))
                      }

                    </div>

                  </div>
                  {/* <div className="grid">
                <h3>Insights</h3>
                <div className="insights-content">
                  <ul>
                    <li>
                      <img src={iconcircle} alt="MagicMoment" /> 39% of your
                      visitors are coming from Twitter
                    </li>
                    <li>
                      <img src={iconcircle} alt="MagicMoment" /> 39% of your
                      visitors are coming from Twitter
                    </li>
                    <li>
                      <img src={iconcircle} alt="MagicMoment" /> 39% of your
                      visitors are coming from Twitter
                    </li>
                    <li>
                      <img src={iconcircle} alt="MagicMoment" /> 39% of your
                      visitors are coming from Twitter
                    </li>
                  </ul>
                  <div className="viewmore-insights">
                    <div className="view">
                      View all insights{" "}
                      <img src={nextArrowBlack} alt="MagicMoment" />
                    </div>
                  </div>
                </div>
              </div> */}
                </div>
                :
                null
            }

            <h2 className="sold-p">{t('soldProducts')}</h2>
            <div className="upsells-listing">
              {
                detail?.upsells?.length == 0 ? <NoData /> : null
              }
              { detail?.upsells ?
                  <>
                    {detail?.upsells.map((upsell: any) => (
                      <div className="item-details">
                        <div className="_items active">
                          <h4>{upsell?.name}</h4>
                          <div className="_image">
                            <img
                              src={upsell?.image ? upsell?.image : Iconcategory}
                              onError={(e: any) => {
                                e.target.src = Iconcategory;
                              }}
                              alt="ProfileImg"
                            />
                          </div>
                        </div>
                        <div className="desc-info">
                          <span>{t("Description")}:</span> {upsell?.description}
                        </div>

                        {
                          upsell?.tags && upsell?.tags.length > 0 ?
                            <div className="tagstxt">
                              <span> {t("Tag")} :</span> {tags(upsell?.tags)}
                            </div>
                            :
                            null
                        }

                        {
                          upsell?.conditions ?
                            <div className="tagstxt">
                              <span>{t("Conditions")}:</span> {upsell?.conditions}
                            </div>
                            :
                            null
                        }
                      </div>
                    ))}

                  </>
                :
                null
              }

            </div>
          </>
        }
        <Modal.Footer>
          <Button variant="cancel" type="button" onClick={handleClose}>
            {t("Cancel")}
          </Button>
        </Modal.Footer>
      </div>

    </Modal>
  );
}
