import { Navigate, useLocation, Outlet } from "react-router-dom";
import { ROLE } from "../constants";
import { useAuth } from "../hooks/useAuth";
import useSideBar from "../hooks/useSideBar";
import { ROUTE_NAVIGATION_PATH } from "../routes/routes";
import SideMenu from "./SideMenu";

export default function RequireAuth() {
  let { user } = useAuth();
  let location = useLocation();
  const { expendMenu, toggleMenuExpend } = useSideBar();
  var date = (user && user.invoiceId != null) ? new Date(user.invoiceId.planEndDate) : null;

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={ROUTE_NAVIGATION_PATH.SIGN_IN}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <>
      { user.role === ROLE.TEAM_LEADER ? 
          (user.invoiceId === null || (date && date < new Date()) ?
            null
            :
            <SideMenu 
              toggleMenuExpend={toggleMenuExpend}
              expendMenu={expendMenu}
            />
          )
          :
          <SideMenu
            toggleMenuExpend={toggleMenuExpend}
            expendMenu={expendMenu}
          />
      }
      <section className={`main-container ${expendMenu ? "shrink-width" : ""}`}>
        <Outlet />
      </section>
      
    </>
  );
}
