import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { ELEMENT_LIST } from "../../../../constants";
import { ROUTE_NAVIGATION_PATH } from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import AddQuestion from "../../../../components/Question/AddQuestion";
import { useEffect } from "react";
import { useCreateEditQuestion } from "../../../../hooks/useCreateEditQuestion";
import { useAuth } from "../../../../hooks/useAuth";


export default function CreateQuestion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateQuestionData } = useCreateEditQuestion();
  const searchParams = new URLSearchParams(document.location.search).get('fromProduct')
  const auth = useAuth();

  function backTo(pageNo: number) {
    if(pageNo === ELEMENT_LIST.CATEGORY) {
      navigate(ROUTE_NAVIGATION_PATH.CREATE_CATEGORIES);
    } 
    else if(auth.user.productCount > 0 && pageNo === ELEMENT_LIST.PRODUCT){
      navigate(ROUTE_NAVIGATION_PATH.CREATE_PRODUCTS);
    } 
    else if(auth.user.productCount > 0 && pageNo === ELEMENT_LIST.QUESTION) {
      navigate(ROUTE_NAVIGATION_PATH.CREATE_QUESTIONS)
    } else if(auth.user.questionCount >= 5 && pageNo === ELEMENT_LIST.PATH)  {
      navigate(ROUTE_NAVIGATION_PATH.CREATE_PATH)
    }
    else {
      return;
    }
  }

  useEffect(() => {
    if(!searchParams) {
      updateQuestionData({
        question: "",
        time: "",
        answer: "",
        category: [],
        productDetails: []
      });
    }
    
  }, []);

  return (
    <>
      <Row>
        {/* <section className="main-container aligncenter_section resize_container shrink-width"> */}
          <div className="mid_outer_content">
            <div className="tabs-top">
              <ul>
                <li className="completed" onClick={() => backTo(1)}>1. {t("Create categories")}</li>
                <li className="completed" onClick={() => backTo(2)}>2. {t("Create Product")}</li>
                <li className="active" onClick={() => backTo(3)}>3. {t("Create Questions")}</li>
                <li onClick={() => backTo(4)}>4. {t("Create Path")}</li>
              </ul>
            </div>
            <div className="stepheading questionwrapper">
              3. {t("Create Questions")}
            </div>
            <div className="select-category-wrapper questionwrapper">
              <div className="mid-categorycontent"> 
                <div className="form-category _questioncategory">
                    <AddQuestion createForm={true}/>
                </div>
              </div>
            </div>
          </div>
        {/* </section> */}
      </Row>
    </>
  );
}
