import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { authetication } from "../../../../api";
import React from "react";
import { useParams } from "react-router-dom";
import quotes from "../../../../assets/images/quotes.svg";
import profileimg from "../../../../assets/images/profile-img.png";
import logo from "../../../../assets/images/logo.svg";
import backIcon from "../../../../assets/images/iconBack.svg";
import { AxiosError } from "axios";

function useFetchData() {
  const params = useParams();
  const [data, setMessage] = React.useState<string>();

  React.useEffect(() => {
    const token = params.token;
    const values = {
      token,
    };

    authetication
      .doVerifyEmail(values)
      .then((res: { message: string }) => {
        setMessage(res.message);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setMessage(error.response?.data?.errorMessage);
      });
  }, [params]);
  return { data };
}

function App() {
  const { data } = useFetchData();

  return (
    <Row className="bg-authlayout">
      <Col sm={4} className="p-0 left-column">
        <div className="bg-secondary vh-100">
          <div className="welcome-container">
            <div className="welcome-text">
              <h1>
                Ihr smarter Assistant <br />
                im Kundenservice
              </h1>
              <p>
              Intelligente Unterstützung ihrer Call-Center-Mitarbeiter zur Ermittlung von Upsell-Potentialen beim Kunden
              </p>
            </div>
            <div className="testimonials-login">
              <div className="testimonialcontent-bottom">
                <div className="quotes">
                  <img src={quotes} alt="MAGICMOMENT" />
                </div>
                <div className="testimonialstxt">
                  <p>
                    “Das Tool ermöglicht es mir, Kunden basierend auf ihren individuellen Anfragen und Bedürfnissen zum optimalen Zeitpunkt passende Produktvorschläge zu unterbreiten.”
                  </p>
                </div>
                <div className="testimonialprofile">
                  <div className="profiletestim">
                    <img src={profileimg} alt="MAGICMOMENT" />
                  </div>
                  <div className="profile-info">
                    <div className="name">Leslie Alexander</div>
                    <div className="designation">React Developer</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col
        sm={8}
        className="d-flex justify-content-center align-items-center login-signup-page-card"
      >
        <div className="outer-form">
          <div className="scrollbar100">
            <div className="login-form">
              <div className="w-100">
                <div className="_logo">
                  <img src={logo} alt="MAGICMOMENT" />
                </div>
                <h2 className="mt-5">
                  Verifizierung deiner Em-Mail
                </h2>
                <div className="email-verification-message">Du hast dein Konto erfolgreich verifiziert. Bitte melde dich mit deinen Anmeldedaten an.</div>
                <Button href="/auth" variant="link2">
                <img src={backIcon} alt="" /> Zurück zur Anmeldung
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default function VerifyEmail() {
  return <App />;
}
